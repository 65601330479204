import React from 'react'
import { CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card'
import ShowCardChart from './report_Part_Components/ShowCardChart'
// import Audience from '../../Analytics/Audience';

const StatHistoryData = ({ data }) => {
	return (
		<div className='row px-5 scroll-margin' id='growth'>
			<div className='d-flex justify-content-center'>
				<CardHeader>
					<CardLabel>
						<CardTitle>
							<span>Audience Data By Followers</span>
						</CardTitle>
					</CardLabel>
				</CardHeader>
			</div>
			<hr />
			{/* Followers this month */}
			{data[0]?.followers && (
				<ShowCardChart key='followers' data={data} name='followers' title='Followers' />
			)}
			{/* following */}
			{data[0]?.following && (
				<ShowCardChart key='following' data={data} name='following' title='Following' />
			)}
			{/* Likes this month */}
			{data[0]?.avgLikes && (
				<ShowCardChart key='avgLikes' data={data} name='avgLikes' title='Avg Likes' />
			)}
		</div>
	);
};

export default StatHistoryData;
