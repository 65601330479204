import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './workspace.slice';

const workspaceSlice = (state) => state.workspace || initialState;

export const selectWorkspaces = createSelector([workspaceSlice], (state) => state.workspaces);

export const selectWorkspace = createSelector([workspaceSlice], (state) => state.workspace);

export const selectTeamSync= createSelector([workspaceSlice], (state) => state.teamSync);

export const selectWorkspaceSync = createSelector([workspaceSlice], (state) => state.workspaceSync);