import React from 'react';
import { useGetSavedFilters } from '../../../../framework/basic-rest/discovery/savedHistory/get-saved-search-history';
import { checkArrayData } from '../../../../helpers/helpers';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../bootstrap/OffCanvas';
import SavedFilterCard from './SavedFilterCard';

const SearchHistory = ({ isOpen, setOpen }) => {
	const {
		data: savedSearch,
		isLoading: savedSearchFetching
	} = useGetSavedFilters("d");

	return (
		<OffCanvas
			id='SearchHistory'
			// titleId={String}
			isBackdrop={false}
			// isBodyScroll={Boolean}
			placement='end' // 'start || end || bottom'
			isOpen={isOpen} // Example: state
			setOpen={setOpen}>
			<OffCanvasHeader
				// className={String}
				setOpen={setOpen} // Example: setState
			>
				<OffCanvasTitle id="SearchHistoryTitle"><b>Search</b> History</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				{
					!savedSearchFetching && checkArrayData(savedSearch) && savedSearch.map(filter => {
						return <SavedFilterCard key={filter._id} id={filter._id} title={filter.name} desc={filter.desc} filter={filter.appliedSearchFilters} />
					})
				}
			</OffCanvasBody>
		</OffCanvas>
	);
};

export default SearchHistory;
