import moment from 'moment';
import React, { useState } from 'react';
import { getColorNameWithIndex } from '../../../../common/data/enumColors';
import Alert from '../../../../components/bootstrap/Alert';
import Button from '../../../../components/bootstrap/Button';
import { CardBody } from '../../../../components/bootstrap/Card';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../components/PaginationButtons';
import useTaskRemoveAssociationMutation from '../../../../framework/basic-rest/crm/tasks/remove-task-association';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import { getFirstLetter, nFormatter } from '../../../../helpers/helpers';
import useDarkMode from '../../../../hooks/useDarkMode';

const Projects = ({ associatedProject, emailId, removeTaskAssociation }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['3']);

	return (
		<div>
			{associatedProject && associatedProject.length === 0 ? (
				<div className='row g-4'>
					<div className='col-md-12'>
						<Alert color='warning' isLight icon='Report'>
							No Associated Projects Found.
						</Alert>
					</div>
				</div>
			) : (
				<CardBody className='table-response overflow-auto'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th className='text-center'>PROJECT NAME</th>
								<th className='text-center'>PROJECT OWNER</th>
								<th className='text-center'>PROJECT STAGE</th>
								<th className='text-center'>PROJECT START DATE</th>
								<th className='text-center'>PROJECT END DATE</th>
								<th className='text-center'>BUDGET</th>
								<th className='text-center'>CURRENCY</th>
								<th className='text-center'>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{associatedProject &&
								dataPagination(associatedProject, currentPage, perPage).map(
									(project, i) => (
										<tr key={project._id} className='tableRow'>
											<td>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 48 }}>
															<div
																className={`bg-l${
																	darkModeStatus ? 'o25' : '25'
																}-${getColorNameWithIndex(
																	i,
																)} text-${getColorNameWithIndex(
																	i,
																)} rounded-2 d-flex align-items-center justify-content-center`}>
																<span className='fw-bold'>
																	{getFirstLetter(
																		project?.projectName,
																	)}
																</span>
															</div>
														</div>
													</div>
													<div className='flex-grow-1'>
														<div className='fs-6'>
															<span
																className='text-nowrap'
																aria-hidden='true'
																role='button'>
																{`${project?.projectName}`}
															</span>
														</div>
													</div>
												</div>
											</td>
											<td className='text-center'>
												{`${project?.createdBy?.firstName} ${project?.createdBy?.lastName}`}
											</td>
											<td className='text-center'>
												{project?.project_stage}
											</td>
											<td className='text-center'>
												{moment(project?.startDate).format(
													'MMMM Do YYYY, h:mm:ss a',
												)}
											</td>
											<td className='text-center'>
												{moment(project?.endDate).format(
													'MMMM Do YYYY, h:mm:ss a',
												)}
											</td>

											<td className='text-center'>
												<h6>{nFormatter(project?.budget, 1)}</h6>
											</td>
											<td className='text-center'>{project?.currency}</td>
											<td className='text-center'>
												<Button
													isLink
													color='info'
													onClick={() => {
														ConfirmAlert(
															`Project - ${project?.projectName}`,
															`Do you really want to remove this Project?`,
															() =>
																removeTaskAssociation({
																	toId: project?._id,
																	emailId,
																	type: 'project',
																}),
														);
													}}>
													Remove
												</Button>
											</td>
										</tr>
									),
								)}
						</tbody>
					</table>
					{associatedProject && associatedProject.length > 3 && (
						<PaginationButtons
							data={associatedProject}
							label='projects'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					)}
				</CardBody>
			)}
		</div>
	);
};

export default Projects;
