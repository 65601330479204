import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import { addDays } from 'date-fns';
import { DateRangePicker, Calendar } from 'react-date-range';
import Checks from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import { ModalBody, ModalFooter } from '../../bootstrap/Modal';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import useAnalyticsAddInfluencersMutation from '../../../framework/basic-rest/analytics/add-analytics-project-influencers';
import useAnalyticsUpdateInfluencersMutation from '../../../framework/basic-rest/analytics/update-analytics-project-influencers';
import Popovers from '../../bootstrap/Popovers';
import validateInfluencers from '../../../validations/Analytics/addInfluencers';
import Spinner from '../../bootstrap/Spinner';
import Search from './Search';
import {
	selectCurrentProjectId,
	selectProject,
} from '../../../store/App/SocialAnalytics/Project/project.selector';
import {
	selectCurrentTrackingId,
	selectInfluencers,
	selectTrackingDetails,
} from '../../../store/App/SocialAnalytics/Tracking/tracking.selector';
import { ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import FormText from '../../bootstrap/forms/FormText';
import { selectSocialTrackingPostTypes } from '../../../store/App/SocialAnalytics/analytics.selector';
import useInfluencerSuggestionQuery from '../../../framework/basic-rest/analytics/get-suggestion-inflencer';
import { checkArrayData } from '../../../helpers/helpers';

export const AnalyticsInfluencerModal = () => {
	const projectId = useSelector(selectCurrentProjectId);
	const analyticsPostTypes = useSelector(selectSocialTrackingPostTypes);
	const projectInfluencers = useSelector(selectInfluencers);
	const trackingId = useSelector(selectCurrentTrackingId);
	const project = useSelector(selectProject);
	const currentTrackingData = useSelector(selectTrackingDetails);

	const projectBudget = useRef(0);
	const consumedBudget = useRef(0);
	const projectCurrency = useRef(null);
	const trackingCost = useRef(0.5);
	const dispatch = useDispatch();

	const [addManually, setAddManually] = useState(false);


	useEffect(() => {
		dispatch({
			type: ANALYTICS_STORE_CONSTANTS.SET_POST_TYPES,
			payload: {
				platform: project.platform,
			},
		});
		projectCurrency.current = project.currency;
		projectBudget.current = project.budget;
		consumedBudget.current =
			_.sum(
				_.map(projectInfluencers, (x1) => {
					return x1._id !== trackingId && x1.budget;
				}),
			) || 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);
	// useEffect(()=>{
	// 	if(projectId && currencies?.data.length>0){
	// 		const updateCurrency = currencies?.data.filter((c) => c.cc === formik.values.currency);
	// 		setCurrency(updateCurrency)
	// 	}
	// 	},[currencies,formik.values.currency, projectId])

	const {
		mutate: createAnalyticsProjectInfluencers,
		isLoading: creatingAnalyticsProjectInfluencers,
	} = useAnalyticsAddInfluencersMutation();
	const {
		mutate: updateAnalyticsProjectInfluencers,
		isLoading: updatingAnalyticsProjectInfluencers,
	} = useAnalyticsUpdateInfluencersMutation();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			trackingId: '',
			influencer: {
				globalInfluencerIndex: '',
				influencerProfile: '',
				name: '',
				isCustom: false,
			},
			contentType: '',
			platform: '',
			linkToTrack: '',
			targetUrl: '',
			utmLink: '',
			shortLink: '',
			startDate: '',
			endDate: '',
			budget: 0,
			totalBudget: 0,
			socialProjectId: projectId,
			trackingCost: 0,
			updatedTrackingCost: 0,
		},
		validate: validateInfluencers,
	});

	useEffect(() => {
		formik.setFieldValue('totalBudget', projectBudget.current - consumedBudget.current);
		// eslint-disable-next-line no-unused-expressions
		!formik.values.trackingCost && formik.setFieldValue('trackingCost', trackingCost.current);
		formik.setFieldValue('updatedTrackingCost', trackingCost.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectBudget.current, consumedBudget.current, trackingCost.current]);

	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: addDays(new Date(), 7),
		key: 'selection',
	});
	const [currency, setCurrency] = useState([]);

	const datePicker = currentTrackingData?.isStarted ? (
		<Calendar
			onChange={(item) =>
				onDatePickerChange({
					startDate: dateRange.startDate,
					endDate: item,
					key: 'selection',
				})
			}
			date={dateRange.endDate}
			minDate={new Date()}
			{...(trackingId && { maxDate: new Date(project?.endDate || new Date()) })}
		/>
	) : (
		<DateRangePicker
			minDate={new Date()}
			maxDate={new Date(project?.endDate || new Date())}
			onChange={(item) => onDatePickerChange(item?.range1 || item?.selection)}
			showSelectionPreview
			moveRangeOnFirstSelection={false}
			ranges={[dateRange]}
			direction='horizontal'
			preventSnapRefocus
			calendarFocus='backwards'
		/>
	);

	const onDatePickerChange = (range) => {
		setDateRange(range);
		trackingCost.current =
			Math.ceil(moment(range.endDate).diff(moment(range.startDate), 'days') + 1) * 0.5;
	};
	useEffect(() => {
		formik.setFieldValue('startDate', new Date(dateRange?.startDate).toISOString());
		formik.setFieldValue('endDate', new Date(dateRange?.endDate).toISOString());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange]);

	const onInfluencerSelect = (e) => {
		formik.setFieldValue(
			'influencer.globalInfluencerIndex',
			checkArrayData(e) ? e.map((x) => x.influencer_index_id)[0] : '',
		);
		formik.setFieldValue(
			'influencer.influencerProfile',
			checkArrayData(e) ? e.map((x) => x._id)[0] : '',
		);
	};

	const onContentTypeChange = (e) => {
		formik.setFieldValue('contentType', checkArrayData(e) ? e[0].value : '');
	};

	const onCheckChange = (e) => {

		// console.log("manual event", !formik.values.influencer.isCustom);
		if(!formik.values.influencer.isCustom) {
			setAddManually(true);
		}
		formik.setFieldValue('influencer.isCustom', !formik.values.influencer.isCustom);
		if (!formik.values.influencer.isCustom) {
			formik.setFieldValue('influencer.globalInfluencerIndex', '');
			formik.setFieldValue('influencer.influencerProfile', '');
		} else {
			formik.setFieldValue('influencer.name', '');
		}
	};
	const [defaultInfluencer, setDefaultInfluencer] = useState(null);
	const [contentType, setContentType] = useState([]);
	useEffect(() => {
		if (trackingId && analyticsPostTypes.length > 0) {
			const updateContentType = analyticsPostTypes.filter(
				(x) => x.label === formik.values?.contentType,
			);
			setContentType(updateContentType);
		}
	}, [formik.values?.contentType, trackingId, analyticsPostTypes]);

	useEffect(() => {
		if (trackingId) {
			const projectInfluencerObject = projectInfluencers.find(
				(projectInfluencer) => projectInfluencer._id === trackingId,
			);

			dispatch({
				type: ANALYTICS_STORE_CONSTANTS.SET_CURRENT_TRACKING_DATA,
				payload: projectInfluencerObject,
			});

			const defaultData = {
				isCustom: projectInfluencerObject?.influencer.isCustom,
				name: projectInfluencerObject?.influencer.influencerProfile.influencerData.profile
					.username,
			};

			setDefaultInfluencer(defaultData);
			formik.setValues({ ...projectInfluencerObject, platform: project?.platform });
			trackingCost.current = projectInfluencerObject.trackingCost;

			setDateRange({
				startDate: new Date(projectInfluencerObject?.startDate || new Date()),
				endDate: new Date(projectInfluencerObject?.endDate || new Date()),
				key: 'selection',
			});
			formik.setFieldValue('trackingId', trackingId);
			trackingCost.current =
				Math.ceil(
					moment(projectInfluencerObject?.endDate).diff(
						moment(projectInfluencerObject?.startDate),
						'days',
					) + 1,
				) * 0.5;
		} else {
			dispatch({
				type: ANALYTICS_STORE_CONSTANTS.SET_CURRENT_TRACKING_DATA,
				payload: null,
			});
			formik.setFieldValue('platform', project.platform);
			onDatePickerChange({
				startDate: new Date(project?.startDate),
				endDate: new Date(project?.endDate),
				key: 'selection',
			});
			// formik.setFieldValue('startDate', new Date(project?.startDate).toISOString());
			// formik.setFieldValue('endDate', new Date(project?.endDate).toISOString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingId]);

	const handlehandleSubmit = (callback) => {
		const form = {
			...formik.values,
			linkToTrack: formik.values.linkToTrack.replace(/reel/g, 'p'),
		};
		callback(form);
	};

	return (
		<>
			<ModalBody>
				<div className='row g-4'>
					<div className='col-12'>
						<FormGroup label='Enter influencer name'>
							{formik.values.influencer.isCustom ? (
								<Input
									id='name'
									name='name'
									placeholder='Carry minati'
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched?.name}
									invalidFeedback={formik.errors?.name}
									validFeedback='Looks good!'
									onChange={(e) => {
										formik.setFieldValue('influencer.name', e.target.value);
									}}
									value={formik.values.influencer?.name}
								/>
							) : (
								<Search
									onSelect={onInfluencerSelect}
									id='globalInfluencerIndex'
									name='globalInfluencerIndex'
									isReadOnly={!!trackingId}
									readValue={defaultInfluencer?.name}
									labelKey='username'
									optionKey='username'
									placeHolder='Search by username'
									searchQuery={useInfluencerSuggestionQuery}
								/>
							)}
							{/* <Search
								onSelect={onInfluencerSelect}
								id='globalInfluencerIndex'
								name='globalInfluencerIndex'
								isReadOnly={!!trackingId}
								readValue={defaultInfluencer?.name}
								labelKey='username'
								optionKey='username'
								placeHolder='Search by username'
								searchQuery={useInfluencerSuggestionQuery}
							/> */}
						</FormGroup>
						<div className='d-flex flex-row-reverse'>
							<Checks
								className='text-end'
								type='switch'
								id='inlineCheckOne'
								label='Add manually'
								name='influencer.isCustom'
								onChange={(e) => {
									onCheckChange(e);
								}}
								{...(trackingId && { readOnly: 'plaintext', disabled: true })}
								checked={formik.values.influencer?.isCustom}
								validFeedback='Looks good!'
							/>
						</div>
					</div>
					<div className='col-12'>
						<FormGroup id='contentType' label='Select Content Type'>
							{/* <Select
								id='contentType'
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched?.contentType}
								invalidFeedback={formik.errors?.contentType}
								validFeedback='Looks good!'
								onChange={formik.handleChange}
								value={formik.values?.contentType}
								ariaLabel='Select ContentType Type'
								placeholder='Select ContentType type'>
								{analyticsPostTypes &&
									analyticsPostTypes.map((x) => (
										<Option value={x.value}>{x.label}</Option>
									))}
							</Select> */}
							{/* <Search
								onSelect={onContentTypeChange}
								id='contentType'
								name='contentType'
								isReadOnly={currentTrackingData?.isStarted}
								readValue={formik.values?.contentType}
								labelKey='label'
								optionKey='label'
								placeHolder='Select ContentType type'
								isStatic
								staticOptions={analyticsPostTypes}
							/> */}
							{trackingId && analyticsPostTypes.length > 0 ? (
								<AsyncTypeahead
									id='contentType'
									name='contentType'
									labelKey='label'
									minLength={0}
									onChange={(e) => onContentTypeChange(e)}
									selected={contentType}
									disabled
									options={analyticsPostTypes}
									placeholder='Select ContentType type'
									useCache={false}
									renderMenuItemChildren={(option, props) => (
										<div>
											<span>{option.label}</span>
										</div>
									)}
								/>
							) : (
								<AsyncTypeahead
									id='contentType'
									name='contentType'
									labelKey='label'
									minLength={0}
									onChange={(e) => onContentTypeChange(e)}
									options={analyticsPostTypes}
									placeholder='Select ContentType type'
									useCache={false}
									renderMenuItemChildren={(option, props) => (
										<div>
											<span>{option.label}</span>
										</div>
									)}
								/>
							)}
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='platform' label='Project platform'>
							<Input
								className='fw-bold'
								id='platform'
								name='platform'
								placeholder='Platform'
								readOnly='plaintext'
								disabled
								value={formik.values?.platform}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup
							id='linkToTrack'
							label='Content URL'
							{...(formik.errors.linkToTrack &&
								formik.values?.platform === 'INSTAGRAM' && {
									formText: (
										<b className='text-danger'>
											Eg. https://www.instagram.com/p/abcXyZZ1/
										</b>
									),
								})}>
							<Input
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.linkToTrack}
								invalidFeedback={formik.errors.linkToTrack}
								validFeedback='Looks good!'
								placeholder='Enter url to track'
								onChange={formik.handleChange}
								{...(currentTrackingData?.isStarted && {
									readOnly: 'plaintext',
									disabled: true,
								})}
								value={formik.values.linkToTrack}
							/>
						</FormGroup>
					</div>
					{project.projectType !== 'SOCIAL' && (
						<div className='col-12'>
							<FormGroup id='targetUrl' label='Webpage to Target'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.targetUrl}
									placeHolder='Enter the url of website you want to target'
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.targetUrl}
									invalidFeedback={formik.errors.targetUrl}
									validFeedback='Looks good!'
								/>
							</FormGroup>
						</div>
					)}

					{/* <div className='col-12'>
						<FormGroup id='utmLink' label='UTM Link'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.utmLink}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.projectType}
								invalidFeedback={formik.errors.projectType}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div> */}
					<div className='col-12'>
						<FormGroup
							label='Start date - End date'
							{...(currentTrackingData?.isStarted && {
								formText: (
									<b>
										You can only edit the end date because tracking has started.
									</b>
								),
							})}>
							<FormText className='text-danger float-end' id='exampleLabel2-text'>
								<b>
									Cost for tracking&nbsp;
									{trackingCost.current} credits.
								</b>
							</FormText>
							<Popovers
								placement='bottom-end'
								className='mw-100 overflow-hidden'
								data-tour='date-range-menu'
								bodyClassName='p-0'
								trigger='click'
								desc={datePicker}>
								<Button
									color='dark'
									isLight
									data-tour='date-range'
									className='w-100'>
									{`${moment(dateRange.startDate).format('MMM Do YY')} - ${moment(
										dateRange.endDate,
									).format('MMM Do YY')}`}
								</Button>
							</Popovers>
						</FormGroup>
					</div>
					<div className='col-6'>
						<FormGroup id='currency' label='Your currency'>
							<Input
								placeholder='Currency'
								readOnly
								disabled
								value={projectCurrency.current}
							/>
						</FormGroup>
					</div>
					<div className='col-6'>
						<FormGroup
							id='budget'
							label='Enter Budget'
							formText={
								<>
									Your available project budget&nbsp;
									<b>{formik.values.totalBudget - formik.values.budget}</b>
								</>
							}>
							<Input
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.budget}
								invalidFeedback={formik.errors.budget}
								validFeedback='Looks good!'
								onChange={formik.handleChange}
								value={formik.values.budget}
							/>
						</FormGroup>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				{!trackingId ? (
					<Button
						isDisable={
							creatingAnalyticsProjectInfluencers || !(formik.isValid && formik.dirty) || !addManually
						}
						className='w-100'
						color='info'
						icon='Save'
						onClick={() => handlehandleSubmit(createAnalyticsProjectInfluencers)}>
						{creatingAnalyticsProjectInfluencers ? 'Please wait...' : 'Create'}
						{creatingAnalyticsProjectInfluencers && <Spinner isSmall inButton />}
					</Button>
				) : (
					<Button
						isDisable={
							updatingAnalyticsProjectInfluencers ||
							!(formik.isValid && formik.dirty && formik.touched) || !addManually
						}
						className='w-100'
						color='info'
						onClick={() => handlehandleSubmit(updateAnalyticsProjectInfluencers)}>
						{updatingAnalyticsProjectInfluencers ? 'Please wait...' : 'Update'}
						{updatingAnalyticsProjectInfluencers && <Spinner isSmall inButton />}
					</Button>
				)}
			</ModalFooter>
		</>
	);
};
