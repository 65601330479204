import Toasts from '../components/bootstrap/Toasts';

export const successToast = ({ title, message, icon }) => {
	return (
		// eslint-disable-next-line react/react-in-jsx-scope
		<Toasts
			title={title || 'Success'}
			icon={icon || 'VerifiedUser'}
			iconColor='success'
			time='Now'
			isDismiss>
			{message}
		</Toasts>
	);
};

export const infoToast = ({ title, message, icon }) => {
	return (
		// eslint-disable-next-line react/react-in-jsx-scope
		<Toasts
			title={title || 'Information'}
			icon={icon || 'Info'}
			iconColor='info'
			time='Now'
			isDismiss>
			{message}
		</Toasts>
	);
};

export const warningToast = ({ title, message, icon }) => {
	return (
		// eslint-disable-next-line react/react-in-jsx-scope
		<Toasts
			title={title || 'Warning'}
			icon={icon || 'Warning'}
			iconColor='warning'
			time='Now'
			isDismiss>
			{message}
		</Toasts>
	);
};

export const dangerToast = ({ title, message, icon }) => {
	return (
		// eslint-disable-next-line react/react-in-jsx-scope
		<Toasts
			title={title || 'Danger'}
			icon={icon || 'Bolt'}
			iconColor='danger'
			time='Now'
			isDismiss>
			{message || 'Something went wrong'}
		</Toasts>
	);
};
