import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import showNotification from '../../../components/extras/showNotification';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function removeInfluencer(input) {
	return http.delete(
		`${API_ENDPOINTS.REMOVE_INFLUENCERS_FROM_LIST}?influencerId=${input.influencer_id}&listId=${input.list_id}`,
	);
}

const useRemoveInfluencerFromListMutation = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	return useMutation((input) => removeInfluencer(input), {
		onSuccess: (data, input) => {
			const resp = data.data;
			addToast(
				successToast({
					message: 'Influencer Removed from list',
				}),
				{
					autoDismiss: true,
				},
			);

			dispatch({
				type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
				payload: `LIST/Crate-list/${new Date()}`
			});
			recordEvent(`Influencer Removed From List(Search)`, {
				input,
				output:data
			});
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err.response.data.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to remove influencer from list(Search)`, {
				input,
				output:err.response.data
			});
			// showNotification('Remove Influencer', 'Some Error', 'error');
			// console.log(err);
		},
	});
};
export default useRemoveInfluencerFromListMutation;
