import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import API_ENDPOINTS from '../../utils/api-endpoints';
import http from '../../utils/http';
import recordEvent from '../../mixpanel/mixpanel';

export const deleteSearchFilter = async ({ id }) => {
	const { data } = await http.delete(`${API_ENDPOINTS.DISCOVERY.DELETE_SEARCH_FILTERS}?id=${id}`);

	return data.data || [];
};

export const useDeleteSearchFilter = () => {
    const { addToast } = useToasts();
    const queryClient = useQueryClient();
    const onSuccess = (data, input) => {
		addToast(
			successToast({
				message: 'Filter is deleted successfully',
			}),
			{
				autoDismiss: true,
			},
		);
        queryClient.invalidateQueries([API_ENDPOINTS.GET_SAVE_SEARCH_HISTORY]);
		recordEvent('Delete Filter(Search)', {
			input,
			output:data
		});
	};
	const onError = (err, input) => {
		addToast(
			dangerToast({
				message: err?.data?.errorMessage,
			}),
			{
				autoDismiss: true,
			},
		);
		recordEvent('Failed to delete filter(Search)', {
			input,
			output:err?.data
		});
	};

    return useMutation((input) => deleteSearchFilter(input), {
		onSuccess,
		onError,
	});
};
