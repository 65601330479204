import { takeLatest, put, call } from 'redux-saga/effects';
import {
	setCrmTaskById,
	setCrmTasks,
	setIsUpdateCrmTask,
	setShowCRMtaskData,
} from './crm-tasks-slice';
import { CRM_TASKS_STORE_CONSTANTS } from '../../../constants.store';
import { getAllCrmTasks } from '../../../../framework/basic-rest/crm/tasks/get-crm-tasks';

function* getCRMtasks(actions) {
	try {
		const tasks = actions.payload;
		yield put(setCrmTasks(tasks));
		return true;
	} catch (error) {
		return false;
	}
}

function* updateCRMtasks(actions) {
	try {
		const id = actions.payload;
		yield put(setIsUpdateCrmTask(id));
		return true;
	} catch (error) {
		return false;
	}
}

function* getCRMtaskById(actions) {
	try {
		const { taskId } = actions.payload;
		yield put(setCrmTaskById(taskId));
		return true;
	} catch (error) {
		return false;
	}
}

function* SetShowCRMtaskData(actions) {
	try {
		const { taskData } = actions.payload;
		yield put(setShowCRMtaskData(taskData));
		return true;
	} catch (error) {
		return false;
	}
}
function* setCRMtaskData(actions) {
	try {
		const tasks = yield call(getAllCrmTasks);
		yield put(setCrmTasks(tasks?.data));
		return true;
	} catch (error) {
		return false;
	}
}
export default function* crmTaskSaga() {
	yield takeLatest(CRM_TASKS_STORE_CONSTANTS.UPDATE_CRM_TASKS, updateCRMtasks);
	yield takeLatest(CRM_TASKS_STORE_CONSTANTS.GET_CRM_TASKS, getCRMtasks);
	yield takeLatest(CRM_TASKS_STORE_CONSTANTS.GET_CRM_TASK_BY_ID, getCRMtaskById);
	yield takeLatest(CRM_TASKS_STORE_CONSTANTS.SET_CRM_TASK_DATA, SetShowCRMtaskData);
	yield takeLatest(CRM_TASKS_STORE_CONSTANTS.FETCH_CRM_TASK_AGAIN, setCRMtaskData);
}
