import { useInfiniteQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import recordEvent from '../mixpanel/mixpanel';

async function getFilteredInfluencers({ queryKey, pageParam = 1 }) {
	const [_key, params] = queryKey;
	console.log("all",[_key, params])
	// if (!params) return false;
	const { platform } = params;
	// console.log({ params });
	const filters = params;
	const res = await http.post(
		`${API_ENDPOINTS.GET_INFLUENCERS}?platform=${platform}&page=${pageParam}`,
		filters,
	);
	return res?.data?.data || null;
}

const useGetFilteredInfluencersQuery = (options) => {
	const dispatch = useDispatch();

	const onSuccess = (data) => {
		dispatch({ type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_RESULTS, payload: data });
		dispatch({
			type: CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS,
			payload: `SearchFilter/${JSON.stringify(new Date())}`,
		});
	};

	const onError = (data) => {
		dispatch({ type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_RESULTS, payload: null });
		recordEvent('Modash Error(Search)', {
			options,
			output:data
		});
	};

	console.log({
		options
	})

	return useInfiniteQuery([API_ENDPOINTS.GET_INFLUENCERS, options], getFilteredInfluencers, {
		getNextPageParam: (lastPage) => {
			return lastPage.isNext && lastPage.currentPage + 1;
		},
		onSuccess,
		onError,
		enabled: !!options,
	});
};

const erVal = (er) => {
	if (er) {
		return er.value;
	}
	return null;
};

const getFilters = (filters) => {
	// return {
	// 	filters: {
	// 		influencer: {
	// 			accountTypes: [],
	// 			engagementRate: null,
	// 			location: [304716],
	// 			gender: null,
	// 			language: null,
	// 			followers: {},
	// 			hasYoutube: false,
	// 			hasContactDetails: [],
	// 			relevance: { usernames: [], hashtags: [] },
	// 			textTags: [],
	// 			interests: [],
	// 			lastposted: null,
	// 			bio: '',
	// 			keywords: '',
	// 			growthRate: null,
	// 		},
	// 		audience: {
	// 			location: [],
	// 			language: null,
	// 			gender: null,
	// 			age: [],
	// 			interests: [],
	// 			brands: [],
	// 			credibility: null,
	// 		},
	// 	},
	// 	page: 0,
	// 	sort: {},
	// };

	if (filters.Search) {
		return {
			sort: {
				field: 'followers',
				id: 123,
				direction: 'desc',
			},
			filter: {
				influencer: {
					relevance: filters.platform === 'youtube' ? null : filters.relevance,
					identifier: filters.platform === 'youtube' ? filters.identifier : null,
				},
			},
		};
	}

	// if (filters.platform === "instagram") {
	// 	return {
	// 		sort: {
	// 			field: 'followers',
	// 			id: 123,
	// 			direction: 'desc',
	// 		},
	// 		filter: {
	// 			influencer: {
	// 				followers: filters.influencerFollowers,
	// 				views: filters.views,
	// 				engagementRate: filters.engagementRate, // 0.02,
	// 				location: filters.influencerLocation, // [148838, 307573, 79510],
	// 				language: filters.influencerLanguage, // 'en',
	// 				lastposted: filters.lastposted, //  90,
	// 				gender: filters.influencerGender === 'ANY' ? null : filters.influencerGender, // 'FEMALE',
	// 				hasYouTube: false,
	// 				hasContactDetails: [
	// 					{
	// 						contactType: 'email',
	// 						filterAction: 'must',
	// 					},
	// 				],
	// 				accountTypes: filters.accountType,
	// 				interests: filters.influencerInterests,
	// 				keywords: filters.keywords,
	// 				bio: filters.bio,
	// 				textTags: [
	// 					...filters.hashtags.map(hashtag => ({ type: "hashtag", value: hashtag.slice(1, hashtag.length) })),
	// 					...filters.mentions.map(mention => ({ type: "mention", value: mention }))
	// 				],
	// 			},
	// 			audience: {
	// 				location: filters.audienceLocation, // [148838, 307573, 79510],
	// 				language: filters.audienceLanguage,
	// 				gender: filters.audienceGender === 'ANY' ? null : filters.audienceGender, // 'FEMALE',
	// 				age: filters.audienceAge, // ['18-24', '65-'],
	// 				interests: filters.audienceInterests, // [1708, 13],
	// 				// brands: [],
	// 				credibility: filters.credibility, // 0.75, --> inverse of fake followers (eg.:- 25% of fake followers   is  0.75 credibility)
	// 			},
	// 		},
	// 	};
	// }

	return {
		sort: {
			field: 'followers',
			id: 123,
			direction: 'desc',
		},
		filter: {
			influencer: {
				followers: filters.influencerFollowers,
				views: filters.views,
				engagementRate: erVal(filters.engagementRate), // 0.02,
				location: filters.influencerLocation
					? filters.influencerLocation.map((x) => x.id)
					: [], // [148838, 307573, 79510],
				language: filters.influencerLanguage ? filters.influencerLanguage.code : null, // 'en',
				lastposted: filters.lastposted, //  90,
				// relevance: filters.relevance, // ['#cars', '@topgear'],
				// identifier: filters.identifier,
				gender: filters.influencerGender === 'ANY' ? null : filters.influencerGender, // 'FEMALE',
				hasYouTube: false,
				hasContactDetails: [
					{
						contactType: 'email',
						filterAction: 'must',
					},
				],
				followersGrowthRate: filters.growthRate
					? {
							interval: filters.growthRate,
							value: 1.5,
							operator: 'gt',
					  }
					: null,
				accountTypes: filters.accountType ? filters.accountType.map((x) => x.id) : [], // [2],
				interests: filters.influencerInterests
					? filters.influencerInterests.map((x) => x.id)
					: [], // [1708, 13],
				keywords: filters.keywords, // 'cats',
				bio: filters.bio, // 'no animal testing',
				textTags:
					filters.platform === 'instagram'
						? [
								...filters.hashtags.map((hashtag) => ({
									type: 'hashtag',
									value: hashtag.slice(1, hashtag.length),
								})),
								...filters.mentions.map((mention) => ({
									type: 'mention',
									value: mention.username,
								})),
						  ]
						: [], //  [
				// 	{
				// 		type: 'hashtag',
				// 		value: 'food',
				// 	},
				// 	{
				// 		type: 'mention',
				// 		value: 'world_record_egg',
				// 	},
				// ],
			},
			audience: {
				location: filters.audienceLocation ? filters.audienceLocation.map((x) => x.id) : [], // [148838, 307573, 79510],
				language: filters.audienceLanguage ? filters.audienceLanguage.code : null,
				gender: filters.audienceGender === 'ANY' ? null : filters.audienceGender, // 'FEMALE',
				age: filters.audienceAge ? filters.audienceAge.map((x) => x.value) : [], // ['18-24', '65-'],
				interests: filters.audienceInterests
					? filters.audienceInterests.map((x) => x.id)
					: [], // [1708, 13],
				// brands: [],
				credibility: erVal(filters.credibility), // 0.75, --> inverse of fake followers (eg.:- 25% of fake followers   is  0.75 credibility)
			},
		},
	};
};
export default useGetFilteredInfluencersQuery;
