import React, { useLayoutEffect, useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import Search from '../../../components/bootstrap/Search';

import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey } from '../../../lang';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Spinner from '../../../components/bootstrap/Spinner';
import Select from '../../../components/bootstrap/forms/Select';
import { Option } from '../../../components/icon/bootstrap';
import CustomDropdown from '../../../components/custom/Common/CustomDropdown';
import { selectWorkspaces } from '../../../store/App/Workspace/workspace.selector';
import { selectCreditsDetails } from '../../../store/App/Credits/credits.selector';
import {
	fetchCredits,
	useCreditsQuery,
} from '../../../framework/basic-rest/credits/get-credits-details';
import { useUI } from '../../../states/contexts/ui.context';
import { WORKSPACE_STORE_CONSTANTS } from '../../../store/constants.store';
import { checkArrayData } from '../../../helpers/helpers';
import { useGetUsersOfWorkspace } from '../../../framework/basic-rest/control_panel/get-all-users-of-workspace';
import recordEvent from '../../../framework/basic-rest/mixpanel/mixpanel';
import { useFetchMyAccess } from '../../../framework/basic-rest/control_panel/get-myAccess-of-workspace';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';

const DashboardHeader = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { data: allCrmUser } = useGetUsersOfWorkspace();

	const workspaces = useSelector(selectWorkspaces);
	const credits = useSelector(selectCreditsDetails);

	const { setModalMeta, openModal } = useUI();

	const { refetch: refetchCredits, isLoading: creditsIsLoading } = useCreditsQuery();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'md',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng = 'en-US') => {
		i18n.changeLanguage(lng).then(() =>
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site. (Only "Aside" was prepared as an example.)',
			),
		);
	};

	const switchedWorkspace = (workspace) => {
		recordEvent(`Workspace Selected(Workspace)`, {
			input: workspace,
		});

		// navigate('/dashboard', { replace: true });
	};

	const setModalMetaData = () => {
		setModalMeta({
			view: 'CREATE_WORKSPACE',
			title: 'Create workspace',
		});
		return openModal();
	};
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<div className='row'>
			<div className='col'>
				<Header>
					<HeaderLeft>
						<div className='row d-flex align-items-center'>
							{/* <div className='col-auto'>
						<CustomDropdown
							defaultOption='Select Campaign'
							list={campaign}
							valueText='id'
							labelText='value'
							onClick={(value) => {
								setDefaultCampaign(value);
							}}
						/>
					</div> */}
						</div>
					</HeaderLeft>
					<HeaderRight>
						<div className='row g-3 align-items-center'>
							<div className='col-auto'>{/* <Search /> */}</div>
							<div className='col-auto'>
								<Popovers trigger='hover' desc='Credits'>
									<Button {...styledBtn} size='lg'>
										{credits} <Icon icon='Coin' />
									</Button>
								</Popovers>
							</div>
							<div className='col-auto'>
								{/* <Button {...styledBtn} icon='CreditCardFill' size='lg' /> */}
								{/* <Button {...styledBtn} icon='Help' size='lg' /> */}
								{/* <Button {...styledBtn} icon='Bell' size='lg' /> */}
							</div>
							{/* Dark Mode */}
							<div className='col-auto'>
								{/* <Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers> */}
							</div>
							{/* Lang Selector */}
							<div className='col-auto'>
								{/* <Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language)?.icon === 'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={getLangWithKey(i18n.language)?.icon}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown> */}
							</div>
							{/* <div className='col-auto'>
						<Button {...styledBtn}>
							<Avatar
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								userName='Comapny Name'
								color={USERS.CHLOE.color}
								size={40}
							/>
						</Button>
					</div> */}
						</div>
					</HeaderRight>
				</Header>
			</div>

			{checkArrayData(workspaces) && !creditsIsLoading && credits === 0 && (
				<div className='col-12'>
					<Alert
						icon='Verified'
						isLight
						color='danger'
						borderWidth={0}
						className='shadow-3d-primary'
						isDismissible>
						<AlertHeading tag='h2' className='h4'>
							OOPS!
						</AlertHeading>
						<span>
							You have used all your credits. Connect with us to continue an
							interrupted experience
						</span>
					</Alert>
				</div>
			)}
		</div>
	);
};

export default DashboardHeader;
