export const pages = {
	// dashboard: {
	// 	id: 'dashboard',
	// 	text: 'Dashboard',
	// 	path: '/dashboard',
	// 	icon: 'Dashboard',
	// 	subMenu: null,
	// },
	discoveryType: {
		id: 'discovery',
		text: 'Discover',
	},
	search: {
		id: 'search',
		text: 'Search',
		path: '/discovery',
		icon: 'PersonSearch',
		subMenu: null,
	},
	comparison: {
		id: 'comparison',
		text: 'Compare',
		path: '/discovery/comparison',
		icon: 'PersonSearch',
		subMenu: null,
	},
	list: {
		id: 'list',
		text: 'List',
		path: '/myInfluencer',
		icon: 'Bullseye',
		subMenu: null,
	},
	createType: {
		id: 'create',
		text: 'Create',
	},
	creatorCRM: {
		id: 'creatorCRM',
		text: 'CRM ',
		path: '/CRM',
		icon: 'Upcoming',
		subMenu: {
			Projects: {
				id: 'Project',
				text: 'Campaign',
				path: '/campaign',
				icon: 'ContentPaste',
			},
			Creator: {
				id: 'Creator',
				text: 'Creator',
				path: '/creator',
				icon: 'CashCoin ',
			},
			Partners: {
				id: 'Partners',
				text: 'Partners',
				path: '/partners',
				icon: 'CashCoin ',
			},
			Tasks: {
				id: 'Tasks',
				text: 'Tasks',
				path: '/tasks',
				icon: 'CashCoin ',
			},
			Inbox: {
				id: 'Inbox',
				text: 'Inbox',
				path: '/inbox',
				icon: 'Inbox ',
				isNested: true,
				nestedRoute: {
					email: {
						id: 'authentication',
						path: 'identifier',
					},
				},
			},
		},
	},
	// contentManagement: {
	// 	id: 'content',
	// 	text: 'Content Management',
	// 	path: '/manage_content',
	// 	icon: 'PersonWorkspace',
	// 	subMenu: null,
	// },
	// visionAI: {
	// 	id: 'visionAI',
	// 	text: 'Vision AI',
	// 	path: '/',
	// 	icon: 'PersonWorkspace',
	// 	subMenu: null,
	// },
	optimizeType: {
		id: 'optimize',
		text: 'Optimize',
	},
	SMA: {
		id: 'sma',
		text: 'Reports & Analytics',
		path: '/social-analytics',
		icon: 'Campaign',
		subMenu: null,
	},
	product_analytics: {
		id: 'Product & Analytics',
		text: 'Product & Analytics',
		path: '/product-analytics',
		icon: 'Insights',
		subMenu: null,
	},
	integrations: {
		id: 'integrations',
		text: 'Integrations',
		path: '/integrations',
		icon: 'AppSettingsAlt',
		subMenu: null,
	},

	// controlPanelType: {
	// 	id: 'controlPanel',
	// 	text: 'Control Panel',
	// },
	// userManagement: {
	// 	id: 'userManagement',
	// 	text: 'User Management',
	// 	path: '/manage-user',
	// 	icon: 'Campaign',
	// 	subMenu: null,
	// },
	// creditManagement: {
	// 	id: 'creditManagement',
	// 	text: 'Credit Management',
	// 	path: '/manage-credit',
	// 	icon: 'Campaign',
	// 	subMenu: null,
	// },
	// account: {
	// 	id: 'account',
	// 	text: 'My Account',
	// 	path: '/account',
	// 	icon: 'AccountCircle',
	// 	subMenu: null,
	// },
	// resources: {
	// 	id: 'resources',
	// 	text: 'Resources',
	// 	path: '/resources',
	// 	icon: 'AccountCircle',
	// 	subMenu: null,
	// },
	// help: {
	// 	id: 'help',
	// 	text: 'Help',
	// 	path: '/help',
	// 	icon: 'AccountCircle',
	// 	subMenu: null,
	// },
	// campaign: {
	// 	id: 'campaign',
	// 	text: 'Campaign',
	// 	path: '/campaign',
	// 	icon: 'Campaign',
	// 	subMenu: null,
	// },
	control_panel: {
		id: 'control_panel',
		text: 'User Management',
		path: '/usermanagement',
		icon: 'ManageAccounts',
		subMenu: null,
		isNested: true,
		nestedRoute: {
			all: {
				id: 'InNesting',
				path: '',
			},
			access_permission: {
				id: 'access-permission',
				path: 'access-permission',
			},
			manage_credits: {
				id: 'manage-credits',
				path: 'manage-credits',
			},
			subscription_billing: {
				id: 'subscriptions-billing',
				path: 'subscriptions-billing',
			},
		},
	},
	user_guide: {
		id: 'user_guide',
		text: 'User Guide',
		path: '/userguide',
		icon: 'CodeSquare',
		subMenu: null,
	},
	cms: {
		id: 'cms',
		text: 'Content Management System',
		path: '/cms',
		icon: 'ContentPasteSearch',
		subMenu: null,
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
	user_guide_view: {
		id: 'user_guide_view',
		text: 'User Guide',
		path: '/userguideview/:id',
		icon: 'CodeSquare',
		subMenu: null,
	},

	user_manual: {
		id: 'user_manual',
		text: 'User Manual',
		path: '/usermanual/manual/:label/:title',
		icon: 'CodeSquare',
		subMenu: null,
	},
	user_pricing: {
		id: 'user_pricing',
		text: 'Pricing',
		path: '/userpricing',
		icon: 'CodeSquare',
		subMenu: null,
	},
	brief_dashboard: {
		id: "brief_dashboard",
		text: "Brief Dashboard",
		path: "/brief_dashboard",
		icon: "File",
		subMenu: null,
	  },

	bucket_dashboard: {
		id: "bucket_dashboard",
		text: "Bucket Dashboard",
		path: "/bucket_dashboard/:bucketId",
	},

	// analytics: {
	// 	id: 'analytics',
	// 	text: 'Analytics',
	// 	path: '/analytics',
	// 	icon: 'GraphUpArrow',
	// 	subMenu: null,
	// },
	// account: {
	// 	id: 'account',
	// 	text: 'Account',
	// 	path: '/account',
	// 	icon: 'AccountCircle',
	// 	subMenu: null,
	// },
	// market_place: {
	// 	id: 'market_place',
	// 	text: 'Market Place',
	// 	path: '/market_place',
	// 	icon: 'Store',
	// 	subMenu: null,
	// },
	creator_profile: {
		id: 'creator_profile',
		// text: 'Market Place',
		path: '/creator_profile/:id',
		// icon: 'Store',
		subMenu: null,
	},
	project_profile: {
		id: 'project_profile',
		// text: 'Market Place',
		path: '/project_profile/:id',
		// icon: 'Store',
		subMenu: null,
	},
	company_profile: {
		id: 'company_profile',
		// text: 'Market Place',
		path: '/company_profile/:id',
		// icon: 'Store',
		subMenu: null,
	},
	influencer_profile: {
		id: 'influencer_profile',
		// text: 'Market Place',
		path: '/influencer_profile/',
		// icon: 'Store',
		subMenu: null,
	},
	visionAI: {
		id: 'visionAI',
		text: 'Vision AI',
		path: '/visionAI',
		icon: 'RemoveRedEye',
		subMenu: {
			Collect: {
				id: 'Collect',
				text: 'Collect',
				path: '/Collect',
				icon: 'ViewDay',
			},
			Curate: {
				id: 'Curate',
				text: 'Segment',
				path: '/Curate',
				icon: 'ViewDay',
			},
			Analyze: {
				id: 'Analyze',
				text: 'Analyze',
				path: '/Analyze',
				icon: 'ViewArray',
			},
		},
	},

	// community: {
	// 	id: 'community',
	// 	text: 'Community',
	// 	path: '/community',
	// 	icon: 'ThumbUpOffAlt',
	// 	subMenu: null,
	// 	nestedRoute: {
	// 		// ShowCommunityCard: {
	// 		// 	id: 'ShowCommunityCard',
	// 		// 	path: '',
	// 		// },
	// 		ShowCommunityUser: {
	// 			id: 'ShowCommunityUser',
	// 			path: ':name',
	// 		},
	// 	},
	// },
};

export const commonRoute = {
	userControl: {
		id: 'userControl',
		path: '/user-control',
		isNested: true,
		nestedRoute: {
			workspace: {
				id: 'manage-workspace',
				path: 'manage-workspace',
			},
			member: {
				id: 'manage-member',
				path: 'manage-member',
			},
			// credit: {
			// 	id: "manage-credit",
			// 	path: "manage-credit"
			// }
		},
	},
};

export const commonPage = {
	listPage: {
		id: 'listPage',
		path: '/listPage/:listId',
	},
};

export const publicPage = {
	signin: {
		id: 'signin',
		path: '/signin',
		isNested: true,
		nestedRoute: {
			email: {
				id: 'email',
				path: 'identifier',
				isNested: true,
				nestedRoute: {
					email: {
						id: 'email',
						path: ':encryptedEmail',
					},
				},
			},
			password: {
				id: 'password',
				path: 'challenge/:encryptedEmail',
			},
		},
	},
	verification: {
		id: 'verification',
		path: '/verify-otp',
	},
	signup: {
		id: 'signup',
		path: '/signup',
		isNested: true,
		nestedRoute: {
			email: {
				id: 'email',
				path: 'identifier',
				isNested: true,
				nestedRoute: {
					email: {
						id: 'email',
						path: ':encryptedEmail',
					},
				},
			},
			verify: {
				id: 'verification',
				path: 'verify/:encryptedEmail',
			},
			create: {
				id: 'user-details',
				path: 'webcreateaccount/:encryptedEmail',
			},
		},
	},
	forgotpassword: {
		id: 'forgotpassword',
		path: '/forgotpassword',
		isNested: true,
		nestedRoute: {
			email: {
				id: 'email',
				path: 'identifier',
				isNested: true,
				nestedRoute: {
					email: {
						id: 'email',
						path: ':encryptedEmail',
					},
				},
			},
			verify: {
				id: 'verification',
				path: 'verify/:encryptedEmail',
			},
			change: {
				id: 'change-password',
				path: 'changepassword/:encryptedEmail',
			},
		},
	},
	briefEmail: {
		id: 'briefEmail',
		path: '/creatorbriefemail/:id',
	},
};

export const bandEdgePages = {
	bandEdge: {
		id: 'bandEdge',
		path: '/bandEdge/home',
	},
};


export const privacyPolicyPage = {
	privacyPolicy: {
		id: 'priacyPolicy',
		path: '/privacypolicy',
	}
}

export const termsAndConditionPage = {
	termsAndCondition: {
		id: 'termsAndCondition',
		path: '/termsandcondition'
	}
}

export const refundPolicyPage = {
	refundPolicy: {
		id: 'refundPolicy',
		path: '/refundpolicy'
	}
}

export const pricingPage = {
	pricing: {
		id: 'pricing',
		path: '/pricing'
	}
}

export const aboutUsPage = {
	aboutus: {
		id: 'aboutus',
		path: '/aboutus'
	}
}

export const contactUsPage = {
	contactus: {
		id: 'contactus',
		path: '/contactus'
	}
}

export const componentsMenu = {
	create_campaign: {
		id: 'create_campaign',
		text: 'Create campaign',
		path: 'create-campaign',
		icon: 'Campaign',
	},
	account: {
		id: 'account',
		text: 'My Account',
		path: '/account',
		icon: 'AccountCircle',
		subMenu: null,
	},
};
export const newPage = {
	content_details: {
		id: 'content_details',
		path: '/cms/content/details/:id',
	},
};
