import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const getCurrencies = async () => {
	const { data } = await http.get(`${API_ENDPOINTS.GET_SYSTEM_CURRENCIES}`);
	return data.data || [];
};

export const useGetCurrencies = (options) => {
	return useQuery([API_ENDPOINTS.GET_SYSTEM_CURRENCIES, options], getCurrencies);
};


