import { takeLatest, put, select } from 'redux-saga/effects';
import { CREATOR_ASSOCIATION_CONSTANTS } from '../../../constants.store';
import { selectSelectedCreators } from './crm.utils.selector';
import { setSelectCreator, setSelectedCreators } from './crm.utils.slice';

function* setSelectedCreatorArray(actions) {
	try {
		const creator = actions.payload;
		const selectedCreators = yield select(selectSelectedCreators);
		let creators = [];
		if (selectedCreators.some((cre) => cre?.toId === creator?.toId)) {
			creators = selectedCreators.filter((data) => data?.toId !== creator?.toId);
		} else {
			creators = [...selectedCreators, creator];
		}
		yield put(setSelectedCreators(creators));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* creatorAssociaitonSaga() {
	yield takeLatest(
		CREATOR_ASSOCIATION_CONSTANTS.CREATOR_ARRAY_SELECTED_FOR_ASSOCIATION,
		setSelectedCreatorArray,
	);
}
