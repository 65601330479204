import { useQueryClient, useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { useUI } from '../../../states/contexts/ui.context';
import { dangerToast, successToast } from '../../../helpers/toast-helper';

const sendMessage = async (option) => {
	const queryParams = {};
	if (option.campaignId) {
		queryParams.campaignId = option.campaignId;
	}
	const payload = {
		creatorId: option.creatorId,
		message: option.message,
	};
	const { data } = await http.post(`${API_ENDPOINTS.WhatsApp}/message`, payload, {
		params: queryParams,
	});
	return data.data;
};

const sendTemplateFn = async (option) => {
	const queryParams = {};
	if (option.campaignId) {
		queryParams.campaignId = option.campaignId;
	}
	const payload = {
		creatorId: option.creatorId,
	};
	const { data } = await http.post(`${API_ENDPOINTS.WhatsApp}/template`, payload, {
		params: queryParams,
	});
	return data.data;
};

const useSendMessageMutation = (payload) => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const { closeModal } = useUI();

	return useMutation({
		mutationFn: ({ message, sendTemplate }) => {
			if (sendTemplate) {
				return sendTemplateFn(payload);
			}
			return sendMessage({ ...payload, message });
		},
		onSuccess: () => {
			payload.creatorId.forEach((qry) => {
				queryClient.invalidateQueries([API_ENDPOINTS.WhatsApp, qry], {
					refetchActive: true,
					refetchInactive: true,
				});
			});
			const threadQueryKey = payload.campaignId || null;
			queryClient.invalidateQueries([API_ENDPOINTS.WhatsApp, threadQueryKey], {
				refetchActive: true,
				refetchInactive: true,
			});
			closeModal();
		},

		onError: (res) => {
			addToast(dangerToast({ message: res.response.data.errorMessage }), {
				autoDismiss: true,
			});
		},
	});
};

export default useSendMessageMutation;
