import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import { isUpdateInLists } from '../../../store/App/List/list.selector';
import { selectWorkspace } from '../../../store/App/Workspace/workspace.selector';

export const fetchAllListNames = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { data } = await http.get(`${API_ENDPOINTS.GET_BUCKET_LIST}`);
	console.log("List details ----", data);
	
	return data.data || [];
};

export const useFetchAllListNames = (options) => {
	const dispatch = useDispatch();
	const workspace = useSelector(selectWorkspace);
	const isListUpdated = useSelector(isUpdateInLists);
	const onSuccess = (data) => {
		dispatch({
			type: LIST_STORE_CONTANTS.SET_LIST_DATA,
			payload: data,
		});
	};
	const onError = (data) => {
		dispatch({
			type: LIST_STORE_CONTANTS.SET_LIST_DATA,
			payload: [],
		});
	};
	return useQuery(
		[API_ENDPOINTS.GET_LISTS, options, workspace, isListUpdated],
		fetchAllListNames,
		{
			onSuccess,
			onError,
			enabled: !!workspace || !!options,
		},
	);
};
