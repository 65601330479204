import React from 'react';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
} from '../../../../bootstrap/Card';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const AudienceStat = ({ data, title, isDownloading, desc, className = 'col-md-6' }) => {
	return (
		<div className={className}>
			<Card stretch>
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
					<CardActions>
						<Popovers desc={desc} trigger='hover'>
							<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
						</Popovers>
					</CardActions>
				</CardHeader>
				<CardBody
					className='overflow-auto'
					style={{ height: data.length >= 6 && !isDownloading ? '30rem' : 'auto' }}>
					{data.map((val) => {
						return (
							<div className='row'>
								<div className='col-md-9'>{val.name}</div>
								<div className='col-md-3'>
									{(Math.round(val.weight * 1000) / 10).toFixed(1)}%
								</div>
								<hr />
							</div>
						);
					})}
				</CardBody>
			</Card>
		</div>
	);
};

export default AudienceStat;
