import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import contentRoute from '../../routes/contentRoutes';
import Wrapper from '../Wrapper/Wrapper';
import { checkArrayData } from '../../helpers/helpers';

const PAGE_404 = lazy(() => import('../../pages/auth/Page404'));

const ContentRoutes = () => {
	console.log("content routes", contentRoute);
	const buildRoutes = (route) =>
		route?.isNested ? (
			<Route key={route.id} path={route.path} element={route.element}>
				{route?.nested.map((page) => buildRoutes(page))}
			</Route>
		) : (
			<Route key={route.id} path={route.path} element={route.element} />
		);

	return (
		<Routes>
			<Route path='' element={<Wrapper />} >
				{contentRoute.contents.map((page) => {
					return buildRoutes(page);
				})}
				{
				  contentRoute.bandedge.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/discovery' />} />
			</Route>
			<Route path='' >
				{
				  contentRoute.bandedge.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/bandEdge/home' />} />
			</Route>
			{
				checkArrayData(contentRoute.commonPages) && contentRoute.commonPages.map((page) => {
					return buildRoutes(page);
				})
			}
			<Route path='*' element={<PAGE_404 />} />
			{/* <Route path='' element={<Navigate to='/dashboard' />} /> */}
		</Routes>
	);
};

export default ContentRoutes;
