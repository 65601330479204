import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	currentTrackingId: null,
	influencers: [],
	trackingIds: [],
	currentTrackingDetails: null,
};

const slice = createSlice({
	name: 'socialTracking',
	initialState,
	reducers: {
		setSelectedTracking: (state, action) => {
			state.currentTrackingId = action.payload;
		},
		setInfluencers: (state, action) => {
			state.influencers = action.payload;
		},
		setTrackingIds: (state, action) => {
			state.trackingIds = action.payload;
		},
		setCurrentTrackingDetails: (state, action) => {
			state.currentTrackingDetails = action.payload;
		},
	},
});

export const { setSelectedTracking, setInfluencers, setTrackingIds, setCurrentTrackingDetails } =
	slice.actions;

export default slice.reducer;
