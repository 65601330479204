import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	crmCreator: [],
	crmCreatorId: null,
	crmCreatorById: null,
	isUpdateCrmCreator: null,
	getCreator: null,
};

const slice = createSlice({
	name: 'creators',
	initialState,
	reducers: {
		setCrmCreator: (state, action) => {
			state.crmCreator = action.payload;
		},
		setCrmCreatorId: (state, action) => {
			state.crmCreatorId = action.payload;
		},
		setCrmCreatorById: (state, action) => {
			state.crmCreatorById = action.payload;
		},
		setIsUpdateCrmCreator: (state, action) => {
			state.isUpdateCrmCreator = action.payload;
		},
		setGetCreator: (state, action) => {
			state.getCreator = action.payload;
		},
	},
});

export const {
	setCrmCreator,
	setCrmCreatorId,
	setCrmCreatorById,
	setIsUpdateCrmCreator,
	setGetCreator,
} = slice.actions;

export default slice.reducer;
