import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';

async function deletePricing(input) {
	return http.delete(
		`${API_ENDPOINTS.DELETE_PRICING}?listId=${input.list_id}&influencer_id=${input.influencer_id}&list_id=${input.list_id}&i_index_id=${input.i_index_id}&pricing_id=${input.pricing_id}`,
	);
}

export const useDeletePricing = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();

	return useMutation((input) => deletePricing(input), {
		onSuccess: (data) => {
			if (data.data.success) {
				dispatch({
					type: LIST_STORE_CONTANTS.SET_IS_UPDATE_PRICE,
					payload: `add/pricing/${new Date()}`,
				});
				addToast(successToast({ message: data.data.message }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err) => {
			console.log(err);
			addToast(dangerToast({ message: err.data.errorMessage }), {
				autoDismiss: true,
			});
		},
	});
};
