import React from 'react';
import useDarkMode from '../../../hooks/useDarkMode';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Icon from '../../icon/Icon';

const DefaultContactCard = ({ influencer, source }) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<Card stretch className='overflow-auto'>
			<CardHeader>
				<CardLabel icon='StackedLineChart'>
					<CardTitle>Influencer Contact</CardTitle>
				</CardLabel>
				<CardActions>
					Source : <strong>{source}</strong>.
				</CardActions>
			</CardHeader>
			<div style={{ height: '30rem' }}>
				<CardBody className='align-items-center justify-content-center'>
					<div className='row g-4 align-items-center'>
						<div className='col-xl-12'>
							<div
								className={`d-flex align-items-center bg-l${
									darkModeStatus ? 'o25' : '10'
								}-warning rounded-2 p-2`}>
								<div className='flex-shrink-0'>
									<Icon icon='PersonPin' size='3x' color='warning' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='fw-bold fs-4 mb-0'>
										{influencer.influencerData.profile.fullname}
									</div>
									<div className='text-muted mt-n2 truncate-line-1'>FullName</div>
								</div>
							</div>
						</div>
						{influencer?.influencerData.contacts &&
						influencer?.influencerData.contacts?.length !== 0 ? (
							influencer?.influencerData.contacts?.map((contact) => (
								<div className='col-xl-12'>
									<div
										className={`d-flex overflow-auto align-items-center bg-l${
											darkModeStatus ? 'o25' : '10'
										}-info rounded-2 p-2`}>
										<div className='flex-shrink-0'>
											<Icon icon='Email' ovonts size='3x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-4 mb-0'>{contact.value}</div>
											<div className='text-muted mt-n2 truncate-line-1'>
												{contact.type}
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<div className='col-xl-12'>
								<div
									className={`d-flex overflow-auto align-items-center bg-l${
										darkModeStatus ? 'o25' : '10'
									}-info rounded-2 p-2`}>
									<div className='flex-shrink-0'>
										<Icon icon='Email' ovonts size='3x' color='info' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-4 mb-0'>No Contacts </div>
										<div className='text-muted mt-n2 truncate-line-1'>
											View full report to get contacts
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</CardBody>
			</div>
		</Card>
	);
};

export default DefaultContactCard;
