import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	lists: null,
	listInfluencers: null,
	isUpdateInLists: null,
	listInfluencer: null,
	influencerContact: null,
	isContactUpdated: null,
	influencerPricing: null,
	isPriceUpdated: null,
	profileListId: null,
	favouriteList: null,
	crmListId: null,
	filteredInfluencers: [],
	filtersClicked: false,
};

const slice = createSlice({
	name: 'list',
	initialState,
	reducers: {
		setLists: (state, action) => {
			state.lists = action.payload;
		},
		setFavouriteList: (state, action) => {
			state.favouriteList = action.payload;
		},
		setListInfluencers: (state, action) => {
			state.listInfluencers = action.payload;
		},
		setIsUpdateInLists: (state, action) => {
			state.isUpdateInLists = action.payload;
		},
		setListInfluencer: (state, action) => {
			state.listInfluencer = action.payload;
		},
		setInfluencerContact: (state, action) => {
			state.influencerContact = action.payload;
		},
		setIsContactUpdated: (state, action) => {
			state.isContactUpdated = action.payload;
		},
		setInfluencerPricing: (state, action) => {
			state.influencerPricing = action.payload;
		},
		setIsPriceUpdated: (state, action) => {
			state.isPriceUpdated = action.payload;
		},
		setProfileListId: (state, action) => {
			state.profileListId = action.payload;
		},
		setCrmListId: (state, action) => {
			state.crmListId = action.payload;
		},
		setFilteredInfluencers: (state, action) => {
			state.filteredInfluencers = action.payload;
		},
		setFiltersClicked: (state, action) => {
			state.filtersClicked = action.payload;
		},
	},
});

export const {
	setLists,
	setFavouriteList,
	setListInfluencers,
	setIsUpdateInLists,
	setListInfluencer,
	setInfluencerContact,
	setIsContactUpdated,
	setInfluencerPricing,
	setIsPriceUpdated,
	setProfileListId,
	setCrmListId,
	setFilteredInfluencers,
	setFiltersClicked
} = slice.actions;

export default slice.reducer;
