const validateProject = (values) => {
	const errors = {};
	if (!values.projectName) {
		errors.projectName = 'Please provide valid Project Name';
	}
	if (!values.projectType) {
		errors.projectType = 'Please select project type';
	}
	if (!values.platform) {
		errors.platform = 'Please select platform';
	}
	if (!values.currency) {
		errors.currency = 'Please select your currency';
	}
	// eslint-disable-next-line no-restricted-globals
	if (!parseInt(values.budget, 10) && !isNaN(values.budget)) {
		errors.budget = 'Please enter valid budget';
	}
	return errors;
};

export default validateProject;
