export const AUTH_STORE_CONSTANTS = {
	LOGIN: 'DO_LOGIN',
	SYNC_TOKEN: 'SYNC_TOKEN',
	SIGNUP: 'DO_SIGNUP',
	EXISTING_USER: 'SET_EXISTING_USER',
	LOGGING_IN_USER_EMAIL: 'LOGGING_IN_USER_EMAIL',
	SET_VALID_USER: 'SET_VALID_USER',
	LOGGING_IN_USER_PASSWORD: 'LOGGING_IN_USER_PASSWORD',
	LOGOUT: 'DO_LOGOUT',
	AUTHORIZE: 'AUTHORIZE',
	UNAUTHORIZE: 'UNAUTHORIZE',
	SYNC_USER_DETAILS: 'START_LOGIN_SYNC',
};

export const ORGANIZATION_CONSTANTS = {
	GET_APP_ID: 'GET_APP_ID',
};

export const WORKSPACE_STORE_CONSTANTS = {
	SET_WORKSPACE: 'SET_WORKSPACE',
	SYNC_WORKSPACE_DETAILS: 'START_WORKSPACE_SYNC',
	SYNC_TEAM_MEMBER: 'SYNC_TEAM_MEMBER',
	WORKSPACE_SYNC: 'WORKSPACE_SYNC',
	CLEAR_WORKSPACE: 'CLEAR_WORKSPACE',
};

export const CONTROL_PANEL_STORE_CONSTANTS = {
	SET_WORKSPACE_INFO: 'SET_WORKSPACE_INFO',
	CHECK_IS_WORKSPACE_UPDATE: 'CHECK_IS_WORKSPACE_UPDATE',
	CHECK_IS_WORKSPACE_SHARE: 'CHECK_IS_WORKSPACE_SHARE',
	CHECK_IS_PERMISSION_REMOVE: 'CHECK_IS_PERMISSION_REMOVE',
	GET_MENU: 'GET_MENU',
	GET_ALL_USER_IN_WORKSPACE: 'GET_ALL_USER_IN_WORKSPACE',
	UPDATE_MY_ACCESS_LIST: 'UPDATE_MY_ACCESS_LIST',
};

export const CREDITS_STORE_CONSTANTS = {
	SET_CREDITS_DETAILS: 'SET_CREDITS_DETAILS',
	SET_ISUPDATE_CREDITS: 'SET_ISUPDATE_CREDITS',
};

export const DISCOVERY_STORE_CONSTANTS = {
	SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
	SET_SEARCH_FILTERS: 'SET_SEARCH_FILTERS',
	SEARCH_FILTERS: 'SEARCH_FILTERS',
	SET_FOLLWERS_VIEW: 'SET_FOLLWERS_VIEW',
	SET_INFLUENCER_REPORT: 'SET_INFLUENCER_REPORT',
	CHANGE_ACTIVE_TABS: 'CHANGE_ACTIVE_TABS',
	CLEAR_SEARCH_FILTERS: 'RESET_SEARCH_FILTERS',
	CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
	IS_FILTER_CLEAR: 'IS_FILTER_CLEAR',
	IS_FILTER_APPLY: 'IS_FILTER_APPLY',
	APPLY_SEARCH_FILTERS: 'APPLY_SEARCH_FILTERS',
	ADD_INFLUENCER_IDS: 'ADD_INFLUENCER_IDS',
	SAVE_COMPARISON_REPORTS: 'SAVE_COMPARISON_REPORTS',
	SET_COMPARISON_PROFILE: 'SET_COMPARISON_PROFILE',
	FETCH_MORE_INFLUENCER_REPORT: 'FETCH_MORE_INFLUENCER_REPORT',
};

export const LIST_STORE_CONTANTS = {
	SET_LIST_DATA: 'SET_LIST_DATA',
	SET_FAVOURITE_LIST: 'SET_FAVOURITE_LIST',
	SET_LIST_INFLUENCERS_DATA: 'SET_LIST_INFLUENCERS_DATA',
	CHANGE_LIST_UPDATE: 'CHANGE_LIST_UPDATE',
	SET_LIST_INFLUENCER_DATA: 'SET_LIST_INFLUENCER_DATA',
	SET_LIST_INFLUENCERS_CONTACT: 'SET_LIST_INFLUENCERS_CONTACT',
	SET_IS_UPDATE_CONTACT: 'SET_IS_UPDATE_CONTACT',
	SET_LIST_INFLUENCERS_PRICE: 'SET_LIST_INFLUENCERS_PRICE',
	SET_IS_UPDATE_PRICE: 'SET_IS_UPDATE_PRICE',
	SET_PROFILE_LIST_ID: 'SET_PROFILE_LIST_ID',
	SET_CRM_LIST_ID: 'SET_CRM_LIST_ID',
	SET_FILTERED_INFLUENCERS: 'SET_FILTERED_INFLUENCERS',
};

export const ANALYTICS_STORE_CONSTANTS = {
	GET_SOCIAL_ANALYTICS_META: 'GET_SOCIAL_ANALYTICS_META',
	OPEN_TRACKING_TABLE: 'OPEN_TRACKING_TABLE',
	SET_PROJECT_DATA: 'SET_PROJECT_DATA',
	SET_TRACKING_DATA: 'SET_TRACKING_DATA',
	SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
	SET_CURRENT_TRACKING: 'SET_CURRENT_TRACKING',
	SET_CURRENT_TRACKING_DATA: 'SET_CURRENT_TRACKING_DATA',

	SET_TRACKING_IDS: 'SET_TRACKING_IDS',

	SET_ANALYTICS_STATUS: 'SET_ANALYTICS_STATUS',

	SET_SOCIAL_CONTENT: 'SET_SOCIAL_CONTENT',
	SET_SOCIAL_ANALYTICS: 'SET_SOCIAL_ANALYTICS',

	SET_PROJECT_ANALYTICS: 'SET_PROJECT_ANALYTICS',
	SET_PROJECT_CONTENT: 'SET_PROJECT_CONTENT_STREAM',

	SET_CRM_PROJECT_ID: 'SET_CRM_PROJECT_ID',

	SET_POST_TYPES: 'SET_POST_TYPES',
	GET_ANALYTICS: 'GET_ANALYTICS',

	HANDLE_INFLUENCER_VIEW: 'HANDLE_INFLUENCER_VIEW',
	HANDLE_ANALYTICS_VIEW: 'HANDLE_ANALYTICS_VIEW',
	SET_ANALYTICS_VIEW: 'SET_ANALYTICS_VIEW',
};

export const CRM_CREATORS_STORE_CONSTANTS = {
	SET_CRM_CREATOR_DATA: 'SET_CRM_CREATOR_DATA',
	UPDATE_CRM_CREATOR_DATA: 'UPDATE_CRM_CREATOR_DATA',
	UPDATE_CRM_CREATOR_ID: 'UPDATE_CRM_CREATOR_ID',
	SET_CRM_CREATOR_BY_ID: 'SET_CRM_CREATOR_BY_ID',
	IS_UPDATE_CREATOR: 'IS_UPDATE_CREATOR',
	SET_SELECTED_CREATOR: 'SET_SELECTED_CREATOR',
};

export const CRM_PROJECTS_STORE_CONSTANTS = {
	SET_CRM_PROJECT_DATA: 'SET_CRM_PROJECT_DATA',
	UPDATE_CRM_PROJECT_DATA: 'UPDATE_CRM_PROJECT_DATA',
	UPDATE_CRM_PROJECT_ID: 'UPDATE_CRM_PROJECT_ID',
	SET_CRM_PROJECT_BY_ID: 'SET_CRM_PROJECT_BY_ID',
	IS_UPDATE_PROJECT: 'IS_UPDATE_PROJECT',
	SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
};

export const CRM_COMPANIES_STORE_CONSTANTS = {
	SET_CRM_COMPANY_DATA: 'SET_CRM_COMPANY_DATA',
	UPDATE_CRM_COMPANY_DATA: 'UPDATE_CRM_COMPANY_DATA',
	UPDATE_CRM_COMPANY_ID: 'UPDATE_CRM_COMPANY_ID',
	SET_CRM_COMPANY_BY_ID: 'SET_CRM_COMPANY_BY_ID',
	SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
	IS_UPDATE_COMPANY: 'IS_UPDATE_COMPANY',
	SET_COMPANY_TO_EDIT: 'SET_COMPANY_TO_EDIT',
};

export const CRM_STORE_CONTANT = {
	GET_ALL_CRM_DATA: 'GET_ALL_CRM_DATA',
	SYNC_CRM_DATA: 'SYNC_CRM_DATA',
};

export const CRM_NOTES_STORE_CONSTANTS = {
	UPDATE_CRM_NOTES: 'UPDATE_CRM_NOTES',
	SET_CRM_NOTES_DATA: 'SET_CRM_NOTES_DATA',
};
export const CRM_TASKS_STORE_CONSTANTS = {
	UPDATE_CRM_TASKS: 'UPDATE_CRM_TASKS',
	GET_CRM_TASKS: 'GET_CRM_TASKS',
	GET_CRM_TASK_BY_ID: 'GET_CRM_TASK_BY_ID',
	SET_CRM_TASK_DATA: 'SET_CRM_TASK_DATA',
	FETCH_CRM_TASK_AGAIN: 'FETCH_CRM_TASK_AGAIN',
};

export const CRM_MAILS_STORE_CONSTANTS = {
	MAIL_SENT: 'MAIL_SENT',
};

export const OT_ANALYTICS_STORE_CONSTANTS = {
	GET_ALL_APP_KEYS: 'GET_ALL_APP_KEYS',
	REFRESH_COMMERCE_PAGE: 'REFRESH_COMMERCE_PAGE',
};

export const CONVERSION_ANALYTICS_STORE_CONSTANTS = {
	GET_CONVERSION_ANALYTICS_DETAILS: 'GET_CONVERSION_ANALYTICS_DETAILS',
	GET_CONVERSION_ANALYTICS_BASED_ON_EVENT_SELECTED:
		'GET_CONVERSION_ANALYTICS_BASED_ON_EVENT_SELECTED',
	GET_CONVERSION_ANALYTICS_CITY_LIST: 'GET_CONVERSION_ANALYTICS_CITY_LIST',
	GET_CONVERSION_ANALYTICS_BY_CITY: 'GET_CONVERSION_ANALYTICS_BY_CITY',
	GET_CONVERSION_ANALYTICS_BY_COUNTRY: 'GET_CONVERSION_ANALYTICS_BY_COUNTRY',

	GET_CONVERSION_ANALYTICS_BY_CITY_DEVICE_PLATFORM:
		'GET_CONVERSION_ANALYTICS_BY_CITY_DEVICE_PLATFORM',
	GET_ALL_THE_EVENTS: 'GET_ALL_THE_EVENTS',
	GET_SEGMENTATION_COUNT: 'GET_SEGMENTATION_COUNT',
	GET_SEGMENTATION_FOR_PROJECT: 'GET_SEGMENTATION_FOR_PROJECT',
};

export const CREATOR_ASSOCIATION_CONSTANTS = {
	CREATOR_SELECTED_FOR_ASSOCIATION: 'CREATOR_SELECTED_FOR_ASSOCIATION',
	CREATOR_ARRAY_SELECTED_FOR_ASSOCIATION: 'CREATOR_ARRAY_SELECTED_FOR_ASSOCIATION',
};

export const CMS_STORE_CONSTANTS = {
	SET_CMS_CONTENT: 'SET_CMS_CONTENT',
	SET_CMS_SEARCH: 'SET_CMS_SEARCH',
	SET_CMS_HASHTAGS: 'SET_CMS_HASHTAGS',
	SET_CMS_TYPES: 'SET_CMS_TYPES',
	SET_CMS_STATUS: 'SET_CMS_STATUS',
	SET_CMS_SEARCH_PARAMETERS: 'SET_CMS_SEARCH_PARAMETERS',
};
