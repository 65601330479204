import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	currentProject: null,
	projects: [],
	stats: null,
	contentStreams: [],
};

const slice = createSlice({
	name: 'socialProject',
	initialState,
	reducers: {
		setSelectedProject: (state, action) => {
			state.currentProject = action.payload;
		},
		setProjects: (state, action) => {
			state.projects = action.payload;
		},
		setProjectStats: (state, action) => {
			state.stats = action.payload;
		},
		setProjectContentStreams: (state, action) => {
			state.contentStreams = action.payload;
		},
	},
});

export const { setSelectedProject, setProjects, setProjectStats, setProjectContentStreams } =
	slice.actions;

export default slice.reducer;
