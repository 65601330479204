import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	workspaceInfo: null,
	isWorkspaceUpdate: null,
	isWorkspaceShare: null,
	isPermissionRemove: null,
	menu: null,
	allUsers: null,
	myAccessList: null,
};

const slice = createSlice({
	name: 'controlPanel',
	initialState,
	reducers: {
		setWorkspaceInfo: (state, action) => {
			state.workspaceInfo = action.payload;
		},
		setIsWorkspaceUpdate: (state, action) => {
			state.isWorkspaceUpdate = action.payload;
		},
		setIsWorkspaceShare: (state, action) => {
			state.isWorkspaceShare = action.payload;
		},
		setIsPermissionRemove: (state, action) => {
			state.isPermissionRemove = action.payload;
		},
		setMenu: (state, action) => {
			state.menu = action.payload;
		},
		setAllUsers: (state, action) => {
			state.allUsers = action.payload;
		},
		setMyAccessList: (state, action) => {
			state.myAccessList = action.payload;
		},
	},
});

export const {
	setWorkspaceInfo,
	setIsWorkspaceUpdate,
	setIsWorkspaceShare,
	setMenu,
	setAllUsers,
	setMyAccessList,
	setIsPermissionRemove,
} = slice.actions;

export default slice.reducer;
