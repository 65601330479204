import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import recordEvent from '../mixpanel/mixpanel';

async function uploadFile(input) {
	const { data } = await http.post(input.formType, input.formData);
	return data;
}

const useUploadFile = () => {
	const { addToast } = useToasts();

	return useMutation((input) => uploadFile(input), {
		onSuccess: (res, input) => {
			addToast(successToast({ message: res.message }), {
				autoDismiss: true,
			});
			recordEvent(`Imported File`, {
				input,
				output:res
			});


		},
		onError: (err, input) => {
			addToast(dangerToast(err.response.data.errorMessage), {
				autoDismiss: true,
			});
			recordEvent(err.response.data.errorMessage,{
				input,
				output:err
			});
		},
	});
};
export default useUploadFile;
