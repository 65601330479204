import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { CONTROL_PANEL_STORE_CONSTANTS } from '../../../store/constants.store';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import recordEvent from '../mixpanel/mixpanel';

export const shareModule = async ({ body }) => {
	const { data } = await http.post(`${API_ENDPOINTS.SHARE_MODULE_PERMISSION}`, body);
	return data || { message: 'No data', data: [] };
};

export const useShareModule = (options) => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const onSuccess = (data, input) => {
		addToast(
			successToast({
				message: 'Module Shared SuccessFully',
			}),
			{
				autoDismiss: true,
			},
		);
		queryClient.invalidateQueries([API_ENDPOINTS.TO_GET_WORKSPACE_INFO]);
		dispatch({
			type: CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_WORKSPACE_SHARE,
			payload: `Module/Share/${new Date()}`,
		});
		recordEvent('Shared Module(Control Panel)', {
			input,
			output: data,
		});
	};

	const onError = (err, input) => {
		addToast(
			dangerToast({
				message: err?.data?.errorMessage,
			}),
			{
				autoDismiss: true,
			},
		);
		recordEvent('Share Module Failed(Control Panel)', {
			input,
			output:err?.data,
		});
	};
	
	return useMutation((input) => shareModule(input), {
		onSuccess,
		onError,
	});
};
