import { takeLatest, put } from 'redux-saga/effects';
import { CRM_NOTES_STORE_CONSTANTS } from '../../../constants.store';
import { setCrmNotes, setIsUpdateCrmNote } from './crm.notes.slice';

function* updateCRMnotes(actions) {
	try {
		const creatorId = actions.payload;
		yield put(setIsUpdateCrmNote(creatorId));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMnotes(actions) {
	try {
		const notes = actions.payload;
		yield put(setCrmNotes(notes));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* crmNoteSaga() {
	yield takeLatest(CRM_NOTES_STORE_CONSTANTS.UPDATE_CRM_NOTES, updateCRMnotes);
	yield takeLatest(CRM_NOTES_STORE_CONSTANTS.SET_CRM_NOTES_DATA, setCRMnotes);
}
