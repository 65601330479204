import React, { useEffect, useState, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CSVLink } from 'react-csv';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmAlert } from '../../helpers/confirm-helper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Icon from '../../components/icon/Icon';
import { checkArrayData, fixDecimal, nFormatter } from '../../helpers/helpers';
import Chips from '../../components/custom/Common/Chips';
import useRemoveInfluencerFromListMutation from '../../framework/basic-rest/discovery/remove-influencer-from-list';
import { useFetchAllListNames } from '../../framework/basic-rest/list/get-all-list-names';
import { lists } from '../../store/App/List/list.selector';

// import useGetMultipleLists from '../../framework/basic-rest/list/get-multiple-lists';
import { successToast } from '../../helpers/toast-helper';

import Spinner from '../../components/bootstrap/Spinner';
import { NoListState } from '../../components/custom/Common/EmptyStates';
import FilterComponent from './FilterComponent';
import useDarkMode from '../../hooks/useDarkMode';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import ManageListModal from '../../components/custom/MyInfluencerComponents/ManageListModal';
import InfluencerProfileModal from '../../components/custom/MyInfluencerComponents/InfluencerProfileModal';
import useGetFilteredInfluencers from '../../framework/basic-rest/list/get-filtered-influencers';
import { LIST_STORE_CONTANTS } from '../../store/constants.store';
import AddToList from '../../components/custom/DiscoveryComponents/AddToList';

import { selectWorkspace } from '../../store/App/Workspace/workspace.selector';
import ItemShare from '../../components/custom/control_panel/common/ItemsShare';

import { TableSkeleton } from '../../components/custom/Common/Skeletons';
import Popovers from '../../components/bootstrap/Popovers';
import API_ENDPOINTS from '../../framework/basic-rest/utils/api-endpoints';
import FileImporter from '../../components/custom/Common/FileImporter';
import recordEvent from '../../framework/basic-rest/mixpanel/mixpanel';

import AddToProject from '../../components/custom/DiscoveryComponents/AddToAnalytics';
import ListExport from '../../components/custom/Common/List/Components/ListExport';
import { csvInfluencer, headers } from '../../components/custom/Common/List/Components/listHelper';
import UserImage from '../../assets/img/wanna/wanna1.png';
import ListPage from './ListPage';

// eslint-disable-next-line react/prop-types
const InfluencerTable = ({ isFluid, isGettingInfluencers }) => {
	const listData = useSelector(lists);

	const dispatch = useDispatch();
	const workspace = useSelector(selectWorkspace);

	const { refetch: refetchAllList } = useFetchAllListNames();

	const {
		mutate: getFilteredInfluencers,
		isLoading: isGettingFilteredInfluencers,
		data: influencers,
	} = useGetFilteredInfluencers();

	const getInfluencers = async (body) => {
		getFilteredInfluencers(body);
	};

	const filterList = useFormik({
		enableReinitialize: true,
		initialValues: {
			listIds: [],
			listNames: [],
			minFollowers: '',
			maxFollowers: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			let body;
			if (!values.listIds.length) {
				body = {
					listIds: [],
				};
			}
			body = {
				listIds: values.listIds,
			};
			getInfluencers(body);
		},
	});

	useEffect(() => {
		if (workspace) {
			filterList.handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listData, workspace]);

	const { themeStatus } = useDarkMode();
	const { addToast } = useToasts();

	// useEffect(() => {
	// 	// eslint-disable-next-line no-unused-vars
	// }, [influencers]);

	const { data: allLists } = useFetchAllListNames();

	const checkLocationAvailable = (geoCities, geoCountries) => {
		if (geoCities.length) {
			return geoCities;
		}
		return geoCountries;
	};

	const sortAges = (value) => {
		const sortedAge = value.sort((age, prevAge) => {
			if (age.weight > prevAge.weight) {
				return -1;
			}
			return 1;
		});
		return sortedAge;
	};

	const [isOpen, setIsOpen] = useState(false);

	const [manageList, setManageList] = useState(false);
	// const [showProile, setShowProfile] = useState(false);
	const [isAscendingFollowers, setIsAscendingFollowers] = useState(true);
	const [isAscendingName, setIsAscendingName] = useState(true);
	const [isAscendingER, setIsAscendingER] = useState(true);
	const [isAscendingFullName, setIsAscendingFullName] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	// const [exportData, setExportData] = useState([]);
	const [exportSampleData, setExportSample] = useState([]);
	const [importOpen, setImportOpen] = useState(false);
	// const [importOpen, setImportOpen] = useState(false);

	const navigate = useNavigate();
	const sampleLink = useRef();

	const sampleHeaders = [
		{ label: 'username', key: 'username' },
		{ label: 'fullname', key: 'fullname' },
		{ label: 'gender', key: 'gender' },
		{ label: 'platform', key: 'influencerType' },
		{ label: 'followers', key: 'followers' },
		{ label: 'engagement_rate', key: 'engagementRate' },
		{ label: 'estimated_reach', key: 'estimatedReach' },
		{ label: 'handle_link', key: 'handle' },
		{ label: 'posts', key: 'posts' },
		{ label: 'average_views', key: 'avgViews' },
		{ label: 'average_likes', key: 'avgLikes' },
		{ label: 'average_comments', key: 'avgComments' },
		{ label: 'interests', key: 'interests' },
		{ label: 'gender_split', key: 'gender_split' },
	];

	useEffect(() => {
		if (exportSampleData) {
			sampleLink?.current?.link?.click();
		}
	}, [exportSampleData]);
	const {
		mutate: removeInfluencer,
		isLoading: isRemoving,
		data: deletedData,
	} = useRemoveInfluencerFromListMutation();
	const [removedInfluencer, setRemovedInfluencer] = useState({});
	// const removeInfluencers = (influencerId, listId, influencerIndex, listIndex) => {
	// 	removeInfluencer({
	// 		influencer_id: influencerId,
	// 		list_id: listId,
	// 	});
	// 	setRemovedInfluencer({ influencerIndex, listIndex });
	// 	filterList.handleSubmit();
	// };
	const useInfluencer = useFormik({
		enableReinitialize: true,
		initialValues: {
			influencer: {},
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			console.log(values);
		},
	});

	const exportSample = () => {
		const data = [
			{
				username: 'Username on Social media handle',
				fullname: 'Fullname of Creator',
				influencerType: 'Platform / Social Media type',
				followers: 'Total Number of Followers',
				engagementRate: 'Engagement rate of Creator',
				estimatedReach: 'Estimated Reach of Creator',
				avgViews: 'Average views on platform',
				avgLikes: 'Average Likes on platform',
				avgComments: 'Average comments on platform',
				gender: 'Creator Gender',
				interests: 'Creator Interests',
				posts: 'Total number of posts',
				gender_split: 'Gender Split',
				handle: 'Handle Link of Creator',
			},
		];
		setExportSample(data);
		recordEvent('Exported Sample List of Influencers(List)', {
			exportData: data,
		});
	};

	const refreshbtnClick = () => {
		recordEvent('Refresh button(List)');
	};
	useEffect(() => {
		refetchAllList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRemoving]);

	// useEffect(() => {
	// 	addToast(successToast({ message: 'Influencer removed from list' }), {
	// 		autoDismiss: true,
	// 	});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [deletedData]);
	const influencerIds = [];

	// useEffect(() => {
	// 	filterList.handleSubmit();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAscendingFollowers, isAscendingName, isAscendingER, isAscendingFullName]);

	return (
		<div className='row'>
			<ListPage lists={listData} className='col-md-4' />
		</div>
	);
};

export default InfluencerTable;
