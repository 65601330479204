import classNames from 'classnames';
import { useFormik } from 'formik';
import React from 'react';
import useDarkMode from '../../hooks/useDarkMode';
import Button from '../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Label from '../../components/bootstrap/forms/Label';
import Badge from '../../components/bootstrap/Badge';
import { SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import CommonFilterTag from './CommonFilterTag';
// import useGetMultipleLists from '../../framework/basic-rest/list/get-multiple-lists';
import handlers from '../discovery/FiltersHelper';
import recordEvent from '../../framework/basic-rest/mixpanel/mixpanel';
import { useUI } from '../../states/contexts/ui.context';

const FilterComponent = ({ lists, formik, influencersFiltered }) => {
	const { darkModeStatus } = useDarkMode();

	const handleChange = (e) => {
		formik.setFieldValue('listIds', handlers.handleList(e, formik.values.listIds));
		const { checked, name } = e.target;
		let selectedItems;
		if (checked) {
			selectedItems = [...formik.values.listNames, name];
			recordEvent('Filtered lists', {
				filteredLists: selectedItems,
				minFollowers: formik.values.minFollowers,
				maxFollowers: formik.values.maxFollowers,
				influencersFiltered,
			});
		} else {
			selectedItems = formik.values.listNames.filter((v) => v !== name);
			recordEvent('Lists filtered post unfiltering', {
				filteredLists: selectedItems,
				minFollowers: formik.values.minFollowers,
				maxFollowers: formik.values.maxFollowers,
				influencersFiltered,
			});
		}
		formik.setFieldValue('listNames', selectedItems);
		formik.handleSubmit();
	};
	const minFollowers = (body) => {
		recordEvent('Applied Followers filters', {
			minFollowers: body,
			maxFollowers: formik.values.maxFollowers,
			influencersFiltered,
		});
	};
	const maxFollowers = (body) => {
		recordEvent('Applied Followers filters', {
			minFollowers: formik.values.minFollowers,
			maxFollowers: body,
			influencersFiltered,
		});
	};
	const { setFiltersClicked } = useUI();

	return (
		<SubHeaderRight>
			{lists && (
				<CommonFilterTag
					title='Showing '
					text={
						lists.length === 0 || !formik.values.listNames.length ? (
							<Badge className='fs-6'>All Influencers</Badge>
						) : (
							lists &&
							lists.map((list) =>
								formik.values.listNames.includes(list.listName) ? (
									<Badge className='fs-6'>{list.listName}</Badge>
								) : (
									''
								),
							)
						)
					}
					// text={
					//     formik.values.listNames.length !== 0 ?
					//         formik.values.listNames.map(listName => (
					//             `${listName} , `
					//         )) :
					//         "All list"
					// }
				/>
			)}
			<SubheaderSeparator />
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<Button icon='Filter' color='primary' isLight>
						Filter
						{/* <span
							className={classNames(
								'position-absolute',
								'top-0 start-95',
								'translate-middle',
								'badge',
								'rounded-pill',
								'bg-danger',
								'border border-2',
								{
									'border-white': !darkModeStatus,
									'border-dark': darkModeStatus,
								},
							)}>
							<span className='visually-hidden'>filter</span>
						</span> */}
					</Button>
				</DropdownToggle>
				<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
					<div className='container py-2'>
						<form className='row g-3' onSubmit={formik.handleSubmit}>
							<div className='col-12'>
								<FormGroup>
									<Label htmlFor='minPrice'>Followers</Label>
									<InputGroup>
										<Input
											id='minFollowers'
											ariaLabel='Minimum Followers'
											placeholder='Min.'
											onChange={(e) => {
												formik.setFieldValue(
													'minFollowers',
													e.target.value,
												);
												minFollowers(e.target.value);
											}}
											value={formik.values.minFollowers}
										/>
										<InputGroupText>to</InputGroupText>
										<Input
											id='maxFollowers'
											ariaLabel='Maximum Followers'
											placeholder='Max.'
											onChange={(e) => {
												formik.setFieldValue(
													'maxFollowers',
													e.target.value,
												);
												maxFollowers(e.target.value);
											}}
											value={formik.values.maxFollowers}
										/>
									</InputGroup>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									{lists && lists.length ? (
										<ChecksGroup>
											{lists.map((list, index) => (
												<Checks
													// eslint-disable-next-line react/no-array-index-key
													key={`list-check-${index}`}
													id={list.listName}
													label={list.listName}
													name={list.listName}
													value={list._id}
													onChange={handleChange}
													checked={formik.values.listIds.includes(
														list._id,
														list.listName,
													)}
												/>
											))}
										</ChecksGroup>
									) : (
										<p>Create A New List</p>
									)}
								</FormGroup>
							</div>
							<div className='col-6'>
								<Button color='primary' className='w-100' type='submit'>
									Filter
								</Button>
							</div>
							<div className='col-6'>
								<Button
									color='primary'
									className='w-100'
									onClick={() => {
										formik.handleReset();
										setFiltersClicked(false);
									}}>
									Reset
								</Button>
							</div>
						</form>
					</div>
				</DropdownMenu>
			</Dropdown>
			<SubheaderSeparator />
		</SubHeaderRight>
	);
};

export default FilterComponent;
