
import classNames from 'classnames';
import React from 'react';
import { checkArrayData } from '../../../../helpers/helpers';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
} from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import AudienceDataChart from './report_Part_Components/AudienceDataChart';
import AudienceStat from './report_Part_Components/AudienceStat';
import CardBarChart from './report_Part_Components/CardBarChart';
import GenderPieChar from './report_Part_Components/GenderPieChar';
import Popovers from '../../../bootstrap/Popovers';

const AudienceLikersData = ({ report, darkModeStatus, isDownloading }) => {
	const checkData = (value) => {
		if (value && value.length) {
			return true;
		}
		return false;
	};
	return (
		<div className='row px-5 scroll-margin'>
			<div className='row'>
				{/* Audience data by likes */}
				<div className='col-md-12'>
					<div className='d-flex justify-content-center'>
						<CardHeader>
							<CardLabel>
								<CardTitle>
									<span>Audience data by Likers</span>
								</CardTitle>
							</CardLabel>
						</CardHeader>
					</div>
				</div>
				<hr />
				{/* Likers Credibility */}
				<div className='col-md-4'>
					{report?.audienceLikers?.credibility && (
						<div
							className={classNames('d-flex align-items-center rounded-2 p-3', {
								'bg-l10-primary': !darkModeStatus,
								'bg-lo25-primary': darkModeStatus,
							})}>
							<div className='flex-shrink-0'>
								<Icon icon='SupervisorAccount' size='4x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{(
										Math.round(report.audienceLikers.credibility * 100 * 10) /
										10
									).toFixed(1)}
									%
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>Likers Credibility</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='Is this influencer purchasing likes? If an influencer has low likers credibility, they are trying to fake a high engagement rate.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					)}
				</div>
				{/* Likes from non followers */}
				<div className='col-md-4'>
					{report?.audienceLikers?.nonFollowerLikes && (
						<div
							className={classNames('d-flex align-items-center rounded-2 p-3', {
								'bg-l10-primary': !darkModeStatus,
								'bg-lo25-primary': darkModeStatus,
							})}>
							<div className='flex-shrink-0'>
								<Icon icon='SupervisorAccount' size='4x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{(
										Math.round(report.audienceLikers.nonFollowerLikes * 1000) /
										10
									).toFixed(1)}
									%
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>
										Likes from non{' '}
										{report.influencerType === 'youtube'
											? 'Subscribers'
											: 'followers'}
									</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='The higher this number is,the more effective this influencer is at reaching outside of their existing audience and could be a sign of "viral" potential'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					)}
				</div>
				{/* Location by Country */}
				{checkArrayData(report?.audienceLikers?.geoCountries) && (
					<AudienceDataChart
						key='Audience Likes by Countries'
						data={report?.audienceLikers?.geoCountries}
						title='Audience Likers by Countries'
						desc="This indicates the countries the influencer's audience is located in."
					/>
				)}

				{/* Location by City */}
				{checkArrayData(report?.audienceLikers?.geoCities) && (
					<AudienceDataChart
						key='Audience Likes by Cities'
						data={report?.audienceLikers?.geoCities}
						title='Audience Likers by Cities'
						desc="This indicates the cities the influencer's audience is located in."
					/>
				)}

				{/* Age Split */}
				{checkArrayData(report?.audienceLikers?.ages) && (
					<AudienceDataChart
						key="Audience Liker's Age Split"
						data={report?.audienceLikers?.ages}
						title='Audience Likers Age Split'
						desc='A breakdown of the age an influencer reaches.'
					/>
				)}

				{/* Age and gender split */}
				{checkData(report?.audienceLikers?.gendersPerAge) && (
					<CardBarChart
						isDownloading={isDownloading}
						data={report?.audienceLikers}
						title='Age and gender split of Likers'
						desc='A breakdown of the age and gender an influencer reaches.'
					/>
				)}
				{/* Gender split */}
				{checkArrayData(report?.audienceLikers?.genders) && (
					<GenderPieChar
						data={report?.audienceLikers?.genders}
						title='Gender Split by Audience Likers'
						desc='What gender categories does the influencer reach?'
					/>
				)}
				{/* Brand Affinity */}
				{checkArrayData(report?.audienceLikers?.brandAffinity) && (
					<AudienceStat
						key='Brand Affinity by Audience Likers'
						isDownloading={isDownloading}
						data={report?.audienceLikers?.brandAffinity}
						title='Brand Affinity by Audience Likers'
						desc='Brand Affinity shows which brands the audience frequently interacts.'
					/>
				)}

				{/* Interests */}
				{checkArrayData(report?.audienceLikers?.interests) && (
					<AudienceStat
						key='Interests of Audience Likers'
						isDownloading={isDownloading}
						data={report?.audienceLikers?.interests}
						title='Interests of Audience Likers'
						desc='These are the topics the audience posts about and interacts with the most often.'
					/>
				)}

				{/* Popular # and @ */}
				<div className='col-md-6'>
					<Card stretch>
						{/* <CardHeader className='py-2'>
                            <CardLabel>
                                <CardTitle>Popular # and @</CardTitle>
                            </CardLabel>
                        </CardHeader> */}
						<CardBody className='py-4'>
							<div className='row'>
								<div className='row'>
									<div className='h3 bw-bold'>HashTags</div>
									{report?.hashtags &&
										report.hashtags.length !== 0 &&
										report.hashtags.map((hash) => (
											<div className='col-md-6' key={hash}>
												#{hash.tag}
											</div>
										))}
								</div>
								<div className='row py-2'>
									<div className='h3 bw-bold py-2'>Mentions</div>
									{report?.mentions &&
										report.mentions.length !== 0 &&
										report.mentions.map((mention) => (
											<div className='col-md-6' key={mention}>
												@{mention.tag}
											</div>
										))}
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
				{/* Gender & Split */}
				{/* <div className='col-md-12'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Gender & Split</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Gender</th>
                                            <th scope='col'>Commentors</th>
                                            <th scope='col'>Followers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope='row'>Male</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div> */}
				{/* Language */}
				{/* <div className='col-md-12'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Language</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <table class='table table-modern'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Language </th>
                                            <th scope='col'>Commentors</th>
                                            <th scope='col'>Followers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope='row'>Hindi</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Marathi</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Bengali</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div> */}
				{/* Age Split */}
				{/* <div className='col-md-12'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Age Split</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Age</th>
                                            <th scope='col'>Commentors</th>
                                            <th scope='col'>Followers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope='row'>Male</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div> */}
				{/* Male age Split */}
				{/* <div className='col-md-12'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Male age Split</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <table class='table table-modern'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Age</th>
                                            <th scope='col'>Commentors</th>
                                            <th scope='col'>Followers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope='row'>Male</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div> */}
				{/* Female Age Split */}
				{/* <div className='col-md-12'>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel>
                                <CardTitle>Female Age Split</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <th scope='col'>Age</th>
                                            <th scope='col'>Commentors</th>
                                            <th scope='col'>Followers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope='row'>Male</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                        </tr>
                                        <tr>
                                            <th scope='row'>Female</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </div> */}
			</div>
		</div>
	);
};

export default AudienceLikersData;