import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import recordEvent from '../../mixpanel/mixpanel';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import {
	CRM_COMPANIES_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
	CRM_PROJECTS_STORE_CONSTANTS,
	CRM_TASKS_STORE_CONSTANTS,
} from '../../../../store/constants.store';

async function taskRemoveAssociation(input) {
	const { data } = await http.post(`${API_ENDPOINTS.CRM_TASK_REMOVE_ASSOCIATION}`, input);
	return data;
}

const useTaskRemoveAssociationMutation = () => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation((input) => taskRemoveAssociation(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				dispatch({
					type: CRM_TASKS_STORE_CONSTANTS.UPDATE_CRM_TASKS,
					payload: `/Task/Remove/Associate/${new Date()}`,
				});
				addToast(successToast({ message: 'Task associated Deleted' }), {
					autoDismiss: true,
				});
				recordEvent(`Deleted Task Association(CRM)`, {
					input,
					output:res
				});
			} else {
				addToast(dangerToast({ message: `${res.message}` }), {
					autoDismiss: true,
				});
				recordEvent(res.message, {
					taskDetails:res
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({message:err.response.data.errorMessage}), {
				autoDismiss: true,
			});
			recordEvent(`Failed to Delete Task Association(CRM)`, {
				input,
				output:err
			});
		},
	});
};
export default useTaskRemoveAssociationMutation;
