import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, { CardBody } from '../../../../bootstrap/Card';
import Button from '../../../../bootstrap/Button';
import Avatar from '../../../../Avatar';
import { cardIcon, profileUrl } from '../../../../../helpers/helpers';
// import Card, { CardBody } from './bootstrap/Card';
// import Button from './bootstrap/Button';
// import Avatar from './Avatar';

const UserCard = ({
	userId,
	name,
	url,
	position,
	src,
	srcSet,
	color,
	mail,
	phone,
	influencerType,
	onChat,
	...props
}) => {
	console.log({
		userId,
		url,
		name,
		srcSet
	})
	const cardColor = (typeOfInfluencer) => {
		if (typeOfInfluencer === 'youtube') {
			return 'danger';
		}
		if (typeOfInfluencer === 'tiktok') {
			return 'info';
		}
		return 'secondary';
	};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Card {...props} className={classNames(props.className)} stretch>
			<CardBody className='d-flex align-items-center'>
				<div className='flex-grow-1'>
					<div className='fs-5 fw-bold'>{name}</div>
					{position && (
						<a
							href={profileUrl(url, position, userId, influencerType)}
							target='_blank'
							rel='noreferrer'
							className='text-muted'>
							@{position}
						</a>
					)}
					<div className='row mt-2 g-3'>
						{userId && (
							<div className='col-auto'>
								<Button
									color={cardColor(influencerType)}
									icon={cardIcon(influencerType)}
									isLight
									aria-label={influencerType}
									target='_blank'
									rel='noreferrer'
									tag='a'
									href={profileUrl(url, position, userId, influencerType)}
								/>
							</div>
						)}
						{phone && (
							<div className='col-auto'>
								<Button
									color='info'
									icon='PhoneIphone'
									isLight
									aria-label='Phone'
									tag='a'
									href={`tel:${phone}`}
								/>
							</div>
						)}
						{onChat && (
							<div className='col-auto'>
								<Button
									color='info'
									icon='Sms'
									isLight
									aria-label='Chat'
									onClick={onChat}
								/>
							</div>
						)}
					</div>
				</div>
				{(src || srcSet) && (
					<div className='flex-shrink-0'>
						<Avatar
							src={src || null}
							srcSet={srcSet || null}
							color={color}
							className='rounded-circle'
							shadow='none'
							size={110}
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
};
UserCard.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	position: PropTypes.string,
	src: PropTypes.string,
	srcSet: PropTypes.string,
	color: PropTypes.string,
	mail: PropTypes.string,
	phone: PropTypes.string,
	onChat: PropTypes.func,
};
UserCard.defaultProps = {
	className: null,
	position: null,
	src: null,
	srcSet: null,
	color: null,
	mail: null,
	phone: null,
	onChat: null,
};

export default UserCard;
