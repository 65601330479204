import { takeLatest, put, select } from 'redux-saga/effects';
import { CRM_MAILS_STORE_CONSTANTS } from '../../../constants.store';
import { selectMailSent } from './crm-mails-selector';
import { setMailSent } from './crm-mails-slice';

function* mailSent(actions) {
	try {
		const fetchMails = yield select(selectMailSent);
		yield put(setMailSent(!fetchMails));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* crmMailsSaga() {
	yield takeLatest(CRM_MAILS_STORE_CONSTANTS.MAIL_SENT, mailSent);
}
