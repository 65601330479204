import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';

async function getProjects() {
	const { data } = await http.get(API_ENDPOINTS.GET_PROJECT_INFLUENCERS);
	return data;
}

const useGetProjectsQuery = (options) => {
	return useQuery([API_ENDPOINTS.GET_PROJECT_INFLUENCERS, options], getProjects);
};

export default useGetProjectsQuery;
