import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';

async function fetchInfluencerSuggestion({ queryKey }) {
	const [_key, _params] = queryKey;
	const { query } = _params;
	const res = await http.get(`${API_ENDPOINTS.GET_INFLUENCERS_SUGGESTIONS}?query=${query}`);
	return res.data.data;
}

const useInfluencerSuggestionQuery = (options) => {
	return useQuery(
		[API_ENDPOINTS.GET_INFLUENCERS_SUGGESTIONS, options],
		fetchInfluencerSuggestion,
	);
};

export default useInfluencerSuggestionQuery;
