import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useDeleteSearchFilter } from '../../../../framework/basic-rest/discovery/savedHistory/delete-save-filters';
import { useSaveSearchFilter } from '../../../../framework/basic-rest/discovery/savedHistory/save-search-filters';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import { DISCOVERY_STORE_CONSTANTS } from '../../../../store/constants.store';
import Button from '../../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardTitle,
} from '../../../bootstrap/Card';
import SaveSearchModal from '../advancedSearch/SaveSearchModal';

const SavedFilterCard = ({ id, title, filter, desc }) => {
	const dispatch = useDispatch();
	const applyFilter = () => {
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.APPLY_SEARCH_FILTERS,
			payload: filter,
		});
	};

	const { mutate: saveFilters, isLoading: isFilterSaved } = useSaveSearchFilter();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id,
			name: title,
			desc,
		},
		onSubmit: (values) => {
			ConfirmAlert(
				'Save Search Filter',
				'Do you really want to save the search filter',
				() => {
					saveFilters({ ...values });
				},
			);
		},
	});

	const [isOpen, setIsOpen] = useState(false);

	const { mutate: deleteSearch } = useDeleteSearchFilter();

	const onDelete = () => {
		ConfirmAlert('Delete Filter', 'Are you really want to delete this filter', () => {
			deleteSearch({
				id,
			});
		});
	};

	return (
		<>
			<SaveSearchModal formik={formik} isOpen={isOpen} setIsOpen={setIsOpen} />
			<Card borderSize={2}>
				<CardHeader>
					<CardTitle className='w-100'>
						<div className='d-flex justify-content-between w-100'>
							<div className='title'>{title}</div>
							<div className='d-flex w-25 justify-content-between'>
								<Button
									color='info'
									isLight
									icon='Edit'
									onClick={() => setIsOpen(true)}
								/>
								<Button color='danger' isLight icon='Delete' onClick={onDelete} />
							</div>
						</div>
					</CardTitle>
				</CardHeader>
				<CardBody>
					{filter.platform} - {desc}
				</CardBody>
				<CardFooter>
					<CardFooterRight>
						<Button onClick={applyFilter}>Apply</Button>
					</CardFooterRight>
				</CardFooter>
			</Card>
		</>
	);
};

export default SavedFilterCard;
