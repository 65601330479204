import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../store/App/Auth/auth.selector';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const userDetails = useSelector(selectAuthUser);

	return <UserContext.Provider value={userDetails}>{children}</UserContext.Provider>;
};

export default UserContext;
