export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const checkArrayData = (value) => {
	if (value && value.length) {
		return value;
	}
	return false;
};

export const defaultSelectedValue = (value) => {
	if (value) {
		return [value];
	}
	return [];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const profileUrl = (url, username, userId, influencerType) => {
	if (url) {
		return url;
	}
	if (influencerType === 'youtube') {
		return `https://www.youtube.com/channel/${userId}`;
	}
	if (influencerType === 'tiktok') {
		return `https://www.tiktok.com/@${userId}`;
	}
	return `https://www.instagram.com/${username}/`;
};

export const cardIcon = (influencerType) => {
	if (influencerType !== 'tiktok') {
		return influencerType;
	}
	return 'CustomTiktok';
};
export const cardColor = (typeOfInfluencer) => {
	if (typeOfInfluencer === 'youtube') {
		return 'danger';
	}
	if (typeOfInfluencer === 'tiktok') {
		return 'info';
	}
	return 'secondary';
};
export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) => {
	try {
		return (
			text &&
			text
				.toUpperCase()
				.match(/\b(\w)/g)
				.join('')
				.substring(0, letterCount)
		);
	} catch (e) {
		const alphabet = 'abcdefghijklmnopqrstuvwxyz';

		return alphabet[Math.floor(Math.random() * alphabet.length)];
	}
};

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const fixDecimal = (value, count = 2) => {
	// console.log(value);
	// console.log(count);
	return value.toFixed(count);
};

// To get a first Letter of string capital
export const FirstLetterCaps = (strVal) => {
	if (strVal) {
		strVal = strVal[0].toUpperCase() + strVal.slice(1, strVal.length).toLowerCase();
	}
	return strVal;
};

export const nFormatter = (num, digits = 2) => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: ' M' },
		{ value: 1e10, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	const item = lookup
		.slice()
		.reverse()
		.find((e) => {
			return num >= e.value;
		});
	return item
		? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
		: num?.toFixed(digits) || 0;
};

export const shortString = (value, count = 100) => {
	return value.length > count ? `${value.substring(0, count)}...` : value;
};

export const toDateString = (date) => {
	return new Date(date).toDateString();
};
export const ISOtoGMT = (date) => {
	return toDateString(new Date(date).toGMTString());
};
export const GMTtoISO = (date) => {
	return toDateString(new Date(date).toISOString());
};
export const btoaEncoded = (string) => {
	let encrypt = null;
	try {
		encrypt = window.btoa(string);
	} catch (e) {
		window.location.href = '/';
	}
	return encrypt;
};
export const atobDecode = (token) => {
	let decrypt = null;
	try {
		decrypt = window.atob(token);
	} catch (e) {
		window.location.href = '/';
	}
	return decrypt;
};
export const btoaLoginDetails = (json) => {
	let encrypt = null;
	try {
		encrypt = btoaEncoded(JSON.stringify(json));
	} catch (e) {
		window.location.href = '/';
	}
	return encrypt;
};
export const atobLoginDetails = (token) => {
	let decrypt = null;
	try {
		decrypt = JSON.parse(atobDecode(token));
	} catch (e) {
		window.location.href = '/';
	}
	return decrypt;
};
export const getFieldFromArrayOfObj = (array) => {
	const obj = {};
	array.forEach(function (element) {
		Object.keys(element).forEach(function (field) {
			if (obj[field]) obj[field].push(element[field]);
			else obj[field] = [element[field]];
		});
	});

	return obj;
};

export const checkArray = (array) => {
	const checkedArray = array.filter((el) => {
		if (el) {
			return el;
		}
		return null;
	});
	return checkedArray.length;
};
