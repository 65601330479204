import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import {
	fetchCRMCompany,
	useCRMCompanyQuery,
} from '../../../../framework/basic-rest/crm/company/get-crm-companies';
import {
	getAllCrmCreators,
	useGetAllCrmCreators,
} from '../../../../framework/basic-rest/crm/creators/get-crm-creators';
import { getAllCrmNotes } from '../../../../framework/basic-rest/crm/notes/get-crm-notes';
import {
	getAllCrmProjects,
	useGetAllCrmProjects,
} from '../../../../framework/basic-rest/crm/projects/get-crm-projects';
import { getAllCrmTasks } from '../../../../framework/basic-rest/crm/tasks/get-crm-tasks';
import { fetchAllListNames } from '../../../../framework/basic-rest/list/get-all-list-names';
// import { fetchCRMCreators } from '../../../../framework/basic-rest/crm/get-crm-creators';
import {
	CRM_COMPANIES_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
	CRM_PROJECTS_STORE_CONSTANTS,
	CRM_STORE_CONTANT,
} from '../../../constants.store';
import { setLists } from '../../List/list.slice';
import { setCrmCompany } from '../companies/crm.companies.slice';
import { setCrmCreator } from '../creators/crm.creators.slice';
import { setCrmNotes } from '../notes/crm.notes.slice';
import { setCrmProject } from '../projects/crm.projects.slice';
import { setCrmTasks } from '../tasks/crm-tasks-slice';

function* setInitCrmData(actions) {
	try {
		// const Company = actions.payload;
		const companies = yield call(fetchCRMCompany);
		yield put(setCrmCompany(companies.data));
		const creators = yield call(getAllCrmCreators);
		yield put(setCrmCreator(creators.data));
		const projects = yield call(getAllCrmProjects);
		yield put(setCrmProject(projects.data));
		const tasks = yield call(getAllCrmTasks);
		yield put(setCrmTasks(tasks.data));
		const notes = yield call(getAllCrmNotes);
		yield put(setCrmNotes(notes.data));

		return true;
	} catch (error) {
		return false;
	}
}

export default function* initCrmSaga() {
	yield takeLatest(CRM_STORE_CONTANT.GET_ALL_CRM_DATA, setInitCrmData);
}
