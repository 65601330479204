import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

const fetchDevicePlatform = async (input) => {
	const { data } = await http.post(`${API_ENDPOINTS.GET_EVENTS_BASED_ON_DEVICE_PLATFORM}`, input);
	return data?.data;
};
const useFetchDevicePlatform = (options) => {
	return useQuery(
		[API_ENDPOINTS.GET_EVENTS_BASED_ON_DEVICE_PLATFORM, options],
		fetchDevicePlatform,
	);
};
export { useFetchDevicePlatform };

export default fetchDevicePlatform;
