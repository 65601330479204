import { useQuery, useQueryClient } from "react-query"
import { useDispatch, useSelector } from "react-redux";
import http from "../utils/http";
import API_ENDPOINTS from "../utils/api-endpoints";
import { CREDITS_STORE_CONSTANTS } from "../../../store/constants.store";
import { selectIsCreditsUpdate } from "../../../store/App/Credits/credits.selector";
import { selectIsWorkspaceUpdate } from "../../../store/App/ControlPanel/controlPanel.selector";
import { selectWorkspace } from "../../../store/App/Workspace/workspace.selector";

export const fetchCredits = async () => {
	// const [_key, _params] = queryKey;
	// const { userId, platform } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_CREDITS}`,
	);
	return data.data || [];
};

export const useCreditsQuery = (options) => {
    const dispatch = useDispatch();
    const isCreditsUpdate = useSelector(selectIsCreditsUpdate);
    const isWorkspaceUpdate = useSelector(selectIsWorkspaceUpdate);
    const workspace = useSelector(selectWorkspace);
    const onSuccess = (data) =>{
        // cache.invalidateQueries(API_ENDPOINTS.ADMIN_GET_ALL_USERS);
        dispatch({
            type: CREDITS_STORE_CONSTANTS.SET_CREDITS_DETAILS,
            payload: data.credits
        })
    }
    return useQuery([API_ENDPOINTS.GET_CREDITS, options, isCreditsUpdate, isWorkspaceUpdate], fetchCredits, {
        onSuccess,
        enabled: !!workspace
    })
}