import { Alert } from 'bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


export const ConfirmAlert = (
  title,
  message,
  onYes,
  onNo
) => {
  confirmAlert({
    title,
    message,
    overlayClassName: "custom-confirm-alert-overlay",
    buttons: [
      {
        label: 'Yes',
        className: "btn-primary",
        onClick: () => onYes()
      },
      {
        label: 'No',
        className: "btn-primary",
        onClick: onNo
      }
    ]
  });
}