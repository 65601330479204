export const calculate = (list) => {
	const statusFactory = {
		approved: 0,
		disapproved: 0,
		undecided: 0,
	};
	list?.influencers?.map((influencer) => {
		statusFactory[influencer?.approvedStatus?.toLowerCase()] += 1;
		return true;
	});
	return statusFactory;
};
