import { takeLatest, put, select } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import { selectAuthUser, selectIsAuthenticated } from './auth.selector';
import {
	setAuthToken,
	setAuthUser,
	setExistingUser,
	setIsAuthenticated,
	setSignupDetails,
	setLoginDetails,
} from './auth.slice';
import { deleteToken, getToken, removeWorkspace, setToken } from './helper/cookies';
import {
	ANALYTICS_STORE_CONSTANTS,
	AUTH_STORE_CONSTANTS,
	CRM_STORE_CONTANT,
	WORKSPACE_STORE_CONSTANTS,
} from '../../constants.store';

function* startLoginSync(action) {
	try {
		// secret will be in env on deployment as JWT_SECRET
		const secret = '51655468576D5A71343777217A25432A462D4A614E645266556A586E32723575';

		// uncomment below to get token form cookie
		const token = getToken();
		const valid = true; // validateToken(token);
		if (valid && token) {
			const decoded = jwt(token);
			console.log(decoded, 'decoded');
			yield put(setAuthToken(token));
			yield put(setAuthUser(decoded));
			yield put({ type: AUTH_STORE_CONSTANTS.AUTHORIZE });
			yield put({ type: WORKSPACE_STORE_CONSTANTS.SYNC_WORKSPACE_DETAILS });
			yield put({ type: CRM_STORE_CONTANT.GET_ALL_CRM_DATA });
			yield put({ type: ANALYTICS_STORE_CONSTANTS.GET_SOCIAL_ANALYTICS_META });
			return true;
		}
	} catch (error) {
		console.log(error);
		yield put({ type: AUTH_STORE_CONSTANTS.UNAUTHORIZE });
	}
	return false;
}

function* doLogin(action) {
	try {
		const {
			user: { email, passwordHash, authToken, _id: userId, firstName, lastName },
		} = action.payload;

		console.log(action, 'doLogin');
		const user = {
			userId,
			username: firstName,
			firstName,
			lastName,
			email,
		};
		yield put(setAuthToken(authToken[authToken.length - 1]));
		yield setToken(authToken[authToken.length - 1]);

		yield put(setAuthUser(user));
		yield put({ type: AUTH_STORE_CONSTANTS.AUTHORIZE });
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* syncAuthToken(action) {
	try {
		const { token } = action.payload;
		console.log(token);
		yield put(setAuthToken(token));
		yield setToken(token);
		const decoded = jwt(token);
		yield put(setAuthUser(decoded));
		yield put({ type: AUTH_STORE_CONSTANTS.AUTHORIZE });
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* makeAuthorize(action) {
	try {
		const authUser = yield select(selectAuthUser);
		if (!authUser) {
			throw new Error('authUser is not setted !');
		} else {
			yield put(setIsAuthenticated(true));
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* initCrmData(action) {
	try {
		const authUser = yield select(selectAuthUser);
		if (!authUser) {
			throw new Error('authUser is not setted !');
		} else {
			yield put({ type: CRM_STORE_CONTANT.GET_ALL_CRM_DATA });
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* doLogout(action) {
	try {
		const authUser = yield select(selectAuthUser);
		const isAuthenticated = yield select(selectIsAuthenticated);
		if (!authUser) {
			throw new Error('authUser is not setted !');
		} else if (!isAuthenticated) {
			throw new Error('user is not Authenticated or already logged out !');
		} else {
			return yield put({ type: AUTH_STORE_CONSTANTS.UNAUTHORIZE });
		}
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* makeUnauthorize(action) {
	try {
		yield deleteToken();
		yield removeWorkspace();
		yield put({
			type: WORKSPACE_STORE_CONSTANTS.CLEAR_WORKSPACE,
			payload: `logOut/${new Date()}`,
		});
		yield put(setAuthToken(null));
		yield put(setAuthUser(null));
		yield put(setIsAuthenticated(false));
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* doSignup(action) {
	try {
		yield put(setSignupDetails(action.payload));

		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

function* isExistingUser(action) {
	try {
		yield put(setExistingUser(action.payload));

		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}
function* setValidUser(action) {
	try {
		yield put(setLoginDetails(action.payload));

		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
}

export default function* authSaga() {
	yield takeLatest(AUTH_STORE_CONSTANTS.LOGIN, doLogin);

	yield takeLatest(AUTH_STORE_CONSTANTS.SYNC_TOKEN, syncAuthToken);

	yield takeLatest(AUTH_STORE_CONSTANTS.LOGOUT, doLogout);

	yield takeLatest(AUTH_STORE_CONSTANTS.AUTHORIZE, makeAuthorize);

	yield takeLatest(AUTH_STORE_CONSTANTS.UNAUTHORIZE, makeUnauthorize);

	yield takeLatest(AUTH_STORE_CONSTANTS.SYNC_USER_DETAILS, startLoginSync);

	yield takeLatest(AUTH_STORE_CONSTANTS.SIGNUP, doSignup);

	yield takeLatest(AUTH_STORE_CONSTANTS.EXISTING_USER, isExistingUser);

	yield takeLatest(AUTH_STORE_CONSTANTS.SET_VALID_USER, setValidUser);

	yield takeLatest(CRM_STORE_CONTANT.SYNC_CRM_DATA, initCrmData);
}
