import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './analytics.slice';

const analyticsSlice = (state) => state.socialAnalytics || initialState;

export const selectSocialTrackingTypes = createSelector(
	[analyticsSlice],
	(state) => state.socialTrackingTypes,
);
export const selectSocialTrackingStatus = createSelector(
	[analyticsSlice],
	(state) => state.socialTrackingStatus,
);
export const selectSocialTrackingPlatform = createSelector(
	[analyticsSlice],
	(state) => state.socialTrackingPlatform,
);
export const selectInstagramPostTypes = createSelector(
	[analyticsSlice],
	(state) => state.instagramTrackingPostTypes,
);
export const selectYoutubePostTypes = createSelector(
	[analyticsSlice],
	(state) => state.youtubeTrackingPostTypes,
);
export const selectTiktokPostTypes = createSelector(
	[analyticsSlice],
	(state) => state.tiktokTrackingPostTypes,
);
export const selectSocialTrackingPostTypes = createSelector(
	[analyticsSlice],
	(state) => state.socialTrackingPostTypes,
);

export const selectCrmProjectId = createSelector([analyticsSlice], (state) => state.crmProjectId);

export const selectInfluencerViewStatus = createSelector(
	[analyticsSlice],
	(state) => state.influencerViewStatus,
);

export const selectAnalyticsViewStatus = createSelector(
	[analyticsSlice],
	(state) => state.analyticsViewStatus,
);
export const selectAnalyticsView = createSelector([analyticsSlice], (state) => state.analyticsView);
export const selectAnalyticsViewType = createSelector(
	[analyticsSlice],
	(state) => state.analyticsViewType,
);
export const selectAppkeys = createSelector([analyticsSlice], (state) => state.appkeys);
export const selectSetRefreshCommerceProject = createSelector(
	[analyticsSlice],
	(state) => state.refreshCommerceProject,
);
