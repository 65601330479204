import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './discovery.slice';

const discoverySlice = (state) => state.discovery || initialState;

export const selectSearchResults = createSelector([discoverySlice], (state) => state.searchResults);

export const selectInfluencerReport = createSelector(
	[discoverySlice],
	(state) => state.influencerReport,
);

export const selectSearchFilters = createSelector([discoverySlice], (state) => state.searchFilters);

export const selectActiveTabIndex = createSelector(
	[discoverySlice],
	(state) => state.activeTabIndex,
);

export const selectIsFilterClear = createSelector([discoverySlice], (state) => state.isFilterClear);

export const selectIsFilterApply = createSelector([discoverySlice], (state) => state.isFilterApply);

export const selectOptionFilter = createSelector([discoverySlice], (state) => state.optionFilter);

export const selectComparisonProfiles = createSelector([discoverySlice], (state) => state.comparisonProfiles);

export const selectComparisonReports = createSelector([discoverySlice], (state) => state.comparisonReports);
