import { useQueryClient } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Button from '../../bootstrap/Button';
import Input from '../../bootstrap/forms/Input';
import InputGroup from '../../bootstrap/forms/InputGroup';
import useUploadFile from '../../../framework/basic-rest/crm/use-upload';
import API_ENDPOINTS from '../../../framework/basic-rest/utils/api-endpoints';
import Modal, { ModalBody, ModalHeader } from '../../bootstrap/Modal';
import Popovers from '../../bootstrap/Popovers';

const FileImporter = ({
	id,
	className,
	formType,
	isOpen,
	setIsOpen,
	exportSample,
	sampleHeadersProject,
	exportSampleData,
	sampleLink,
	from,
}) => {
	const [uploadResponse, setUploadResponse] = useState(null);
	const { mutate: uploadFile, data: response } = useUploadFile();
	const queryClient = useQueryClient();
	useEffect(() => {
		queryClient.invalidateQueries([API_ENDPOINTS.GET_ALL_CRM_CREATORS]);
		queryClient.invalidateQueries([API_ENDPOINTS.GET_ALL_CRM_PROJECTS]);
		queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNIES]);
		queryClient.invalidateQueries([API_ENDPOINTS.GET_LISTS]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadResponse]);
	useEffect(() => {
		setUploadResponse(response);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			file: null,
		},
		onSubmit: () => {
			const formData = new FormData();
			formData.append('file', formik.values.file);
			uploadFile({ formData, formType });
		},
	});
	const onFileSelect = (e) => {
		formik.setFieldValue('file', e.target.files[0]);
	};

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop isAnimation={false}>
			<ModalBody>
				<ModalHeader setIsOpen={setIsOpen}>
					<h3 className='fs-bold'>Upload</h3>
				</ModalHeader>
				<InputGroup id={id} className={className}>
					<Input type='file' onChange={onFileSelect} />
					<Button color='info' icon='CloudUpload' onClick={formik.submitForm}>
						Upload
					</Button>
				</InputGroup>
				<div
					className='row pt-3 d-flex'
					style={{ paddingRight: '28rem', paddingLeft: '1rem' }}>
					<Popovers
						placement='left-end'
						className='mw-100 px-10 overflow-hidden'
						data-tour='date-range-menu'
						bodyClassName='p-4'
						trigger='hover'
						desc='Download sample csv for imports'>
						<Button
							style={{ width: '100%' }}
							icon='CloudDownload'
							isLight
							color='info'
							onClick={() => {
								exportSample();
							}}>
							Sample
						</Button>
					</Popovers>
					<CSVLink
						headers={sampleHeadersProject}
						data={exportSampleData}
						ref={sampleLink}
						filename={`sample${from}.csv`}
					/>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default FileImporter;
