import React, { useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
} from '../../../../bootstrap/Card';
import Chart from '../../../../extras/Chart';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const CardBarChart = ({ data, title, height, desc, className="col-md-6" }) => {
	const checkData = (value) => {
		if (value && value.length) {
			return true;
		}
		return false;
	};

	const [dataBar] = useState({
		series: [
			{
				name: 'male',
				data:
					checkData(data?.gendersPerAge) &&
					data?.gendersPerAge.map((val) => {
						return (Math.round(val.male * 1000) / 10).toFixed(1);
					}),
			},
			{
				name: 'female',
				data:
					checkData(data?.gendersPerAge) &&
					data?.gendersPerAge.map((val) => {
						return (Math.round(val.female * 1000) / 10).toFixed(1);
					}),
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '55%',
					endingShape: 'rounded',
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				categories:
					checkData(data?.gendersPerAge) && data?.gendersPerAge.map((val) => val.code),
			},
			yaxis: {
				title: {
					text: 'Pecentage',
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter(val) {
						return `${val} %`;
					},
				},
			},
		},
	});

	return (
		<div className={className}>
			<Card stretch>
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
					<CardActions>
						<Popovers desc={desc} trigger='hover'>
							<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
						</Popovers>
					</CardActions>
				</CardHeader>
				<CardBody>
					<Chart
						series={dataBar.series}
						options={dataBar.options}
						height={height}
						type='bar'
						width={dataBar.options.chart.width}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

CardBarChart.defaultProps = {
	height: 'auto',
};

export default CardBarChart;
