export const PERMISSION = [
    {
        _id: "READ",
        type: "READ",
        permission: "Read",
    },
    {
        _id: "WRITE",
        type: "WRITE",
        permission: "Write",
    },
    {
        _id: "ADMIN",
        type: "ADMIN",
        permission: "Admin",
    },
];

export const ACCESS_TYPE = [
    {
        _id: "CUSTOM",
        type: "CUSTOM",
        permission: "Custom"
    },
    {
        _id: "FULL",
        type: "FULL",
        permission: "Full"
    },
    {
        _id: "NULL",
        type: "NULL",
        permission: "No Access"
    }
]

export const Modules = [
    {
        moduleType: "DISCOVERY",

    },
    {
        moduleType: "LIST"
    },
    {
        moduleType: "CRM"
    },
    {
        moduleType: "ANALYTICS"
    },
    {
        moduleType: "VISIONAI"
    },
]

export const ItemName = {
    lists: 'listName',
    crm_company: '',
    crm_project: '',
    crm_creator: '',
    social_project: 'projectName',
}

export const ITEM_NAME = {
    LIST: 'listName',
    CRM_COMPANY: '',
    CRM_PROJECT: '',
    CRM_CREATOR: '',
    ANALYTICS: 'projectName',
}

export const ItemRef = {
    LIST: 'lists',
    CRM_COMPANY: 'crm_company',
    CRM_PROJECT: 'crm_project',
    CRM_CREATOR: 'crm_creator',
    ANALYTICS: 'social_project',
}