import React, { useState } from 'react';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
} from '../../../../bootstrap/Card';
import Chart from '../../../../extras/Chart';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const GenderPieChar = ({ data, title, height, desc }) => {
	const [chartData] = useState({
		series: data.map((val) => val.weight * 100),
		options: {
			chart: {
				type: 'donut',
				width: 380,
			},
			labels: data.map((val) => val.code),
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: 'bottom',
						},
					},
				},
			],
		},
	});

	return (
		<div className='col-md-10'>
			<Card stretch>
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
					<CardActions>
						<Popovers desc={desc} trigger='hover'>
							<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
						</Popovers>
					</CardActions>
				</CardHeader>
				<CardBody>
					<Chart
						series={chartData.series}
						options={chartData.options}
						type={chartData.options.chart.type}
						width={chartData.options.chart.width}
						height={height}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

GenderPieChar.defaultProps = {
	height: 'auto',
};

export default GenderPieChar;
