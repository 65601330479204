import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { ORGANIZATION_CONSTANTS } from '../../../store/constants.store';

const getOrganizationData = async ({ queryKey }) => {
	const [_, userId] = queryKey;
	const { data } = await http.post(`${API_ENDPOINTS.GET_ORGANIZATION_DETAIL}`, {
		memberId: userId,
	});
	return data.data || {};
};

export const useGetOrganizationData = (options) => {
	const dispatch = useDispatch();
	return useQuery([API_ENDPOINTS.GET_ORGANIZATION_DETAIL, options], getOrganizationData, {
		onSuccess(data) {
			dispatch({
				type: ORGANIZATION_CONSTANTS.GET_APP_ID,
				payload: data,
			});
		},
	});
};
