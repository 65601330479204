import { createSlice } from '@reduxjs/toolkit';
import { OVERVIEW_TYPES, SocialAnalyticsType } from '../../../framework/enums/Tracking';

export const initialState = {
	socialTrackingTypes: [],
	socialTrackingStatus: [],
	socialTrackingPlatform: [],
	instagramTrackingPostTypes: [],
	youtubeTrackingPostTypes: [],
	tiktokTrackingPostTypes: [],
	socialTrackingPostTypes: [],
	graphs: [],
	crmProjectId: null,
	influencerViewStatus: false,
	analyticsViewStatus: false,
	analyticsViewType: SocialAnalyticsType.PROJECT,
	analyticsView: OVERVIEW_TYPES.CONTENT,
	appkeys: null,
	refreshCommerceProject: false,
};

const slice = createSlice({
	name: 'socialAnalytics',
	initialState,
	reducers: {
		setSocialTrackingTypes: (state, action) => {
			state.socialTrackingTypes = action.payload;
		},
		setSocialTrackingStatus: (state, action) => {
			state.socialTrackingStatus = action.payload;
		},
		setSocialTrackingPlatform: (state, action) => {
			state.socialTrackingPlatform = action.payload;
		},
		setInstagramTrackingPostTypes: (state, action) => {
			state.instagramTrackingPostTypes = action.payload;
		},
		setYoutubeTrackingPostTypes: (state, action) => {
			state.youtubeTrackingPostTypes = action.payload;
		},
		setTiktokTrackingPostTypes: (state, action) => {
			state.tiktokTrackingPostTypes = action.payload;
		},
		setSocialTrackingPostTypes: (state, action) => {
			state.socialTrackingPostTypes = action.payload;
		},
		setGraphs: (state, action) => {
			state.graphs = action.payload;
		},
		setCrmProjectId: (state, action) => {
			state.crmProjectId = action.payload;
		},
		setInfluencerViewStatus: (state, action) => {
			state.influencerViewStatus = action.payload;
		},
		setAnalyticsViewStatus: (state, action) => {
			state.analyticsViewStatus = action.payload;
		},
		setAnalyticsView: (state, action) => {
			state.analyticsView = action.payload;
		},
		setAnalyticsViewType: (state, action) => {
			state.analyticsViewType = action.payload;
		},
		setAppkeys: (state, action) => {
			state.appkeys = action.payload;
		},
		setRefreshCommerceProject: (state, action) => {
			state.refreshCommerceProject = action.payload;
		},
	},
});

export const {
	setSocialTrackingTypes,
	setSocialTrackingStatus,
	setSocialTrackingPlatform,
	setInstagramTrackingPostTypes,
	setYoutubeTrackingPostTypes,
	setTiktokTrackingPostTypes,
	setSocialTrackingPostTypes,
	setGraphs,
	setCrmProjectId,

	setInfluencerViewStatus,
	setAnalyticsViewStatus,
	setAnalyticsView,
	setAnalyticsViewType,
	setAppkeys,
	setRefreshCommerceProject,
} = slice.actions;

export default slice.reducer;
