import React from 'react';
import Lottie from 'react-lottie';
import * as NoDataFound from '../../../assets/lottie/no_data_found.json';
import * as EmptySearch from '../../../assets/lottie/empty_search_result.json';
import NoDataAvailable from '../../../assets/img/No_Data_Found.png';
import * as NoData from '../../../assets/lottie/no_data.json';
import * as EmptySearchGreenTheme from '../../../assets/lottie/no-result-green-theme.json';
import * as NoList from '../../../assets/lottie/no-lists.json';
import * as SomethingWentWrong from '../../../assets/lottie/something_went_wrong.json';
import * as SearchForInfluencer from '../../../assets/lottie/search_for_influencer.json';

const defaultOptions = {
	loop: true,
	autoplay: true,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};
export const EmptyTableDataState = ({ message = '', height = 400, width = 400 }) => {
	return (
		<>
			<Lottie
				options={{ ...defaultOptions, animationData: NoData }}
				height={height}
				width={width}
			/>
			{/* <img
				src={NoDataAvailable}
				alt={message}
				height={300}
				width={300}
			/> */}
			{message && <div className='h5 text-center'>{message}</div>}
		</>
	);
};

export const EmptyDataState = ({ message }) => {
	return (
		<>
			{/* <Lottie options={{ ...defaultOptions, animationData: NoData }} height={300} width={400} /> */}
			<img src={NoDataAvailable} alt={message} height={300} width={300} />
			<div className='h5 text-center'>{message}</div>
		</>
	);
};

export const NoDataFound1 = ({ message }) => {
	return (
		<>
			<Lottie
				options={{ ...defaultOptions, animationData: NoDataFound }}
				height={400}
				width={400}
			/>
			<div className='h5 text-center w-25'>{message}</div>
		</>
	);
};

export const EmptySearchState = ({ height = 400, width = 400 }) => {
	return (
		<Lottie
			options={{ ...defaultOptions, animationData: EmptySearch }}
			height={height}
			width={width}
		/>
	);
};

export const EmptySearchStateGreen = ({ height = 400, width = 400 }) => {
	return (
		<Lottie
			options={{ ...defaultOptions, animationData: EmptySearchGreenTheme }}
			height={height}
			width={width}
		/>
	);
};

export const NoListState = ({ height = 400, width = 400 }) => {
	return (
		<Lottie
			options={{ ...defaultOptions, animationData: NoList }}
			height={height}
			width={width}
		/>
	);
};

export const SomethingWentWrongState = ({ height = 400, width = 400 }) => {
	return (
		<Lottie
			options={{ ...defaultOptions, animationData: SomethingWentWrong, loop: false }}
			height={height}
			width={width}
		/>
	);
};

export const SearchForInfluencerState = ({ height = 400, width = 400 }) => {
	return (
		<Lottie
			options={{ ...defaultOptions, animationData: SearchForInfluencer }}
			height={height}
			width={width}
		/>
	);
};
