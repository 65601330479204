export const TABS = {
	INSTAGRAM: {
		title: 'Instagram',
		icon: 'Instagram',
		class: 'instagram',
		filters: ['location', 'size', 'age', 'gender', 'interests', 'hashtags', 'mentions', 'bio'],
		view: 'ADVANCE_INSTAGRAM_FILTER_VIEW',
		value: 'instagram',
	},
	YOUTUBE: {
		title: 'Youtube',
		icon: 'Youtube',
		class: 'youtube',
		filters: ['location', 'age', 'gender', 'views per video', 'size', 'keywords'],
		view: 'ADVANCE_YOUTUBE_FILTER_VIEW',
		value: 'youtube',
	},
	TIKTOK: {
		title: 'Tiktok',
		icon: 'CustomTiktok',
		class: 'tiktok',
		filters: ['location', 'age', 'gender', 'views per video', 'size', 'average views', 'bio'],
		view: 'ADVANCE_TIKTOK_FILTER_VIEW',
		value: 'tiktok',
	},
};

export const TAB = [
	{
		tab: "instagram",
		value: "Instagram",
	}, {
		tab: "youtube",
		value: "Youtube",
	}, {
		tab: "tiktok",
		value: "Tiktok",
	}
]

export const rangeType = [
	{
		id: 1,
		from: 1000,
		to: 10000,
		label: 'Nano (1K - 10K)',
	},
	{
		id: 2,
		from: 10000,
		to: 100000,
		label: 'Micro (10K - 100K)',
	},
	{
		id: 3,
		from: 100000,
		to: 500000,
		label: 'Mid-Tier (100K - 500K)',
	},
	{
		id: 4,
		from: 500000,
		to: 1000000,
		label: 'Macro (500K - 1M)',
	},
	{
		id: 5,
		from: 1000000,
		to: null,
		label: 'Mega 1M+',
	},
];

export const viewRangeType = [
	{
		id: 1,
		from: 1000,
		to: 10000,
		label: '1K - 10K',
	},
	{
		id: 2,
		from: 10000,
		to: 50000,
		label: '10K - 50K',
	},
	{
		id: 3,
		from: 50000,
		to: 500000,
		label: '50K - 500K',
	},
	{
		id: 4,
		from: 500000,
		to: 1000000,
		label: '500K - 1M',
	},
	{
		id: 5,
		from: 1000000,
		to: null,
		label: '1M+',
	},
];

export const maxOptions = [
	{
		id: 1,
		value: 5000,
		label: '5,000',
	},
	{
		id: 2,
		value: 10000,
		label: '10,000',
	},
	{
		id: 3,
		value: 15000,
		label: '15,000',
	},
	{
		id: 4,
		value: 25000,
		label: '25,000',
	},
	{
		id: 5,
		value: 35000,
		label: '35,000',
	},
	{
		id: 6,
		value: 50000,
		label: '50,000',
	},
	{
		id: 7,
		value: 75000,
		label: '75,000',
	},
	{
		id: 8,
		value: 100000,
		label: '100,000',
	},
	{
		id: 9,
		value: 175000,
		label: '175,000',
	},
	{
		id: 10,
		value: 250000,
		label: '250,000',
	},
	{
		id: 11,
		value: 350000,
		label: '350,000',
	},
	{
		id: 12,
		value: 500000,
		label: '500,000',
	},
	{
		id: 13,
		value: 1000000,
		label: '1,000,000',
	},
	{
		id: 14,
		value: 2000000,
		label: '2,000,000',
	},
	{
		id: 15,
		value: 3000000,
		label: '3,000,000+',
	},
];

export const minOptions = [
	{
		id: 1,
		value: 1000,
		label: '1,000',
	},
	{
		id: 2,
		value: 3000,
		label: '3,000',
	},
	{
		id: 3,
		value: 5000,
		label: '5,000',
	},
	{
		id: 4,
		value: 10000,
		label: '10,000',
	},
	{
		id: 5,
		value: 15000,
		label: '15,000',
	},
	{
		id: 6,
		value: 25000,
		label: '25,000',
	},
	{
		id: 7,
		value: 35000,
		label: '35,000',
	},
	{
		id: 8,
		value: 50000,
		label: '50,000',
	},
	{
		id: 9,
		value: 75000,
		label: '75,000',
	},
	{
		id: 10,
		value: 100000,
		label: '100,000',
	},
	{
		id: 11,
		value: 175000,
		label: '175,000',
	},
	{
		id: 12,
		value: 250000,
		label: '250,000',
	},
	{
		id: 13,
		value: 350000,
		label: '350,000',
	},
	{
		id: 14,
		value: 500000,
		label: '500,000',
	},
	{
		id: 15,
		value: 1000000,
		label: '1,000,000',
	},
	{
		id: 16,
		value: 2000000,
		label: '2,000,000',
	},
];

export const growthMonthOptions = [
	'i1month',
	'i2months',
	'i3months',
	'i4months',
	'i5months',
	'i6months',
];

export const ERValue = [
	{
		id: 1,
		value: 0.01,
		label: '>1%',
	},
	{
		id: 2,
		value: 0.02,
		label: '>2%',
	},
	{
		id: 3,
		value: 0.03,
		label: '>3%',
	},
	{
		id: 4,
		value: 0.04,
		label: '>4%',
	},
	{
		id: 5,
		value: 0.05,
		label: '>5%',
	},
	{
		id: 6,
		value: 0.06,
		label: '>6%',
	},
	{
		id: 7,
		value: 0.07,
		label: '>7%',
	},
	{
		id: 8,
		value: 0.08,
		label: '>8%',
	},
	{
		id: 9,
		value: 0.09,
		label: '>9%',
	},
	{
		id: 10,
		value: 0.01,
		label: '>10%',
	},
];

export const Gender = [
	{
		id: 'infulencer-gender-male',
		label: 'Male',
		value: 'MALE',
	},
	{
		id: 'infulencer-gender-female',
		label: 'Female',
		value: 'FEMALE',
	},
	{
		id: 'infulencer-gender-any',
		label: 'Any',
		value: 'ANY',
	},
];

export const accountTypes = [
	{
		id: 1,
		value: 'Regular',
		label: 'Regular',
	},
	{
		id: 2,
		value: 'Business',
		label: 'Business',
	},
	{
		id: 3,
		value: 'Creator',
		label: 'Creator',
	},
];

export const ageOption = [
	{
		id: 1,
		value: '13-17',
		label: '13-17',
	},
	{
		id: 2,
		value: '18-24',
		label: '18-24',
	},
	{
		id: 3,
		value: '25-34',
		label: '25-34',
	},
	{
		id: 4,
		value: '35-44',
		label: '35-44',
	},
	{
		id: 5,
		value: '45-64',
		label: '45+',
	},
];

export const credibilityOptions = [
	{
		id: 1,
		value: (1 - 0.25).toString(),
		label: '≤25%',
	},
	{
		id: 2,
		value: (1 - 0.35).toString(),
		label: '≤35%',
	},
	{
		id: 3,
		value: (1 - 0.5).toString(),
		label: '≤50%',
	},
];

export const languageOptions = [
	{
		code: 'en',
		name: 'English',
		count: 89242290,
	},
	{
		code: 'es',
		name: 'Spanish',
		count: 54913954,
	},
	{
		code: 'pt',
		name: 'Portuguese',
		count: 52769151,
	},
	{
		code: 'fr',
		name: 'French',
		count: 37916082,
	},
	{
		code: 'ar',
		name: 'Arabic',
		count: 35415509,
	},
	{
		code: 'ru',
		name: 'Russian',
		count: 34503728,
	},
	{
		code: 'it',
		name: 'Italian',
		count: 27306506,
	},
	{
		code: 'de',
		name: 'German',
		count: 24973744,
	},
	{
		code: 'fa',
		name: 'Persian',
		count: 23381298,
	},
	{
		code: 'id',
		name: 'Indonesian',
		count: 21519963,
	},
];

export const initialFilterState = {
	influencerLocation: [],
	influencerInterests: [],
	influencerLanguage: null,
	influencerGender: null,
	lastposted: null,
	engagementRate: null,
	accountType: [],
	bio: '',
	hashtags: [],
	mentions: [],
	growthRate: null,
	keywords: '',
	Search: false,
	influencerFollowers: {},
	views: {},
	audienceLocation: [],
	audienceAge: [],
	audienceGender: null,
	audienceInterests: [],
	audienceLanguage: null,
	credibility: null,
	relevance: [],
	identifier: [],
	checked: '',
};
