const validateInfluencers = (values) => {
	const errors = {};
	// if (values.influencer.isCustom) {
	// 	if (!values.influencer.name) {
	// 		errors.name = 'Please enter influencer name';
	// 	}
	// } else
	// if (!values.influencer.globalInfluencerIndex) {
	// 	errors.globalInfluencerIndex = 'Please select an influencer';
	// }
	if (!values?.contentType) {
		errors.contentType = 'Choose content type';
	}
	// if (!values?.linkToTrack) {
	// 	errors.linkToTrack = 'Enter the URL to track';
	// } else if (!validateLink(values.platform, values.linkToTrack)) {
	// 	errors.linkToTrack = 'Enter valid url';
	// }
	// eslint-disable-next-line no-restricted-globals
	if (!parseInt(values.budget, 10) || isNaN(values.budget)) {
		errors.budget = 'Enter valid budget';
	} else if (values.budget > values.totalBudget) {
		errors.budget = 'Check your project budget.';
	}
	return errors;
};

// eslint-disable-next-line consistent-return
function validateLink(platform, url) {
	let regex = null;
	if (platform === 'INSTAGRAM') {
		// eslint-disable-next-line prefer-regex-literals
		regex = new RegExp(
			/(?:(?:https):\/\/)(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(?:p|reel)\/([^\\/?#&]+)/gim,
		);
	} else if (platform === 'YOUTUBE') {
		// eslint-disable-next-line prefer-regex-literals
		regex = new RegExp(
			/(?:https:\/\/)?((?:www|m)\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)*(?:v=|v\/|\/)([\w\-_]+)/gim,
		);
	}
	return regex.test(url);
}

export default validateInfluencers;
