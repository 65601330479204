import { call, put, takeLatest } from 'redux-saga/effects';
import { ANALYTICS_STORE_CONSTANTS } from '../../../constants.store';
import {
	setProjectContentStreams,
	setProjects,
	setProjectStats,
	setSelectedProject,
} from './project.slice';

function* setProjectsData(action) {
	try {
		const { data } = action.payload;
		yield put(setProjects(data));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCurrentProject(action) {
	try {
		yield put(setSelectedProject(action.payload));
		return true;
	} catch (error) {
		return false;
	}
}

function* setProjectContent(action) {
	try {
		const { projectId, contentStreams } = action.payload;
		yield put(setProjectContentStreams(contentStreams));
		return true;
	} catch (error) {
		return false;
	}
}

function* setProjectAnalytics(action) {
	try {
		// const { projectId, analytics } = action.payload;
		yield put(setProjectStats(action.payload));
		return true;
	} catch (error) {
		return false;
	}
}
export default function* socialProjectSaga() {
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_PROJECT_DATA, setProjectsData);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_CURRENT_PROJECT, setCurrentProject);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_PROJECT_CONTENT, setProjectContent);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_PROJECT_ANALYTICS, setProjectAnalytics);
}
