import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import API_ENDPOINTS from '../../utils/api-endpoints';
import http from '../../utils/http';
import recordEvent from '../../mixpanel/mixpanel';

export const saveFilters = async ({ id, name, desc }) => {
	const { data } = await http.post(`${API_ENDPOINTS.DISCOVERY.SAVE_SEARCH_FILTERS}?id=${id}`, {
		name,
        desc
	});

	return data.data || [];
};

export const useSaveSearchFilter = () => {
    const { addToast } = useToasts();
    const queryClient = useQueryClient();
    const onSuccess = (data,input) => {
		addToast(
			successToast({
				message: 'Filter is saved successfully',
			}),
			{
				autoDismiss: true,
			},
		);
        queryClient.invalidateQueries([API_ENDPOINTS.GET_SAVE_SEARCH_HISTORY]);
		recordEvent('Save Filters(Search)', {
			input,
			output:data
		});
	};
	const onError = (err,input) => {
		addToast(
			dangerToast({
				message: err?.data?.errorMessage,
			}),
			{
				autoDismiss: true,
			},
		);
		recordEvent('Failed to save filter(Search)', {
			input,
			output:err?.data
		});
	};

    return useMutation((input) => saveFilters(input), {
		onSuccess,
		onError,
	});
};
