import { useMutation } from 'react-query';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUI } from '../../../states/contexts/ui.context';
import { AUTH_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';
import recordEventAnonymous from '../mixpanel/mixpanel-anonymous';

async function logout(dispatch) {
	// return http.post(API_ENDPOINTS.LOGIN, input);
	return dispatch({ type: AUTH_STORE_CONSTANTS.LOGOUT });
}
const useLogoutMutation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const { unauthorize } = useUI();

	return useMutation(() => logout(dispatch), {
		onSuccess: (_data) => {
			// recordEventAnonymous('User Logged out');
			// mixpanel.reset();
			// unauthorize();
			// navigate('/', { replace: true });
		},
		onError: (data) => {
			console.log(data, 'logout error response');
			// recordEventAnonymous('Something went wrong while logging out',{
			// 	userDetails:data
			// });
		},
	});
};
export default useLogoutMutation;
