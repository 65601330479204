import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

const fetchSegmentationForProject = async ({ queryKey }) => {
	const { projectId } = queryKey[1];
	const { data } = await http.post(`${API_ENDPOINTS.GET_SEGMENT_FOR_PROJECT}`, { projectId });
	JSON.stringify(data);
	return data;
};
const useFetchSegmentationForProject = (options) => {
	return useQuery([API_ENDPOINTS.GET_SEGMENT_FOR_PROJECT, options], fetchSegmentationForProject);
};
export { useFetchSegmentationForProject };

export default fetchSegmentationForProject;
