import { Formik, useFormik } from 'formik';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import { ModalBody, ModalFooter } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import CreatorDropdown from '../custom/crm/searchCreator/dropdown';
import useSendMessageMutation from '../../framework/basic-rest/whatsApp/sendMessage';

const MessageModel = ({ campaignId, creatorId, influencerFullName, outreach }) => {
	const initialState = {
		creatorId: [],
	};
	if (campaignId) {
		initialState.campaignId = campaignId;
	}
	if (creatorId) {
		initialState.creatorId = creatorId;
	}

	const [payload, setPayload] = useState(initialState);

	const [selectedCreator, setSelectedCreator] = useState([]);

	const messageFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			message: '',
			template: false,
		},

		onSubmit: (values, { resetForm }) => {
			const message = values.message.replace(/(<([^>]+)>)/gi, '');
			const sendTemplate = values.template;
			sendMessage({ message, sendTemplate });
			resetForm();
		},
	});

	const handleSelect = (influencer) => {
		if (!payload.creatorId.includes(influencer._id)) {
			setPayload({ ...payload, creatorId: [...payload.creatorId, influencer._id] });
			setSelectedCreator([...selectedCreator, influencer.fullName]);
		}
	};
	const handleDeSelect = (index) => {
		const updatedSelectedCreator = selectedCreator.filter((_, i) => index !== i);
		const updatedPayloadCreatorId = payload.creatorId.filter((_, i) => index !== i);
		setPayload({ ...payload, creatorId: updatedPayloadCreatorId });
		setSelectedCreator(updatedSelectedCreator);
	};
	const { mutate: sendMessage, isSuccess, isLoading } = useSendMessageMutation(payload);
	return (
		<ModalBody>
			<form onSubmit={messageFormik.handleSubmit}>
				<div className='row g-4'>
					<div className='col-12'>
						{outreach ? (
							<CreatorDropdown
								selectedCreator={selectedCreator}
								handleSelect={handleSelect}
								handleDeSelect={handleDeSelect}
							/>
						) : (
							<FormGroup id='to' label='To'>
								<Input readOnly placeholder='To' value={influencerFullName} />
							</FormGroup>
						)}
					</div>
					<div className='col-12'>
						<FormGroup id='message' label='Message' className='mb-3'>
							<ReactQuill
								theme='snow'
								value={messageFormik.values.message}
								onChange={(e) => {
									messageFormik.setFieldValue('message', e);
								}}
							/>
						</FormGroup>
					</div>
				</div>

				<ModalFooter className='bg-transparent'>
					<Button type='submit' color='info' className='btn-auth' isDisable={isLoading}>
						Send
					</Button>
					<Button
						type='submit'
						color='success'
						className='btn-auth'
						isDisable={isLoading}
						onClick={() => messageFormik.setFieldValue('template', true)}>
						Send template
					</Button>
				</ModalFooter>
			</form>
			<p class='text-muted truncate-line-2 mt-0 '>
				Note: If you haven't initiated conversation with creator send a Template message and
				wait form reply before sending normal text message
			</p>
		</ModalBody>
	);
};

export default MessageModel;
