import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function addInfluencerToList(input) {
	const res = await http.post(`${API_ENDPOINTS.ADD_TO_LIST}?listId=${input.list_ids[0]}`, input);
	return res.data;
}

const useAddInfluencerToListMutation = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();

	return useMutation((input) => addInfluencerToList(input), {
		onSuccess: (data, input) => {
			if (data.success) {
				// const resp = data.data;
				addToast(
					successToast({
						message: data.message,
					}),
					{
						autoDismiss: true,
					},
				);
				dispatch({
					type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
					payload: `List/Added-Influencer/${data.data._id}`,
				});
				recordEvent(`Influencer Added to List(List)`, {
					input,
					output: data,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to add Influencerto List(List)`, {
				input,
				output:err?.response?.data,
			});
		},
	});
};

export default useAddInfluencerToListMutation;
