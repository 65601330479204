import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	selectedCreators: [],
};

const slice = createSlice({
	name: 'creator_association',
	initialState,
	reducers: {
		setSelectedCreators: (state, action) => {
			state.selectedCreators = action.payload;
		},
	},
});

export const { setSelectCreator, setSelectedCreators } = slice.actions;

export default slice.reducer;
