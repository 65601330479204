import { useSelector } from 'react-redux';
import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import { LIST_STORE_CONTANTS } from '../../constants.store';
import {
	setCrmListId,
	setFavouriteList,
	setFilteredInfluencers,
	setInfluencerContact,
	setInfluencerPricing,
	setIsContactUpdated,
	setIsPriceUpdated,
	setIsUpdateInLists,
	setListInfluencer,
	setListInfluencers,
	setLists,
	setProfileListId,
} from './list.slice';

function* saveList(actions) {
	try {
		const lists = actions.payload;
		yield put(setLists(lists));
		yield put({
			type: LIST_STORE_CONTANTS.SET_FAVOURITE_LIST,
			payload: lists,
		});
		return true;
	} catch (error) {
		return false;
	}
}

function* saveFavouriteList(actions) {
	try {
		const lists = actions.payload;
		const favouriteList = lists.find((list) => list.isFavourite);
		yield put(setFavouriteList(favouriteList));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveListInfluencers(actions) {
	try {
		const influencers = actions.payload;
		yield put(setListInfluencers(influencers));
		return true;
	} catch (error) {
		return false;
	}
}

function* changeIsUpdateLists(actions) {
	try {
		const changeOccurs = actions.payload;
		yield put(setIsUpdateInLists(changeOccurs));
		return true;
	} catch (error) {
		return false;
	}
}

function* setIsListInfluencer(actions) {
	try {
		const influencer = actions.payload;
		yield put(setListInfluencer(influencer));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveInfluencerContact(actions) {
	try {
		const contactData = actions.payload;
		yield put(setInfluencerContact(contactData));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveIsContactUpdated(actions) {
	try {
		const isUpdated = actions.payload;
		yield put(setIsContactUpdated(isUpdated));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveInfluencerPricing(actions) {
	try {
		const priceData = actions.payload;
		yield put(setInfluencerPricing(priceData));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveIsPriceUpdated(actions) {
	try {
		const isPriceUpdated = actions.payload;
		yield put(setIsPriceUpdated(isPriceUpdated));
		return true;
	} catch (error) {
		return false;
	}
}

function* setSelectProfileListId(actions) {
	try {
		const isPriceUpdated = actions.payload;
		yield put(setProfileListId(isPriceUpdated));
		return true;
	} catch (error) {
		return false;
	}
}

function* setSelectCrmListId(actions) {
	try {
		const listId = actions.payload;
		yield put(setCrmListId(listId));
		return true;
	} catch (error) {
		return false;
	}
}

function* setSelectedFilteredInfluencers(actions) {
	try {
		const influencers = actions.payload;
		yield put(setFilteredInfluencers(influencers));
		return true;
	} catch (error) {
		return false;
	}
}

function* setSelectFiltersClicked(actions) {
	try {
		const click = actions.payload;
		yield put(setFilteredInfluencers(click));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* listSaga() {
	yield takeLatest(LIST_STORE_CONTANTS.SET_LIST_DATA, saveList);
	yield takeLatest(LIST_STORE_CONTANTS.SET_FAVOURITE_LIST, saveFavouriteList);
	yield takeLatest(LIST_STORE_CONTANTS.SET_LIST_INFLUENCERS_DATA, saveListInfluencers);
	yield takeLatest(LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE, changeIsUpdateLists);
	yield takeLatest(LIST_STORE_CONTANTS.SET_LIST_INFLUENCER_DATA, setIsListInfluencer);
	yield takeLatest(LIST_STORE_CONTANTS.SET_LIST_INFLUENCERS_CONTACT, saveInfluencerContact);
	yield takeLatest(LIST_STORE_CONTANTS.SET_IS_UPDATE_CONTACT, saveIsContactUpdated);
	yield takeLatest(LIST_STORE_CONTANTS.SET_LIST_INFLUENCERS_PRICE, saveInfluencerPricing);
	yield takeLatest(LIST_STORE_CONTANTS.SET_IS_UPDATE_PRICE, saveIsPriceUpdated);
	yield takeLatest(LIST_STORE_CONTANTS.SET_PROFILE_LIST_ID, setSelectProfileListId);
	yield takeLatest(LIST_STORE_CONTANTS.SET_CRM_LIST_ID, setSelectCrmListId);
	yield takeLatest(LIST_STORE_CONTANTS.SET_FILTERED_INFLUENCERS, setSelectedFilteredInfluencers);
	// yield takeLatest(LIST_STORE_CONTANTS.SET_FILTERS_CLICKED, setSelectFiltersClicked);
}
