import mixpanel from 'mixpanel-browser';
import jwt from 'jwt-decode';
import { getToken } from '../../../store/App/Auth/helper/cookies';

const recordEvent = (eventName, properties) => {
	const token = getToken();
    const decoded = jwt(token);
	if(!decoded.email.includes("ovonts.com")){
		mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN, { debug: true });
		mixpanel.track(eventName, properties);
	}	
	
};

export default recordEvent;
