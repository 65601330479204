import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
	pages,
	componentsMenu,
	publicPage,
	commonPage,
	newPage,
	commonRoute,
	bandEdgePages,
	privacyPolicyPage,
	termsAndConditionPage,
	refundPolicyPage,
	pricingPage,
	aboutUsPage,
	contactUsPage
} from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	DASHBOARD_PAGE: lazy(() => import('../pages/dashboard/Dashboard')),
	DISCOVERY: lazy(() => import('../pages/discovery/DiscoveryPage')),
	CONTENT_MANAGEMENT: lazy(() => import('../pages/content_management/ContentsManagementPage')),
	CAMPAIGN: lazy(() => import('../pages/campaign/CampaignPage')),
	CREATE_CAMPAIGN: lazy(() => import('../pages/campaign/CreateCampaignPage')),
	CONTROL_PANEL: lazy(() => import('../pages/control_panel/ControlPanel')),
	ACCESS_PERMISSION: lazy(() =>
		import('../components/custom/Member_System/Components/AccessPermissions/Access_Permission'),
	),
	MANAGE_CREDITS: lazy(() =>
		import('../components/custom/Member_System/Components/ManageCredits/Manage_Credits'),
	),
	USER_GUIDE: lazy(() => import('../pages/knowldge_grid/KnowldgeGridPage')),
	USER_GUIDE_VIEW: lazy(() => import('../pages/knowldge_grid/KnowldgeViewPage')),
	USER_MANUAL: lazy(() => import('../pages/knowldge_grid/KnowldgeViewPage')),
	USER_PRICING: lazy(() => import('../pages/knowldge_grid/PricingTablePage')),
	ANALYTICS: lazy(() => import('../pages/analytics/AnalyticsPage')),
	ACCOUNT: lazy(() => import('../pages/account/Accountpage')),
	COMMUNITY: lazy(() => import('../pages/community/CommunityPage')),
	MARKET_PLACE: lazy(() => import('../pages/market_place/MarketPlace')),

	PRODUCT_ANALYTICS: lazy(() => import('../pages/product_analytics/product_analyticsPage')),

	INTEGRATIONS: lazy(() => import('../pages/integrations/IntegrationsPage')),
	MY_INFLUENCER: lazy(() => import('../pages/myInfluencer/MyInfluencerPage')),
	UPCOMING_MODULE: lazy(() => import('../components/custom/Common/UpcomingModule')),
	PROJECT: lazy(() => import('../pages/crm/project/Project')),
	CREATOR: lazy(() => import('../pages/crm/creator/Creator')),
	BRIEF_DASHBOARD: lazy(() => import('../pages/crm/briefDashboard/BriefDashboard')),
	BUCKET_DASHBOARD: lazy(() => import('../pages/crm/bucketDashboard/BucketDashboard')),
	PARTNERS: lazy(() => import('../pages/crm/company/Company')),
	INBOX: lazy(() => import('../pages/crm/inbox/Index')),
	// changed
	INBOX_AUTHENTICATION: lazy(() => import('../pages/crm/inbox/Authentication/Index')),
	CMS: lazy(() => import('../pages/cms/CMSPage')),
	CREATORPROFILE: lazy(() =>
		import('../components/custom/crm/creator_profile/CreatorProfilePage'),
	),
	PROJECTPROFILE: lazy(() =>
		import('../components/custom/crm/project_profile/ProjectProfilePage'),
	),
	COMPANYPROFILE: lazy(() =>
		import('../components/custom/crm/company_profile/CompanyProfilePage'),
	),
	INFLUENCER_PROFILE: lazy(() =>
		import('../components/custom/MyInfluencerComponents/InfluencerProfileModal'),
	),
	INFLUENCER_LIST: lazy(() => import('../components/custom/Common/List/ListTable')),
	TASKS: lazy(() => import('../pages/crm/Tasks/Task')),
	COMPARISON: lazy(() => import('../pages/comparison/Comparison')),
	VISION_ANALYZE: lazy(() => import('../pages/visionAI/Analyze')),
	VISION_COLLECT: lazy(() => import('../pages/visionAI/Collect')),
	VISION_CURATE: lazy(() => import('../pages/visionAI/Curate')),
	CONTENT_DETAILS: lazy(() => import('../components/custom/cms/ContentDetails')),
};
const SUBLANDING = {
	INSTAGRAMFILTER: lazy(() =>
		import('../components/custom/DiscoveryComponents/advancedSearch/InstagramFilter'),
	),
	YOUTUBEFILTER: lazy(() =>
		import('../components/custom/DiscoveryComponents/advancedSearch/YouTubeFilter'),
	),
	TIKTOKFILTER: lazy(() =>
		import('../components/custom/DiscoveryComponents/advancedSearch/TikTokFilter'),
	),
	SHOWCOMMUNITYCARD: lazy(() =>
		import('../components/custom/CommunityComponents/ShowCommunityCards'),
	),
	SHOWCOMMUNITYUSER: lazy(() =>
		import('../components/custom/CommunityComponents/ShowCommunityUser'),
	),
};

const COMMON_PAGE = {
	WS_MANAGE: lazy(() => import('../components/custom/AccountComponents/WsManage/WsMange')),
};

const PUBLIC = {
	SIGNIN: lazy(() => import('../pages/auth/SignIn/Index')),
	SIGNIN_EMAIL: lazy(() => import('../pages/auth/SignIn/Email')),
	SIGNIN_PASSWORD: lazy(() => import('../pages/auth/SignIn/Password')),

	SIGNUP: lazy(() => import('../pages/auth/SignUp/Index')),
	SIGNUP_EMAIL: lazy(() => import('../pages/auth/SignUp/Email')),
	SIGNUP_USER_DETAILS: lazy(() => import('../pages/auth/SignUp/UserDetails')),

	FORGOT_PASSWORD: lazy(() => import('../pages/auth/ForgotPassword/Index')),
	FORGOT_PASSWORD_EMAIL: lazy(() => import('../pages/auth/ForgotPassword/Email')),
	FORGOT_PASSWORD_CHANGE_PASSWORD: lazy(() =>
		import('../pages/auth/ForgotPassword/ChangePassword'),
	),

	VERIFICATION: lazy(() => import('../pages/auth/OtpVerification')),

	CREATOR_BRIEF: lazy(() => import('../pages/auth/BreifEmail/BriefEmail')),

	BAND_EDGE: lazy(() => import('../pages/bandEdge/bandEdgeHome')),

	PRIVACY_POLICY: lazy(() => import('../pages/privacyPolicy/privacyPolicy')),

	TERMS_AND_CONDITION: lazy(() => import('../pages/privacyPolicy/termsAndCondition')),

	REFUND_POLICY: lazy(() => import('../pages/privacyPolicy/refundPolicy')),

	PRICING: lazy(() => import('../pages/privacyPolicy/pricing')),

	ABOUT_US: lazy(() => import('../pages/privacyPolicy/aboutUs')),

	CONTACT_US: lazy(() => import('../pages/privacyPolicy/contactUs')),

};

const contents = [
	// {
	// 	path: pages.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	{
		path: pages.creator_profile.path,
		element: <LANDING.CREATORPROFILE />,
		exact: true,
	},
	{
		path: pages.comparison.path,
		element: <LANDING.COMPARISON />,
		exact: true,
	},
	{
		path: pages.cms.path,
		element: <LANDING.CMS />,
		exact: true,
	},
	{
		path: pages.dashboard.path,
		element: <LANDING.DASHBOARD_PAGE />,
		exact: true,
	},
	{
		path: newPage.content_details.path,
		element: <LANDING.CONTENT_DETAILS />,
		exact: true,
	},
	{
		path: pages.project_profile.path,
		element: <LANDING.PROJECTPROFILE />,
		exact: true,
	},
	{
		path: pages.company_profile.path,
		element: <LANDING.COMPANYPROFILE />,
		exact: true,
	},

	{
		path: pages.influencer_profile.path,
		element: <LANDING.INFLUENCER_PROFILE />,
		exact: true,
	},
	{
		path: pages.creatorCRM.subMenu.Creator.path,
		element: <LANDING.CREATOR />,
		exact: true,
	},
	{
		path: pages.brief_dashboard.path,
		element: <LANDING.BRIEF_DASHBOARD />,
		exact: true,
	},
	{
		path: pages.bucket_dashboard.path,
		element: <LANDING.BUCKET_DASHBOARD />,
		exact: true,
	},
	{
		path: pages.creatorCRM.subMenu.Projects.path,
		element: <LANDING.PROJECT />,
		exact: true,
	},
	{
		path: pages.creatorCRM.subMenu.Partners.path,
		element: <LANDING.PARTNERS />,
		exact: true,
	},
	{
		path: pages.creatorCRM.subMenu.Tasks.path,
		element: <LANDING.TASKS />,
		exact: true,
	},
	{
		path: pages.product_analytics.path,
		element: <LANDING.PRODUCT_ANALYTICS />,
		exact: true,
	},
	{
		path: pages.creatorCRM.subMenu.Inbox.path,
		element: <LANDING.INBOX />,
		exact: true,
		isNested: pages.creatorCRM.subMenu.Inbox.isNested,
		nested: [
			{
				id: pages.creatorCRM.subMenu.Inbox.nestedRoute.email.id,
				path: pages.creatorCRM.subMenu.Inbox.nestedRoute.email.path,
				element: <LANDING.INBOX_AUTHENTICATION />,
				exact: true,
			},
		],
	},
	{
		path: pages.search.path,
		element: <LANDING.DISCOVERY />,
		exact: true,
	},
	{
		path: pages.SMA.path,
		element: <LANDING.ANALYTICS />,
		exact: true,
	},
	{
		path: pages.integrations.path,
		element: <LANDING.INTEGRATIONS />,
		exact: true,
	},

	{
		path: componentsMenu.account.path,
		element: <LANDING.ACCOUNT />,
		exact: true,
	},
	{
		path: pages.control_panel.path,
		element: <LANDING.CONTROL_PANEL />,
		exact: true,
		isNested: pages.control_panel.isNested,
		nested: [
			{
				id: pages.control_panel.nestedRoute.access_permission.id,
				path: pages.control_panel.nestedRoute.access_permission.path,
				element: <LANDING.ACCESS_PERMISSION />,
			},
			{
				id: pages.control_panel.nestedRoute.manage_credits.id,
				path: pages.control_panel.nestedRoute.manage_credits.path,
				element: <LANDING.MANAGE_CREDITS />,
			},
			{
				id: pages.control_panel.nestedRoute.all.id,
				path: pages.control_panel.nestedRoute.all.path,
				element: <Navigate to={pages.control_panel.nestedRoute.access_permission.path} />,
			},
		],
	},
	{
		path: pages.user_guide.path,
		element: <LANDING.USER_GUIDE />,
		exact: true,
	},
	{
		path: pages.user_guide_view.path,
		element: <LANDING.USER_GUIDE_VIEW />,
		exact: true,
	},
	{
		path: pages.user_manual.path,
		element: <LANDING.USER_MANUAL />,
		exact: true,
	},
	{
		path: pages.user_pricing.path,
		element: <LANDING.USER_PRICING />,
		exact: true,
	},
	{
		path: commonPage.listPage.path,
		element: <LANDING.INFLUENCER_LIST />,
		exact: true,
	},
	{
		path: pages.list.path,
		element: <LANDING.MY_INFLUENCER />,
		exact: true,
	},
	{
		id: 'creatorbriefemail',
		path: publicPage.briefEmail.path,
		element: <PUBLIC.CREATOR_BRIEF />,
		exact: true,
	},
	{
		path: pages.visionAI.subMenu.Analyze.path,
		element: <LANDING.VISION_ANALYZE />,
		exact: true,
	},
	{
		path: pages.visionAI.subMenu.Collect.path,
		element: <LANDING.VISION_COLLECT />,
		exact: true,
	},
	{
		path: pages.visionAI.subMenu.Curate.path,
		element: <LANDING.VISION_CURATE />,
		exact: true,
	},
];

const commonPages = [
	{
		id: commonRoute.userControl.id,
		path: commonRoute.userControl.path,
		element: <LANDING.ACCOUNT />,
		exact: true,
		isNested: commonRoute.userControl.isNested,
		nested: [
			{
				id: commonRoute.userControl.nestedRoute.workspace.id,
				path: commonRoute.userControl.nestedRoute.workspace.path,
				element: <COMMON_PAGE.WS_MANAGE />,
			},
			{
				id: commonRoute.userControl.nestedRoute.member.id,
				path: commonRoute.userControl.nestedRoute.member.path,
				element: <LANDING.ACCESS_PERMISSION />,
			},
		],
	},
];

const auth = [
	{
		id: 'signin',
		path: publicPage.signin.path,
		element: <PUBLIC.SIGNIN />,
		exact: true,
		isNested: publicPage.signin.isNested,
		nested: [
			{
				id: publicPage.signin.nestedRoute.email.id,
				path: publicPage.signin.nestedRoute.email.path,
				element: <PUBLIC.SIGNIN_EMAIL />,
				exact: true,
				isNested: publicPage.signin.nestedRoute.email.isNested,
				nested: [
					{
						id: publicPage.signin.nestedRoute.email.nestedRoute.email.id,
						path: publicPage.signin.nestedRoute.email.nestedRoute.email.path,
						element: <PUBLIC.SIGNIN_EMAIL />,
						exact: true,
					},
				],
			},
			{
				id: publicPage.signin.nestedRoute.password.id,
				path: publicPage.signin.nestedRoute.password.path,
				element: <PUBLIC.SIGNIN_PASSWORD />,
				exact: true,
			},
		],
	},
	{
		id: 'signup',
		path: publicPage.signup.path,
		element: <PUBLIC.SIGNUP />,
		exact: true,
		isNested: publicPage.signup.isNested,
		nested: [
			{
				id: publicPage.signup.nestedRoute.email.id,
				path: publicPage.signup.nestedRoute.email.path,
				element: <PUBLIC.SIGNUP_EMAIL />,
				exact: true,
				isNested: publicPage.signup.nestedRoute.email.isNested,
				nested: [
					{
						id: publicPage.signup.nestedRoute.email.nestedRoute.email.id,
						path: publicPage.signup.nestedRoute.email.nestedRoute.email.path,
						element: <PUBLIC.SIGNUP_EMAIL />,
						exact: true,
					},
				],
			},
			{
				id: publicPage.signup.nestedRoute.verify.id,
				path: publicPage.signup.nestedRoute.verify.path,
				element: <PUBLIC.VERIFICATION />,
				exact: true,
			},
			{
				id: publicPage.signup.nestedRoute.create.id,
				path: publicPage.signup.nestedRoute.create.path,
				element: <PUBLIC.SIGNUP_USER_DETAILS />,
				exact: true,
			},
		],
	},
	{
		id: publicPage.forgotpassword.id,
		path: publicPage.forgotpassword.path,
		element: <PUBLIC.FORGOT_PASSWORD />,
		exact: true,
		isNested: publicPage.forgotpassword.isNested,
		nested: [
			{
				id: publicPage.forgotpassword.nestedRoute.email.id,
				path: publicPage.forgotpassword.nestedRoute.email.path,
				element: <PUBLIC.FORGOT_PASSWORD_EMAIL />,
				exact: true,
				isNested: publicPage.signup.nestedRoute.email.isNested,
				nested: [
					{
						id: publicPage.forgotpassword.nestedRoute.email.nestedRoute.email.id,
						path: publicPage.forgotpassword.nestedRoute.email.nestedRoute.email.path,
						element: <PUBLIC.FORGOT_PASSWORD_EMAIL />,
						exact: true,
					},
				],
			},
			{
				id: publicPage.forgotpassword.nestedRoute.verify.id,
				path: publicPage.forgotpassword.nestedRoute.verify.path,
				element: <PUBLIC.VERIFICATION />,
				exact: true,
			},
			{
				id: publicPage.forgotpassword.nestedRoute.change.id,
				path: publicPage.forgotpassword.nestedRoute.change.path,
				element: <PUBLIC.FORGOT_PASSWORD_CHANGE_PASSWORD />,
				exact: true,
			},
		],
	},
	{
		id: 'verification',
		path: publicPage.verification.path,
		element: <PUBLIC.VERIFICATION />,
		exact: true,
	},
	{
		id: 'creatorbriefemail',
		path: publicPage.briefEmail.path,
		element: <PUBLIC.CREATOR_BRIEF />,
		exact: true,
	},
];

const bandedge = [
	{
		id: 'bandEdge',
		path: bandEdgePages.bandEdge.path,
		element: <PUBLIC.BAND_EDGE />,
		exact: true,
	},
];

const privacyPolicy = [
	{
		id: 'privacyPolicy',
		path: privacyPolicyPage.privacyPolicy.path,
		element: <PUBLIC.PRIVACY_POLICY />,
		exact: true,
	}
];

const termsAndCondition = [
	{
		id: 'termsAndCondition',
		path: termsAndConditionPage.termsAndCondition.path,
		element: <PUBLIC.TERMS_AND_CONDITION />,
		exact: true,
	}
];

const refundpolicy = [
	{
		id: 'refundPolicy',
		path: refundPolicyPage.refundPolicy.path,
		element: <PUBLIC.REFUND_POLICY />,
		exact: true
	}
];

const pricing = [
	{
		id: 'pricing',
		path: pricingPage.pricing.path,
		element: <PUBLIC.PRICING />,
		exact: true
	}
];


const aboutus = [
	{
		id: 'aboutus',
		path: aboutUsPage.aboutus.path,
		element: <PUBLIC.ABOUT_US />,
		exact: true
	}
];


const contactus = [
	{
		id: 'contactus',
		path: contactUsPage.contactus.path,
		element: <PUBLIC.CONTACT_US />,
		exact: true
	}
];

const contentRoute = { contents, commonPages, auth, bandedge, privacyPolicy, termsAndCondition, refundpolicy, pricing, aboutus, contactus };

export default contentRoute;
