import { ANALYZE_REDUCER_CONSTANT } from './reducers';

export const setShowAnalyseDetails = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SHOW_INFLUENCER_DETAILS,
		value: data,
	};
};

export const setCitiesDateRange = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_CITIES_DATE_RANGE,
		value: data,
	};
};

export const setCountryDateRange = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_COUNTRY_DATE_RANGE,
		value: data,
	};
};

export const setInfluencerDateRange = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_DATE_RANGE,
		value: data,
	};
};

export const getAllInfluencerEventRequest = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_ALL_INFLUENCER_EVENT_REQUEST,
		value: data,
	};
};

export const setOnInfluencerDetailsCLick = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_DETAILS_ON_CLICK,
		value: data,
	};
};

export const setOnClickDonutGraph = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_ON_CLICK_DONUT_GRAPH,
		value: data,
	};
};

export const setEventMatrixValue = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SELECTED_MATRIX_VALUE,
		value: data,
	};
};

export const setTotalInflueEventMatrix = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_TOTAL_INFLUE_EVENT_MATRIX,
		value: data,
	};
};

export const setAllInfluencerEventData = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_ALL_INFLUENCER_EVENT_DATA,
		value: data,
	};
};

export const setDefaultEventMatrix = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.DEFAULT_EVENT_VALUE,
		value: data,
	};
};

export const setEventMatrixError = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_SELECT_EVENT_MATRIX_ERROR,
		value: data,
	};
};

export const setCitiesData = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_CITIES_DATA,
		value: data,
	};
};

export const setCountriesData = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_COUNTRIES_DATA,
		value: data,
	};
};
export const getCitiesDataRequest = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_CITIES_DATE_REQUEST,
		value: data,
		history,
	};
};

export const setInfluencerUserDateRange = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_USER_DATE_RANGE,
		value: data,
	};
};

export const setDeviceEventDateRange = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DATE_RANGE,
		value: data,
	};
};

export const setDeviceEventDateLabel = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DATE_LABEL,
		value: data,
	};
};

export const setInfluencerUserLabelRange = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_USER_DATE_LABEL,
		value: data,
	};
};

export const setDeviceEventDetailsData = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DETAILS_DATA,
		value: data,
	};
};

export const setTotalDeviceEventDetailsCount = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_TOTAL_DEVICE_EVENT_COUNT,
		value: data,
	};
};

export const getCountriesDataRequest = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_COUNTRIES_DATE_REQUEST,
		value: data,
		history,
	};
};

export const getInfluencerEventRequest = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_INFLU_EVENT_MATRIX_REQUEST,
		value: data,
		history,
	};
};

export const getDeviceEventDataRequest = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_DEVICE_EVENT_DETAILS_REQUEST,
		value: data,
		history,
	};
};

export const setInflTotalCount = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFL_TOTAL_COUNT,
		value: data,
	};
};

export const setInfluencerEventData = (data, eventName) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_EVENT_ARRAY,
		value: data,
		eventName,
	};
};

export const setCampaignEventMatrix = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_CAMPAIGN_EVENT_MATRIX,
		value: data,
	};
};

export const setTotalEventCount = (data) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.SET_TOTAL_EVENT_COUNT,
		value: data,
	};
};

export const getCampaignAllEventMatrixRequest = (data, history) => {
	return {
		type: ANALYZE_REDUCER_CONSTANT.GET_CAMPAIGN_ALL_EVENT_MATRIX_REQUEST,
		data,
		history,
	};
};
