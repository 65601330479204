import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { CRM_PROJECTS_STORE_CONSTANTS } from '../../../../store/constants.store';
import {
	CRMProject,
	selectIsUpdateCrmProject,
} from '../../../../store/App/crm/projects/crm.projects.selectors';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';

import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';
// import { setCrmCreators } from '../../../store/App/crm/creators/crm.creators.slice';

const getAllCrmProjects = async () => {
	const data = await http.get(`${API_ENDPOINTS.GET_ALL_CRM_PROJECTS}`);
	return data?.data;
};

const useGetAllCrmProjects = (options) => {
	// const selectProjects = useSelector(CRMProject);
	const isCompanyUpdated = useSelector(selectIsCompanyUpdate);
	const isCreatorUpdated = useSelector(selectIsUpdateCrmCreator);
	const isProjectUpdated = useSelector(selectIsUpdateCrmProject);
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);

	const dispatch = useDispatch();

	const onSuccess = (data) => {
		dispatch({
			type: CRM_PROJECTS_STORE_CONSTANTS.SET_CRM_PROJECT_DATA,
			payload: data?.data,
		});
	};
	return useQuery(
		[
			API_ENDPOINTS.GET_ALL_CRM_PROJECTS,
			isCompanyUpdated,
			isCreatorUpdated,
			isProjectUpdated,
			isNOTEupdated,
			isTaskUpdated,
		],
		getAllCrmProjects,
		{
			onSuccess,
		},
	);
};

export { useGetAllCrmProjects, getAllCrmProjects };
