export const CampaignPermissionTypes = [
	{
		id: 'campaign-permission-view',
		label: 'View',
		value: 'VIEW',
	},
	{
		id: 'campaign-permission-edit',
		label: 'Edit',
		value: 'EDIT',
	},
	
];
export const CreatorPermissionTypes = [
	{
		id: 'creator-permission-view',
		label: 'View',
		value: 'VIEW',
	},
	{
		id: 'creator-permission-edit',
		label: 'Edit',
		value: 'EDIT',
	},
	
];
export const ListPermissionTypes = [
	{
		id: 'list-permission-view',
		label: 'View',
		value: 'VIEW',
	},
	{
		id: 'list-permission-edit',
		label: 'Edit',
		value: 'EDIT',
	},
	
];
export const SocialProjectPermissionTypes = [
	{
		id: 'socialproject-permission-view',
		label: 'View',
		value: 'VIEW',
	},
	{
		id: 'socialproject-permission-edit',
		label: 'Edit',
		value: 'EDIT',
	},
	
];

export const EmailBriefAcceptanceTypes = [
	{
		id: 'emailbrief-permission-view',
		label: 'Accept',
		value: 'Accept',
	},
	{
		id: 'emailbrief-permission-edit',
		label: 'Reject	',
		value: 'Reject',
	},
]