// import { platformRegex } from './consRegex';

const validateCreator = (values) => {
	const errors = {};
	if (!values.email) {
		errors.email = 'Email is required';
	} else if (!validateEmail(values.email)) {
		errors.email = 'Email is Incorrect';
	}
	// eslint-disable-next-line no-restricted-globals
	if (values.fullName.length === 0) {
		errors.fullName = 'Enter valid Full Name';
	}
	if (!values.userName) {
		errors.userName = 'Username is required';
	}
	if (values.handle_link.length === 0) {
		errors.handle_link = 'Enter valid Handle Link';
	}
	if (!values.platform) {
		errors.platform = 'PlatForm is required';
	}
	// if (!values.platform || !validateHandle(values.handle_link, values.platform)) {
	// 	errors.handle_link = !values.platform ? 'Select Platform first' : 'Link Invalid';
	// }
	if (values.phone.length === 0) {
		errors.phone = 'Enter valid phone number';
	}
	if (values.pricing.length > 0) {
		values.pricing.map((x) => {
			if (x.price.lower <= 0) {
				errors.pricing = `Invalid price`;
			}
			if (x.price.upper <= 0) {
				errors.pricing = `Invalid price`;
			}
			if (!Number(x.price.lower)) {
				errors.pricing = `Invalid price`;
			}
			if (!Number(x.price.upper)) {
				errors.pricing = `Invalid price`;
			}
			return null;
		});
	}
	return errors;
};

// eslint-disable-next-line consistent-return
function validateEmail(email) {
	// eslint-disable-next-line prefer-regex-literals
	const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
	const isValid = emailRegex.test(email);
	return isValid;
}
// eslint-disable-next-line camelcase, consistent-return
// function validateHandle(handle_link, platform) {
// 	// eslint-disable-next-line camelcase, consistent-return
// 	console.log({ platform, handle_link });
// 	const handleRegex = platformRegex[platform];
// 	const isHandle = handleRegex.test(handle_link);
// 	console.log({ isHandle });
// 	return isHandle;
// }

export default validateCreator;
