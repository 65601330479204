import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	search: '',
	content: [],
	hashtags: [],
	types: [],
	status: [],
};

const slice = createSlice({
	name: 'cms',
	initialState,
	reducers: {
		setSearch: (state, action) => {
			state.search = action.payload;
		},
		setContents: (state, action) => {
			state.content = action.payload;
		},
		setHashtags: (state, action) => {
			state.hashtags = action.payload;
		},
		setTypes: (state, action) => {
			state.types = action.payload;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
});

export const { setSearch, setContents, setHashtags, setTypes, setStatus } = slice.actions;

export default slice.reducer;
