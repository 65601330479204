import { takeLatest, put } from 'redux-saga/effects';
import { CRM_CREATORS_STORE_CONSTANTS } from '../../../constants.store';
import {
	setCrmCreator,
	setCrmCreatorById,
	setCrmCreatorId,
	setGetCreator,
	setIsUpdateCrmCreator,
} from './crm.creators.slice';

function* setCRMCreator(actions) {
	try {
		const creator = actions.payload;
		yield put(setCrmCreator(creator));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMCreatorId(actions) {
	try {
		const creator = actions.payload;
		yield put(setCrmCreatorId(creator));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMCreatorById(actions) {
	try {
		const creator = actions.payload;
		yield put(setCrmCreatorById(creator));
		return true;
	} catch (error) {
		return false;
	}
}

function* setUpdateCreator(actions) {
	try {
		const creator = actions.payload;
		yield put(setIsUpdateCrmCreator(creator));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCreator(actions) {
	try {
		const creator = actions.payload;
		yield put(setGetCreator(creator));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* crmCreatorSaga() {
	yield takeLatest(CRM_CREATORS_STORE_CONSTANTS.SET_CRM_CREATOR_DATA, setCRMCreator);
	yield takeLatest(CRM_CREATORS_STORE_CONSTANTS.UPDATE_CRM_CREATOR_ID, setCRMCreatorId);
	yield takeLatest(CRM_CREATORS_STORE_CONSTANTS.SET_CRM_CREATOR_BY_ID, setCRMCreatorById);
	yield takeLatest(CRM_CREATORS_STORE_CONSTANTS.IS_UPDATE_CREATOR, setUpdateCreator);
	yield takeLatest(CRM_CREATORS_STORE_CONSTANTS.SET_SELECTED_CREATOR, setCreator);
}
