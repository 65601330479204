import React, { useState, useRef } from 'react';
import Card, { CardBody } from '../../../../bootstrap/Card';
import Icon from '../../../../icon/Icon';
import {
	shortString,
	nFormatter
} from '../../../../../helpers/helpers';

const PostCard = ({ post }) => {
	const [defaultImg, setDefaultImg] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDVuaQbojYLTlYezNW7HPVIYO6QiLZsd8RFP86jMuySoBlJ369aVAK0Mtzo7La2hyVcxU&usqp=CAU");
	const postUrlRef = useRef();

	const goToPage = () => {
		postUrlRef.current.click();
	}

	return (
		<div className='col-md-4 overflow-hidden'>
			<a href={post.url} target='_blank' rel="noreferrer" ref={postUrlRef}>
				<Card shadow='lg' className='rounded-2 overflow-hidden' onClick={goToPage}>
					<CardBody className='px-0 pt-0 overflow-hidden'>
						{/* <div className='text-muted mx-3 mb-3 fw-bold'>{post?.created}</div> */}
						{post?.thumbnail && <div style={{ height: "18rem", width: "100%", textAlign: "center", background: "black" }}>
							<img
								className='mb-3'
								height='100%'
								src={post?.thumbnail ? post.thumbnail : defaultImg}
								width='auto'
								alt='Img'
							/>
						</div>}
						<div className='d-flex flex-wrap px-3'>
							{post?.likes && (
								<div className='w-50 d-flex align-items-center text-info'>
									<Icon className='mx-1' icon='HandThumbsUpFill' size='lg' />
									<div className='mx-2 h5 my-2'>{nFormatter(post.likes, 1)}</div>
								</div>
							)}

							{post?.dislikes && (
								<div className='w-50 d-flex align-items-center text-danger'>
									<Icon className='mx-1' icon='HandThumbsDownFill' size='lg' />
									<div className='mx-2 h5 my-2'>{nFormatter(post.dislikes, 1)}</div>
								</div>
							)}

							{post?.views && (
								<div className='w-50 d-flex align-items-center text-red'>
									<Icon className='mx-1' icon='EyeFill' size='lg' />
									<div className='mx-2 h5 my-2'>{nFormatter(post.views, 1)}</div>
								</div>
							)}

							{post?.comments && (
								<div className='w-50 d-flex align-items-center text-info'>
									<Icon className='mx-1' icon='ChatLeftTextFill' size='lg' />
									<div className='mx-2 h5 my-2'>{nFormatter(post.comments, 1)}</div>
								</div>
							)}
						</div>
						{post?.text && (
							<div className='text-muted p-3 fs-6 overflow-hidden'>
								{shortString(post.text)}
							</div>
						)}
					</CardBody>
				</Card>
			</a>
		</div>
	);
};

export default PostCard;
