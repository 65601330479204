import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import { fetchComparisonReports } from '../../../framework/basic-rest/discovery/get-influencer-refetch';
import { DISCOVERY_STORE_CONSTANTS } from '../../constants.store';
import { selectComparisonProfiles, selectSearchFilters } from './discovery.selector';
import {
	setActiveTabIndex,
	setComparisonReports,
	setComparisonProfiles,
	setInfluencerReport,
	setIsFilterApply,
	setIsFilterClear,
	setOptionFilter,
	setSearchFilters,
	setSearchResults,
} from './discovery.slice';

function* saveSearchResults(actions) {
	try {
		const searchResults = actions.payload;
		yield put(setSearchResults(searchResults));
		return true;
	} catch (error) {
		return false;
	}
}

function* searchFilterInfluencer(actions) {
	try {
		const filters = actions.payload;
		yield put(setOptionFilter(filters));
		return true;
	} catch (error) {
		return false;
	}
}

function* saveSearchFilters(actions) {
	try {
		const { fieldName, value } = actions.payload;
		const filters = yield select(selectSearchFilters);
		yield put(
			setSearchFilters({
				...filters,
				[fieldName]: value,
			}),
		);
		return true;
	} catch (error) {
		return false;
	}
}

function* saveFollowerAndViewFilters(actions) {
	try {
		const { mainField, fieldName, value } = actions.payload;
		const filters = yield select(selectSearchFilters);
		yield put(
			setSearchFilters({
				...filters,
				[mainField]: {
					...filters[mainField],
					[fieldName]: value,
				},
			}),
		);
		return true;
	} catch (error) {
		return false;
	}
}

function* saveInfluencerReport(actions) {
	try {
		const influencerReport = actions.payload;
		yield put(setInfluencerReport(influencerReport));
		return true;
	} catch (error) {
		return false;
	}
}

function* changeActiveTabIndex(actions) {
	try {
		const tab = actions.payload;
		yield put({
			type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_FILTERS,
			payload: {
				fieldName: 'platform',
				value: tab,
			},
		});
		let index = 0;
		if (tab === 'youtube') {
			index = 1;
		} else if (tab === 'tiktok') {
			index = 2;
		}
		yield put(setActiveTabIndex(index));
		yield put({
			type: DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_FILTERS,
		});
		return true;
	} catch (error) {
		return false;
	}
}

function* clearSearchFilters() {
	try {
		const filters = yield select(selectSearchFilters);
		yield put(
			setSearchFilters({
				...filters,
				influencerLocation: [],
				influencerInterests: [],
				influencerLanguage: null,
				influencerGender: null,
				lastposted: null,
				engagementRate: null,
				accountType: [],
				bio: '',
				hashtags: [],
				mentions: [],
				growthRate: null,
				keywords: '',
				Search: false,
				influencerFollowers: {},
				influencerAvgReelPlay: {},
				views: {},
				audienceLocation: [],
				audienceAge: [],
				audienceGender: null,
				audienceInterests: [],
				audienceLanguage: null,
				credibility: null,
				// platform: 'instagram',
				relevance: [],
				identifier: [],
				checked: '',
				
			}),
		);
		yield put({
			type: DISCOVERY_STORE_CONSTANTS.IS_FILTER_CLEAR,
		});
		yield put(setComparisonProfiles([]));
		return true;
	} catch (error) {
		return false;
	}
}

function* applyFilters(actions) {
	try {
		const filters = actions.payload;
		yield put(setSearchFilters(filters));
		yield put({
			type: DISCOVERY_STORE_CONSTANTS.IS_FILTER_APPLY,
		});
		return true;
	} catch (error) {
		return false;
	}
}

function* clearSearchResults() {
	try {
		yield put(setSearchResults(null));
		return true;
	} catch (error) {
		return false;
	}
}

function* isFilterClearNow() {
	try {
		yield put(setIsFilterClear(`${new Date()}`));
		return true;
	} catch (error) {
		return false;
	}
}

function* isFilterApplyNow() {
	try {
		yield put(setIsFilterApply(`${new Date()}`));
		return true;
	} catch (error) {
		return false;
	}
}

function* addInfluencerIds(actions) {
	try {
		const comparisonProfiles = yield select(selectComparisonProfiles);
		const influencer = actions.payload;
		if (
			comparisonProfiles.some(
				(Influencer) => Influencer.influencer.userId === influencer.userId,
			)
		) {
			yield put(
				setComparisonProfiles([
					...comparisonProfiles.filter(
						(id) => id.influencer.userId !== influencer.userId,
					),
				]),
			);
		} else {
			yield put(setComparisonProfiles([...comparisonProfiles, influencer]));
		}
		return true;
	} catch (error) {
		return false;
	}
}

function* saveComparisonReports(actions) {
	try {
		const reports = actions.payload;
		yield put(setComparisonReports(reports));
		return true;
	} catch (error) {
		return false;
	}
}

function* addComparisonProfile(actions) {
	try {
		const comparisonProfiles = yield select(selectComparisonProfiles);
		const { influencer, platform } = actions.payload;

		if (
			comparisonProfiles.some(
				(Influencer) => Influencer.influencer.userId === influencer.userId,
			)
		) {
			yield put(
				setComparisonProfiles([
					...comparisonProfiles.filter(
						(id) => id.influencer.userId !== influencer.userId,
					),
				]),
			);
		} else {
			yield put(setComparisonProfiles([...comparisonProfiles, { influencer, platform }]));
		}
		return true;
	} catch (error) {
		return false;
	}
}

function* fetchMoreInfluencerReport(actions) {
	try {
		// eslint-disable-next-line prettier/prettier
		const { influencer, platform } = actions.payload;
		const influencerReport = yield call(fetchComparisonReports, {
			userId: influencer,
			platform,
		});
		const comparisonProfiles = yield select(selectComparisonProfiles);
		yield put(
			setComparisonProfiles([
				...comparisonProfiles.map((id) =>
					id.influencer.userId === influencerReport.userId
						? {
								influencer: influencerReport,
								platform: influencerReport.influencerType,
						  }
						: id,
				),
			]),
		);
		return true;
	} catch (error) {
		return false;
	}
}

export default function* discoverySaga() {
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SET_SEARCH_RESULTS, saveSearchResults);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SET_SEARCH_FILTERS, saveSearchFilters);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SEARCH_FILTERS, searchFilterInfluencer);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SET_FOLLWERS_VIEW, saveFollowerAndViewFilters);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SET_INFLUENCER_REPORT, saveInfluencerReport);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.CHANGE_ACTIVE_TABS, changeActiveTabIndex);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_FILTERS, clearSearchFilters);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_RESULTS, clearSearchResults);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.IS_FILTER_CLEAR, isFilterClearNow);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.IS_FILTER_APPLY, isFilterApplyNow);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.APPLY_SEARCH_FILTERS, applyFilters);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.ADD_INFLUENCER_IDS, addInfluencerIds);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SET_COMPARISON_PROFILE, addComparisonProfile);
	yield takeLatest(DISCOVERY_STORE_CONSTANTS.SAVE_COMPARISON_REPORTS, saveComparisonReports);
	yield takeLatest(
		DISCOVERY_STORE_CONSTANTS.FETCH_MORE_INFLUENCER_REPORT,
		fetchMoreInfluencerReport,
	);
}
