import React from 'react';
import Card, { CardBody, CardHeader, CardTitle } from '../../../../bootstrap/Card';

const ContactsData = ({ data }) => {
	const isValidUrl = (urlString) => {
		const urlPattern = new RegExp(
			'^(https?:\\/\\/)?' + // validate protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // validate fragment locator
		return !!urlPattern.test(urlString);
	};
	return (
		<div className='col-md-12'>
			<Card>
				<CardHeader>
					<CardTitle>Influencer Contacts</CardTitle>
				</CardHeader>
				<CardBody>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>Type</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{data.map((contact) => (
								<tr>
									<td>{contact?.type}</td>
									<td>
										{isValidUrl(contact?.value) ? (
											<a
												href={contact?.value}
												target='_blank'
												rel='noopener noreferrer'>
												{contact?.value}
											</a>
										) : (
											contact?.value
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</div>
	);
};

export default ContactsData;
