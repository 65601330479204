import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

const fetchInfluencerContent = async ({ projectId, trackingIds }) => {
	const { data } = await http.post(
		`${API_ENDPOINTS.GET_ANALYTICS_INFLUENCER_CONTENT}?projectId=${projectId}`,
		{
			projectId,
			trackingIds,
		},
	);
	return data;
};

const fetchInfluencerStats = async ({ projectId, trackingIds }) => {
	const { data } = await http.post(
		`${API_ENDPOINTS.GET_ANALYTICS_INFLUENCER_STATS}?projectId=${projectId}`,
		{
			projectId,
			trackingIds,
		},
	);
	return data;
};

const useFetchInfluencerStats = (options) => {
	// console.log({ options });
	const onSuccess = (data) => {
		console.log({data})
		// alert(JSON.stringify(data));
	}
	return useQuery(
		[API_ENDPOINTS.GET_ANALYTICS_INFLUENCER_STATS, options],
		fetchInfluencerStats(options),
		{
			enabled: !!(options.projectId && options.trackingIds),
			onSuccess
		},
	);
};

export { fetchInfluencerContent, fetchInfluencerStats };

export default useFetchInfluencerStats;
