import React from 'react';
import PropTypes from 'prop-types';
import Pagination, { PaginationItem } from '../../../bootstrap/Pagination';
import Popovers from '../../../bootstrap/Popovers';
import Icon from '../../../icon/Icon';

const PrevAndNextButton = ({
	prevIsDisabled,
	prevActive,
	prevOnClick,
	nextIsDisabled,
	nextActive,
	nextOnClick,
	isNexthasPopovers,
	desc,
}) => {
	return (
		<div className='card-footer-right d-flex'>
			<Pagination ariaLabel='Example prev string'>
				<PaginationItem
					isDisabled={prevIsDisabled}
					isActive={prevActive}
					onClick={prevOnClick}>
					<Icon icon='ChevronLeft' />
					Prev
				</PaginationItem>
				<PaginationItem
					isDisabled={nextIsDisabled}
					isActive={nextActive}
					onClick={nextOnClick}>
					{isNexthasPopovers ? 
							<Popovers desc={desc} trigger='hover'>
								Next
							</Popovers>: (
						'Next'
					)}
					<Icon icon='ChevronRight' />
				</PaginationItem>
			</Pagination>
		</div>
	);
};

PrevAndNextButton.propTypes = {
	prevIsDisabled: PropTypes.bool,
	prevActive: PropTypes.bool,
	prevOnClick: PropTypes.func,
	nextIsDisabled: PropTypes.bool,
	nextActive: PropTypes.bool,
	nextOnClick: PropTypes.func,
	isNexthasPopovers: PropTypes.bool,
	desc: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
PrevAndNextButton.defaultProps = {
	prevIsDisabled: false,
	prevActive: false,
	nextIsDisabled: false,
	nextActive: false,
	isNexthasPopovers: false,
    prevOnClick: null,
    nextOnClick: null,
    desc: null
};

export default PrevAndNextButton;
