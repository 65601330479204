import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { lists } from '../../../store/App/List/list.selector';
import useCreateListMutation from '../../../framework/basic-rest/list/create-list';
import useAddInfluencerToListMutation from '../../../framework/basic-rest/list/add-to-list';
import { useFetchAllListNames } from '../../../framework/basic-rest/list/get-all-list-names';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { NoListState } from '../Common/EmptyStates';
import handlers from '../../../pages/discovery/FiltersHelper';
import useRemoveInfluencerFromListMutation from '../../../framework/basic-rest/discovery/remove-influencer-from-list';
import Spinner from '../../bootstrap/Spinner';

const AddToList = ({ creator, influencerId, existingList, direction="down",color="danger" }) => {
	const listData = useSelector(lists);

	const [filterMenu, setFilterMenu] = useState(false);
	const { mutate: createList, isLoading: isCreatingList } = useCreateListMutation();
	const createNewList = async (body) => {
		createList(body);
	};

	const {
		mutate: removeInfluencer,
		isLoading: isRemoving,
		data: deletedData,
	} = useRemoveInfluencerFromListMutation();

	const newListForm = useFormik({
		initialValues: {
			listName: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			// setFilterMenu(false);
			const body = {
				listName: values.listName,
			};
			createNewList(body);
			// eslint-disable-next-line no-unused-vars
		},
	});
	const {
		mutate: addInfluencerToList,
		isLoading: isAddingInfluencer,
		data,
	} = useAddInfluencerToListMutation();

	const addInfluencers = async (body) => {
		addInfluencerToList(body);
		// eslint-disable-next-line no-unused-expressions
		!isAddingInfluencer && data && setFilterMenu(false);
	};
	
	const {
		// data: lists,
		// isLoading: isLoadingAllLists,
		refetch: refetchAllLists,
	} = useFetchAllListNames();

	const selectedListsForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			selectedList: existingList || [],
		},
		onSubmit: (values) => {
			const body = {
				list_ids: values.selectedList,
				influencer_ids: [influencerId],
			};
			addInfluencers(body);
			refetchAllLists();
		},
	});

	const handleChange = (e) => {
		console.log(e);
		const { checked, value, name } = e.target;
		if (checked) {
			const body = {
				list_ids: [value],
				influencer_ids: [influencerId],
			};
			addInfluencers(body);
			
		} else {
			removeInfluencer({
				influencer_id: influencerId,
				list_id: value,
			});
			
		}
		selectedListsForm.setFieldValue(
			'selectedList',
			handlers.handleList(e, selectedListsForm.values.selectedList),
		);
	};
	

	useEffect(() => {
		console.log("creator data on add list", creator);
	})

	// useEffect(() => {
	//     refetchAllLists();

	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isCreatingList, isRemoving]);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps

	}, [isAddingInfluencer]);
	return (
		<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu} direction={direction}>
			<DropdownToggle>
				<Button className='w-100' color={color} isLight>
					Add to list
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
				<div className='container py-2'>
					<form>
						<div classpcommName='row g-4'>
							<div className='col-12'>
								<FormGroup id='listName' label='Create List'>
									<InputGroup>
										<Input
											id='listName'
											ariaLabel='Create List'
											placeholder='Enter list name'
											onChange={newListForm.handleChange}
											value={newListForm.values.listName}
										/>
										<Button
											color='dark'
											isLight
											icon='Add'
											isDisable={newListForm.values.listName.length <= 2}
											onClick={() => {
												newListForm.handleSubmit();
												
											}}
										/>
									</InputGroup>
								</FormGroup>
							</div>
							<div className='col-12 dv-list-check-group'>
								<FormGroup label='Lists'>
									{listData && listData.length ? (
										<ChecksGroup>
											{listData.map((list, index) => (
												<>
													<Checks
														// eslint-disable-next-line react/no-array-index-key
														key={`list-check-${index}`}
														id={list.listName}
														label={list.listName}
														value={list._id}
														onChange={handleChange}
														checked={
															list
																? list.influencers
																		.map((val) => {
																			if (
																				val?.influencer_id?._id ===
																				influencerId
																			) {
																				return true;
																			}
																			return false;
																		})
																		.includes(true)
																: false
														}
													/>
													{(isCreatingList || isRemoving) && (
														<Spinner isSmall inButton />
													)}
												</>
											))}
										</ChecksGroup>
									) : (
										<NoListState height={100} width={100} />
									)}
								</FormGroup>
							</div>
						</div>
						{!(listData && listData.length) && (
							<p className='text-muted text-center'>Create a list</p>
						)}
					</form>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default AddToList;
