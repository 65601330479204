import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import { ModalBody, ModalFooter } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import CreatorDropdown from '../custom/crm/searchCreator/dropdown';
import { emailModalSchema } from '../../validations/Email/email';
import useSendMailMutation from '../../framework/basic-rest/email/send-email';
import { useBucketEmailList } from '../../framework/basic-rest/email/get-bucket-emails';
import Spinner from '../bootstrap/Spinner';
import CustomDropdown from '../custom/Common/CustomDropdown';
import { lists } from '../../store/App/List/list.selector';
import useGetEmailList from '../../framework/basic-rest/email/get-emails-list';


const EmailModel = ({ campaignId, creatorId, email, userName, outreach }) => {
	const initialState = {
		creatorId: [],
	};
	if (campaignId) {
		initialState.campaignId = campaignId;
	}
	if (creatorId) {
		initialState.creatorId = creatorId;
	}

	const [payload, setPayload] = useState(initialState);
	
	
	const [selectedCreator, setSelectedCreator] = useState([]);
	const [selectedList, setSelectedList] = useState('');

	// const {data: emailList, refetch, isLoading: emailLoading} = useBucketEmailList();
	const {data: bucketEmails,mutate: getEmailList, isLoading: emailLoading} = useGetEmailList();

	const listData = useSelector(lists);


	console.log("list from bucket", listData);

	const mailFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			subject: '',
			messageBody: '',
		},
		validateSchema: emailModalSchema,
		onSubmit: (values) => {
			console.log("values-----",values);
			sendMail(values);
		},
	});
	// need to changed to send mail to multiple creator in a single time
	const handleSelect = (influencer) => {
		if (!payload.creatorId.includes(influencer._id)) {
			setPayload({ ...initialState, creatorId: [...payload.creatorId, influencer._id] });
			setSelectedCreator([...selectedCreator, influencer.email]);
			
		}
	};
	const handleDeSelect = (index) => {
		const updatedSelectedCreator = selectedCreator.filter((_, i) => index !== i);
		const updatedPayloadCreatorId = payload.creatorId.filter((_, i) => index !== i);
		setPayload({ ...initialState, creatorId: updatedPayloadCreatorId });
		setSelectedCreator(updatedSelectedCreator);
	};

	const handleSelectedBucket = (element) => {
		console.log(element.bucketName);
		setSelectedList(element.bucketName);
		console.log(selectedList);
		const body = {
			bucketName: selectedList
		}
		getEmailList(body);
	}

	const { mutate: sendMail, isSuccess, isLoading } = useSendMailMutation(payload);

	const importFromBucket = async() => {
		setSelectedCreator([]);
		setPayload(initialState);
		
		// const {data}  = await refetch();

		const body = {
			bucketName: selectedList
		}
		getEmailList(body);
		console.log("bucketEmails", bucketEmails);
		
		
		setSelectedCreator(bucketEmails?.data[0]?.emails);
		
		setPayload({ ...initialState, creatorId: bucketEmails?.data[1]?.creatorId });
	}

	const clearList = () => {
		setSelectedCreator([]);
		
		setPayload(initialState);
		const body = {
			bucketName: selectedList
		}
		getEmailList(body);
	}
	return (
		<ModalBody>
			<form onSubmit={mailFormik.handleSubmit}>
				<div className='row g-4'>
					<div className='col-12'>
						{outreach ? (
							<CreatorDropdown
								selectedCreator={selectedCreator}
								handleSelect={handleSelect}
								handleDeSelect={handleDeSelect}
								showEmail
							/>
						) : (
							<FormGroup id='to' label='To'>
								<Input
									readOnly
									placeholder='To'
									onChange={mailFormik.handleChange}
									value={email}
								/>
							</FormGroup>
						)}
					</div>

					<div  className='col-12'>
					<CustomDropdown
						list={listData}
						labelText='bucketName'
						valueText='_id'
						defaultOption= "Select List" 
						onClick={(element) => {
							// handleUpdateStage(element, creator?._id);
							handleSelectedBucket(element);
						}}
					/>
					</div>
					<div className='col-12'>
					
						<Button
							color='info'
							className='btn-auth'
							onClick={() => importFromBucket()}>
								{emailLoading && <Spinner isSmall inButton />}
							Import From List
						</Button>
					</div>
					<div className='col-12' >
					
						<Button
							color='info'
							className='btn-auth'
							onClick={() => clearList()}>
								{emailLoading && <Spinner isSmall inButton />}
							Clear
						</Button>
					</div>
					<div className='col-12'>
						<FormGroup id='subject' label='Subject'>
							<Input
								placeholder='Subject'
								onChange={mailFormik.handleChange}
								value={mailFormik.values.subject}
								onBlur={mailFormik.handleBlur}
								isValid={mailFormik.isValid}
								isTouched={mailFormik.touched.subject}
								invalidFeedback={mailFormik.errors.subject}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='messageBody' label='Message' className='mb-3'>
							<ReactQuill
								theme='snow'
								value={mailFormik.values.messageBody}
								onChange={(e) => {
									mailFormik.setFieldValue('messageBody', e);
								}}
							/>
						</FormGroup>
					</div>

				</div>

				<ModalFooter className='bg-transparent'>
					<Button
						type='submit'
						color='info'
						className='btn-auth'
						isDisable={isLoading || !mailFormik.isValid}>
						send
					</Button>
				</ModalFooter>
			</form>
		</ModalBody>
	);
};

export default EmailModel;
