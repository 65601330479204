import { takeLatest, put, call, select, take } from 'redux-saga/effects';
// import { fetchCRMCreators } from '../../../../framework/basic-rest/crm/get-crm-creators';
import { CRM_COMPANIES_STORE_CONSTANTS } from '../../../constants.store';
import {
	setCompanyToEdit,
	setCrmCompany,
	setCrmCompanyById,
	setCrmCompanyId,
	setGetCompany,
	setIsCompanyUpdate,
} from './crm.companies.slice';

function* setCRMCompany(actions) {
	try {
		const Company = actions.payload;
		yield put(setCrmCompany(Company));
		console.log(Company);
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMCompanyId(actions) {
	try {
		const Company = actions.payload;
		yield put(setCrmCompanyId(Company));
		console.log(Company);
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMCompanyById(actions) {
	try {
		const Company = actions.payload;
		yield put(setCrmCompanyById(Company));
		console.log(Company);
		return true;
	} catch (error) {
		return false;
	}
}

function* setUpdateCompany(actions) {
	try {
		const company = actions.payload;
		yield put(setIsCompanyUpdate(company));
		console.log(company);
		return true;
	} catch (error) {
		return false;
	}
}

function* setCompany(actions) {
	try {
		const company = actions.payload;
		yield put(setGetCompany(company));
		console.log(company);
		return true;
	} catch (error) {
		return false;
	}
}
function* setEditCompany(actions) {
	try {
		const company = actions.payload;
		yield put(setCompanyToEdit(company));
		console.log(company);
		return true;
	} catch (error) {
		return false;
	}
}
export default function* crmCompanySaga() {
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.SET_CRM_COMPANY_DATA, setCRMCompany);
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.UPDATE_CRM_COMPANY_ID, setCRMCompanyId);
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.SET_CRM_COMPANY_BY_ID, setCRMCompanyById);
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.SET_SELECTED_COMPANY, setCompany);
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.IS_UPDATE_COMPANY, setUpdateCompany);
	yield takeLatest(CRM_COMPANIES_STORE_CONSTANTS.SET_COMPANY_TO_EDIT, setEditCompany);
}
