import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

const fetchAnalyticsProjectMetaData = async () => {
	const { data } = await http.get(`${API_ENDPOINTS.GET_ANALYTICS_PROJECT_METADATA}`);
	return data;
};

const useProjectProjectMetaDataQuery = () => {
	return useQuery([API_ENDPOINTS.GET_ANALYTICS_PROJECT_METADATA], fetchAnalyticsProjectMetaData);
};

export { useProjectProjectMetaDataQuery, fetchAnalyticsProjectMetaData };
