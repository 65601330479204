import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const fetchComparisonReports = async (argument) => {
	const { platform, userId } = argument;
	const isRefetch = true;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_INFLUENCERS_REFETCH}?platform=${platform}&userId=${userId}&isRefetch=${isRefetch}`,
	);
	return data.data || [];
};

export const fetchInfluencerDetails = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { userId, platform, isRefetch } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_INFLUENCERS_REFETCH}?platform=${platform}&userId=${userId}&isRefetch=${isRefetch}`,
	);
	return data.data || [];
};

export const useGetInfluencerReport = (options) => {
	const dispatch = useDispatch();
	const onSuccess = (data) => {
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_INFLUENCER_REPORT,
			payload: data,
		});
		dispatch({
			type: CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS,
			payload: `Full/Report/${data.updatedAt}`,
		});
	};
	const onError = (data) => {
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_INFLUENCER_REPORT,
			payload: null,
		});
	};

	return useQuery([API_ENDPOINTS.GET_INFLUENCERS_REFETCH, options], fetchInfluencerDetails, {
		onSuccess,
		onError,
		enabled: !!options,
	});
};
