import { Dropdown } from 'bootstrap';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useGetInfluencerReport } from '../../../framework/basic-rest/discovery/get-influencer-refetch';
import { useInfluencerReportQuery } from '../../../framework/basic-rest/discovery/get-influencers-report';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import { selectInfluencerReport } from '../../../store/App/Discovery/discovery.selector';
import { lists, selectListInfluencer } from '../../../store/App/List/list.selector';
import Avatar from '../../Avatar';
import Alert from '../../bootstrap/Alert';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import Card, { CardBody } from '../../bootstrap/Card';
import ContactDetails from '../Common/ContactDetailsCard';
import { NoDataFound1, NoListState } from '../Common/EmptyStates';
import MyContactsCard from '../Common/MyContacts';
import MyPricingCard from '../Common/MyPricings';
import NotesCard from '../Common/Notes';
import PricingDetails from '../Common/PricingDetailsCard';
import AddToFavourite from '../DiscoveryComponents/AddToFavourite';
import AddToList from '../DiscoveryComponents/AddToList';
import ProfileSkeleton from '../DiscoveryComponents/ProfileSkeleton';
import InfluencerProfile from '../DiscoveryComponents/report_Components/InfluencerProfile';

const InfluencerProfileModal = () => {
	const listData = useSelector(lists);

	const navigate = useNavigate();

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Creator Profile Report',
	});
	const [isDownloading, setIsDownloading] = useState(false);

	const mangePrint = () => {
		setIsDownloading(true);
		setTimeout(() => {
			handlePrint();
			setTimeout(() => {
				setIsDownloading(false);
			}, 200);
		}, 200);
	};

	const { darkModeStatus, themeStatus } = useDarkMode();

	const influencerReport = useSelector(selectInfluencerReport);
	const influencer = useSelector(selectListInfluencer);

	const [influencerData, setInfluencerData] = useState(null);

	const {
		data: influencerFullReport,
		isLoading: isGettingReport,
		refetch: influencerReportRefetch,
	} = useGetInfluencerReport(influencerData);

	const [isFullReport, setIsFullReport] = useState(influencer?.isFullReport);

	useEffect(() => {
		setIsFullReport(influencer?.isFullReport);
	}, [influencer]);

	const TABS = {
		CREATOR_ANALYTICS: 'Creator Analytics',
		PRICING: 'Pricings',
		MY_CONTACTS: 'Contacts',
		NOTES: 'Notes',
	};
	const [isAddContact, setIsAddContact] = useState(false);
	const [isAddPricing, setAddPricing] = useState(false);
	const [activeTab, setActiveTab] = useState(TABS.CREATOR_ANALYTICS);
	return influencer ? (
		<Page container='fluid'>
			<SubHeader>
				<SubHeaderLeft>
					<span className='h4 fw-bold'>Influencer Profile</span>
					<Button
						color='info'
						isLink
						icon='ArrowBack'
						onClick={() => {
							navigate(-1);
						}}>
						Go Back
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<ContactDetails
				// influencer={influencer}
				isAddContact={isAddContact}
				setIsAddContact={setIsAddContact}
			/>

			<PricingDetails
				// influencer={influencer}
				isAddPricing={isAddPricing}
				setAddPricing={setAddPricing}
			/>
			<div className='d-flex flex-column'>
				<div className='row'>
					<div className='col-md-12'>
						<Card>
							<CardBody stretch>
								<div className='row g-3'>
									<div className='col-4'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={TABS.CREATOR_ANALYTICS !== activeTab}
											onClick={() => setActiveTab(TABS.CREATOR_ANALYTICS)}>
											{TABS.CREATOR_ANALYTICS}
										</Button>
									</div>
									<div className='col-4'>
										<Button
											icon='ContactPage'
											color='info'
											className='w-100 p-3'
											isLight={TABS.MY_CONTACTS !== activeTab}
											onClick={() => setActiveTab(TABS.MY_CONTACTS)}>
											{TABS.MY_CONTACTS}
										</Button>
									</div>
									<div className='col-4'>
										<Button
											icon='AttachMoney'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PRICING !== activeTab}
											onClick={() => setActiveTab(TABS.PRICING)}>
											{TABS.PRICING}
										</Button>
									</div>
									{/* <div className='col-12'>
											<Button
												icon='Notes'
												color='info'
												className='w-100 p-3'
												isLight={TABS.NOTES !== activeTab}
												onClick={() => setActiveTab(TABS.NOTES)}>
												{TABS.NOTES}
											</Button>
										</div> */}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
				<div className='overflow'>
					{(activeTab === TABS.CREATOR_ANALYTICS && (
						// <ReportAndAnalytics influencer={influencer} isProfileOpen={showProfile} setIsProfileOpen={setShowProfile} />
						<Page container='fluid'>
							{!isGettingReport ? (
								<>
									<div />
									{influencerReport &&
									influencerReport.userId === influencer.influencerData.userId ? (
										<>
											<div className='d-flex justify-content-end'>
												{!isGettingReport && (
													<>
														<div className='mx-4'>
															<AddToFavourite
																_id={
																	influencerReport?.influencer_id
																}
															/>
														</div>
														<AddToList
															influencerId={
																influencerReport?.influencer_id
															}
															existingList={influencerReport?.lists}
														/>
													</>
												)}
												<Button
													className='mx-4'
													isLight
													color='danger'
													onClick={mangePrint}>
													Download
												</Button>
												{influencerReport && (
													<Button
														className='me-4'
														isLight
														color='danger'
														icon='Refresh'
														onClick={() => {
															setInfluencerData({
																userId: influencerReport.userId,
																platform:
																	influencerReport.influencerType,
																isRefetch: true,
																date: new Date(),
															});
														}}>
														Refresh <Badge>1.5</Badge>
													</Button>
												)}
											</div>
											<div
												ref={componentRef}
												className='h-100 d-inline-block'>
												<InfluencerProfile
													isDownloading={isDownloading}
													ref={componentRef}
													report={influencerReport}
												/>
											</div>
										</>
									) : (
										<>
											<div />
											{influencer.isFullReport ? (
												<div
													className='col-md-12'
													style={{ height: '30rem' }}>
													<Card stretch>
														<CardBody className='d-flex align-items-center justify-content-center'>
															<Button
																color='info'
																size='lg'
																isLight
																className='w-100 h-100'
																onClick={() => {
																	setInfluencerData({
																		userId: influencer
																			.influencerData.userId,
																		platform:
																			influencer.influencerType,
																		isRefetch: false,
																	});
																}}
																icon='AddCircle'>
																View Full-Report
															</Button>
														</CardBody>
													</Card>
												</div>
											) : (
												<div
													className='col-md-12'
													style={{ height: '30rem' }}>
													<Card stretch>
														<CardBody className='d-flex align-items-center justify-content-center'>
															<Button
																color='info'
																size='lg'
																isLight
																className='w-100 h-100'
																onClick={() => {
																	setInfluencerData({
																		userId: influencer
																			.influencerData.userId,
																		platform:
																			influencer.influencerType,
																		isRefetch: true,
																	});
																}}
																icon='AddCircle'>
																View Full-Report :{' '}
																<Badge>1.5</Badge>
															</Button>
														</CardBody>
													</Card>
												</div>
											)}
										</>
									)}
								</>
							) : (
								<ProfileSkeleton />
							)}
						</Page>
					)) ||
						(activeTab === TABS.MY_CONTACTS &&
							(influencer?.lists?.length !== 0 ? (
								<MyContactsCard
									influencer={influencer}
									isAddContact={isAddContact}
									setIsAddContact={setIsAddContact}
								/>
							) : (
								<div className='row g-4'>
									<div className='col-md-12'>
										<Alert color='warning' isLight icon='Report'>
											Add This Influencer to List and create Contacts
										</Alert>
									</div>
								</div>
							))) ||
						(activeTab === TABS.PRICING &&
							(influencer?.lists?.length !== 0 ? (
								<MyPricingCard
									influencer={influencer}
									isAddPricing={isAddPricing}
									setAddPricing={setAddPricing}
								/>
							) : (
								<div className='row g-4'>
									<div className='col-md-12'>
										<Alert color='warning' isLight icon='Report'>
											Add This Influencer to List and Create Pricing
										</Alert>
									</div>
								</div>
							))) ||
						(activeTab === TABS.NOTES && <NotesCard influencer={influencer} />)}
				</div>
			</div>
		</Page>
	) : (
		<Page>
			<div className='d-flex flex-column align-items-center'>
				<NoDataFound1 message='Please Select Influencer from List to view profile' />
			</div>
		</Page>
	);
	// 	</ModalBody>
	// </Modal>
};

export default InfluencerProfileModal;
