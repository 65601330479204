import React from 'react'
import { checkArrayData } from '../../../../helpers/helpers'
import useOnClickMode from '../../../../hooks/OnClickMode';
import Button, { ButtonGroup } from '../../../bootstrap/Button';

const CustomButtonGroup = ({
    buttonArr,
    activeTab,
    onClickTab,
    isDisabled=false
}) => {
    const { themeStatus, onClickStatus } = useOnClickMode();
    return (
        <ButtonGroup>
            {checkArrayData(buttonArr) && buttonArr.map((button) => (
                <Button
                    id={button._id}
                    key={`${activeTab}-${button._id}`}
                    disabled={isDisabled}
                    color={
                        activeTab === button._id
                            ? 'info'
                            : themeStatus
                    }
                    onClick={onClickTab}>
                    {button.permission}
                </Button>
            ))}
        </ButtonGroup>
    )
}

export default CustomButtonGroup