import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const fetchPermission = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { type, name } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_SHARE_PERMISSION}?type=${type}&name=${name}`,
	);
	return data.data || [];
};

export const useSharedPermission = (options) => {
	return useQuery([API_ENDPOINTS.GET_SHARE_PERMISSION, options], fetchPermission, {
		enabled: !!options,
	});
};
