import { Alert } from 'bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import useDarkMode from '../../../../hooks/useDarkMode';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../../../bootstrap/Modal';
import Icon from '../../../icon/Icon';
import Page from '../../../../layout/Page/Page';
import Badge from '../../../bootstrap/Badge';

const OpenContact = ({ isOpenContact, setIsOpenContact, contact, source, additionalInfo }) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<Modal
			setIsOpen={setIsOpenContact}
			isOpen={isOpenContact}
			size='lg'
			titleId='add-new-card'
			isCentered
			isAnimation={false}
			isScrollable
			height='50rem'>
			<ModalHeader setIsOpen={setIsOpenContact} />
			<ModalBody>
				<div className='pt-3 pb-5 d-flex align-items-center'>
					<span className='display-4 fw-bold me-3'>{`${contact.firstName} ${contact.lastName}`}</span>
					<span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
						{contact.label}
					</span>
				</div>
				<div className='row'>
					<div className='col-lg-6'>
						<Card className='shadow-3d-info'>
							<CardBody>
								<div className='row g-5'>
									<div className='col-12'>
										<div className='row g-3'>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon icon='Mail' size='3x' color='info' />
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{`${contact.email}`}
														</div>
														<div className='text-muted'>
															Email Address
														</div>
													</div>
												</div>
											</div>
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon icon='Tag' size='3x' color='info' />
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{`${contact.phone}`}
														</div>
														<div className='text-muted'>
															Phone Number
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<CardLabel icon='Stream' iconColor='warning'>
									<CardTitle>Additional Info.</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								{additionalInfo && additionalInfo.length !== 0 ? (
									<div className='row g-2'>
										{additionalInfo.map((info) => (
											<div className='col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon icon='Mail' size='3x' color='info' />
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{`${info.value}`}
														</div>
														<div className='text-muted'>
															<Badge className='fs-6'>
																{info.key}
															</Badge>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								) : (
									<div className='row'>
										<div className='col'>
											<Alert
												color='warning'
												isLight
												icon='Report'
												className='mb-0'>
												No results to show
											</Alert>
										</div>
									</div>
								)}
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card className='shadow-3d-info'>
							<CardBody>
								<div className='row g-3'>
									<div className='col-xl-12'>
										<div
											className={`d-flex align-items-center bg-l${
												darkModeStatus ? 'o25' : '10'
											}-info rounded-2 p-2`}>
											<div className='flex-shrink-0'>
												<Icon icon='Notes' size='3x' color='info' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-4 mb-0'>Notes</div>
												<div className='text-muted mt-n2 truncate-line-1'>
													{contact.notes}
												</div>
											</div>
										</div>
									</div>
									<div className='col-xl-12'>
										<div
											className={`d-flex align-items-center bg-l${
												darkModeStatus ? 'o25' : '10'
											}-info rounded-2 p-2`}>
											<div className='flex-shrink-0'>
												<Icon icon='LocationCity' size='3x' color='info' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-4 mb-0'>Location</div>
												<div className='text-muted mt-n2 truncate-line-1'>
													{contact.loc}
												</div>
											</div>
										</div>
									</div>
									<div className='col-xl-12'>
										<div
											className={`d-flex align-items-center bg-l${
												darkModeStatus ? 'o25' : '10'
											}-info rounded-2 p-2`}>
											<div className='flex-shrink-0'>
												<Icon icon='List' size='3x' color='info' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-4 mb-0'>{source}</div>
												<div className='text-muted mt-n2 truncate-line-1'>
													Source
												</div>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default OpenContact;
