import React, { useState, useEffect, useRef } from 'react';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import { ListPermissionTypes } from '../../../Common/Workspace/PermissionTypes';
import CustomRadioButton from '../../../Common/CustomRadioButton';
import { PERMISSIONS } from '../../utils/constData';
import Option from '../../../../bootstrap/Option';
import Select from '../../../../bootstrap/forms/Select';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../bootstrap/Card';

const List = ({ filtersForm }) => {
	const onChangeHandle = (fieldName, value) => {
		filtersForm.setFieldValue(fieldName, value);
	};
	return (
		<div className='col-md-6'>
			<Card>
				<CardBody>
					<div className='row'>
						<div className='col-md-12'>
							<CardLabel icon='Bullseye' iconColor='warning'>
								<CardTitle>List</CardTitle>
							</CardLabel>
							<FormGroup id='list_permission' label='Permission'>
								<CustomRadioButton
									radioButtons={ListPermissionTypes}
									id='list-permission'
									changed={(event) =>
										// filtersForm.setFieldValue(
										// 	'influencerGender',
										// 	event.target.value,
										// )
										onChangeHandle('list_permission', event.target.value)
									}
									isSelected={filtersForm.values.list_permission || 'VIEW'}
								/>
							</FormGroup>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default List;
