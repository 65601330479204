import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CustomSkeleton = ({ count, isCircle = false, ...props }) => {
	return (
		// <SkeletonTheme width={props.width || '50'} height={props.width || '50'}>
		// 	<p>
		<Skeleton count={count} circle={isCircle} className={props.className} style={props.style} />
		// 	</p>
		// </SkeletonTheme>
	);
};

export default CustomSkeleton;
