import { all } from 'redux-saga/effects';
import analyzeSaga from './App/Analyze/analyze.saga';
import authSaga from './App/Auth/auth.saga';
import discoverySaga from './App/Discovery/discovery.saga';
import listSaga from './App/List/list.saga';
import queryBuilderSaga from './App/QueryBuilder/queryBuilder.saga';
import workspaceSaga from './App/Workspace/workpace.saga';
// import analyticsSaga from './App/SocialAnalytics/analytics.saga';
import creditsSaga from './App/Credits/credits.saga';
import controlPanelSaga from './App/ControlPanel/controlPanel.saga';
import socialTrackingSaga from './App/SocialAnalytics/Tracking/tracking.saga';
import socialProjectSaga from './App/SocialAnalytics/Project/project.saga';
import crmCreatorsSaga from './App/crm/creators/crm.creators.saga';
import crmProjectSaga from './App/crm/projects/crm.projects.saga';
import crmCompanySaga from './App/crm/companies/crm.companies.saga';
import analyticsSaga from './App/SocialAnalytics/analytics.saga';
import initCrmSaga from './App/crm/common/crm.saga';
import crmNoteSaga from './App/crm/notes/crm.notes.saga';
import crmTaskSaga from './App/crm/tasks/crm-tasks-saga';
import crmMailsSaga from './App/crm/outreachMails/crm-mails-saga';
import conversionAnalyticsSaga from './App/ConversionAnalytics/conversionAnalytics.saga';
import creatorAssociaitonSaga from './App/crm/utils/crm.utils.saga';
import cmsSaga from './App/cms/cms.saga';
import organizationSaga from './App/Organization/organization.saga';

export default function* () {
	yield all([
		discoverySaga(),
		authSaga(),
		workspaceSaga(),
		analyzeSaga(),
		queryBuilderSaga(),
		listSaga(),
		creditsSaga(),
		controlPanelSaga(),
		analyticsSaga(),
		socialTrackingSaga(),
		socialProjectSaga(),
		crmCreatorsSaga(),
		crmProjectSaga(),
		crmCompanySaga(),
		initCrmSaga(),
		crmNoteSaga(),
		crmTaskSaga(),
		crmMailsSaga(),
		conversionAnalyticsSaga(),
		creatorAssociaitonSaga(),
		cmsSaga(),
		organizationSaga(),
	]);
}
