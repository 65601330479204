import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	isUpdateCrmTask: null,
	crmTasks: null,
	crmTaskById: null,
	showCRMtaskData: null,
};

const slice = createSlice({
	name: 'tasks',
	initialState,
	reducers: {
		setIsUpdateCrmTask: (state, action) => {
			state.isUpdateCrmTask = action.payload;
		},
		setCrmTasks: (state, action) => {
			state.crmTasks = action.payload;
		},
		setCrmTaskById: (state, action) => {
			state.crmTaskById = action.payload;
		},
		setShowCRMtaskData: (state, action) => {
			state.showCRMtaskData = action.payload;
		},
	},
});

export const { setIsUpdateCrmTask, setCrmTasks, setCrmTaskById, setShowCRMtaskData } =
	slice.actions;

export default slice.reducer;
