import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import useDarkMode from '../../../hooks/useDarkMode';
import Button from '../../bootstrap/Button';
import Icon from '../../icon/Icon';

const CustomDropdown = ({
	className,
	children,
	defaultOption,
	isDefaultValue = false,
	list,
	labelText,
	valueText,
	onClick,
	direction = 'end',
	emptyState = 'No Data',
	isLabelChangeable = true,
}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'md',
	};
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (isDefaultValue) {
			// eslint-disable-next-line no-unused-expressions
			list && setValue(list[0]);
		}
	}, [isDefaultValue, list]);

	return (
		<Dropdown className={className} direction={direction}>
			<DropdownToggle>
				<Button
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...styledBtn}
					color='success'
					aria-label='Change workspace'>
					{value && isLabelChangeable ? value[labelText] : defaultOption}
				</Button>
			</DropdownToggle>
			<DropdownMenu className='overflow-auto' style={{ height: '10rem' }}>
				{list && list.length ? (
					list?.map((element, i) => (
						<DropdownItem
							key={element[valueText]}
							onClick={() => {
								setValue(element);
								onClick(element);
							}}>
							{element[labelText]}
						</DropdownItem>
					))
				) : (
					<DropdownItem key='empty-state'>{emptyState}</DropdownItem>
				)}
				{children}
			</DropdownMenu>
		</Dropdown>
	);
};

export default CustomDropdown;
