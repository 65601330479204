import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkspace } from '../../../store/App/Workspace/workspace.selector';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';

async function getFilteredInfluencers(input) {
	const res = await http.post(`${API_ENDPOINTS.GET_FILTERED_INFLUENCERS}`, input);
	return res.data.data;
}

const useGetFilteredInfluencers = () => {
	const dispatch = useDispatch();
	const workspace = useSelector(selectWorkspace);
	return useMutation((input) => getFilteredInfluencers(input), {
		onSuccess: (data) => {
			// console.log(
			// 	'🚀 ~ file: get-filtered-influencers.js ~ line 38 ~ returnuseMutation ~ data',
			// 	data,
			// );
			// dispatch({
			// 	type: LIST_STORE_CONTANTS.SET_LIST_INFLUENCERS_DATA,
			// 	payload: data
			// })
		},
		onError: (err) => {
			console.log(
				'🚀 ~ file: get-filtered-influencers.js ~ line 21 ~ returnuseMutation ~ err',
				err,
			);
		},
		enabled: !!workspace
	});
};

export default useGetFilteredInfluencers;
