import axios from 'axios';

const http = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	timeout: 300000,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export default http;
