import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useGetCurrencies } from '../../../framework/basic-rest/contacts_and_pricing/get-system-currencies';
import Button from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Spinner from '../../bootstrap/Spinner';
import { NoListState } from './EmptyStates';

const PricingCard = ({ type, formik, influencer, isCreatingPrice }) => {
	const {
		data: currencies,
		isLoading: isLoadingCurrencies,
		refetch: refetechCurrencies,
	} = useGetCurrencies();
	return (
		<>
			<CardHeader>
				<CardLabel icon='Person' iconColor='info'>
					<CardTitle>{type}</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody style={{ height: '30rem' }}>
				<CardHeader>
					<CardLabel icon='Edit' iconColor='warning'>
						<CardTitle>Add Price</CardTitle>
					</CardLabel>
				</CardHeader>
				<div className='row g-4'>
					<div className='col-md-6'>
						<FormGroup
							id='content_type'
							label='Content Type'
							isFloating
							formText='E.g. Reels, IGTV, Post, etc'>
							<Input
								placeholder='Content type'
								autoComplete='content type'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.content_type}
								isValid={formik.isValid}
								isTouched={formik.touched.content_type}
								invalidFeedback={formik.errors.content_type}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='lower_price' label='Lower-price' isFloating>
							<Input
								placeholder='lower-price'
								autoComplete='additional-name'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.lower_price}
								isValid={formik.isValid}
								isTouched={formik.touched.lower_price}
								invalidFeedback={formik.errors.lower_price}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='upper_price' label='Upper-price' isFloating>
							<Input
								placeholder='upper-price'
								autoComplete='additional-name'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.upper_price}
								isValid={formik.isValid}
								isTouched={formik.touched.upper_price}
								invalidFeedback={formik.errors.upper_price}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='currency'>
							<AsyncTypeahead
								id='async-currency'
								// isLoading={isLoadingCurrencies || false}
								labelKey='name'
								minLength={0}
								onSearch={(query) => {
									// setLocationQuery(query);
									// refetechCurrencies();
								}}
								onChange={(e) => {
									formik.setFieldValue('currency', e.map((x) => x.cc)[0]);
								}}
								options={currencies && currencies?.data}
								placeholder='Select currency'
								useCache={false}
								renderMenuItemChildren={(option, props) => (
									<div>
										<span>{option.name}</span>
									</div>
								)}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterRight>
					<Button
						color='info'
						icon='Save'
						type='submit'
						isDisable={!formik.isValid || isCreatingPrice}
						onClick={() => formik.handleSubmit()}>
						{isCreatingPrice ? 'Please wait...' : 'Save'}
						{isCreatingPrice && <Spinner isSmall inButton />}
					</Button>
				</CardFooterRight>
			</CardFooter>
		</>
	);
};

export default PricingCard;
