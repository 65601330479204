import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './crm.projects.slice';

const crmProjectSlice = (state) => state.projects || initialState;

export const CRMProject = createSelector([crmProjectSlice], (state) => state.crmProject);
export const selectCrmProjectId = createSelector([crmProjectSlice], (state) => state.crmProjectId);
export const selectCrmProjectById = createSelector(
	[crmProjectSlice],
	(state) => state.crmProjectById,
);
export const selectIsUpdateCrmProject = createSelector(
	[crmProjectSlice],
	(state) => state.isUpdateCrmProject,
);
export const selectGetProject = createSelector([crmProjectSlice], (state) => state.getProject);
