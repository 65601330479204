import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { CRM_COMPANIES_STORE_CONSTANTS } from '../../../../store/constants.store';
import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsUpdateCrmProject } from '../../../../store/App/crm/projects/crm.projects.selectors';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';

const fetchCRMCompany = async () => {
	const data = await http.get(`${API_ENDPOINTS.GET_CRM_COMAPNIES}`);
	console.log("allpartners", data);
	return data?.data;
};


const useCRMCompanyQuery = () => {
	// const selectCompany = useSelector(CRMCompany);
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);

	const isUpdateCompany = useSelector(selectIsCompanyUpdate);
	const isUpdateCreator = useSelector(selectIsUpdateCrmCreator);
	const isProjectUpdated = useSelector(selectIsUpdateCrmProject);

	const dispatch = useDispatch();
	const onSuccess = (data) => {
		dispatch({
			type: CRM_COMPANIES_STORE_CONSTANTS.SET_CRM_COMPANY_DATA,
			payload: data?.data,
		});
	};
	return useQuery(
		[
			API_ENDPOINTS.GET_CRM_COMAPNIES,
			isUpdateCompany,
			isUpdateCreator,
			isProjectUpdated,
			isNOTEupdated,
			isTaskUpdated,
		],
		fetchCRMCompany,
		{
			onSuccess,
		},
	);
};

export { useCRMCompanyQuery, fetchCRMCompany };
