import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

const fetchGetEventDetails = async (input) => {
	const { data } = await http.post(`${API_ENDPOINTS.GET_EVENTSDATA_BY_SELECTED_EVENT}`, input);
	return data?.data;
};
const useFetchGetEventDetails = (options) => {
	return useQuery(
		[API_ENDPOINTS.GET_EVENTSDATA_BY_SELECTED_EVENT, options],
		fetchGetEventDetails,
	);
};
export { useFetchGetEventDetails };

export default fetchGetEventDetails;
