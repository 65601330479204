import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeletePricing } from '../../../framework/basic-rest/contacts_and_pricing/delete-pricing';
import { useGetAllContacts } from '../../../framework/basic-rest/contacts_and_pricing/get-all-contacts';
import { useGetAllPricing } from '../../../framework/basic-rest/contacts_and_pricing/get-all-pricing';
import { useFetchAllInfluencerCP } from '../../../framework/basic-rest/list/get-influencer-cp';
import { ConfirmAlert } from '../../../helpers/confirm-helper';
import { nFormatter, priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import CommonFilterTag from '../../../pages/common/CommonFilterTag';
import {
	selectInfluencerPricing,
	selectIsPriceUpdated,
} from '../../../store/App/List/list.selector';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import { NoListState } from './EmptyStates';

const MyPricingCard = ({ influencer, setAddPricing }) => {
	// const { data: influencerInfo } = useFetchAllInfluencerCP();
	const pricings = useSelector(selectInfluencerPricing);

	const isUpdatePrice = useSelector(selectIsPriceUpdated);

	const { darkModeStatus } = useDarkMode();
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			influencer_id: influencer._id,
			listId: influencer.lists[0]._id || '',
			i_index_id: influencer.influencer_index_id,
			listName: influencer.lists[0].listName || '',
		},
	});
	const {
		// data: pricings,
		isLoading: isLoadingPricing,
		refetch: refetchPricing,
	} = useGetAllPricing({
		influencerId: formik.values.influencer_id,
		listId: formik.values.listId,
		iIndexId: formik.values.i_index_id,
	});

	const { mutate: removePricing, isLoading: isRemoving, data: deletedData } = useDeletePricing();
	const deletePricing = (influencerId, listId, iIndexId, pricingId, contactIndex) => {
		removePricing({
			influencer_id: influencerId,
			list_id: listId,
			i_index_id: iIndexId,
			pricing_id: pricingId,
		});
	};
	useEffect(() => {
		refetchPricing();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deletedData, isUpdatePrice]);

	const dispatch = useDispatch();
	dispatch({
		type: LIST_STORE_CONTANTS.SET_PROFILE_LIST_ID,
		payload: formik.values.listId,
	});
	const handleChange = () => {
		dispatch({
			type: LIST_STORE_CONTANTS.SET_PROFILE_LIST_ID,
			payload: formik.values.listId,
		});
	};

	return (
		// <Card>
		// 	{/* <ContactDetails
		// 		influencer={influencer}
		// 		isAddContact={isAddContact}
		// 		setIsAddContact={setIsAddContact}
		// 	/> */}
		// 	<CardHeader>
		// 		<CardLabel icon='Phone' iconColor='danger'>
		// 			<CardTitle>
		// 				<CardLabel>My Pricing</CardLabel>
		// 			</CardTitle>
		// 		</CardLabel>
		// 		<CardActions>
		// 			<div className='row'>
		// 				<div className='col'>
		// 					<Dropdown direction='down'>
		// 						<DropdownToggle>
		// 							<Button color='danger' isLight>
		// 								Select List
		// 							</Button>
		// 						</DropdownToggle>
		// 						<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
		// 							<div className='container py-2'>
		// 								<form>
		// 									<div className='col-12 dv-list-check-group'>
		// 										<FormGroup label='Lists'>
		// 											{influencer &&
		// 											influencer.lists &&
		// 											influencer.lists.length ? (
		// 												<ChecksGroup>
		// 													{influencer.lists.map((list, index) => (
		// 														<Checks
		// 															// eslint-disable-next-line react/no-array-index-key
		// 															key={`list-check-${index}`}
		// 															id={list.listName}
		// 															label={list.listName}
		// 															value={list._id}
		// 															onChange={(e) => {
		// 																formik.setFieldValue(
		// 																	'listId',
		// 																	e.target.value,
		// 																);
		// 															}}
		// 															checked={
		// 																list._id ===
		// 																formik.values.listId
		// 															}
		// 														/>
		// 													))}
		// 												</ChecksGroup>
		// 											) : (
		// 												<NoListState height={100} width={100} />
		// 											)}
		// 										</FormGroup>
		// 									</div>
		// 								</form>
		// 							</div>
		// 						</DropdownMenu>
		// 					</Dropdown>
		// 				</div>
		// 			</div>
		// 		</CardActions>
		// 	</CardHeader>
		// 	<CardBody isScrollable className="overflow-auto" style={{height:"20rem"}}>
		// 		<div className='table-responsive'>
		// 			<table className='table table-modern mb-0'>
		// 				<thead>
		// 					<tr>
		// 						<th>Upper Price</th>
		// 						<th>Lower Price</th>
		// 						<th>Content Type</th>
		// 						<th>Currency</th>
		// 						{/* <th>Contact Type</th>
		// 						<th>Location</th> */}
		// 					</tr>
		// 				</thead>
		// 				<tbody>
		// 					{!isLoadingPricing&&
		// 						pricings && pricings.length !==0 && pricings.map((data)=>{
		// 							return(
		// 								data.pricing && data.pricing.length !==0 && data.pricing.map((pricing)=>(
		// 									<tr>
		// 										<td>{pricing.price.upper}</td>
		// 										<td>{pricing.price.lower}</td>
		// 										<td>{pricing.content_type}</td>
		// 										<td>{pricing.currency}</td>

		// 									</tr>
		// 								))
		// 							)
		// 						})
		// 					}
		// 				</tbody>
		// 			</table>
		// 		</div>
		// 	</CardBody>
		// </Card>
		<Page container='fluid'>
			<SubHeader>
				<SubHeaderLeft>
					<span className='h4'>Pricing</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<CommonFilterTag
						title='Source'
						text={<Badge className='fs-6'>{formik.values.listName}</Badge>}
					/>
					<div className='row'>
						<div className='col'>
							<Dropdown direction='down'>
								<DropdownToggle>
									<Button color='danger' isLight>
										Select
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<div className='container py-2'>
										<form>
											<div className='col-12 dv-list-check-group'>
												<FormGroup label='Lists'>
													{influencer &&
													influencer.lists &&
													influencer.lists.length !== 0 ? (
														<ChecksGroup>
															{influencer.lists.map((list, index) => (
																<Checks
																	// eslint-disable-next-line react/no-array-index-key
																	key={`list-check-${index}`}
																	id={list.listName}
																	label={list.listName}
																	value={list._id}
																	onChange={(e) => {
																		formik.setFieldValue(
																			'listId',
																			e.target.value,
																		);
																		formik.setFieldValue(
																			'listName',
																			e.target.id,
																		);
																		// handleChange();
																	}}
																	checked={
																		list._id ===
																		formik.values.listId
																	}
																/>
															))}
														</ChecksGroup>
													) : (
														<NoListState height={100} width={100} />
													)}
												</FormGroup>
											</div>
										</form>
									</div>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</SubHeaderRight>
			</SubHeader>
			<br />
			<div className='row g-3 h-100'>
				{!isLoadingPricing &&
					pricings &&
					pricings.length !== 0 &&
					pricings.map(
						(data) =>
							data.pricing &&
							!data.isDeleted &&
							data.pricing.length !== 0 &&
							data.pricing.map((pricing) => (
								<div className='col-md-6'>
									<Card stretch>
										<CardHeader>
											<CardLabel icon='StackedLineChart'>
												<CardTitle>{pricing.content_type}</CardTitle>
											</CardLabel>
											<CardActions>
												Source : <strong>{data.list.listName}</strong>.
											</CardActions>
										</CardHeader>
										<div style={{ height: '28rem' }}>
											<CardBody className='d-flex align-items-center justify-content-center'>
												<div className='row g-4 align-items-center'>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-warning rounded-2 p-3`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Money'
																	size='3x'
																	color='warning'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{nFormatter(
																		pricing.price.upper,
																		1,
																	)}
																</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	Max. Price
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-warning rounded-2 p-3`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Money'
																	size='3x'
																	color='warning'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{nFormatter(
																		pricing.price.lower,
																		1,
																	)}
																</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	Min. Price
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-primary rounded-2 p-3`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='AttachMoney'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{pricing.currency}
																</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	Currency
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<Button
															onClick={() => {
																ConfirmAlert(
																	`Pricing ${pricing.content_type}`,
																	`Do you really want to remove Pricing for ${pricing.content_type}`,
																	() =>
																		deletePricing(
																			influencer._id,
																			data.list._id,
																			influencer.influencer_index_id,
																			data._id,
																		),
																);
															}}
															color='danger'
															isLight
															className='w-100'>
															Delete
														</Button>
													</div>
												</div>
											</CardBody>
										</div>
									</Card>
								</div>
							)),
					)}
				<div className='col-md-6' style={{ height: '30rem' }}>
					<Card stretch>
						<CardBody className='d-flex align-items-center justify-content-center'>
							<Button
								color='info'
								size='lg'
								isLight
								className='w-100 h-100'
								onClick={() => {
									setAddPricing(true);
								}}
								icon='AddCircle'>
								Create Pricing
							</Button>
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	);
};

export default MyPricingCard;
