import React from 'react';
import { useUI } from '../../../states/contexts/ui.context';
import Modal, { ModalHeader, ModalTitle } from '../../bootstrap/Modal';

const CustomModal = ({ children, open, title = 'Modal', isCentered=false, fullscreen, size = 'md' }) => {
	const { closeModal } = useUI();

	return (
		<Modal
			isOpen={open}
			setIsOpen={closeModal}
			isStaticBackdrop
			isAnimation={false}
			isCentered={isCentered}
			size={size}>
			<ModalHeader setIsOpen={open && closeModal}>
				<ModalTitle>{title}</ModalTitle>
			</ModalHeader>
			{children}
		</Modal>
	);
};

export default CustomModal;
