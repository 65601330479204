import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchWorkspace } from '../../../framework/basic-rest/workspace/get-all-workspace';
import { CONTROL_PANEL_STORE_CONSTANTS, CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS, LIST_STORE_CONTANTS, WORKSPACE_STORE_CONSTANTS } from '../../constants.store';
import { setWorkspace } from '../Auth/helper/cookies';
import { setDefaultWorkspace, setTeamSync, setWorkspaceSync, setWorkspaces } from './workspace.slice';

function* changeWorkspace(action) {
	try {
		const workspace = action.payload;

		yield put(setDefaultWorkspace(workspace));
		yield setWorkspace(workspace);

		yield put({
			type: CONTROL_PANEL_STORE_CONSTANTS.UPDATE_MY_ACCESS_LIST,
			payload: []
		})

		yield put({
			type: CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_WORKSPACE_UPDATE,
			payload: `Change/Workspace/${new Date()}`
		})

		yield put({
			type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
			payload: `Change/Workspace/${new Date()}`
		})

		yield put({
			type: WORKSPACE_STORE_CONSTANTS.WORKSPACE_SYNC,
			payload: `workspace/set/${new Date()}`
		})

		yield put({ type: DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_FILTERS })
		yield put({ type: DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_RESULTS })

		return true;
	} catch (error) {
		return false;
	}
}

function* clearWorkspace(action){
	try {
		const data = action.payload;
		yield put(setDefaultWorkspace(null));
		yield put(setWorkspaces([]))
		yield put({
			type: WORKSPACE_STORE_CONSTANTS.WORKSPACE_SYNC,
			payload: data
		})
		return true;
	} catch (error) {
		return false;
	}
}

function* updateWorkspaceSync(action){
	try {
		const data = action.payload;
		yield put(setWorkspaceSync(data));
		return true;
	} catch (error) {
		return false;
	}
}

function* syncWorkspace(action) {
	try {
		const workspace = action.payload;

		const workspaces = yield call(fetchWorkspace);
		yield put(setWorkspaces(workspaces.data));
		yield put({
			type: CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS,
			payload: `Workspace/fetch/${new Date()}`
		})

		return true;
	} catch (error) {
		return false;
	}
}

function* syncTeamMember(action) {
	try {
		const syncData = action.payload;

		yield put(setTeamSync(syncData));

		return true;
	} catch (error) {
		return false;
	}
}

export default function* workspaceSaga() {
	yield takeLatest(WORKSPACE_STORE_CONSTANTS.SET_WORKSPACE, changeWorkspace);
	yield takeLatest(WORKSPACE_STORE_CONSTANTS.SYNC_WORKSPACE_DETAILS, syncWorkspace);
	yield takeLatest(WORKSPACE_STORE_CONSTANTS.SYNC_TEAM_MEMBER, syncTeamMember);
	yield takeLatest(WORKSPACE_STORE_CONSTANTS.WORKSPACE_SYNC, updateWorkspaceSync);
	yield takeLatest(WORKSPACE_STORE_CONSTANTS.CLEAR_WORKSPACE, clearWorkspace);
}
