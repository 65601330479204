import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	creditsDetails: 0,
	isCreditsUpdate: null,
};

const slice = createSlice({
	name: 'credits',
	initialState,
	reducers: {
		setCreditsDetails: (state, action) => {
			state.creditsDetails = action.payload;
		},
		setIsCreditsUpdate: (state, action) => {
			state.isCreditsUpdate = action.payload;
		},
	},
});

export const { setCreditsDetails, setIsCreditsUpdate } = slice.actions;

export default slice.reducer;
