import React from 'react';
import CustomSkeleton from '../../CustomSkeleton';

export const TableSkeleton = ({ trCount = 0, tdCount = 0 }) => {
	return Array(trCount)
		.fill('0')
		.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<tr key={`user-card-tr-${index}`}>
				{Array(tdCount)
					.fill('0')
					.map((x, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<td key={`user-card-td-${i}`}>
							<CustomSkeleton count={1} style={{ width: '100%' }} />
						</td>
					))}
			</tr>
		));
};
export const ContentStreamSkeleton = ({ count = 4, height = '30rem' }) => {
	return Array(count)
		.fill('0')
		.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div className='col-md-3' key={`skeleton-${index}`}>
				<CustomSkeleton count={1} style={{ width: '100%', height }} />
			</div>
		));
};

export const OutreachSkeleton = ({ count = 3, height = '2rem' }) => {
	return Array(count)
		.fill('0')
		.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div className='mail' key={`skeleton-${index}`}>
				<div className='mail-content'>
					<div className='mail-header'>
						<p className='contact-name'>
							<CustomSkeleton count={1} style={{ width: '100%', height }} />
						</p>
						<p className='mail-time'>
							<CustomSkeleton count={1} style={{ width: '100%', height }} />
						</p>
					</div>
					<p className='mail-text'>
						<CustomSkeleton count={1} style={{ width: '100%', height }} />
					</p>
				</div>
			</div>
		));
};
export const OutreachDetailsSkeleton = ({ count = 3, height = '2rem' }) => {
	return Array(count)
		.fill('0')
		.map((item, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<div className='content-wrapper' key={`skeleton-${index}`}>
				<div className='profile-pic'>
					<CustomSkeleton count={1} isCircle style={{ height: '4rem', width: '4rem' }} />{' '}
				</div>
				<div className='mail-content-wrapper'>
					<div className='contact-info'>
						<p className='contact-name w-100'>
							<CustomSkeleton count={1} style={{ width: '50%', height }} />
						</p>
						<div className='right w-100'>
							<p className='mail-time w-100'>
								<CustomSkeleton count={1} style={{ width: '100%', height }} />
							</p>
						</div>
					</div>
					<p className='mt-3'>
						<CustomSkeleton count={4} style={{ width: '100%', height }} />
					</p>
				</div>
			</div>
		));
};
