import React, { useState } from 'react';
import useDarkMode from '../../../../hooks/useDarkMode';
import InfluencerUser from './report_Part_Components/InfluencerUser';
import StatHistoryData from './StatHistoryData';
import ProfileInfluencer from './ProfileInfluencer';
import InfluencerPost from './InfluencerPost';
import AudienceData from './AudienceData';
import AudienceLikersData from './AudienceLikersData';
import AddToList from '../AddToList';
import { checkArrayData } from '../../../../helpers/helpers';
import AddToFavourite from '../AddToFavourite';
import ContactsData from './report_Part_Components/ContactsData';

const InfluencerProfile = ({ report, isDownloading }) => {
	const { darkModeStatus } = useDarkMode();

	const checkData = (value) => {
		if (value && value.length !== 0) {
			return true;
		}
		return false;
	};
	return (
		<div>
			<div className='px-3'>
				<ProfileInfluencer darkModeStatus={darkModeStatus} report={report} />
			</div>
			<hr />
			{/* {!isDownloading && <div className='d-flex justify-content-between'>
                <AddToList
                    influencerId={report.influencer_id}
                    existingList={report.lists}
                />
                <AddToFavourite
                    _id={report.influencer_id}
                />
            </div>} */}
			{/* <hr /> */}
			<div className='overflow-auto px-1'>
				{report?.audience && (
					<AudienceData
						isDownloading={isDownloading}
						report={report}
						darkModeStatus={darkModeStatus}
					/>
				)}

				{report?.audienceLikers && (
					<AudienceLikersData
						isDownloading={isDownloading}
						report={report}
						darkModeStatus={darkModeStatus}
					/>
				)}

				{/* Stat-History of Influencer */}
				{report?.statHistory && <StatHistoryData data={report.statHistory} />}

				{/* Influencer Profile */}
				<InfluencerPost report={report} />

				<div className='row px-5 scroll-margin'>
					<div className='row'>
						{/* Notable Followers */}
						{checkArrayData(report?.audience?.notableUsers) && (
							<InfluencerUser
								key='Notable Followers'
								isDownloading={isDownloading}
								data={report.audience.notableUsers}
								title='Notable Followers'
							/>
						)}

						{/* Notable Likers */}
						{checkArrayData(report?.audienceLikers?.notableUsers) && (
							<InfluencerUser
								key='Notable Likers'
								isDownloading={isDownloading}
								data={report.audienceLikers.notableUsers}
								title='Notable Likers'
							/>
						)}

						{/* audienceLookalikes */}
						{checkArrayData(report?.audience?.audienceLookalikes) && (
							<InfluencerUser
								key='Audience Lookalikes'
								isDownloading={isDownloading}
								data={report.audience.audienceLookalikes}
								title='Audience LookAlikes'
							/>
						)}

						{/* lookalikes */}
						{checkArrayData(report?.lookalikes) && (
							<InfluencerUser
								key='Lookalikes'
								isDownloading={isDownloading}
								data={report.lookalikes}
								title='LookAlikes'
							/>
						)}
						{checkArrayData(report?.contacts) && <ContactsData data={report.contacts} />}

					</div>
				</div>
			</div>
		</div>
	);
};

export default InfluencerProfile;
