import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

const fetchCities = async (input) => {
	const { data } = await http.post(`${API_ENDPOINTS.GET_CITIES}`, input);
	return data?.data;
};
const useFetchCities = (options) => {
	return useQuery([API_ENDPOINTS.GET_CITIES, options], fetchCities);
};
export { useFetchCities };

export default fetchCities;
