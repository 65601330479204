import React, { useState } from 'react';
import { getColorNameWithIndex } from '../../../../common/data/enumColors';
import Alert from '../../../../components/bootstrap/Alert';
import Button from '../../../../components/bootstrap/Button';
import { CardBody } from '../../../../components/bootstrap/Card';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../components/PaginationButtons';
import useTaskRemoveAssociationMutation from '../../../../framework/basic-rest/crm/tasks/remove-task-association';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import { getFirstLetter } from '../../../../helpers/helpers';
import useDarkMode from '../../../../hooks/useDarkMode';

const Companies = ({ associatedCompany, emailId, removeTaskAssociation }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['3']);

	return (
		<div>
			{associatedCompany && associatedCompany.length === 0 ? (
				<div className='row g-4'>
					<div className='col-md-12'>
						<Alert color='warning' isLight icon='Report'>
							No Associated Partners Found.
						</Alert>
					</div>
				</div>
			) : (
				<CardBody className='table-response overflow-auto'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>PARTNER NAME</th>
								<th className='text-center'>INDUSTRY</th>
								<th className='text-center'>EMAIL</th>
								<th className='text-center'>PHONE</th>
								<th className='text-center'>CREATED BY</th>
								<th className='text-center'>STATE</th>
								<th className='text-center'>CITY</th>
								<th className='text-center'>WEBSITE</th>
								<th className='text-center'>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{associatedCompany &&
								dataPagination(associatedCompany, currentPage, perPage).map(
									(company, i) =>
										!company?.isDeleted && (
											<tr key={company._id} className='tableRow'>
												<td>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1 me-3'
																style={{ width: 48 }}>
																<div
																	className={`bg-l${
																		darkModeStatus
																			? 'o25'
																			: '25'
																	}-${getColorNameWithIndex(
																		i,
																	)} text-${getColorNameWithIndex(
																		i,
																	)} rounded-2 d-flex align-items-center justify-content-center`}>
																	<span className='fw-bold fs-3'>
																		{getFirstLetter(
																			company?.name,
																		)}
																	</span>
																</div>
															</div>
														</div>
														<div className='flex-grow-1'>
															<span>{`${company?.name}`}</span>
														</div>
													</div>
												</td>

												<td className='text-center'>{company?.industry}</td>
												<td className='text-center'>
													<Button
														isLink
														color='light'
														icon='Email'
														className='text-lowercase'
														tag='a'
														href={`mailto:${company?.email}`}>
														{company?.email}
													</Button>
												</td>
												<td className='text-center'>{company?.phone}</td>
												<td className='text-center'>
													{`${company?.createdBy?.firstName} ${company?.createdBy?.lastName}`}
												</td>
												<td className='text-center'>{company?.state}</td>
												<td className='text-center'>{company?.city}</td>
												<td className='text-center'>
													<a
														style={{
															textDecoration: 'none',
															color: 'blue',
														}}
														href={
															`https://${company?.website}` ||
															`http://${company?.website}`
														}
														target='_blank'
														rel='noreferrer'>
														{company?.website}
													</a>
												</td>
												<td className='text-center'>
													<Button
														isLink
														color='info'
														onClick={() => {
															ConfirmAlert(
																`company - ${company?.name}`,
																`Do you really want to remove this company?`,
																() =>
																	removeTaskAssociation({
																		toId: company?._id,
																		emailId,
																		type: 'company',
																	}),
															);
														}}>
														Remove
													</Button>
												</td>
											</tr>
										),
								)}
						</tbody>
					</table>
					{associatedCompany && associatedCompany.length > 3 && (
						<PaginationButtons
							data={associatedCompany}
							label='companies'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					)}
				</CardBody>
			)}
		</div>
	);
};

export default Companies;
