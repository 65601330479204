import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { useUI } from '../../../states/contexts/ui.context';
import recordEvent from '../mixpanel/mixpanel';

async function removeInfluencerFromProject({ influencerId, projectId }) {
	const { data } = await http.delete(
		`${API_ENDPOINTS.REMOVE_INFLUENCERS_FROM_PROJECT}?projectId=${projectId}&influencerId=${influencerId}`,
	);
	return data;
}

const useRemoveInfluencerFromProject = () => {
	const { addToast } = useToasts();
	const cache = useQueryClient();

	return useMutation((input) => removeInfluencerFromProject(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				cache.invalidateQueries(API_ENDPOINTS.GET_PROJECT_INFLUENCERS);
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
				recordEvent(`Influencer removed from Project(Search)`, {
					input,
					output:res
				});
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				recordEvent(res.data.data.errorMessage, {
					input,
					output:res.data.data
				});
			}
		},
		onError: (err,input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to remove Influencer from Project(Search)`, {
				input,
				output:err?.response?.data
			});
		},
	});
};
export default useRemoveInfluencerFromProject;
