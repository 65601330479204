import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import { ModalBody, ModalFooter } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import { whatsAppAccountSchema } from '../../validations/whatsApp/whatsApp';
import useUpdateUserWhatsApDetailMutation from '../../framework/basic-rest/whatsApp/updateUserWhatsAppAccout';

const WhatsAppAccountModal = ({ setShowModal }) => {
	const { mutate: sendUpdate, isSuccess } = useUpdateUserWhatsApDetailMutation();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			phoneNumberId: '',
			accessToken: '',
		},
		validationSchema: whatsAppAccountSchema,
		onSubmit: (values) => {
			sendUpdate(values);
		},
	});

	useEffect(() => {
		if (isSuccess) {
			formik.resetForm();
			setShowModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	return (
		<ModalBody>
			<form onSubmit={formik.handleSubmit}>
				<div className='row g-4'>
					<div className='col-12'>
						<FormGroup id='phoneNumberId' label='Phone number Id'>
							<Input
								placeholder='Phone number Id'
								onChange={formik.handleChange}
								value={formik.values.phoneNumberId}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='accessToken' label='Access Token'>
							<Input
								placeholder='Access Token'
								onChange={formik.handleChange}
								value={formik.values.accessToken}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='mt-4'>
					<ModalFooter className='bg-transparent'>
						<Button
							type='submit'
							color='info'
							isDisable={!formik.isValid}
							className='btn-auth'>
							Save
						</Button>
						<Button
							onClick={() => setShowModal(false)}
							className='btn btn-outline-danger ms-2'>
							close
						</Button>
					</ModalFooter>
				</div>
			</form>
		</ModalBody>
	);
};

export default WhatsAppAccountModal;
