import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './crm.creators.slice';

const crmCreatorSlice = (state) => state.creators || initialState;

export const CRMCreator = createSelector([crmCreatorSlice], (state) => state.crmCreator);
export const selectCrmCreatorId = createSelector([crmCreatorSlice], (state) => state.crmCreatorId);
export const selectCrmCreatorById = createSelector(
	[crmCreatorSlice],
	(state) => state.crmCreatorById,
);
export const selectIsUpdateCrmCreator = createSelector(
	[crmCreatorSlice],
	(state) => state.isUpdateCrmCreator,
);
export const selectGetCreator = createSelector([crmCreatorSlice], (state) => state.getCreator);
