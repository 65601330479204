import React, { useRef, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import Button from '../../../../bootstrap/Button';
import Popovers from '../../../../bootstrap/Popovers';

const ListExport = ({ listName, influencers, headers, dataFunction }) => {
	const csvLink = useRef();

	const [exportData, setExportData] = useState([]);

	const exportCSV = async () => {
		const data = await dataFunction(influencers);
		setExportData(data);
	};

	useEffect(() => {
		if (exportData.length) {
			csvLink.current.link.click();
		}
	}, [exportData]);

	return (
		<>
			<Popovers
				placement='left-end'
				className='mw-100 px-10 overflow-hidden'
				data-tour='date-range-menu'
				bodyClassName='p-4'
				trigger='hover'
				desc={`Download list ${listName || 'MyLists.csv'}`}>
				<Button
					style={{ width: '100%' }}
					icon='CloudDownload'
					isLight
					color='info'
					onClick={exportCSV}>
					Export
				</Button>
			</Popovers>
			<CSVLink
				headers={headers}
				data={exportData}
				ref={csvLink}
				filename={listName ? `${listName}` : 'MyLists.csv'}
			/>
		</>
	);
};

export default ListExport;
