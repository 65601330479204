import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../bootstrap/Spinner';
import Tooltips from '../../bootstrap/Tooltips';
import Icon from '../../icon/Icon';

const Chips = ({
	className,
	label,
	value,
	onRemove,
	isRemoving,
	loaderTemplate,
	isDismissible,
	color,
	shadow,
	rounded,
	isLight,
	...props
}) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<span
			className={classNames(
				'custom-badge',
				{
					[`bg-${color}`]: !isLight,
					[`bg-l${darkModeStatus ? 'o25' : '10'}-${color}`]: isLight,
					[`text-${color}`]: isLight,
					[`shadow${shadow !== 'default' ? `-${shadow}` : ''}`]: !!shadow,
					[`rounded${rounded !== 'default' ? `-${rounded}` : ''}`]: rounded,
					'rounded-0':
						rounded === 'bottom' ||
						rounded === 'top' ||
						rounded === 'end' ||
						rounded === 'start' ||
						rounded === 0 ||
						rounded === '0',
				},
				className,
			)}
			{...props}>
			{label}
			{isDismissible && (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
				<span aria-label='Close' className='btnclose'>
					{!isRemoving ? (
						<Tooltips title={`Remove from ${label}`} placement='top'>
							<Icon
								icon='XCircleFill'
								size='lg'
								color='light'
								onClick={() => onRemove(value)}
							/>
						</Tooltips>
					) : (
						loaderTemplate
					)}
				</span>
			)}
		</span>
	);
};

Chips.propTypes = {
	children: PropTypes.node.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	className: PropTypes.string,
	isDismissible: PropTypes.bool,
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
	rounded: PropTypes.oneOf([
		'default',
		0,
		1,
		2,
		3,
		'bottom',
		'top',
		'circle',
		'end',
		'start',
		'pill',
	]),
	shadow: PropTypes.oneOf([null, 'none', 'sm', 'default', 'lg']),
	isLight: PropTypes.bool,
};

Chips.defaultProps = {
	className: null,
	isDismissible: true,
	color: 'primary',
	rounded: null,
	shadow: null,
	isLight: false,
};

export default Chips;
