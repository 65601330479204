/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import useDebounce from '../hooks/useDebounce';
import { useInfluencersSuggestionQuery } from '../framework/basic-rest/discovery/get-suggestion-user';
import Button from './bootstrap/Button';
import Badge from './bootstrap/Badge';
import { DISCOVERY_STORE_CONSTANTS } from '../store/constants.store';
import { checkArrayData } from '../helpers/helpers';
import recordEvent from '../framework/basic-rest/mixpanel/mixpanel';
import MultiSelect from './custom/Common/QuickSearchFilter/components/MultiSelect';

const Search = ({ filtersForm, isGettingInfluencers, isGettingMoreInfluencers }) => {
	const filterBy = () => true;

	const [searchLoader, setSearchLoader] = useState(false);

	const dispatch = useDispatch();

	// const [influencerQuery, setInfluencerQuery] = useState('');

	// const debouncedInfluencerSearch = useDebounce(
	// 	{ text: influencerQuery, platform: filtersForm.values.platform },
	// 	800,
	// );
	// const {
	// 	data: influencers,
	// 	isLoading,
	// 	refetch,
	// } = useInfluencersSuggestionQuery(debouncedInfluencerSearch);

	const onChangeHandle = (fieldName, value) => {
		filtersForm.setFieldValue(fieldName, value);

		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_FILTERS,
			payload: {
				fieldName,
				value,
			},
		});
	};

	useEffect(() => {
		if (!(isGettingInfluencers || isGettingMoreInfluencers)) {
			setSearchLoader(false);
		}
	}, [isGettingInfluencers, isGettingMoreInfluencers]);

	const searchbtnClick = () => {
		recordEvent('Search Bar Button clicked(Search)', {
			platform: filtersForm.values.platform,
			searchedFor: filtersForm.values.relevance,
		});
	};

	return (
		<div className='d-flex w-75'>
			{/* <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
				<Icon icon='Search' size='2x' color='primary' />
			</label> */}
			<MultiSelect
				key='async-user-search'
				id='async-user-search'
				multiple
				labelkey={(option) => `${option?.fullname ? option?.fullname : option?.username}`}
				className='border-0 shadow-none bg-transparent'
				style={{ width: '85%' }}
				valueKey='userId'
				minLength={3}
				onChange={(e) => {
					onChangeHandle(
						'relevance',
						e.map((x) => `@${x.username}`),
					);
					onChangeHandle(
						'identifier',
						e.map((x) => x.userId),
					);
				}}
				// defaultSelected={filtersForm.values.influencerLocation}
				placeholder='Search influencers...'
				isQueryOption={1}
				defaultOpt={(query) => {
					return query ? 
				[
					{
						userId: query,
						username: query,
						fullname: query,
					},
				] : []}}
				platform={filtersForm.values.platform}
				usefetch={useInfluencersSuggestionQuery}
				// eslint-disable-next-line react/no-unstable-nested-components
				ChildrenComponent={(option) => (
					<div>
						{option?.picture && (
							<img
								alt={option.username}
								src={option.picture}
								style={{
									height: '24px',
									marginRight: '10px',
									width: '24px',
								}}
							/>
						)}
						<span>{option?.username ? option?.username : option?.fullname}</span>
					</div>
				)}
			/>
			<Button
				color='info'
				isLight
				className='ms-3'
				isDisable={isGettingMoreInfluencers || isGettingInfluencers}
				onClick={() => {
					onChangeHandle('Search', true);
					setSearchLoader(true);
					searchbtnClick();
					filtersForm.handleSubmit();
				}}>
				{(isGettingMoreInfluencers || isGettingInfluencers) && searchLoader ? (
					'Wait...'
				) : (
					<>
						Search : <Badge>0.5</Badge>
					</>
				)}
			</Button>
		</div>
	);
};

export default Search;
