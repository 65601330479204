import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import fetchCities from '../../../framework/basic-rest/analytics/otanalytics/get-all-cities';
import fetchDevicePlatform from '../../../framework/basic-rest/analytics/otanalytics/get-events-bt-device-platform';
import fetchCityOrCountry from '../../../framework/basic-rest/analytics/otanalytics/get-events-by-cityorCountry';
import fetchGetEventDetails from '../../../framework/basic-rest/analytics/otanalytics/get-getEventDetails';
import fetchGetAllEventDetails from '../../../framework/basic-rest/analytics/otanalytics/get-otanalytics-events';
import fetchSegmentationForProject from '../../../framework/basic-rest/analytics/otanalytics/get-segmentation-for-project';
import fetchSegmentationCount from '../../../framework/basic-rest/analytics/otanalytics/segment-query';
import { CONVERSION_ANALYTICS_STORE_CONSTANTS } from '../../constants.store';
import {
	setGetAllEventDetails,
	setGetCityList,
	setGetEventDetails,
	setGetEventDetailsByCity,
	setGetEventDetailsByCountry,
	setGetEventDetailsByDevicePlatform,
	setGetSegmentationCount,
	setGetSegmentationForProject,
} from './conversionAnalytics.slice';

function* GetAllEvents(actions) {
	const {
		projectId,
		startDate,
		endDate,
		countlyAppId,
		eventName,
		type,
		byCountry,
		analyticsType,
		affiliateId,
	} = actions.payload;

	const getEventsByCity =
		analyticsType === 'PROJECT'
			? { projectId, startDate, endDate, countlyAppId, eventName, type }
			: {
					projectId,
					startDate,
					endDate,
					countlyAppId,
					eventName,
					type,
					affiliateId,
			  };

	const getEventsByCountry =
		analyticsType === 'PROJECT'
			? { projectId, startDate, endDate, countlyAppId, eventName, type, byCountry }
			: {
					projectId,
					startDate,
					endDate,
					countlyAppId,
					eventName,
					type,
					byCountry,
					affiliateId,
			  };

	const getEventsByDevice =
		analyticsType === 'PROJECT'
			? { projectId, startDate, endDate, countlyAppId, eventName, type }
			: { projectId, startDate, endDate, countlyAppId, eventName, type, affiliateId };

	const GetAllEevents =
		analyticsType === 'PROJECT'
			? { projectId, countlyAppId }
			: { projectId, countlyAppId, affiliateId };

	try {
		const alleventsData = yield call(fetchGetAllEventDetails, GetAllEevents);
		yield put(setGetAllEventDetails(alleventsData));

		const selectedEventsData = yield call(fetchGetEventDetails, actions.payload);
		yield put(setGetEventDetails(selectedEventsData));

		const countryEvents = yield call(fetchCityOrCountry, getEventsByCountry);
		yield put(setGetEventDetailsByCountry(countryEvents));

		const citiesEvents = yield call(fetchCityOrCountry, getEventsByCity);
		yield put(setGetEventDetailsByCity(citiesEvents));

		const alleventsBasedOndeviceType = yield call(fetchDevicePlatform, getEventsByDevice);
		yield put(setGetEventDetailsByDevicePlatform(alleventsBasedOndeviceType));

		const allCities = yield call(fetchCities, { countlyAppId });
		yield put(setGetCityList(allCities));

		return true;
	} catch (error) {
		return false;
	}
}

function* GetAllEventDetails(actions) {
	try {
		const { projectId, startDate, endDate, countlyAppId, getCampaignLevelData } =
			actions.payload;
		const getAllEventsObj = {
			projectId,
			startDate,
			endDate,
			countlyAppId,
			getCampaignLevelData,
		};
		const allevents = yield call(fetchGetAllEventDetails, getAllEventsObj);
		yield put(setGetAllEventDetails(allevents));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetAllEventDetailsbasedOnEvent(actions) {
	try {
		const allevents = yield call(fetchGetEventDetails, actions.payload);
		yield put(setGetEventDetails(allevents));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetAllCity(actions) {
	try {
		const allCities = yield call(fetchCities, actions.payload);
		yield put(setGetCityList(allCities));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetAllEventDetailsbasedCountry(actions) {
	try {
		const allEvent = yield call(fetchCityOrCountry, actions.payload);
		yield put(setGetEventDetailsByCountry(allEvent));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetAllEventDetailsbasedCity(actions) {
	try {
		const allEvent = yield call(fetchCityOrCountry, actions.payload);
		yield put(setGetEventDetailsByCity(allEvent));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetAllEventDetailsbasedDevicePlatform(actions) {
	try {
		const allevents = yield call(fetchDevicePlatform, actions.payload);
		yield put(setGetEventDetailsByDevicePlatform(allevents));
		return true;
	} catch (error) {
		return false;
	}
}

function* GetSegmentationCount(actions) {
	try {
		const allevents = yield call(fetchSegmentationCount, actions.payload);
		yield put(setGetSegmentationCount(allevents.resultMetaData));
		return true;
	} catch (error) {
		return false;
	}
}
function* GetSegmentationDataForProject(actions) {
	try {
		const allevents = yield call(fetchSegmentationForProject, actions.payload);
		yield put(setGetSegmentationForProject(allevents));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* conversionAnalyticsSaga() {
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_DETAILS,
		GetAllEventDetails,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_BASED_ON_EVENT_SELECTED,
		GetAllEventDetailsbasedOnEvent,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_CITY_LIST,
		GetAllCity,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_BY_COUNTRY,
		GetAllEventDetailsbasedCountry,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_BY_CITY,
		GetAllEventDetailsbasedCity,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_CONVERSION_ANALYTICS_BY_CITY_DEVICE_PLATFORM,
		GetAllEventDetailsbasedDevicePlatform,
	);
	yield takeLatest(CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_ALL_THE_EVENTS, GetAllEvents);

	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_SEGMENTATION_COUNT,
		GetSegmentationCount,
	);
	yield takeLatest(
		CONVERSION_ANALYTICS_STORE_CONSTANTS.GET_SEGMENTATION_FOR_PROJECT,
		GetSegmentationDataForProject,
	);
}
