import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ANALYTICS_STORE_CONSTANTS, OT_ANALYTICS_STORE_CONSTANTS } from '../../constants.store';
import {
	setSocialTrackingTypes,
	setSocialTrackingStatus,
	setSocialTrackingPlatform,
	setCrmProjectId,
	setInstagramTrackingPostTypes,
	setYoutubeTrackingPostTypes,
	setTiktokTrackingPostTypes,
	setSocialTrackingPostTypes,
	setInfluencerViewStatus,
	setAnalyticsViewStatus,
	setAnalyticsView,
	setAnalyticsViewType,
	setAppkeys,
	setRefreshCommerceProject,
} from './analytics.slice';

import { fetchAnalyticsProjectMetaData } from '../../../framework/basic-rest/analytics/get-analytics-project-metaData';
import {
	fetchInfluencerContent,
	fetchInfluencerStats,
} from '../../../framework/basic-rest/analytics/get-influencer-analytics';
import { selectCurrentProjectId } from './Project/project.selector';
import {
	fetchProjectContent,
	fetchProjectStats,
} from '../../../framework/basic-rest/analytics/get-project-analytics';
import { OVERVIEW_TYPES, SocialAnalyticsType } from '../../../framework/enums/Tracking';
import { setProjectContentStreams, setProjectStats } from './Project/project.slice';
import { selectTrackingIds } from './Tracking/tracking.selector';
import {
	selectAnalyticsView,
	selectAnalyticsViewType,
	selectInstagramPostTypes,
	selectSetRefreshCommerceProject,
	selectTiktokPostTypes,
	selectYoutubePostTypes,
} from './analytics.selector';
import { fetchAppKeys } from '../../../framework/basic-rest/analytics/otanalytics/get-all-keys';

function* getAnalyticsProjectMetaData(action) {
	try {
		const projectMetaData = yield call(fetchAnalyticsProjectMetaData);
		const trackingTypes = projectMetaData.data.find((x) => x.label === 'SOCIAL_TRACKING_TYPES');
		const trackingStatus = projectMetaData.data.find(
			(x) => x.label === 'SOCIAL_TRACKING_STATUS',
		);
		const trackingPlatform = projectMetaData.data.find(
			(x) => x.label === 'SOCIAL_TRACKING_PLATFORM',
		);
		const instagramPostTypes = projectMetaData.data.find(
			(x) => x.label === 'INSTAGRAM_TRACKING_POST_TYPES',
		);
		const youtubePostTypes = projectMetaData.data.find(
			(x) => x.label === 'YOUTUBE_TRACKING_POST_TYPES',
		);
		const tiktokPostTypes = projectMetaData.data.find(
			(x) => x.label === 'TIKTOK_TRACKING_POST_TYPES',
		);
		yield put(setSocialTrackingTypes(trackingTypes?.data));
		yield put(setSocialTrackingStatus(trackingStatus?.data));
		yield put(setSocialTrackingPlatform(trackingPlatform?.data));
		yield put(setInstagramTrackingPostTypes(instagramPostTypes?.data));
		yield put(setYoutubeTrackingPostTypes(youtubePostTypes?.data));
		yield put(setTiktokTrackingPostTypes(tiktokPostTypes?.data));

		return true;
	} catch (error) {
		return false;
	}
}

function* getPostTypes(action) {
	try {
		const { platform } = action.payload;
		switch (platform) {
			case 'INSTAGRAM':
				const insta = yield select(selectInstagramPostTypes);
				yield put(setSocialTrackingPostTypes(insta));
				break;
			case 'YOUTUBE':
				const youtube = yield select(selectYoutubePostTypes);
				yield put(setSocialTrackingPostTypes(youtube));
				break;
			case 'TIKTOK':
				const tiktok = yield select(selectTiktokPostTypes);
				yield put(setSocialTrackingPostTypes(tiktok));
				break;
			default:
				break;
		}
		return true;
	} catch (err) {
		return false;
	}
}

function* getAnalytics(action) {
	const { overview } = action.payload;
	switch (overview) {
		case OVERVIEW_TYPES.CONTENT:
			yield call(onShowContent);
			break;
		case OVERVIEW_TYPES.GRAPHS:
			yield call(onShowAnalytics);
			break;
		default:
			break;
	}
	return true;
}

function* onShowContent() {
	try {
		const type = yield select(selectAnalyticsViewType);
		yield put(setProjectContentStreams(null));
		const projectId = yield select(selectCurrentProjectId);
		if (type === SocialAnalyticsType.PROJECT) {
			const { data } = yield call(fetchProjectContent, { projectId });
			yield put({
				type: ANALYTICS_STORE_CONSTANTS.SET_PROJECT_CONTENT,
				payload: data,
			});
		} else if (type === SocialAnalyticsType.INFLUENCER) {
			const trackingIds = yield select(selectTrackingIds);
			const { data } = yield call(fetchInfluencerContent, {
				projectId,
				trackingIds,
			});
			yield put({
				type: ANALYTICS_STORE_CONSTANTS.SET_PROJECT_CONTENT,
				payload: data,
			});
		}
		return true;
	} catch (error) {
		return false;
	}
}

function* onShowAnalytics() {
	try {
		const type = yield select(selectAnalyticsViewType);
		yield put(setProjectStats(null));
		const projectId = yield select(selectCurrentProjectId);
		if (type === SocialAnalyticsType.PROJECT) {
			const { data } = yield call(fetchProjectStats, { projectId });
			yield put({
				type: ANALYTICS_STORE_CONSTANTS.SET_PROJECT_ANALYTICS,
				payload: data,
			});
		} else if (type === SocialAnalyticsType.INFLUENCER) {
			const trackingIds = yield select(selectTrackingIds);
			const { data } = yield call(fetchInfluencerStats, {
				projectId,
				trackingIds,
			});
			yield put({
				type: ANALYTICS_STORE_CONSTANTS.SET_PROJECT_ANALYTICS,
				payload: data,
			});
		}
		return true;
	} catch (error) {
		return false;
	}
}

function* handleCrmProjectSelect(action) {
	try {
		const projectId = action.payload;
		yield put(setCrmProjectId(projectId));
		return true;
	} catch (e) {
		return false;
	}
}

function* getAppkeys(action) {
	try {
		const appKey = action.payload;
		yield put(setAppkeys(appKey));
		return true;
	} catch (e) {
		return false;
	}
}

function* handleInfluencerView(action) {
	try {
		const { status } = action.payload;
		yield put(setInfluencerViewStatus(status));
		return true;
	} catch (e) {
		return false;
	}
}

function* handleAnalyticsView(action) {
	try {
		const { status, type } = action.payload;
		yield put(setAnalyticsViewStatus(status));
		yield put(setAnalyticsViewType(type));
		const analyticsView = yield select(selectAnalyticsView);
		if (status)
			yield put({
				type: ANALYTICS_STORE_CONSTANTS.GET_ANALYTICS,
				payload: { overview: analyticsView },
			});
		return true;
	} catch (e) {
		return false;
	}
}
function* setAnalyticsViews(action) {
	try {
		const { view } = action.payload;
		yield put(setAnalyticsView(view));
		yield put({
			type: ANALYTICS_STORE_CONSTANTS.GET_ANALYTICS,
			payload: { overview: view },
		});
		return true;
	} catch (e) {
		return false;
	}
}

function* refreshCommercePage(action) {
	try {
		const toggle = yield select(selectSetRefreshCommerceProject);
		yield put(setRefreshCommerceProject(!toggle));
		return true;
	} catch (e) {
		return false;
	}
}

export default function* analyticsSaga() {
	yield takeLatest(
		ANALYTICS_STORE_CONSTANTS.GET_SOCIAL_ANALYTICS_META,
		getAnalyticsProjectMetaData,
	);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_CRM_PROJECT_ID, handleCrmProjectSelect);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_POST_TYPES, getPostTypes);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.GET_ANALYTICS, getAnalytics);

	yield takeLatest(ANALYTICS_STORE_CONSTANTS.HANDLE_INFLUENCER_VIEW, handleInfluencerView);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.HANDLE_ANALYTICS_VIEW, handleAnalyticsView);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_ANALYTICS_VIEW, setAnalyticsViews);
	yield takeLatest(OT_ANALYTICS_STORE_CONSTANTS.GET_ALL_APP_KEYS, getAppkeys);
	yield takeLatest(OT_ANALYTICS_STORE_CONSTANTS.REFRESH_COMMERCE_PAGE, refreshCommercePage);
}
