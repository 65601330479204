import { takeLatest, put, call, select, take } from 'redux-saga/effects';
// import { fetchCRMCreators } from '../../../../framework/basic-rest/crm/get-crm-creators';
import { CRM_PROJECTS_STORE_CONSTANTS } from '../../../constants.store';
import {
	setCrmProject,
	setCrmProjectById,
	setCrmProjectId,
	setGetProject,
	setIsUpdateCrmProject,
} from './crm.projects.slice';

function* setCRMProject(actions) {
	try {
		const project = actions.payload;
		yield put(setCrmProject(project));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMProjectId(actions) {
	try {
		const project = actions.payload;
		yield put(setCrmProjectId(project));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCRMProjectById(actions) {
	try {
		const project = actions.payload;
		yield put(setCrmProjectById(project));
		return true;
	} catch (error) {
		return false;
	}
}

function* setUpdateProject(actions) {
	try {
		const project = actions.payload;
		yield put(setIsUpdateCrmProject(project));
		return true;
	} catch (error) {
		return false;
	}
}

function* setProject(actions) {
	try {
		const project = actions.payload;
		yield put(setGetProject(project));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* crmProjectSaga() {
	yield takeLatest(CRM_PROJECTS_STORE_CONSTANTS.SET_CRM_PROJECT_DATA, setCRMProject);
	yield takeLatest(CRM_PROJECTS_STORE_CONSTANTS.UPDATE_CRM_PROJECT_ID, setCRMProjectId);
	yield takeLatest(CRM_PROJECTS_STORE_CONSTANTS.SET_CRM_PROJECT_BY_ID, setCRMProjectById);
	yield takeLatest(CRM_PROJECTS_STORE_CONSTANTS.IS_UPDATE_PROJECT, setUpdateProject);
	yield takeLatest(CRM_PROJECTS_STORE_CONSTANTS.SET_SELECTED_PROJECT, setProject);
}
