import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { successToast, dangerToast } from '../../../helpers/toast-helper';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';

async function createInfluencerPricing(input) {
	const res = await http.post(`${API_ENDPOINTS.CREATE_PRICING}?listId=${input.listId}`, input);
	return res.data.data;
}

const useCreateInfluencerPricing = () => {
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	return useMutation((input) => createInfluencerPricing(input), {
		onSuccess: (data) => {
			dispatch({
				type: LIST_STORE_CONTANTS.SET_IS_UPDATE_PRICE,
				payload: `add/pricing/${new Date()}`
			})
			addToast(successToast({ message: data?.message }), {
				autoDismiss: true,
			})
		},
		onError: (err) => {
			console.log(
				'🚀 ~ file: create-influencer-pricing.js ~ line 21 ~ returnuseMutation ~ err',
				err,
			);
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			})
		},
	});
};

export default useCreateInfluencerPricing;
