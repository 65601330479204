import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './crm-tasks-slice';

const crmTaskSlice = (state) => state.tasks || initialState;
export const selectIsUpdateCrmTasks = createSelector(
	[crmTaskSlice],
	(state) => state.isUpdateCrmTask,
);
export const selectCrmTasks = createSelector([crmTaskSlice], (state) => state.crmTasks);
export const selectCrmTaskById = createSelector([crmTaskSlice], (state) => state.crmTaskById);
export const selectShowCRMtaskdata = createSelector(
	[crmTaskSlice],
	(state) => state.showCRMtaskData,
);
