import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

const fetchCityOrCountry = async (input) => {
	const { data } = await http.post(`${API_ENDPOINTS.GET_EVENTS_BASED_ON_CITY_OR_COUNTRY}`, input);
	return data?.data;
};
const useFetchCityOrCountry = (options) => {
	return useQuery(
		[API_ENDPOINTS.GET_EVENTS_BASED_ON_CITY_OR_COUNTRY, options],
		fetchCityOrCountry,
	);
};
export { useFetchCityOrCountry };

export default fetchCityOrCountry;
