import React, { lazy } from 'react';
import { useUI } from '../../../states/contexts/ui.context';
import CustomModal from './modal';
import { WorkspaceModal } from '../Workspace/WorkspaceModal';
import { AnalyticsProjectModal } from '../Analytics/AnalyticsProjectModal';
import { AnalyticsInfluencerModal } from '../Analytics/AnalyticsInfluencerModal';
import ProjectImportModal from '../Analytics/ProjectImportModal';
import AssociateEmail from '../../../pages/crm/inbox/AssociateEmail';
import EmailModal from '../../modals/OutreachEmail';
import MessageModel from '../../modals/MessageModel';
import PermissionModal from '../Member_System/Components/AccessPermissions/ModalComp/PermissionModal';
import CreditHistory from '../Member_System/Components/ManageCredits/ModalComp/CreditHistory';
import ShareModal from '../Member_System/Components/ShareWorkspace/ShareModal';
import WhatsAppAccountModal from '../../modals/whatsAppAccoutModel';

const ManagedModal = () => {
	const { displayModal, modalMeta } = useUI();

	console.log('ManagedModal');

	const modalComp = {
		ACCESS_PERMISSION: <PermissionModal {...modalMeta?.props} />,
		CREATE_WORKSPACE: <WorkspaceModal {...modalMeta?.props} />,
		CREATE_ANALYTICS_PROJECT: <AnalyticsProjectModal {...modalMeta?.props} />,
		ADD_INFLUENCER: <AnalyticsInfluencerModal {...modalMeta?.props} />,
		IMPORT_CRM_PROJECT: <ProjectImportModal {...modalMeta?.props} />,
		ASSOCIATE_EMAILS: <AssociateEmail {...modalMeta?.props} />,
		EMAIL: <EmailModal {...modalMeta?.props} />,
		WHATS_APP_MESSAGE: <MessageModel {...modalMeta?.props} />,
		VIEW_CREDITS_CONSUMTION: <CreditHistory {...modalMeta?.props} />,
		SHARE_MODAL: <ShareModal {...modalMeta?.props} />,
		WHATS_APP_ACCOUNT_MODAL: <WhatsAppAccountModal {...modalMeta?.props} />,
	};

	return (
		<CustomModal
			open={displayModal}
			title={modalMeta?.title}
			size={modalMeta?.size}
			isCentered={modalMeta?.isCentered}
			fullscreen={modalMeta?.fullscreen}>
			{modalComp[modalMeta?.view]}
		</CustomModal>
	);
};

export default ManagedModal;
