import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { CRM_CREATORS_STORE_CONSTANTS } from '../../../../store/constants.store';
import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsUpdateCrmProject } from '../../../../store/App/crm/projects/crm.projects.selectors';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';

const getAllCrmCreators = async () => {
	const data = await http.get(`${API_ENDPOINTS.GET_ALL_CRM_CREATORS}`);
	console.log("allcreators", data);
	return data?.data;
};

const useGetAllCrmCreators = (options) => {
	const dispatch = useDispatch();
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);
	const isCompanyUpdated = useSelector(selectIsCompanyUpdate);
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isCreatorUpdated = useSelector(selectIsUpdateCrmCreator);
	const isProjectUpdated = useSelector(selectIsUpdateCrmProject);

	const onSuccess = (data) => {
		dispatch({
			type: CRM_CREATORS_STORE_CONSTANTS.SET_CRM_CREATOR_DATA,
			payload: data?.data,
		});
	};
	return useQuery(
		[
			API_ENDPOINTS.GET_ALL_CRM_CREATORS,
			isCompanyUpdated,
			isCreatorUpdated,
			isProjectUpdated,
			isNOTEupdated,
			isTaskUpdated,
		],
		getAllCrmCreators,
		{
			onSuccess,
		},
	);
};

export { useGetAllCrmCreators, getAllCrmCreators };
