import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	crmCompany: [],
	crmCompanyId: null,
	crmCompanyById: null,
	getCompany: null,
	isCompanyUpdate: null,
	companyToEdit: null,
};

const slice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		setCrmCompany: (state, action) => {
			state.crmCompany = action.payload;
		},
		setCrmCompanyId: (state, action) => {
			state.crmCompanyId = action.payload;
		},
		setCrmCompanyById: (state, action) => {
			state.crmCompanyById = action.payload;
		},
		setGetCompany: (state, action) => {
			state.getCompany = action.payload;
		},
		setIsCompanyUpdate: (state, action) => {
			state.isCompanyUpdate = action.payload;
		},
		setCompanyToEdit: (state, action) => {
			state.companyToEdit = action.payload;
		},
	},
});

export const {
	setCrmCompany,
	setCrmCompanyId,
	setCrmCompanyById,
	setGetCompany,
	setIsCompanyUpdate,
	setCompanyToEdit,
} = slice.actions;

export default slice.reducer;
