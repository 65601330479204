import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DropdownItem } from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import { checkArrayData } from '../../../helpers/helpers';
import WsDropdown from '../../../pages/common/Headers/WsDropdown';
import { useUI } from '../../../states/contexts/ui.context';
import { selectWorkspaceSync, selectWorkspaces } from '../../../store/App/Workspace/workspace.selector';
import { WORKSPACE_STORE_CONSTANTS } from '../../../store/constants.store';

const WorkspaceSelect = () => {
	const dispatch = useDispatch();

	const workspaces = useSelector(selectWorkspaces);

	const wsSync = useSelector(selectWorkspaceSync)

	const defaultWs = localStorage.getItem("icc_workspace");

	const { setModalMeta, openModal } = useUI();

	const navigate = useNavigate();

	const setWorkspaceDetails = (workspace) => {
		dispatch({
			type: WORKSPACE_STORE_CONSTANTS.SET_WORKSPACE,
			payload: workspace,
		});
		// navigate('/dashboard', { replace: true });
	};

	const setModalMetaData = () => {
		setModalMeta({
			view: 'CREATE_WORKSPACE',
			title: 'Create workspace',
		});
		return openModal();
	};

	useEffect(() => {

		console.log("-------------- workspace-----------------", workspaces);

		// eslint-disable-next-line no-unused-expressions
		if (checkArrayData(workspaces) && checkArrayData(workspaces[0].workspace)) {
			// const defaultWs = localStorage.getItem("icc_workspace");
			if (defaultWs) {
				setWorkspaceDetails(defaultWs);
			} else {
				setWorkspaceDetails(workspaces[0]?.workspace[0]?._id);
			}
			// console.log({ work: workspaces[0].workspace });
		}
		// refetchCredits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workspaces, wsSync]);

	return (
		<div>
			{checkArrayData(workspaces) && checkArrayData(workspaces[0].workspace) && (
				<WsDropdown
					defaultOption='Select Workspace'
					isDefaultValue
					// defaultWs={defaultWs}
					list={workspaces}
					Field='type'
					FieldValue='workspace'
					valueText='_id'
					labelText='name'
					onClick={(value) => {
						setWorkspaceDetails(value);
						// switchedWorkspace(value);
						// navigate('/dashboard', { replace: true });
						navigate('/discovery', { replace: true });
					}}>
						{
							workspaces[1].workspace.length === 0 ?
							<DropdownItem
						onClick={() => {
							setModalMetaData();
						}}>
						<div>
							Create Workspace &nbsp;
							<Icon icon='Add' />
						</div>
					</DropdownItem>
					:
					<div><p>You are invited to workspace cannot create one</p></div>

						}
					
				</WsDropdown>
			)}
		</div>
	);
};

const data = {
	_id: { $oid: '62eb7df40a3ee64e1d0de938' },
	firstName: 'Simran',
	lastName: 'Panchal',
	email: 'simran@ovonts.com',
	passwordHash:
		'59067104a70cfd90b1b87bf2dd19e0beef4a4ae8b44048da53123171448c781fa4e63f6019ac22aa38e8ef6799ab1aefb04ecb2acb3315602e8e639463e858f3',
	salt: 'gdfsE94/iXvdqC+6AUOiIDodU390dYqj5B4r89LG3MQ4oLsA/x1vK8etA/1xojzaemR0g3osUz7sAUb9l7UGxY7FjrWnBlKXWB4+msOi6EVaRg09oC17GyLHgT8ke71fGfWZ4Q/BEsAQBH6FSIR/ZbpjbnidzSc5eJBYnpUWrd4=',
	authToken: [
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzRkMTZkNjQ3NTZjNzQyZmUzYzNmZTkiLCJlbWFpbCI6InNpbXJhbkBvdm9udHMuY29tIiwiZmlyc3ROYW1lIjoiU2ltcmFuIiwibGFzdE5hbWUiOiJQYW5jaGFsIiwib3V0cmVhY2hNb2RlRGV0YWlscyI6e30sImlhdCI6MTY2NTk5NjUwMiwiZXhwIjoxNjY2MDgyOTAyfQ.IFIiECxop6X2YogH8Hj1YKyrWGDH5gbfi4rvIyWM038',
	],
	profileImg: { $binary: { base64: '', subType: '00' } },
	mobile: '917977211305',
	countryCode: '91',
	currency: 'DOLLAR',
	emailSubscription: false,
	languagePref: 'ENGLISH',
	createdAt: { $date: { $numberLong: '1665996502576' } },
	updatedAt: { $date: { $numberLong: '1665996502578' } },
	__v: { $numberInt: '0' },
};

export default WorkspaceSelect;
