import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useUI } from '../../../states/contexts/ui.context';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import recordEvent from '../mixpanel/mixpanel';

async function analyticsProjects(input) {
	const { data } = await http.post(API_ENDPOINTS.CREATE_ANALYTICS_PROJECT, input);
	// console.log("input",input)
	return data;
}

const useAnalyticsMutation = () => {
	const { setModalMeta, closeModal } = useUI();
	const { addToast } = useToasts();
	const cache = useQueryClient();

	return useMutation((input) => analyticsProjects(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				cache.invalidateQueries(API_ENDPOINTS.GET_ANALYTICS_PROJECTS);
				setModalMeta(null);
				closeModal();
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
				recordEvent(`Created Project(Reports & Analytics)`, {
					input,
					output: res,
				});
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				recordEvent(res.data.data.errorMessage, {
					input,
					output: res.data.data,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent("Failed to Create Project(Reports & Analytics)", {
				input,
				output: err?.response?.data,
			});
		},
	});
};
export default useAnalyticsMutation;
