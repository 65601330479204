import { useQuery } from "react-query"
import { useDispatch, useSelector } from "react-redux";
import http from "../utils/http";
import API_ENDPOINTS from "../utils/api-endpoints";
import { selectIsWorkspaceShare, selectIsWorkspaceUpdate } from "../../../store/App/ControlPanel/controlPanel.selector";
import { CONTROL_PANEL_STORE_CONSTANTS } from "../../../store/constants.store";
import { selectWorkspace } from "../../../store/App/Workspace/workspace.selector";

export const fetchAllUserOfWorkspace = async ({ queryKey }) => {
    const [_key, _params] = queryKey;
    // const { type, name } = _params;
    const { data } = await http.get(
        `${API_ENDPOINTS.GET_USERS_OF_WORKSPACE}`,
    );

    return data.data || [];
};

export const useGetUsersOfWorkspace = (options) => {
    const dispatch = useDispatch();
    const isWorkspaceUpdate = useSelector(selectIsWorkspaceUpdate);
    const isWOrkspaceShare = useSelector(selectIsWorkspaceShare);
    const workspace = useSelector(selectWorkspace);
    const onSuccess = (data) => {
        dispatch({
            type: CONTROL_PANEL_STORE_CONSTANTS.GET_ALL_USER_IN_WORKSPACE,
            payload: data
        })
    }
    const onError = (data) => {
        dispatch({
            type: CONTROL_PANEL_STORE_CONSTANTS.GET_ALL_USER_IN_WORKSPACE,
            payload: []
        })
    }
    return useQuery(
        [API_ENDPOINTS.GET_USERS_OF_WORKSPACE, isWorkspaceUpdate, isWOrkspaceShare],
        fetchAllUserOfWorkspace,
        {
            onSuccess,
            onError,
            enabled: !!workspace
        }
    )
}