import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import background from '../../assets/img/bg.svg';

const AuthWrapper = () => {
    return (
        <div className='d-lg-flex half'>
            <div
                className='bg order-1 order-md-2'
                style={{ backgroundImage: `url(${background})`, backgroundColor: '#f6f7fc' }}
            />
            <div className='contents order-2 order-md-1'>
                <div className='container'>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-md-7'>
                            {/* <Suspense fallback=''> */}
                                <Outlet />
                            {/* </Suspense> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='auth-footer made-with-love'>
                <span>
                    Made with <b className='animate__heartBeat'>❤️</b> from <b>India</b>
                </span>
            </div>
            <span className='auth-footer'>
                &#169;{new Date().getFullYear()} Ovonts Technologies Pvt Ltd.
            </span>
        </div>
    )
}

export default AuthWrapper