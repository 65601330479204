import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './controlPanel.slice';

const controlPanelSlice = (state) => state.controlPanel || initialState;

export const selectWorkspaceInfo = createSelector(
	[controlPanelSlice],
	(state) => state.workspaceInfo,
);

export const selectIsWorkspaceUpdate = createSelector(
	[controlPanelSlice],
	(state) => state.isWorkspaceUpdate,
);

export const selectIsWorkspaceShare = createSelector(
	[controlPanelSlice],
	(state) => state.isWorkspaceShare,
);

export const selectIsPermissionRemove = createSelector(
	[controlPanelSlice],
	(state) => state.isPermissionRemove,
);

export const selectMenu = createSelector([controlPanelSlice], (state) => state.menu);

export const selectAllUsers = createSelector([controlPanelSlice], (state) => state.allUsers);

export const selectMyAccessList = createSelector(
	[controlPanelSlice],
	(state) => state.myAccessList,
);
