import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { CONTROL_PANEL_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

export const shareItem = async ({ body }) => {
	const { data } = await http.post(`${API_ENDPOINTS.SHARE_ITEM_PERMISSION}`, body);
	return data.data || [];
};

export const useShareItem = (options) => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();

	const queryClient = useQueryClient();

	const onSuccess = (data, input) => {
		addToast(
			successToast({
				message: 'shared Successfully',
			}),
			{
				autoDismiss: true,
			},
		);
		queryClient.invalidateQueries([API_ENDPOINTS.TO_GET_WORKSPACE_INFO]);
		dispatch({
			type: CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_WORKSPACE_SHARE,
			payload: `Item/Share/${new Date()}`,
		});
		recordEvent("Shared Item(Control Panel)", {
			input,
            output: data
          });
	};
	const onError = (err, input) => {
		addToast(
			dangerToast({
				message: err?.data?.errorMessage,
			}),
			{
				autoDismiss: true,
			},
		);
		recordEvent(err?.data?.errorMessage, {
			input,
            output:err
          });
	};
	return useMutation((input) => shareItem(input), {
		onSuccess,
		onError,
	});
};
