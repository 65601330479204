import { useQueryClient, useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { useUI } from '../../../states/contexts/ui.context';
import { dangerToast, successToast } from '../../../helpers/toast-helper';

const sendEmail = async (payload) => {
	const { data } = await http.post(API_ENDPOINTS.EMAIL, payload);
	return data.data;
};

const useSendMailMutation = ({ creatorId, campaignId, threadId }) => {
	const { addToast } = useToasts();

	const queryClient = useQueryClient();
	const { closeModal } = useUI();

	console.log(creatorId);
	console.log(campaignId, threadId);

	let payload;

	return useMutation(
		({ subject, messageBody }) => {
			const emailObj = {
				subject,
				messageBody,
			};
			payload = {
				campaignId,
				creatorId,
				threadId,
				emailObj,
			};
			return sendEmail(payload);
		},
		{
			onSuccess: () => {
				const invalidateQueries = campaignId || null;

				creatorId.forEach((id) => {
					queryClient.invalidateQueries(
						[API_ENDPOINTS.EMAIL, id, payload.emailObj.subject, invalidateQueries],
						{
							refetchActive: true,
							refetchInactive: true,
						},
					);
				});

				queryClient.invalidateQueries([API_ENDPOINTS.EMAIL, invalidateQueries], {
					refetchActive: true,
					refetchInactive: true,
					exact: true,
				});

				closeModal();
				addToast(successToast({ message: 'Email sent successfully' }), {
					autoDismiss: true,
				});
			},
			onError: (res) => {
				addToast(dangerToast({ message: 'Email Not sent' }), { autoDismiss: true });
			},
		},
	);
};

export default useSendMailMutation;
