import React, { Children, cloneElement, forwardRef } from 'react';

const CustomRadioButton = forwardRef(({ id, radioButtons, ...props }, ref) => {
	return (
		<div className='div-custom-radio-button' ref={ref} id={id}>
			<div className='radio-pillbox'>
				<div className='radiogroup'>
					{radioButtons.map((radioBtn, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<RadioButton {...radioBtn} {...props} key={`radio-${i}`} />
					))}
					{/* <div>
						<input
							id={props.id}
							onChange={props.changed}
							value={props.value}
							type='radio'
							checked={props.isSelected}
						/>

						<label htmlFor={props.id}>{props.label}</label>
					</div>
					<div>
						<input
							type='radio'
							name='currentCustomer'
							id='currentCustomer-Male'
							value='MALE'
							className=''
							onChange={(e) => onChange(e.target.value)}
						/>

						<label htmlFor='currentCustomer-Male' className=''>
							Male
						</label>
					</div>
					<div>
						<input
							type='radio'
							name='currentCustomer'
							id='currentCustomer-Any'
							value='ANY'
							className=''
							onChange={(e) => onChange(e.target.value)}
						/>

						<label htmlFor='currentCustomer-Any' className=''>
							Any
						</label>
					</div> */}
				</div>
			</div>
		</div>
	);
});

export default CustomRadioButton;

export const RadioButton = ({ ...props }) => {
	return (
		<div>
			<input
				id={props.id}
				onChange={props.changed}
				value={props.value}
				type='radio'
				checked={props.isSelected === props.value}
			/>

			<label htmlFor={props.id}>{props.label}</label>
		</div>
	);
};
