import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	count: 0,
	anotherCount: 5,
};

const slice = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		setCount: (state, action) => {
			state.count = action.payload;
		},
		setAnotherCount: (state, action) => {
			state.anotherCount = action.payload;
		},
	},
});

export const { setCount, setAnotherCount } = slice.actions;

export default slice.reducer;
