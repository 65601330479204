import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	mailSent: false,
};

const slice = createSlice({
	name: 'mails',
	initialState,
	reducers: {
		setMailSent: (state, action) => {
			state.mailSent = action.payload;
		},
	},
});

export const { setMailSent } = slice.actions;

export default slice.reducer;
