import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import http from '../../utils/http';
import recordEvent from '../../mixpanel/mixpanel';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import {
	CRM_COMPANIES_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
	CRM_PROJECTS_STORE_CONSTANTS,
} from '../../../../store/constants.store';

async function projectRemoveAssociation(input) {
	console.log(input);
	const { data } = await http.post(`${API_ENDPOINTS.CRM_PROJECT_REMOVE_ASSOCIATION}`, input);
	return data;
}

const useProjectRemoveAssociationMutation = () => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation((input) => projectRemoveAssociation(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				queryClient.invalidateQueries([API_ENDPOINTS.GET_PROJECT_BY_ID]);
				queryClient.invalidateQueries([API_ENDPOINTS.GET_ALL_CRM_PROJECTS]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNY_BY_ID]);
				dispatch({
					type: CRM_PROJECTS_STORE_CONSTANTS.IS_UPDATE_PROJECT,
					payload: `/Project/Remove/Associate/${new Date()}`,
				});
				// dispatch({
				// 	type: CRM_COMPANIES_STORE_CONSTANTS.IS_UPDATE_COMPANY,
				// 	payload: `/Project-Company/Associate/${new Date()}`,
				// });
				// dispatch({
				// 	type: CRM_CREATORS_STORE_CONSTANTS.IS_UPDATE_CREATOR,
				// 	payload: `/Project-Creator/Associate/${new Date()}`,
				// });
				addToast(successToast({ message: 'Project Association Deleted' }), {
					autoDismiss: true,
				});
				recordEvent('Project Association Deleted(CRM)', {
					input,
					output: res.data,
				});
			} else {
				addToast(dangerToast({ message: `${res.errorMessage}` }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({message:err.response.data.errorMessage}), {
				autoDismiss: true,
			});
			recordEvent('Failed to delete Project Association(CRM)', {
				input,
				output: err
			});
		},
	});
};
export default useProjectRemoveAssociationMutation;
