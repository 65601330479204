export const TrackingActions = {
	START: 'START',
	RESUME: 'RESUME',
	PAUSE: 'PAUSE',
	DISABLE: 'DISABLE',
	DELETE: 'DELETE',
};
export const SocialAnalyticsType = {
	PROJECT: 'PROJECT',
	INFLUENCER: 'INFLUENCER',
};
export const OVERVIEW_TYPES = {
	CONTENT: 'Content Stream',
	GRAPHS: 'Social Media Analytics',
	BEHAIOUR: 'Conversion Events Intelligence',
	BUILDER: 'Audience',
};

export const COMMERCE_PROJECT = {
	BEHAIOUR: 'Conversion Events Intelligence',
	BUILDER: 'Audience',
};

export const SOCIAL_PROJECT = {
	CONTENT: 'Content Stream',
	GRAPHS: 'Social Media Analytics',
};

export const SOCIOCOMMERCE_PROJECT = {
	CONTENT: 'Content Stream',
	GRAPHS: 'Social Media Analytics',
	BEHAIOUR: 'Conversion Events Intelligence',
	BUILDER: 'Audience',
};
