import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import useGenerateApp from '../../../framework/basic-rest/analytics/otanalytics/generate-app-for-ot-analytics';
import { checkArrayData } from '../../../helpers/helpers';
import { dangerToast } from '../../../helpers/toast-helper';
import { selectAppkeys } from '../../../store/App/SocialAnalytics/analytics.selector';
import { OT_ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import validateKey from '../../../validations/Analytics/Appkey';
import Button from '../../bootstrap/Button';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { organizationslector } from '../../../store/App/Organization/organization.selector';
import Input from '../../bootstrap/forms/Input';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import Popovers from '../../bootstrap/Popovers';
import Spinner from '../../bootstrap/Spinner';
import Icon from '../../icon/Icon';
import MultiSelectOption from './MultiSelectOption';

const SelectProjectKeys = ({ formik, setStepOneDetails, stepOneDetails }) => {
	const { addToast } = useToasts();
	const [generateAppEvent, setGenerateAppEvent] = useState(false);
	const dispatch = useDispatch();
	const Appkeys = useSelector(selectAppkeys);
	const organizationDeatil = useSelector(organizationslector);
	const { countlyResponse } = organizationDeatil;

	console.log();

	useEffect(() => {
		dispatch({
			type: OT_ANALYTICS_STORE_CONSTANTS.GET_ALL_APP_KEYS,
			payload: [countlyResponse],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generateAppEvent]);

	const options = [
		{ value: 'Generate Qualified Leads', label: 'Generate Qualified Leads' },
		{ value: 'Boost Traffic', label: 'Boost Traffic' },
		{ value: 'Drive sign ups', label: 'Drive sign ups' },
		{ value: 'Increase Revenue', label: 'Increase Revenue' },
		{ value: 'Drive sales', label: 'Drive sales' },
		{ value: 'Enhance video views', label: 'Enhance video views' },
	];
	const eventOptions = [
		{ value: 'search', label: 'Search' },
		{ value: 'productViewed', label: 'View Product' },
		{ value: 'productAddedToCart', label: 'Add To Cart' },
		{ value: 'productAddedToWishlist', label: 'Add To Wishlist' },
		{ value: 'cartViewed', label: 'Cart Viewed' },
		{ value: 'orderPlaced', label: 'Order Placed' },
		{ value: 'checkoutStarted', label: 'Checkout Started' },
		{ value: 'productRemovedFromCart', label: 'Removed from Cart' },
		{ value: 'register', label: 'Register' },
		{ value: 'login', label: 'Login' },
	];
	const API_KEY_TYPES = {
		EXISTING: {
			name: 'Choose Existing',
		},
		NEW: {
			name: 'Create New',
		},
	};

	const appkeyFormik = useFormik({
		initialValues: {
			keyType: !checkArrayData(Appkeys)
				? API_KEY_TYPES?.NEW?.name
				: API_KEY_TYPES?.EXISTING?.name,
		},
	});
	const createAppFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: '',
		},
		validate: validateKey,
	});
	useEffect(() => {
		if (organizationDeatil) {
			createAppFormik.setFieldValue('name');
			formik.setFieldValue('commerceTrackingId', organizationDeatil._id);
			setShowCopyIcon({ icon: 'clipboard', status: 'True' });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationDeatil]);
	const [iconType, setIconType] = useState({
		icon: 'Add',
	});
	const [showCopyIcon, setShowCopyIcon] = useState({
		icon: 'clipboard',
		status: 'false',
	});
	const [showCustomeEvent, setShowCustomeEvent] = useState(false);

	const [customEvents, setCustomEvents] = useState('');
	return (
		<>
			{/* THIS SECTION IS USED TO CREATE APP ID BUT NOW THAT IS DONE AT ORGANIZATION LEVAL

			
			 {checkArrayData(Appkeys) && (
				<div className='d-flex justify-content-start'>
					<FormGroup id='keyType'>
						<ChecksGroup isInline>
							{Object.keys(API_KEY_TYPES)?.map((i) => (
								<Checks
									type='radio'
									key={API_KEY_TYPES[i]?.name}
									id='keyType'
									label={API_KEY_TYPES[i]?.name}
									name='keyType'
									value={API_KEY_TYPES[i]?.name}
									onChange={appkeyFormik.handleChange}
									checked={appkeyFormik.values.keyType}
								/>
							))}
						</ChecksGroup>
					</FormGroup>
					<div className='col-md-3'>
						<Popovers
							desc='This campaignId will be used for Integration to track the Events'
							trigger='hover'>
							<Icon
								icon='Info'
								size='lg'
								style={{
									marginLeft: '3px',
									fontSize: '1.55rem',
								}}
							/>
						</Popovers>
					</div>
				</div>
			)} */}

			{/* {appkeyFormik.values.keyType === API_KEY_TYPES?.NEW?.name && (
				<>
					<div className='col-md-11'>
						<FormGroup id='name' label='Create New CampaignId '>
							<Input
								onChange={createAppFormik.handleChange}
								value={createAppFormik.values.name}
								onBlur={createAppFormik.handleBlur}
								isValid={createAppFormik.isValid}
								isTouched={createAppFormik.touched.name}
								invalidFeedback={createAppFormik.errors.name}
								validFeedback='Looks good!'
								placeholder='Enter Campaign name'
							/>
						</FormGroup>
					</div>
					<div className='col-md-1 pt-4 d-flex align-items-center '>
						{showCopyIcon.status === 'false' ? (
							<Icon
								icon='Add'
								role='button'
								color='info'
								isLight
								size='2x'
								onClick={() => {
									if (createAppFormik.values.name) {
										// createNewApp(createAppFormik.values);
										setGenerateAppEvent(true);
									} else {
										addToast(
											dangerToast({
												message: 'Please Enter Campaign name first',
											}),
											{
												autoDismiss: true,
											},
										);
									}
								}}
							/>
						) : (
							<CopyToClipboard
								onCopy={() => console.log('Copied')}
								text={countlyResponse.key}>
								<Icon
									icon={showCopyIcon.icon}
									role='button'
									color='info'
									isLight
									size='2x'
									onClick={() => {
										setShowCopyIcon({
											...showCopyIcon,
											icon: 'clipboard-check',
										});
									}}
								/>
							</CopyToClipboard>
						)}
					</div>
				</>
			)} */}
			{/* {checkArrayData(Appkeys) &&
				appkeyFormik.values.keyType === API_KEY_TYPES?.EXISTING?.name && (
					<div className='col-md-12'>
						<FormGroup id='commerceTrackingId' label='Choose CampaignId'>
							<Select
								onChange={formik.handleChange}
								value={formik.values.appkey}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.commerceTrackingId}
								invalidFeedback={formik.errors.commerceTrackingId}
								validFeedback='Looks good!'
								ariaLabel='Select CampaignId'
								placeholder='Select CampaignId'>
								{Appkeys &&
									Appkeys.map((x) => {
										console.log(x);
										return <Option value={x._id}>{x.name}</Option>;
									})}
							</Select>
						</FormGroup>
					</div>
				)} */}
			<div className='col-md-12'>
				<FormGroup id='goals' label='Choose Goals'>
					<MultiSelectOption
						className='multi-select'
						optionList={options}
						value={stepOneDetails.goalList}
						onChange={(value) =>
							setStepOneDetails({ ...stepOneDetails, goalList: value })
						}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 d-flex justify-content-start'>
				<Button
					size='2x'
					icon='Add'
					color='info'
					isLight
					className='border border-light rounded-2'
					onClick={() => {
						setShowCustomeEvent(!showCustomeEvent);
					}}>
					Add Custom Events
				</Button>
			</div>
			{showCustomeEvent && (
				<div className='row p-3'>
					<div className='col-md-11'>
						<Input
							onChange={(e) => {
								setCustomEvents(e.target.value);
							}}
							value={customEvents}
							placeholder='Enter Custom Event'
						/>
					</div>
					<div className='col-md-1'>
						<Icon
							icon='Add'
							size='2x'
							color='info'
							role='button'
							onClick={() => {
								if (customEvents.length === 0) {
									addToast(
										dangerToast({
											message: 'Please Enter Custom Event',
										}),
										{
											autoDismiss: true,
										},
									);
								} else {
									setStepOneDetails({
										...stepOneDetails,
										eventList: [
											...stepOneDetails.eventList,
											{ value: customEvents, label: customEvents },
										],
									});

									// console.log("step one details",stepOneDetails)
									setCustomEvents('');
								}
							}}
						/>
					</div>
				</div>
			)}
			<div className='col-md-12'>
				<FormGroup id='eventList' label='Events to Track'>
					<MultiSelectOption
						className='multi-select'
						optionList={eventOptions}
						value={stepOneDetails.eventList}
						// defaultValue={formik.values.eventsToTrack}
						onChange={(value) => {
							setStepOneDetails({ ...stepOneDetails, eventList: value });
						}}
					/>
				</FormGroup>
			</div>
		</>
	);
};

export default SelectProjectKeys;
