import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Avatar from '../../Avatar';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Icon from '../../icon/Icon';
import Wizard, { WizardItem } from '../../Wizard';
import showNotification from '../../extras/showNotification';
import Textarea from '../../bootstrap/forms/Textarea';
import PricingCard from './PricingCard';
import ContactCard from './ContactCard';
import validate from '../../extras/validatePricing';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import validate from '../../../helpers/'
import useCreateInfluencerContact from '../../../framework/basic-rest/contacts_and_pricing/create-influencer-contact';
import useCreateInfluencerPricing from '../../../framework/basic-rest/contacts_and_pricing/create-influencer-pricing';
import { selectListInfluencer, selectProfileListId } from '../../../store/App/List/list.selector';

const PricingDetails = ({ isAddPricing, setAddPricing }) => {
	const listId = useSelector(selectProfileListId);
	console.log({listId});
	const influencer = useSelector(selectListInfluencer);
	const {
		mutate: createInfluencerPrice,
		isLoading: isCreatingPrice,
		data: price,
	} = useCreateInfluencerPricing();

	const createPrice = async (body) => {
		createInfluencerPrice(body);
	};

	const pricing = useFormik({
		enableReinitialize: true,
		initialValues: {
			currency: '',
			content_type: '',
			upper_price: '',
			lower_price: '',
			listId: '',
			influencerId: influencer._id,
			i_index_id: influencer.influencer_index_id,
		},
		validate,
		onSubmit: (values) => {
			const body = {
				pricing: [
					{
						price: {
							upper: values.upper_price,
							lower: values.lower_price,
						},
						content_type: values.content_type,
						currency: values.currency,
					},
				],
				listId,
				influencerId: values.influencerId,
				i_index_id: values.i_index_id,
			};
			createPrice(body);
		},
	});
	return (
		<Modal
			setIsOpen={setAddPricing}
			isOpen={isAddPricing}
			size='lg'
			titleId='add-new-card'
			// isCentered
			isScrollable
			height='50rem'
			isAnimation={false}>
			<ModalHeader setIsOpen={setAddPricing} />
			<ModalBody>
				<PricingCard
					formik={pricing}
					type='Pricing Information'
					influencer={influencer}
					isCreatingPrice={isCreatingPrice}
				/>
			</ModalBody>
		</Modal>
	);
};

export default PricingDetails;
