import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { addDays } from 'date-fns';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { useFormik } from 'formik';
import {
	selectCrmProjectId,
	selectSocialTrackingPlatform,
	selectSocialTrackingTypes,
} from '../../../store/App/SocialAnalytics/analytics.selector';
import validateProject from '../../../validations/Analytics/createProject';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Select from '../../bootstrap/forms/Select';
import { useGetCurrencies } from '../../../framework/basic-rest/contacts_and_pricing/get-system-currencies';
import Option from '../../bootstrap/Option';
import Popovers from '../../bootstrap/Popovers';
import Button from '../../bootstrap/Button';
import { useGetProjectById } from '../../../framework/basic-rest/crm/projects/get-project-by-id';
import { ModalBody, ModalFooter } from '../../bootstrap/Modal';
import CustomDropdown from '../Common/CustomDropdown';
import { organizationslector } from '../../../store/App/Organization/organization.selector';
import PaginationButtons, { dataPagination, PER_COUNT } from '../../PaginationButtons';
import useSelectTable from '../../../hooks/useSelectTable';
import Checks from '../../bootstrap/forms/Checks';
import useImportCrmProjectMutation from '../../../framework/basic-rest/analytics/use-import-crm-project';
import { NoListState } from '../Common/EmptyStates';
import SelectProjectKeys from './SelectProjectKeys';
import useGenerateApp from '../../../framework/basic-rest/analytics/otanalytics/generate-app-for-ot-analytics';

const ProjectImportModal = () => {
	const projectId = useSelector(selectCrmProjectId);
	const [organizationDetail] = useSelector(organizationslector);
	const { data: project, isLoading } = useGetProjectById({ id: projectId });
	const [associatedCreators, setAssociatedCreators] = useState([]);
	const { mutate: importProject, isLoading: importingProject } = useImportCrmProjectMutation();
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			projectName: '',
			projectType: '',
			platform: '',
			startDate: moment().startOf('week').add('-1', 'week').toDate(),
			endDate: moment().endOf('week').toDate(),
			commerceTrackingId: '',
			currency: '',
			budget: 0,
		},
		validate: validateProject,

		onSubmit: async (values) => {
			const projectDetails = {
				projectName: values.projectName,
				projectType: values.projectType,
				platform: values.platform,
				startDate: values.startDate,
				endDate: values.endDate,
				currency: values.currency,
				commerceTrackingId: organizationDetail._id,
				eventsToTrack: stepOneDetails.eventList,
				goals: stepOneDetails.goalList,
				budget: values.budget,
				influencers: await getInfluencers(
					project.data.associations.creators.filter((n) =>
						selectedIdList.some((n2) => n._id === n2),
					),
				),

				projectId,
			};
			await importProject(projectDetails);
		},
	});
	const getInfluencers = async (influencers) => {
		let response = [];
		await influencers.forEach((x) => {
			response = [
				...response,
				{
					influencer: {
						globalInfluencerIndex: x.i_index_id,
						influencerProfile: x.influencer._id,
						isCustom: false,
					},
				},
			];
		});
		return response;
	};
	useEffect(() => {
		if (project && project.success && project.data) {
			const { data } = project;
			setAssociatedCreators(data.associations.creators);
			formik.setValues(data);
			onDatePickerChange({
				startDate: new Date(data.startDate),
				endDate: new Date(data.endDate),
				key: 'selection',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project, isLoading]);
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: addDays(new Date(), 7),
		key: 'selection',
	});
	const datePicker = (
		<DateRangePicker
			minDate={new Date()}
			onChange={(item) => onDatePickerChange(item?.range1 || item?.selection)}
			showSelectionPreview
			moveRangeOnFirstSelection={false}
			ranges={[dateRange]}
			direction='horizontal'
			preventSnapRefocus
			calendarFocus='backwards'
		/>
	);
	const onDatePickerChange = (range) => {
		setDateRange(range);
		formik.setFieldValue('startDate', new Date(range?.startDate).toISOString());
		formik.setFieldValue('endDate', new Date(range?.endDate).toISOString());
	};
	const analyticsTypes = useSelector(selectSocialTrackingTypes);
	const analyticsPlatform = useSelector(selectSocialTrackingPlatform);
	const { data: currencies } = useGetCurrencies();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [currency, setCurrency] = useState([]);

	const onCurrentPageItems = dataPagination(associatedCreators, currentPage, perPage);
	const { selectTable, SelectAllCheck, selectedIdList } = useSelectTable(onCurrentPageItems);
	const [appkeyData, setAppkeyData] = useState('');
	const [stepOneDetails, setStepOneDetails] = useState({
		goalList: [],
		eventList: [],
	});
	const { mutate: createNewApp, isLoading: GeneratingApp } = useGenerateApp();
	const createAppFormik = useFormik({
		initialValues: {
			name: '',
		},
	});
	useEffect(() => {
		if (projectId && currencies?.data.length > 0) {
			const updateCurrency = currencies?.data.filter((c) => c.cc === formik.values.currency);
			setCurrency(updateCurrency);
		}
	}, [currencies, formik.values.currency, projectId]);

	return (
		<>
			<ModalBody>
				<div className='row g-4'>
					<div className='col-md-6'>
						<FormGroup id='projectName' label='Project name'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.projectName}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.projectName}
								invalidFeedback={formik.errors.projectName}
								validFeedback='Looks good!'
								placeholder='Enter project name'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup label='Project type' id='projectType'>
							<Select
								onChange={formik.handleChange}
								value={formik.values.projectType}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.projectType}
								invalidFeedback={formik.errors.projectType}
								validFeedback='Looks good!'
								ariaLabel='Select project type'
								placeholder='Select project type'>
								{analyticsTypes &&
									analyticsTypes.map((x) => (
										<Option value={x.value}>{x.label}</Option>
									))}
							</Select>
						</FormGroup>
					</div>
					{formik.values.projectType.toLowerCase() !== 'social' && (
						<SelectProjectKeys
							formik={formik}
							setAppkeyData={setAppkeyData}
							appkeyData={appkeyData}
							setStepOneDetails={setStepOneDetails}
							stepOneDetails={stepOneDetails}
							createNewApp={createNewApp}
							createAppFormik={createAppFormik}
						/>
					)}
					<div className='col-md-6'>
						<FormGroup id='platform' label='Choose Platform'>
							<Select
								id='platform'
								onChange={formik.handleChange}
								value={formik.values?.platform}
								ariaLabel='Select Platform '
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched?.platform}
								invalidFeedback={formik.errors?.platform}
								validFeedback='Looks good!'
								placeholder='Select Platform'>
								{analyticsPlatform &&
									analyticsPlatform.map((x) => (
										<Option value={x.value}>{x.label}</Option>
									))}
							</Select>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='currency' label='Select currency'>
							{projectId && currencies?.data.length ? (
								<AsyncTypeahead
									id='currency'
									labelKey='name'
									minLength={0}
									onSearch={(query) => {}}
									clearButton
									onChange={(e) => {
										formik.setFieldValue('currency', e.map((x) => x.cc)[0]);
									}}
									selected={currency}
									options={currencies && currencies?.data}
									placeholder='Select your currency'
									useCache={false}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.currency}
									invalidFeedback={formik.errors.currency}
									validFeedback='Looks good!'
									renderMenuItemChildren={(option, props) => (
										<div>
											<span>{option.name}</span>
										</div>
									)}
								/>
							) : (
								<AsyncTypeahead
									id='currency'
									labelKey='name'
									minLength={0}
									onSearch={(query) => {}}
									clearButton
									onChange={(e) => {
										formik.setFieldValue('currency', e.map((x) => x.cc)[0]);
									}}
									options={currencies && currencies?.data}
									placeholder='Select your currency'
									useCache={false}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.currency}
									invalidFeedback={formik.errors.currency}
									validFeedback='Looks good!'
									renderMenuItemChildren={(option, props) => (
										<div>
											<span>{option.name}</span>
										</div>
									)}
								/>
							)}
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup id='budget' label='Enter Budget'>
							<Input
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.budget}
								invalidFeedback={formik.errors.budget}
								validFeedback='Looks good!'
								onChange={formik.handleChange}
								value={formik.values.budget}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						<FormGroup label='Start date - End date'>
							<Popovers
								placement='bottom-end'
								className='mw-100 overflow-hidden'
								data-tour='date-range-menu'
								bodyClassName='p-0'
								trigger='click'
								desc={datePicker}>
								<Button
									color='dark'
									isLight
									data-tour='date-range'
									className='w-100'>
									{`${moment(dateRange.startDate).format('MMM Do YY')} - ${moment(
										dateRange.endDate,
									).format('MMM Do YY')}`}
								</Button>
							</Popovers>
						</FormGroup>
					</div>
				</div>
				<div className='row g-4 mt-3'>
					<div className='col-12'>
						<table className='table table-modern'>
							<thead>
								<tr>
									<th scope='col'>{SelectAllCheck}</th>
									<th>Name</th>
									<th>Username</th>
									<th>Email</th>
									<th>Platform</th>
								</tr>
							</thead>
							<tbody>
								{onCurrentPageItems.map((i) => (
									<tr>
										<th scope='row'>
											<Checks
												id={i._id}
												name='selectedList'
												value={i._id}
												onChange={selectTable.handleChange}
												checked={selectTable.values.selectedList.includes(
													i._id,
												)}
											/>
										</th>
										<td>{`${i.fullName}`}</td>
										<td>{i.userName}</td>
										<td>{i.email}</td>
										<td>{i.platform}</td>
									</tr>
								))}
							</tbody>
						</table>
						{!onCurrentPageItems.length && <NoListState height={300} />}
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button color='info' onClick={formik.handleSubmit}>
					Import Project
				</Button>
			</ModalFooter>
		</>
	);
};

export default ProjectImportModal;
