import { call, put, takeLatest } from 'redux-saga/effects';
import { ANALYTICS_STORE_CONSTANTS } from '../../../constants.store';
import {
	setInfluencers,
	setSelectedTracking,
	setTrackingIds,
	setCurrentTrackingDetails,
} from './tracking.slice';

function* setTrackings(action) {
	try {
		const { data } = action.payload;
		yield put(setInfluencers(data));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCurrentTracking(action) {
	try {
		const { trackingId } = action.payload;
		yield put(setSelectedTracking(trackingId));
		return true;
	} catch (error) {
		return false;
	}
}
function* setCurrentTrackingData(action) {
	try {
		yield put(setCurrentTrackingDetails(action.payload));
		return true;
	} catch (error) {
		return false;
	}
}

function* setTrackingId(action) {
	try {
		const { trackingIds } = action.payload;
		yield put(setTrackingIds(trackingIds));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* socialTrackingSaga() {
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_TRACKING_DATA, setTrackings);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_CURRENT_TRACKING_DATA, setCurrentTrackingData);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_CURRENT_TRACKING, setCurrentTracking);
	yield takeLatest(ANALYTICS_STORE_CONSTANTS.SET_TRACKING_IDS, setTrackingId);
}
