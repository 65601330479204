import React from 'react';
import Aside from '../layout/Aside/Aside';
import { pages } from '../menu';

const asides = [
	{
		path: `*`,
		element: <Aside />,
	},
];

export default asides;
