import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './credits.slice';

const creditSlice = (state) => state.credits || initialState;

export const selectCreditsDetails = createSelector([creditSlice], (state) => state.creditsDetails);

export const selectIsCreditsUpdate = createSelector(
	[creditSlice],
	(state) => state.isCreditsUpdate,
);
