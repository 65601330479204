import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	getAllEventDetails: null,
	getEventDetails: null,
	getCityList: null,
	getEventDetailsByCountry: null,
	getEventDetailsByCity: null,
	getEventDetailsByDevicePlatform: null,
	getSegmentationCount: null,
	getSegmentationForProject: [],
};

const slice = createSlice({
	name: 'conversionAnalytics',
	initialState,
	reducers: {
		setGetAllEventDetails: (state, action) => {
			state.getAllEventDetails = action.payload;
		},
		setGetEventDetails: (state, action) => {
			state.getEventDetails = action.payload;
		},
		setGetCityList: (state, action) => {
			state.getCityList = action.payload;
		},
		setGetEventDetailsByCountry: (state, action) => {
			state.getEventDetailsByCountry = action.payload;
		},
		setGetEventDetailsByCity: (state, action) => {
			state.getEventDetailsByCity = action.payload;
		},
		setGetEventDetailsByDevicePlatform: (state, action) => {
			state.getEventDetailsByDevicePlatform = action.payload;
		},
		setGetSegmentationCount: (state, action) => {
			state.getSegmentationCount = action.payload;
		},
		setGetSegmentationForProject: (state, action) => {
			state.getSegmentationForProject = action.payload;
		},
	},
});

export const {
	setGetAllEventDetails,
	setGetEventDetails,
	setGetCityList,
	setGetEventDetailsByCountry,
	setGetEventDetailsByCity,
	setGetEventDetailsByDevicePlatform,
	setGetSegmentationCount,
	setGetSegmentationForProject,
} = slice.actions;

export default slice.reducer;
