export const ROLES = {
    // GUEST: {
    //     key: "GUEST",
    //     text: "Guest",
    // },
    MEMBER: {
        key: "MEMBER",
        text: "Member",
    },
    // ADMIN: {
    //     key: "ADMIN",
    //     text: "Admin",
    // },
}

// Object.keys(obj)

export const PERMISSIONS = {
    VIEW: {
        key: "VIEW",
        text: "View",
    },
    EDIT: {
        key: "EDIT",
        text: "Edit",
    }
    // ADMIN: {
    //     key: "ADMIN",
    //     text: "Admin",
    // },
}

export const CREDIT_TYPE = {
    BLOCK: {
        key: "BLOCK",
        text: "Allot"
    },
    // LIMITED: {
    //     key: "LIMITED",
    //     text: "Limited"
    // },
    UNLIMITED: {
        key: "UNLIMITED",
        text: "Unlimited"
    }
}