const API_ENDPOINTS = {
	DISCOVERY: {
		SAVE_SEARCH_FILTERS: '/search/saveSearchFilter',
		DELETE_SEARCH_FILTERS: '/search/deleteSavedFilter',
		FETCH_MORE_INFLUENCER_REPORT: '/search/comparison',
	},
	MEMBER: {
		GET_WORKSPACE_MEMBER: '/system/member/getAllMem',
		GET_WORKSPACE_MEMBER_CREDIT: '/system/member/getMemCred',
	},

	WORKSPACE_API: {
		GET_MY_WORKSPACE_DETIALS: '/system/member/getWorkspace',
	},
	GET_ORGANIZATION_DETAIL: '/organization/get',
	VERIFY_SESSION: `/user/verify-session`,

	// user
	LOGIN: `/user/login`,
	VALIDATE_EMAIL: `/verification/isEmailPresent`,
	SEND_OTP: `/verification/sendOtpOnEmail`,
	VERIFY_OTP: `/verification/verifyOtp`,
	REGISTER: '/user/register',
	LOGOUT: `/user/signout`,
	UPDATE_USER: '/updateUser',

	RESET_PASSWORD: '/user/resetPassword',
	WORKSPACE: `/workspace/all`,
	ADD_WORKSPACE: 'workspace/create',
	TO_GET_WORKSPACE_INFO: 'shareWorkspace/getMyWs',
	ENTITY_SUGGESTIONS: '/search/getParameters',
	GET_CREDITS: '/credits/getCredits',
	INFLUENCER_SUGGESTION: '/search/getSuggestion',
	LOCATION_SUGGESTION: '/search/locations',
	INTEREST_SUGGESTION: '/search/interests',
	LANGUAGE_SUGGESTION: '/search/languages',
	HASHTAG_SUGGESTION: '/search/hashtags',
	GET_INFLUENCER_REPORT: '/search/getInfluencer/',
	GET_DUMMY_INFLUENCER_REPORT: '/search/getDuInfluencer/',
	GET_INFLUENCERS_REFETCH: '/search/getInfluencerReport',
	GET_INFLUENCERS: '/search/getInfluencers',
	GET_INFLUENCERS_DB: '/search/getInfluencersDb',
	GET_SAVE_SEARCH_HISTORY: '/search/getSavedFilter',
	GET_LISTS: '/list/getAllLists',
	ADD_TO_LIST: '/list/addToList/',
	CREATE_LIST: '/list/create',
	UPLOAD_LIST: '/list/import',
	GET_LIST_INFLUENCER: '/list/listInfluencer',
	CHANGE_INFLUENCER_APPROVEDS_STATUS: '/list/influencerStatus',
	GET_INFLUENCERS_LIST: '/list/getInfluencers',
	GET_INFLUENCERS_BY_LIST_ID: '/list/getList',
	REMOVE_INFLUENCERS_FROM_LIST: '/list/removefromList',
	GET_MULTIPLE_LISTS: '/list/getMultipleLists',
	CREATE_CONTACT: '/contact/create',
	GET_CONTACT_BY_PARAMS: '/contact/getByParam',
	GET_PRICING_BY_PARAMS: '/pricing/getByParam',
	CREATE_PRICING: '/pricing/create',
	UPDATE_CONTACT: '/contact/addToExistingContact',
	UPDATE_PRICING: '/pricing/addToExistingPricing',
	GET_ALL_CONTACTS: '/contact/getAllContacts',
	GET_ALL_PRICINGS: '/pricing/getAllPricings',
	DELETE_CONTACT: '/contact/delete',
	DELETE_PRICING: '/pricing/delete',
	UPDATE_LIST: '/list/update',
	DELETE_LIST: '/list/delete',
	GET_FILTERED_INFLUENCERS: '/list/getFilteredInfluencers',
	CREATE_INFLUENCER_CONTACT_PRICING: '/influencerInformation/create',
	GET_INFO: '/influencerInformation/contactInfo',
	GET_EVENT_BY_INFLUENCER: '/resource/eventsMatrix/getEventDetails',
	GET_EVENT_BY_COUNTRY_CITIES: '/resource/eventsMatrix/getEventDetailsByCityOrCountry',
	GET_EVENT_BY_DEVICE: '/resource/eventsMatrix/getEventDetailsByDevicePlatform',
	GET_SYSTEM_CURRENCIES: '/middleware/currencies',
	GET_SYSTEM_INTEGRATIONS: '/middleware/integratons',
	GET_SYSTEM_INTEGRATIONMAIL: '/integrationmail/integrationmail',
	GET_SYSTEM_USERGUIDE: '/middleware/userguide',

	GET_USER_MANUAL: '/middleware/usemanual',

	GET_ANALYTICS_PROJECT_TYPE: '/analyticsProjectType/all',
	GET_ANALYTICS_PROJECT_STATUS: 'analyticsProjectStatus/all',
	// CREATE_ANALYTICS_PROJECT: '/analytics/create',
	// UPDATE_ANALYTICS_PROJECT: '/analytics/update',
	// GET_ANALYTICS_PROJECTS: 'analytics/all',
	GET_ANALYTICS_PROJECT_INFLUENCERS: '/socialTrack/influencers',
	CREATE_ANALYTICS_PROJECT_INFLUENCERS: '/socialTrack/create',
	// GET_INFLUENCERS_SUGGESTIONS: '/socialTrack/getInfluncer',
	GET_ANALYTICS_PROJECTS_STATUS: '/analyticsProjectStatus/all',
	GET_ANALYTICS_PROJECTS_TYPE: '/analyticsProjectType/all',
	GET_SHARE_PERMISSION: '/permissionData/getAllPermission',
	GET_MENU: '/workspace/getMenu',
	GET_MY_ACCESS: '/workspace/myAccess',
	GET_USERS_OF_WORKSPACE: '/system/member/getCrmMem',
	SHARE_WORKSPACE_PERMISSION: '/shareWorkspace/share',
	SHARE_MODULE_PERMISSION: '/shareWorkspace/shareModule',
	SHARE_ITEM_PERMISSION: '/shareWorkspace/shareItems',
	GET_ANALYTICS_PROJECT_INFLUENCER_STATS: '',
	// GET_ANALYTICS_PROJECT_STATS: '',

	GET_ANALYTICS_PROJECT_METADATA: '/middleware/socialTrackingData',
	IMPORT_CRM_PROJECT: '/socialProject/importCRMProject',
	GET_ANALYTICS_PROJECTS: '/socialProject',
	CREATE_ANALYTICS_PROJECT: '/socialProject',
	UPDATE_ANALYTICS_PROJECT: '/socialProject',
	DELETE_ANALYTICS_PROJECT: '/socialProject',
	GET_PROJECT_INFLUENCERS: '/socialProject/getProjectInfluencers',
	GET_ANALYTICS_PROJECT_STATS: '/analytics/socialProject',
	GET_ANALYTICS_PROJECT_CONTENT: '/socialTrack/project/contentStream',
	GET_INFLUENCERS_SUGGESTIONS: '/socialTrack/getInfluencerSuggetions',
	ADD_TO_PROJECT: '/socialTrack/addToProject',
	GET_TRACKING_POST: '/socialTrack',
	CREATE_TRACKING_POST: '/socialTrack',
	UPDATE_TRACKING_POST: '/socialTrack',
	REMOVE_INFLUENCERS_FROM_PROJECT: '/socialTrack/removeInfluencer',
	TRACKING_POST_ACTION: '/socialTrack/trackingPostAction',
	GET_ANALYTICS_INFLUENCER_STATS: '/analytics/socialInfluencer',
	GET_ANALYTICS_INFLUENCER_CONTENT: '/socialTrack/influencer/contentStream',
	CREATE_CRM_PROJECT: '/crm/project/create',
	GET_ALL_CRM_PROJECTS: '/crm/project/getAll',
	UPDATE_CRM_PROJECT: '/crm/project/update',
	GET_CRM_COMAPNIES: '/crm/company/getAll',
	GET_CRM_COMAPNY_BY_ID: '/crm/company/getById',
	UPDATE_CRM_COMPANY: '/crm/company/update',
	DELETE_CRM_COMPANY: '/crm/company/delete',
	ASSIGN_COMPANY: '/crm/company/assign',
	ASSIGN_CREATOR: '/crm/creator/assign',
	ASSIGN_PROJECT: '/crm/project/assign',
	REMOVE_ASSIGN_COMPANY: '/crm/company/assign/remove',
	REMOVE_ASSIGN_CREATOR: '/crm/creator/assign/remove',
	REMOVE_ASSIGN_PROJECT: '/crm/project/assign/remove',
	CREATE_CRM_COMPANY: '/crm/company/create',
	DELETE_CRM_PROJECT: '/crm/project/delete',
	CREATE_CAMPAIGN_TASK: '/crm/project/create/campaign/task',
	GET_PROJECT_BY_ID: '/crm/project/getById',
	CREATE_PROJECT_ASSOCIATION: '/crm/project/create/association',
	CREATE_PROJECT_BATCH_ASSOCIATION: '/crm/project/create/batch/association',
	CREATE_COMPANY_ASSOCIATION: '/crm/company/create/association',
	UPDATE_CRM_CREATOR: '/crm/creator/update',
	DELETE_CRM_CREATOR: 'crm/creator/delete',
	CREATE_CREATOR_ASSOCIATION: '/crm/creator/create/association',
	GET_CREATORS_ASSOCIATED: '/crm/creator/get',
	GET_ALL_CRM_CREATORS: '/crm/creator/getAll',
	CREATE_CRM_CREATOR: '/crm/creator/create',
	GET_CREATOR_BY_ID: '/crm/creator/getById',
	CRM_CREATOR_REMOVE_ASSOCIATION: '/crm/creator/remove/association',
	CRM_PROJECT_REMOVE_ASSOCIATION: '/crm/project/remove/association',
	CRM_COMPANY_REMOVE_ASSOCIATION: '/crm/company/remove/association',
	UPLOAD_CREATOR_FILE: '/crm/creator/import',
	FILTER_CREATORS: '/crm/creator/filter',
	UPLOAD_PROJECT_FILE: '/crm/project/import',
	UPLOAD_COMPANY_FILE: '/crm/company/importCompany',
	MAP_PARTNER_CREATORS: '/crm/company/mappartnercreators',

	// NOTES
	GET_CRM_NOTES: '/crm/notes/getNotes',
	ASSIGN_NOTES: '/crm/notes/assign',
	REMOVE_ASSIGN_NOTES: '/crm/notes/assign/remove',
	CREATE_CRM_NOTE: '/crm/notes/create',
	UPDATE_CRM_NOTE: '/crm/notes/updateNote',
	DELETE_CRM_NOTE: '/crm/notes/deleteNote',
	CREATE_NOTE_ASSOCIATION: '/crm/notes/create/association',
	GET_CRM_NOTE_BY_ID: '/crm/notes/getNoteById',
	CRM_NOTE_REMOVE_ASSOCIATION: '/crm/notes/remove/association',

	// TASKS
	GET_CRM_TASKS: '/crm/task/getTasks',
	ASSIGN_TASK: '/crm/task/assign',
	REMOVE_ASSIGN_TASK: '/crm/task/assign/remove',
	CREATE_CRM_TASK: '/crm/task/create',
	UPDATE_CRM_TASK: '/crm/task/updateTask',
	DELETE_CRM_TASK: '/crm/task/deleteTask',
	GET_CRM_TASK_BY_ID: '/crm/task/getTaskById',
	CREATE_TASK_ASSOCIATION: 'crm/task/create/association',
	CRM_TASK_REMOVE_ASSOCIATION: '/crm/task/remove/association',

	GENERATE_GOOGLE_AUTH_LINK: '/google/createAuthLink',
	VALIDATE_GOOGLE_TOKEN: '/google/validateToken',
	HANDLE_GOOGLE_CODE: '/google/handleGoogleRedirect',
	HANDLE_SIGNOUT: '/crm/outreach/signout',
	SEND_MAIL: '/google/mails/send',
	REPLY_MAIL: '/google/mails/reply',
	GET_MAILS: '/google/mail/getMails',
	GE_ASSOCIATED_MAILS: '/google/mail/getAssociatedMail',
	GET_MAIL: 'google/mail/getMail',
	CREATE_EMAIL_ASSOCIATION: 'google/create/association',
	EMAIL_REMOVE_ASSOCIATION: 'google/remove/association',
	ASSIGN_EMAIL: '/google/assign',
	REMOVE_ASSIGNED_EMAIL: '/google/assign/remove',

	// OT
	GET_ALL_APP_KEYS: '/ot/getAll/appKeys',
	GENERATE_BITLY_LINK: '/ot/generateutmlink',
	GET_ALL_OT_ANALYTICS_EVENTS: '/ot/getAllEventDetails',
	GET_EVENTSDATA_BY_SELECTED_EVENT: '/ot/getEventDetails',
	GET_CITIES: '/ot/getCityList',
	GET_EVENTS_BASED_ON_CITY_OR_COUNTRY: '/ot/getEventDetailsByCityOrCountry',
	GET_EVENTS_BASED_ON_DEVICE_PLATFORM: '/ot/getEventDetailsByDevicePlatform',
	GET_SEGMENT_QUERY_COUNT: '/ot/query',
	GET_SEGMENT_FOR_PROJECT: '/ot/getSegmentByProject',
	DELETE_SEGMENTATION: '/ot/deleteSegment',
	GENERATE_APP: '/ot/create/appKey',

	// Vision AI
	VISION_RAW_SEARCH: '/vision/rawData',
	CMS_UPLOAD_CONTENT: '/cms/uploadContent',

	// email
	EMAIL: '/email',
	WhatsApp: '/whatsApp',
	BRIEFEMAIL: 'crm/notes/sendNoteEmail',
	GET_BRIEF_DETAILS: 'crm/notes/getBriefbyId',

	// bucket email
	ADD_TO_BUCKET_EMAIL: '/crm/creator/addEmailBucket',
	GET_BUCKET_EMAIL: '/crm/creator/getBucketEmails',

	// create bucket 
	CREATE_BUCKET: 'crm/creator/addBucketList',
	GET_BUCKET_LIST: 'crm/creator/getBucketList',

	// BRIEF Dashboard
	GET_BRIEF_LIST: 'list/briefListing',

	// Bucket Dashboard
	GET_BUCKET_DASHBOARD: 'list/bucketdetailsList',

	// Delete Email from Bucket
	DELETE_FROM_BUCKET: 'list/bucketListDelete',

	BRIEF_REPLY: 'list/briefReply'
};

export const API_ENDPOINTS_CONTROL_PANEL = {
	MEMBER: {
		SHARE_TO_MEMBER: '/system/member/create',
		REMOVE_MEMBER_WORKSPACE: '/system/member/remove',
	},
	REMOVE_WORKSPACE_PERMISSION: '/shareWorkspace/delete',
};

export const API_ENDPOINTS_CMS = {
	GET_CMS_CONTENTS: '/cms/getContents',
	GET_CMS_SEARCH_CONTENTS: '/cms/search',
	GET_CMS_FILTERED_CONTENTS: '/cms/filter',
};

export default API_ENDPOINTS;
