import { useFormik } from 'formik';
import React from 'react';
import Button from '../../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';

const CreateListModal = ({ setCreate, create, createNewList }) => {
	
	const newListForm = useFormik({
		initialValues: {
			listName: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			// setFilterMenu(false);
			const body = {
				listName: values.listName,
			};
			createNewList(body);
			setCreate(false);
		},
	});

	return (
		<Modal
			setIsOpen={setCreate}
			isOpen={create}
			size='md'
			isAnimation={false}
			titleId='add-new-card'
			isCentered>
			<ModalHeader setIsOpen={setCreate}>
				<ModalTitle>List Creation</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardHeader>
						<CardLabel icon='List' iconColor='warning'>
							<CardTitle>Create List</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='pt-0'>
						<form className='row g-3'>
							<div className='col-12'>
								<FormGroup id='list' name='listName' label='Name' isColForLabel>
									<InputGroup>
										<Input
											id='listName'
											ariaLabel='New List Name'
											placeholder='Write here..'
											onChange={(e) => {
												newListForm.setFieldValue(
													'listName',
													e.target.value,
												);
											}}
											value={newListForm.values.listName}
										/>
										<Button
											color='dark'
											isLight
											icon='Add'
											onClick={() => {
												newListForm.handleSubmit();
											}}>
											Create
										</Button>
									</InputGroup>
								</FormGroup>
							</div>
						</form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default CreateListModal;
