import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Card, { CardBody, CardLabel } from '../../bootstrap/Card';
import Avatar from '../../Avatar';
import CustomSkeleton from '../../CustomSkeleton';
import { cardIcon, fixDecimal, nFormatter, profileUrl } from '../../../helpers/helpers';
import AddToList from './AddToList';
import AddToAnalytics from './AddToAnalytics';
import AddToFavourite from './AddToFavourite';
import Icon from '../../icon/Icon';
import { selectFavouriteList } from '../../../store/App/List/list.selector';

const SearchResultTable = ({
	influencers,
	influencerReportRefetch,
	setIsProfileOpen,
	setInfluencerData,
}) => {
	const favouriteList = useSelector(selectFavouriteList);
	return (
		<div className='row'>
			<div className='col'>
				<Card>
					<CardBody>
						<table className='table table-modern'>
							<thead>
								<tr>
									<th>Name</th>
									<th>ER</th>
									<th>Follwers</th>
									<th>Username</th>
									{/* <th>Language</th> */}
									<th>Add To List</th>
									<th>Add To Campaign</th>
									{favouriteList && <th>Favourite</th>}
								</tr>
							</thead>
							<tbody>
								{influencers
									? influencers.map((influencer, index) => (
											// eslint-disable-next-line react/no-array-index-key
											<tr key={`user-card-tr-${index}`}>
												<td>
													<CardLabel
														onClick={() => {
															setInfluencerData({
																userId: influencer.userId,
																platform: influencer.influencerType,
															});
															// setUserId(influencer.userId);
															influencerReportRefetch(
																influencer.userId,
															);
															setIsProfileOpen(true);
														}}>
														<div className='d-flex align-items-center'>
															<Avatar
																className='mx-2'
																srcSet={influencer.profile.picture}
																src={influencer.profile.picture}
																size={50}
															/>
															<div className='mx-2 fw-bold'>
																{influencer.profile.fullname
																	? influencer.profile.fullname
																	: influencer.profile.username}
															</div>
														</div>
													</CardLabel>
												</td>
												<td>
													{fixDecimal(
														influencer.profile.engagementRate * 100,
														2,
													)}
												</td>
												<td>
													{nFormatter(influencer.profile.followers, 1)}
												</td>
												<td>
													<a
														href={profileUrl(
															influencer.profile.url,
															influencer.profile.username,
															influencer.userId,
															influencer.influencerType,
														)}
														target='_blank'
														rel='noreferrer'>
														<Icon
															icon={cardIcon(
																influencer.influencerType,
															)}
															className='icon me-3'
														/>
														@
														{influencer.profile.username
															? influencer.profile.username
															: influencer.profile.fullname}
													</a>
												</td>
												{/* <td>
													<div className='d-flex'>
														{!!influencer.profile.language &&
															// eslint-disable-next-line react/prop-types
															influencer.profile.language.map(
																(tag) => (
																	<div
																		key={tag.code}
																		className='col-auto mx-2'>
																		<Badge
																			isLight
																			color='info'
																			className='px-3 py-2'>
																			{tag.name}
																		</Badge>
																	</div>
																),
															)}
													</div>
												</td> */}
												<td>
													<AddToList
														influencerId={influencer.influencer_id}
														existingList={influencer.lists}
													/>
												</td>
												<td>
													<AddToAnalytics
														influencerId={influencer.influencer_id}
														existingProject={influencer.projects}
													/>
												</td>
												{favouriteList && (
													<td>
														<AddToFavourite
															_id={influencer.influencer_id}
														/>
													</td>
												)}
											</tr>
									  ))
									: Array(8)
											.fill('0')
											.map((item, index) => (
												// eslint-disable-next-line react/no-array-index-key
												<tr key={`user-card-tr-${index}`}>
													{Array(7)
														.fill('0')
														.map((x, i) => (
															// eslint-disable-next-line react/no-array-index-key
															<td key={`user-card-td-${i}`}>
																<CustomSkeleton
																	count={1}
																	style={{ width: '12rem' }}
																/>
															</td>
														))}
												</tr>
											))}
							</tbody>
						</table>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default SearchResultTable;
