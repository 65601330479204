import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	searchResults: null,
	influencerReport: null,
	optionFilter: null,
	searchFilters: {
		influencerLocation: [],
		influencerInterests: [],
		influencerLanguage: null,
		influencerGender: null,
		lastposted: null,
		engagementRate: null,
		accountType: [],
		bio: '',
		hashtags: [],
		mentions: [],
		growthRate: null,
		keywords: '',
		Search: false,
		influencerFollowers: {},
		influencerAvgReelPlay: {},
		views: {},
		audienceLocation: [],
		audienceAge: [],
		audienceGender: null,
		audienceInterests: [],
		audienceLanguage: null,
		credibility: null,
		platform: 'instagram',
		relevance: [],
		identifier: [],
		checked: '',
		
	},
	isFilterClear: null,
	isFilterApply: null,
	activeTabIndex: 0,
	comparisonProfiles: [],
	comparisonReports: [],
};

const slice = createSlice({
	name: 'discovery',
	initialState,
	reducers: {
		setSearchFilters: (state, action) => {
			state.searchFilters = action.payload;
		},
		setOptionFilter: (state, action) => {
			state.optionFilter = action.payload;
		},
		setSearchResults: (state, action) => {
			state.searchResults = action.payload;
		},
		setInfluencerReport: (state, action) => {
			state.influencerReport = action.payload;
		},
		setActiveTabIndex: (state, action) => {
			state.activeTabIndex = action.payload;
		},
		setIsFilterClear: (state, action) => {
			state.isFilterClear = action.payload;
		},
		setIsFilterApply: (state, action) => {
			state.isFilterApply = action.payload;
		},
		setComparisonProfiles: (state, action) => {
			state.comparisonProfiles = action.payload;
		},
		setInfluencerIds: (state, action) => {
			state.influencerIds = action.payload;
		},
		setComparisonReports: (state, action) => {
			state.comparisonReports = action.payload;
		},
	}
});

export const {
	setSearchFilters,
	setOptionFilter,
	setSearchResults,
	setInfluencerReport,
	setActiveTabIndex,
	setIsFilterClear,
	setIsFilterApply,
	setComparisonProfiles,
	setInfluencerIds,
	setComparisonReports,
} = slice.actions;

export default slice.reducer;
