import { put, takeLatest, call } from 'redux-saga/effects';
import { CONTROL_PANEL_STORE_CONSTANTS } from '../../constants.store';
import {
	setAllUsers,
	setIsPermissionRemove,
	setIsWorkspaceShare,
	setIsWorkspaceUpdate,
	setMenu,
	setMyAccessList,
	setWorkspaceInfo,
} from './controlPanel.slice';

function* getWorkspaceInfo(action) {
	try {
		const workspaceAccessInfo = action.payload;

		yield put(setWorkspaceInfo(workspaceAccessInfo));

		return true;
	} catch (error) {
		return false;
	}
}

function* checkIsWorkspaceUpdated(action) {
	try {
		const isUpdated = action.payload;

		yield put(setIsWorkspaceUpdate(isUpdated));

		return true;
	} catch (error) {
		return false;
	}
}

function* checkIsWorkspaceShare(action) {
	try {
		const isUpdated = action.payload;
		yield put(setIsWorkspaceShare(isUpdated));

		return true;
	} catch (error) {
		return false;
	}
}

function* checkIsPermissionRemove(action) {
	try {
		const isUpdated = action.payload;
		yield put(setIsPermissionRemove(isUpdated));

		return true;
	} catch (error) {
		return false;
	}
}

function* getMenu(action) {
	try {
		const menu = action.payload;

		yield put(setMenu(menu));

		return true;
	} catch (error) {
		return false;
	}
}

function* getAllUserInWorkspace(action) {
	try {
		const userInWorkspace = action.payload;

		yield put(setAllUsers(userInWorkspace));

		return true;
	} catch (error) {
		return false;
	}
}

function* updateMyAccessList(action) {
	try {
		const myAccessList = action.payload;

		yield put(setMyAccessList(myAccessList));

		return true;
	} catch (error) {
		return false;
	}
}

export default function* controlPanelSaga() {
	yield takeLatest(CONTROL_PANEL_STORE_CONSTANTS.SET_WORKSPACE_INFO, getWorkspaceInfo);
	yield takeLatest(
		CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_WORKSPACE_UPDATE,
		checkIsWorkspaceUpdated,
	);
	yield takeLatest(CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_WORKSPACE_SHARE, checkIsWorkspaceShare);
	yield takeLatest(CONTROL_PANEL_STORE_CONSTANTS.CHECK_IS_PERMISSION_REMOVE, checkIsPermissionRemove);
	yield takeLatest(CONTROL_PANEL_STORE_CONSTANTS.GET_MENU, getMenu);
	yield takeLatest(
		CONTROL_PANEL_STORE_CONSTANTS.GET_ALL_USER_IN_WORKSPACE,
		getAllUserInWorkspace,
	);
	yield takeLatest(CONTROL_PANEL_STORE_CONSTANTS.UPDATE_MY_ACCESS_LIST, updateMyAccessList);
}
