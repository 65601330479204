import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import {
	CRM_COMPANIES_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
	CRM_PROJECTS_STORE_CONSTANTS,
} from '../../../store/constants.store';

async function emailRemoveAssociation(input) {
	const { data } = await http.post(`${API_ENDPOINTS.EMAIL_REMOVE_ASSOCIATION}`, input);
	return data;
}

const useEmailRemoveAssociationMutation = () => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation((input) => emailRemoveAssociation(input), {
		onSuccess: (res) => {
			if (res.success) {
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNY_BY_ID]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNIES]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNY_BY_ID]);
				// dispatch({
				// 	type: CRM_PROJECTS_STORE_CONSTANTS.IS_UP,
				// 	payload: `/Company/Remove/Associate/${new Date()}`,
				// });
				dispatch({
					type: CRM_COMPANIES_STORE_CONSTANTS.IS_UPDATE_COMPANY,
					payload: `/Company/Remove/Associate/${new Date()}`,
				});
				// dispatch({
				// 	type: CRM_CREATORS_STORE_CONSTANTS.IS_UPDATE_CREATOR,
				// 	payload: `/Company-Creator/Associate/${new Date()}`,
				// });
				addToast(successToast({ message: 'Association Removed' }), {
					autoDismiss: true,
				});
			} else {
				addToast(dangerToast({ message: `${res.message}` }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err) => {
			addToast(dangerToast({message:err.response.data.errorMessage}), {
				autoDismiss: true,
			});
		},
	});
};
export default useEmailRemoveAssociationMutation;
