import moment from 'moment';
import { fixDecimal } from '../../../../helpers/helpers';

export const getLabel = (type, data) => {
	let label;
	if (type === 'Creator') {
		label = `${data?.fullName && data?.fullName || data?.userName}`;
	}
	if (type === 'Company' || type === 'Partner' ) {
		label = `${data?.name}`;
	}
	if (type === 'Project') {
		label = `${data?.projectName}`;
	}
	if (type === 'Task') {
		label = `${data?.task_subject}`;
	}
	if (type === 'Lists') {
		label = `${data?.listName}`;
	}
	return label;
};

export const daysLeft = (closedate) => {
	const days = (new Date(closedate).getTime() - new Date().getTime()) / (1000 * 3600 * 24);
	return fixDecimal(days, 0);
};

export const removeByAttribute = function (entity, arrtibute, value) {
	let i = entity.length;
	// eslint-disable-next-line no-plusplus
	while (i--) {
		if (
			entity[i] &&
			// eslint-disable-next-line no-prototype-builtins
			entity[i].hasOwnProperty(arrtibute) &&
			arguments.length > 2 &&
			entity[i][arrtibute] === value
		) {
			entity.splice(i, 1);
		}
	}
	return entity;
};
