import React, { useState } from 'react';
import CreateCreatorModal from '../../../pages/crm/creator/CreateCreatorModal';
import Button from '../../bootstrap/Button';
import AddToCrmModal from './AddToCrmModal';

const AddToCrm = ({ influencer, platform }) => {
	const [openForm, setOpenForm] = useState(false);
	return (
		<>
			<AddToCrmModal
				influencer={influencer}
				platform={platform}
				setShowEdit={setOpenForm}
				showEdit={openForm}
			/>
			<Button
				className='w-100'
				icon='AddCircle'
				color='danger'
				isLight
				onClick={() => setOpenForm(!openForm)}>
				Creators
			</Button>
		</>
	);
};

export default AddToCrm;
