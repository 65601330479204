import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import USERS from '../../common/data/userDummyData';
import Avatar, { AvatarGroup } from '../../components/Avatar';
import Button from '../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Popovers from '../../components/bootstrap/Popovers';
import Badge from '../../components/bootstrap/Badge';
import Icon from '../../components/icon/Icon';
import useDeleteList from '../../framework/basic-rest/list/delete-list';
import { useFetchAllListNames } from '../../framework/basic-rest/list/get-all-list-names';
import useUpdateListMutation from '../../framework/basic-rest/list/update-list-name';
import { ConfirmAlert } from '../../helpers/confirm-helper';
import useDarkMode from '../../hooks/useDarkMode';
import useTourStep from '../../hooks/useTourStep';
import { calculate } from '../crm/crmHelpers/utils';
import useCreateListMutation from '../../framework/basic-rest/list/create-list';
import CreateListModal from '../../components/custom/MyInfluencerComponents/AddToListModal';
import ListEmailModal from '../../components/custom/MyInfluencerComponents/ListEmailModal';
import FilterComponent from './FilterComponent';
import useGetFilteredInfluencers from '../../framework/basic-rest/list/get-filtered-influencers';
import { useUI } from '../../states/contexts/ui.context';
import CreateAssociation from '../../components/custom/crm/associates/CreateAssociation';
import useProjectRemoveAssociationMutation from '../../framework/basic-rest/crm/projects/remove-project-associations';
import useCreateProjectAssociationMutation from '../../framework/basic-rest/crm/projects/create-project-association';

const ListCard = ({ list, className, removeAssociation, id, associateProject }) => {
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const handleOnClickToEmployeeListPage = useCallback(
		() => navigate(`/bucket_dashboard/${list?._id}`),
		[navigate, list],
	);
	const [editList, setEditList] = useState(false);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			newName: list?.listName,
			list_id: list?._id,
		},
	});
	const { mutate: deleteList, isLoading: isDeleting, data: deletedData } = useDeleteList();
	const { mutate: updateList, isLoading: isUpdatingList } = useUpdateListMutation();

	const handleEditListName = () => {
		updateList(formik.values);
		setEditList(!editList);
	};
	const statuses = calculate(list);

	const [open, setOpen]=useState(false);


	return (
		<div className={className}>
			{
				open && (<ListEmailModal setOpen={setOpen} open={open} list={list} />)
			}
			<Card stretch>
				<CardHeader className='bg-transparent'>
					<CardLabel>
						<CardTitle tag='h4' className='h3 fw-bold'>
							{editList ? (
								<div className='row'>
									<div className='col-md-8'>
										<FormGroup className='col-12' id='newName'>
											<Input
												onChange={formik.handleChange}
												value={formik.values.newName}
											/>
										</FormGroup>
									</div>
									<div className='col-md-2 g-1'>
										<Popovers desc='Cancel' trigger='hover'>
											<Icon
												color='danger'
												icon='Cancel'
												size='2x'
												onClick={() => setEditList(!editList)}
											/>
										</Popovers>
									</div>
									<div className='col-md-2 g-1'>
										<Popovers desc='Update' trigger='hover'>
											<Icon
												color='info'
												icon='Check'
												size='2x'
												onClick={handleEditListName}
											/>
										</Popovers>
									</div>
								</div>
							) : (
								list?.listName
							)}
						</CardTitle>
						<CardSubTitle tag='h5' className='h6 text-muted'>
							{/* {`There are ${list?.influencers?.length} Influencers in this list`} */}
							{`List Name : ${list?.bucketName}`}
						</CardSubTitle>
					</CardLabel>
					<CardActions>
						<Button
							icon='ArrowForwardIos'
							aria-label='Read More'
							hoverShadow='default'
							isLink
							color={darkModeStatus && 'dark'}
							onClick={handleOnClickToEmployeeListPage}
							// onClick={() => setOpen(true)}
						/>
					</CardActions>
				</CardHeader>
				<CardBody>
				{`Email List : ${list?.email_buckets.join(' || ')}`}
					{/* <div className='row'> */}
						{/* <div className='col-md-5'>
							{list?.influencers && list?.influencers?.length !== 0 && (
								<AvatarGroup size={50}>
									{list?.influencers.map((influencer) => (
										<Avatar
											srcSet={
												influencer?.influencer_id?.influencerData?.profile
													?.picture
													? influencer?.influencer_id?.influencerData
															?.profile?.picture
													: USERS.JOHN.srcSet
											}
											src={
												influencer?.influencer_id?.influencerData?.profile
													?.picture
													? influencer?.influencer_id?.influencerData
															?.profile?.picture
													: USERS.JOHN.src
											}
											userName={`@${
												influencer?.influencer_id?.influencerData?.profile
													?.username
													? influencer?.influencer_id?.influencerData
															?.profile?.username
													: influencer?.influencer_id?.influencerData
															?.profile?.fullname
											}`}
										/>
									))}
								</AvatarGroup>
							)}
						</div> */}
						{/* <div className='col-md-7 mt-3'>
							<small className='border border-success border-2 text-success fw-bold px-2 py-1 rounded-1'>
								Approved {statuses.approved}
							</small>{' '}
							<small className='border border-danger border-2 text-danger fw-bold px-2 py-1 rounded-1'>
								DisApproved {statuses.disapproved}
							</small>{' '}
							<small className='border border-warning border-2 text-warning fw-bold px-2 py-1 rounded-1'>
								Undecided {statuses.undecided}
							</small>
						</div> */}
					{/* </div> */}
				</CardBody>
				<CardFooter>
					<CardFooterLeft>
						{/* <div className='d-flex align-items-center'>
							<div className='h5 mb-0'>
								<strong>Updated At</strong>{' '}
								{moment(list?.updatedAt).format('MMMM Do YYYY')}
							</div>
						</div> */}
					</CardFooterLeft>
					<CardFooterRight>
						{list?.isFavourite ? (
							<div />
						) : (
							<Popovers desc='Edit List Name' trigger='hover'>
								<Icon
									color='info'
									icon='pencil-square'
									size='2x'
									onClick={() => setEditList(!editList)}
								/>
							</Popovers>
						)}
						{associateProject ? (
							<Popovers desc='Remove List' trigger='hover'>
								<Icon
									color='danger'
									icon='Delete'
									size='2x'
									onClick={() => {
										ConfirmAlert(
											`Remove`,
											`are you sure you want to remove list - ${list.listName}`,
											() => {
												removeAssociation({
													toId: list?._id,
													id,
													type: 'Lists'.toLowerCase(),
												});
											},
										);
									}}
								/>
							</Popovers>
						) : (
							<Popovers desc='Delete List' trigger='hover'>
								<Icon
									color='danger'
									icon='Delete'
									size='2x'
									onClick={() => {
										ConfirmAlert(
											`Delete`,
											`are you sure you want to delete list - ${list.listName}`,
											() => {
												deleteList({
													listId: list._id,
												});
											},
										);
									}}
								/>
							</Popovers>
						)}
					</CardFooterRight>
				</CardFooter>
			</Card>
		</div>
	);
};

const ListPage = ({ lists, associateProject = false, id, associateLists, className }) => {
	// useTourStep(12);
	const formik = useFormik({
		initialValues: {
			toId: '',
			id,
			type: 'lists',
		},
	});
	const [create, setCreate] = useState(false);
	const [associate, setAssociate] = useState(false);
	const { filtersClicked } = useUI();
	const {
		data: newList,
		mutate: createList,
		isLoading: isCreatingList,
	} = useCreateListMutation();
	const createNewList = async (body) => {
		createList(body);
	};
	const { mutate: removeAssociation, isLoading: removingAssociation } =
		useProjectRemoveAssociationMutation();
	const { mutate: createAssociation, isLoading: isAssociating } =
		useCreateProjectAssociationMutation();

	return (
		<div className='row'>
			<div className={className}>
				{associateProject
					? associate && (
							<CreateAssociation
								formik={formik}
								isOpen={associate}
								setIsOpen={setAssociate}
								associate={associateLists}
								from='Lists'
								createAssociation={createAssociation}
							/>
					  )
					: create && (
							<CreateListModal
								setCreate={setCreate}
								create={create}
								createNewList={createNewList}
							/>
					  )}
				<Card stretch>
					<CardBody className='d-flex align-items-center justify-content-center'>
						<Button
							color='info'
							size='lg'
							isLight
							className='w-100 h-100'
							icon='AddCircle'
							onClick={() =>
								associateProject ? setAssociate(!associate) : setCreate(!create)
							}>
							{associateProject ? 'Add' : 'Create New'}
						</Button>
					</CardBody>
				</Card>
			</div>
			{lists &&
				lists?.length !== 0 &&
				lists?.map((list) => (
					<ListCard
						list={list}
						className={className}
						removeAssociation={removeAssociation}
						id={id}
						associateProject={associateProject}
					/>
				))}
		</div>
	);
};

export default ListPage;
