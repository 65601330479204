import React from 'react'
import { nFormatter } from '../../../../../helpers/helpers';
import Avatar from '../../../../Avatar';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../bootstrap/Card';

const InfluencerUser = ({ data, title, isDownloading }) => {
    return (
        <div className='col-md-12'>
            <Card>
                <CardHeader>
                    <CardLabel>
                        <CardTitle>{title}</CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody className="overflow-auto" style={{ height: (data.length >= 6 && !isDownloading) ? "41rem" : "auto" }}>
                    <table className='table table-modern'>
                        <thead>
                            <tr>
                                <th>Influencers</th>
                                <th>Engagements</th>
                                <th>Followers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((user) => {
                                return (
                                    <tr>
                                        <td className='d-flex align-items-center'>
                                            <Avatar
                                                className='mx-2'
                                                srcSet={user.picture}
                                                // src={val.picture}
                                                size={50}
                                            />
                                            <a href={user.url} target='_blank' rel="noreferrer" className='mx-2 fw-bold text-primary'>
                                                {
                                                    user?.username ?
                                                        `@${user.username}` :
                                                        user.fullname
                                                }
                                            </a>
                                        </td>
                                        <td>{nFormatter(user.engagements, 1)}</td>
                                        <td>{nFormatter(user.followers, 1)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </div>
    )
}

export default InfluencerUser