const handleList = (e, inputValue) => {
	const { checked, value } = e.target;
	let selectedItems;
	if (checked) {
		selectedItems = [...inputValue, value];
	} else {
		selectedItems = inputValue.filter((v) => v !== value);
	}
	return selectedItems;
};

const handleProject = (e, inputValue) => {
	const { checked, value } = e.target;
	let selectedItems;
	if (checked) {
		selectedItems = [...inputValue, value];
	} else {
		selectedItems = inputValue.filter((v) => v !== value);
	}
	return selectedItems;
};

const handlers = { handleList, handleProject };
export default handlers;
