import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectWorkspace } from '../../store/App/Workspace/workspace.selector';

const WorkspaceContext = createContext(null);

export const WorkspaceProvider = ({ children }) => {
	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const workspaceId = useSelector(selectWorkspace);

	return <WorkspaceContext.Provider value={workspaceId}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceContext;
