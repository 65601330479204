import moment from 'moment';

export const QUERY_BUILDER_REDUCER = {
	USER_PROPERTY_LIST_REQUEST: 'USER_PROPERTY_LIST_REQUEST',
	SET_IS_FIELD_LIST_SUCCESS: 'SET_IS_FIELD_LIST_SUCCESS',
	SET_USER_PROPERTY_FIELD_LIST: 'SET_USER_PROPERTY_FIELD_LIST ',
	SET_QUERY_COUNT_NUMBER: 'SET_QUERY_COUNT_NUMBER',
	SET_DOWNLOAD_URL_LINK: 'SET_DOWNLOAD_URL_LINK',
	GET_QUERY_COUNT_REQUEST: 'GET_QUERY_COUNT_REQUEST',
	SET_IS_QUERY_LOADED: 'SET_IS_QUERY_LOADED',
	SET_QUERY_COUNT_REQUEST_ERROR: 'SET_QUERY_COUNT_REQUEST_ERROR',
	SET_SHOW_SEGMENT_DATA: 'SET_SHOW_SEGMENT_DATA',
	SET_RECENT_SEGMENT_DATA: 'SET_RECENT_SEGMENT_DATA',
	FETCH_QUERY_REQUEST: 'FETCH_QUERY_REQUEST',
	SET_FETCH_DATA_ARRAY: 'SET_FETCH_DATA_ARRAY',
	IS_FETCH_DATA_LOADED: 'IS_FETCH_DATA_LOADED',
	DOWNLOAD_FETCH_URL: 'DOWNLOAD_FETCH_URL',
	SET_DOWNLOAD_URL: 'SET_DOWNLOAD_URL',
	GET_SEGMENT_LIST_BY_CAMPAIGN: 'GET_SEGMENT_LIST_BY_CAMPAIGN',
	SET_SEGMENT_LIST_BY_CAMPAIGN: 'SET_SEGMENT_LIST_BY_CAMPAIGN',
	DELETE_SEGMENT_REQUEST: 'DELETE_SEGMENT_REQUEST',
	SET_SEGEMENT_QUERY_BY_USER: 'SET_SEGEMENT_QUERY_BY_USER',
};

export const initialState = {
	isGetFieldLIstSuccess: false,
	userPropertyFieldList: [],
	queryCount: [],
	isQueryLoaded: false,
	failerQueryCount: false,
	showData: false,
	recentSegmentData: {},
	userQueryRequestData: [],
	downloadUrlArray: [],
	isFetchDataLoaded: true,
	downlaodUrlLink: '',
	segmentListByCampaign: [],
	segmentQueryListByUser: [],
};
export const queryBuilderReducers = (state = initialState, action = {}) => {
	switch (action.type) {
		case QUERY_BUILDER_REDUCER.SET_IS_FIELD_LIST_SUCCESS:
			return { ...state, isGetFieldLIstSuccess: action.value };
		case QUERY_BUILDER_REDUCER.SET_USER_PROPERTY_FIELD_LIST:
			return { ...state, userPropertyFieldList: action.value };
		case QUERY_BUILDER_REDUCER.SET_IS_QUERY_LOADED:
			return { ...state, isQueryLoaded: action.value };
		case QUERY_BUILDER_REDUCER.SET_SEGEMENT_QUERY_BY_USER:
			return { ...state, segmentQueryListByUser: action.value };
		case QUERY_BUILDER_REDUCER.SET_DOWNLOAD_URL_LINK:
			return { ...state, downlaodUrlLink: action.value };
		case QUERY_BUILDER_REDUCER.IS_FETCH_DATA_LOADED:
			return { ...state, isFetchDataLoaded: action.value };
		case QUERY_BUILDER_REDUCER.SET_FETCH_DATA_ARRAY:
			return { ...state, userQueryRequestData: action.value };
		case QUERY_BUILDER_REDUCER.SET_RECENT_SEGMENT_DATA:
			return { ...state, recentSegmentData: action.value };
		case QUERY_BUILDER_REDUCER.SET_SEGMENT_LIST_BY_CAMPAIGN:
			return { ...state, segmentListByCampaign: action.value };
		case QUERY_BUILDER_REDUCER.SET_QUERY_COUNT_REQUEST_ERROR:
			return { ...state, failerQueryCount: action.value };
		case QUERY_BUILDER_REDUCER.SET_SHOW_SEGMENT_DATA:
			return { ...state, showData: action.value };
		case QUERY_BUILDER_REDUCER.SET_DOWNLOAD_URL:
			return {
				...state,
				downloadUrlArray: [
					{
						segmentId: action?.value?.segmentId,
						url: action?.value?.url,
					},
					...state.downloadUrlArray,
				],
			};
		case QUERY_BUILDER_REDUCER.SET_QUERY_COUNT_NUMBER: {
			return {
				...state,
				queryCount: [
					{
						queryTime: moment().format('hh:mm A DD MMM YYYY'),
						source: 'Segmentation',
						count: action?.value?.count,
						segmentId: action?.value?.segmentId,
						showData: action?.value?.showData,
						button:
							action.value > 0 && state.showData === true
								? 'Show Data'
								: 'Fetch Data',
					},
					...state.queryCount,
				],
			};
		}
		default:
			return state;
	}
};
