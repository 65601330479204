import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { selectIsUpdateCrmProject } from '../../../../store/App/crm/projects/crm.projects.selectors';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';
import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';

const getProjectById = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const data = await http.get(`${API_ENDPOINTS.GET_PROJECT_BY_ID}?id=${_params.id}`);
	return data?.data;
};

const useGetProjectById = (options) => {
	const isUpdateProject = useSelector(selectIsUpdateCrmProject);
	const isCompanyUpdated = useSelector(selectIsCompanyUpdate);
	const isCreatorUpdated = useSelector(selectIsUpdateCrmCreator);
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);

	return useQuery(
		[
			API_ENDPOINTS.GET_PROJECT_BY_ID,
			options,
			isUpdateProject,
			isNOTEupdated,
			isCompanyUpdated,
			isCreatorUpdated,
			isTaskUpdated,
		],
		getProjectById,
	);
};

export { useGetProjectById, getProjectById };
