import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import Select from 'react-select';

// import AsyncSelect, { useAsync } from 'react-select/async';
import classNames from 'classnames';

import useDarkMode from '../../../../hooks/useDarkMode';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Icon from '../../../icon/Icon';
import InputGroup, { InputGroupText } from '../../../bootstrap/forms/InputGroup';
import Input from '../../../bootstrap/forms/Input';
import { InfoCircle } from '../../../icon/bootstrap';

import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
	CardTitle,
} from '../../../bootstrap/Card';
import CustomRadioButton from '../CustomRadioButton';
import Button from '../../../bootstrap/Button';
import { useUI } from '../../../../states/contexts/ui.context';
import useDebounce from '../../../../hooks/useDebounce';
import { useLocationSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-location';
import { useLanguageSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-language';
import { useInterestSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-interest';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';
import { useHashTagSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-hashtags';
import { useInfluencersSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-user';
import {
	selectActiveTabIndex,
	selectSearchFilters,
	selectSearchResults,
} from '../../../../store/App/Discovery/discovery.selector';
import {
	accountTypes,
	ageOption,
	credibilityOptions,
	ERValue,
	Gender,
	growthMonthOptions,
	initialFilterState,
	languageOptions,
	maxOptions,
	minOptions,
	rangeType,
	TABS,
	viewRangeType,
} from './FilterOptionsData';
import { DISCOVERY_STORE_CONSTANTS } from '../../../../store/constants.store';
import { defaultSelectedValue, nFormatter } from '../../../../helpers/helpers';
import Badge from '../../../bootstrap/Badge';
import Popovers from '../../../bootstrap/Popovers';
import useGetProjectsQuery from '../../../../framework/basic-rest/discovery/get-projects';
import recordEvent from '../../../../framework/basic-rest/mixpanel/mixpanel';
import MultiSelect from './components/MultiSelect';
import SaveFilters from '../../DiscoveryComponents/advancedSearch/SaveFilters';

const QuickSearch = ({ filtersForm, isGettingInfluencers, isGettingMoreInfluencers }) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	// const searchFilter = useSelector(searchFilters);
	const ActiveTabIndex = useSelector(selectActiveTabIndex);

	const searchFilter = useSelector(selectSearchFilters);
	const searchResult = useSelector(selectSearchResults);

	const { themeStatus, darkModeStatus } = useDarkMode();

	const [searchLoader, setSearchLoader] = useState(false);

	const [isAdvanceOpen, setIsAdvanceOpen] = useState(false);

	useEffect(() => {
		if (!(isGettingInfluencers || isGettingMoreInfluencers)) {
			setSearchLoader(false);
		}
	}, [isGettingInfluencers, isGettingMoreInfluencers]);

	const onChangeHandle = (fieldName, value) => {
		filtersForm.setFieldValue(fieldName, value);

		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_FILTERS,
			payload: {
				fieldName,
				value,
			},
		});
	};

	const onFollowersAndViewChange = (mainField, fieldName, value) => {
		filtersForm.setFieldValue(`${mainField}.${fieldName}`, value);
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_FOLLWERS_VIEW,
			payload: {
				mainField,
				fieldName,
				value,
			},
		});
	};

	const refAsync = useRef();
	// const minFollowerRef = useRef();
	// const maxFollowerRef = useRef();
	// const minViewRef = useRef();
	// const maxViewRef = useRef();
	const interestRef = useRef();
	const erRef = useRef();

	const advancedsearchbtnClick = () => {
		recordEvent('Find Influencers button clicked with filters(Search)', {
			platform: filtersForm.values.platform,
			appliedFilters: {
				Search: filtersForm.values.Search,
				platform: filtersForm.values.platform,
				accountType: filtersForm.values.accountType,
				audienceAge: filtersForm.values.audienceAge,
				audienceGender: filtersForm.values.audienceGender,
				audienceInterests: filtersForm.values.audienceInterests,
				audienceLanguage: filtersForm.values.audienceLanguage,
				audienceLocation: filtersForm.values.audienceLocation,
				bio: filtersForm.values.bio,
				checked: filtersForm.values.checked,
				credibility: filtersForm.values.credibility,
				engagementRate: filtersForm.values.engagementRate,
				growthRate: filtersForm.values.growthRate,
				hashtags: filtersForm.values.hashtags,
				identifier: filtersForm.values.identifier,
				influencerFollowers: filtersForm.values.influencerFollowers,
				influencerGender: filtersForm.values.influencerGender,
				influencerInterests: filtersForm.values.influencerInterests,
				influencerLanguage: filtersForm.values.influencerLanguage,
				influencerLocation: filtersForm.values.influencerLocation,
				keywords: filtersForm.values.keywords,
				lastposted: filtersForm.values.lastposted,
				mentions: filtersForm.values.mentions,
				relevance: filtersForm.values.relevance,
				views: filtersForm.values.views,
			},
		});
	};
	const advancedfiltersbtnClick = () => {
		recordEvent('Advanced Filters button clicked(Search)', {
			platform: filtersForm.values.platform,
		});
	};

	return (
		<Card
			hasTab
			tabButtonColor='secondary'
			className='border-0 bg-l25-secondary card_quick_search'
			activeTabIndex={ActiveTabIndex}
			onTabClick={(value) => {
				filtersForm.setValues({ ...initialFilterState, platform: value });
				dispatch({
					type: DISCOVERY_STORE_CONSTANTS.CHANGE_ACTIVE_TABS,
					payload: value,
				});
			}}>
			{Object.keys(TABS).map((key) => (
				<CardTabItem
					id={`tab-item-${TABS[key].class}`}
					title={TABS[key].title}
					icon={TABS[key].icon}
					key={TABS[key].class}
					onTabClick={(value) => {
						filtersForm.setFieldValue('platform', value);
					}}>
					<Card shadow='none' className='border-0 bg-l25-secondary py-0'>
						<CardHeader className='py-0 px-0' borderSize={1}>
							<CardLabel iconColor='info' className='py-0 px-0'>
								<CardTitle>Influencer filters</CardTitle>
								{/* <CardSubTitle>
										For best results, start with location, gender and age before adding more
										filters
									</CardSubTitle> */}
							</CardLabel>
							<CardActions className='d-flex py-0'>
								<Button
									color='danger'
									isLight
									onClick={() => {
										setIsAdvanceOpen(!isAdvanceOpen);
										advancedfiltersbtnClick();
									}}
									size='md'>
									Advanced Filters{' '}
									<Icon
										icon={isAdvanceOpen ? 'ArrowDropUp' : 'ArrowDropDown'}
										size='2x'
									/>
								</Button>
								<Button
									color='danger'
									isLight
									isDisable={isGettingMoreInfluencers || isGettingInfluencers}
									onClick={() => {
										filtersForm.setValues({
											...initialFilterState,
											platform: filtersForm.values.platform,
										});
										dispatch({
											type: DISCOVERY_STORE_CONSTANTS.CLEAR_SEARCH_FILTERS,
											// payload: value,
										});
										setIsAdvanceOpen(false);
										recordEvent('Filters Cleared(Search)', {
											filtersCleared: {
												...initialFilterState,
												platform: filtersForm.values.platform,
											},
										});
									}}>
									Clear All Filters
								</Button>
							</CardActions>
						</CardHeader>
						{
							<CardBody className='px-0 '>
								<form>
									<div className='row'>
										{(TABS[key].filters.includes('location') ||
											searchFilter?.influencerLocation) && (
											<div className='col-md-2 my-2 '>
												<FormGroup label='Influencer Location'>
													<MultiSelect
														key='async-locaiton'
														id='async-locaiton'
														labelkey='name'
														multiple={1}
														ref={refAsync}
														onChange={(e) => {
															onChangeHandle(
																'influencerLocation',
																e.map((x) => x),
															);
														}}
														defaultSelected={
															searchFilter.influencerLocation
														}
														placeholder='Where are your influencers?'
														platform={searchFilter.platform}
														usefetch={useLocationSuggestionQuery}
														// eslint-disable-next-line react/no-unstable-nested-components
														ChildrenComponent={(option) => (
															<div>
																<span>{option.title}</span>
															</div>
														)}
													/>
												</FormGroup>
											</div>
										)}
										{TABS[key].filters.includes('size') && (
											<div className='col-md-2 my-2'>
												<FormGroup
													label={`Influencer ${
														filtersForm.values.platform === 'youtube'
															? 'Subscribers'
															: 'Followers'
													}`}>
													<Dropdown className='w-100'>
														<DropdownToggle>
															<Button
																color={
																	darkModeStatus
																		? 'dark'
																		: 'light'
																}
																hoverShadow='none'
																shadow='none'
																isLight={!darkModeStatus}
																size='md'
																className='w-100'>
																{!filtersForm.values
																	.influencerFollowers?.min &&
																!filtersForm.values
																	.influencerFollowers?.max
																	? `Set ${
																			filtersForm.values
																				.platform ===
																			'youtube'
																				? 'Subscribers'
																				: 'Followers'
																	  } Range`
																	: `${nFormatter(
																			filtersForm.values
																				.influencerFollowers
																				?.min,
																			1,
																	  )} to ${nFormatter(
																			filtersForm.values
																				.influencerFollowers
																				?.max,
																			1,
																	  )} `}
															</Button>
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd>
															<DropdownItem>
																<InputGroup>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		// ref={minFollowerRef}
																		id='search'
																		isLoading={false}
																		labelKey='label'
																		minLength={0}
																		onSearch={() => {}}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			// filtersForm.setFieldValue(
																			// 	'influencerFollowers.min',
																			// 	e.map(
																			// 		(x) => x.value,
																			// 	)[0],
																			// )
																			onFollowersAndViewChange(
																				'influencerFollowers',
																				'min',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		value={
																			filtersForm.values
																				.influencerFollowers
																				?.min
																		}
																		defaultSelected={[
																			{
																				value: filtersForm
																					.values
																					.influencerFollowers
																					?.min,
																				label: `${nFormatter(
																					filtersForm
																						.values
																						.influencerFollowers
																						?.min,
																					1,
																				)}`,
																			},
																		]}
																		options={minOptions}
																		placeholder='From'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																	<InputGroupText>
																		to
																	</InputGroupText>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		id='search'
																		labelKey='label'
																		// ref={maxFollowerRef}
																		isLoading={false}
																		minLength={0}
																		onSearch={() => {}}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			// filtersForm.setFieldValue(
																			// 	'influencerFollowers.max',
																			// 	e.map(
																			// 		(x) => x.value,
																			// 	)[0],
																			// )
																			onFollowersAndViewChange(
																				'influencerFollowers',
																				'max',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		value={
																			filtersForm.values
																				.influencerFollowers
																				?.max
																		}
																		defaultSelected={[
																			{
																				value: filtersForm
																					.values
																					.influencerFollowers
																					?.max,
																				label: `${nFormatter(
																					filtersForm
																						.values
																						.influencerFollowers
																						?.max,
																					1,
																				)}`,
																			},
																		]}
																		options={maxOptions}
																		placeholder='To'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																</InputGroup>
															</DropdownItem>
															<DropdownItem isDivider />
															{rangeType.map((range, id) => (
																<DropdownItem
																	onChange={() => {
																		// filtersForm.setFieldValue(
																		// 	'influencerFollowers.min',
																		// 	range.from,
																		// );
																		// filtersForm.setFieldValue(
																		// 	'influencerFollowers.max',
																		// 	range.to,
																		// );
																		onFollowersAndViewChange(
																			'influencerFollowers',
																			'min',
																			range.from,
																		);
																		onFollowersAndViewChange(
																			'influencerFollowers',
																			'max',
																			range.to,
																		);
																		// filtersForm.setFieldValue(
																		// 	'checked',
																		// 	range.label,
																		// );
																		onChangeHandle(
																			'checked',
																			range.label,
																		);
																	}}>
																	<ChecksGroup
																		id={`f-${id}`}
																		isInline>
																		<Checks
																			id={`influencer-size-${id}`}
																			type='checkbox'
																			label={range.label}
																			value={range.id}
																			checked={
																				filtersForm.values
																					.influencerFollowers
																					?.min ===
																					range.from &&
																				filtersForm.values
																					.influencerFollowers
																					?.max ===
																					range.to
																			}
																		/>
																	</ChecksGroup>
																</DropdownItem>
															))}
														</DropdownMenu>
													</Dropdown>
												</FormGroup>
											</div>
										)}

										{TABS[key].class === 'instagram' && (
											<div className='col-md-2 my-2'>
												<FormGroup
													label={`Influencer ${
														filtersForm.values.platform === 'instagram'
															? 'Avg reel play'
															: 'Followers'
													}`}>
													<Dropdown className='w-100'>
														<DropdownToggle>
															<Button
																color={
																	darkModeStatus
																		? 'dark'
																		: 'light'
																}
																hoverShadow='none'
																shadow='none'
																isLight={!darkModeStatus}
																size='md'
																className='w-100'>
																{!filtersForm.values
																	.influencerAvgReelPlay?.min &&
																!filtersForm.values
																	.influencerAvgReelPlay?.max
																	? `Set ${
																			filtersForm.values
																				.platform ===
																			'instagram'
																				? 'Avg Reel play'
																				: 'Followers'
																	  } Range`
																	: `${nFormatter(
																			filtersForm.values
																				.influencerAvgReelPlay
																				?.min,
																			1,
																	  )} to ${nFormatter(
																			filtersForm.values
																				.influencerAvgReelPlay
																				?.max,
																			1,
																	  )} `}
															</Button>
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd>
															<DropdownItem>
																<InputGroup>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		// ref={minFollowerRef}
																		id='search'
																		isLoading={false}
																		labelKey='label'
																		minLength={0}
																		onSearch={() => {}}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			onFollowersAndViewChange(
																				'influencerAvgReelPlay',
																				'min',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		value={
																			filtersForm.values
																				.influencerAvgReelPlay
																				?.min
																		}
																		defaultSelected={[
																			{
																				value: filtersForm
																					.values
																					.influencerAvgReelPlay
																					?.min,
																				label: `${nFormatter(
																					filtersForm
																						.values
																						.influencerAvgReelPlay
																						?.min,
																					1,
																				)}`,
																			},
																		]}
																		options={minOptions}
																		placeholder='From'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																	<InputGroupText>
																		to
																	</InputGroupText>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		id='search'
																		labelKey='label'
																		// ref={maxFollowerRef}
																		isLoading={false}
																		minLength={0}
																		onSearch={() => {}}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			// filtersForm.setFieldValue(
																			// 	'influencerFollowers.max',
																			// 	e.map(
																			// 		(x) => x.value,
																			// 	)[0],
																			// )
																			onFollowersAndViewChange(
																				'influencerAvgReelPlay',
																				'max',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		value={
																			filtersForm.values
																				.influencerAvgReelPlay
																				?.max
																		}
																		defaultSelected={[
																			{
																				value: filtersForm
																					.values
																					.influencerAvgReelPlay
																					?.max,
																				label: `${nFormatter(
																					filtersForm
																						.values
																						.influencerAvgReelPlay
																						?.max,
																					1,
																				)}`,
																			},
																		]}
																		options={maxOptions}
																		placeholder='To'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																</InputGroup>
															</DropdownItem>
															<DropdownItem isDivider />
															{rangeType.map((range, id) => (
																<DropdownItem
																	onChange={() => {
																		// filtersForm.setFieldValue(
																		// 	'influencerFollowers.min',
																		// 	range.from,
																		// );
																		// filtersForm.setFieldValue(
																		// 	'influencerFollowers.max',
																		// 	range.to,
																		// );
																		onFollowersAndViewChange(
																			'influencerAvgReelPlay',
																			'min',
																			range.from,
																		);
																		onFollowersAndViewChange(
																			'influencerAvgReelPlay',
																			'max',
																			range.to,
																		);
																		// filtersForm.setFieldValue(
																		// 	'checked',
																		// 	range.label,
																		// );
																		onChangeHandle(
																			'checked',
																			range.label,
																		);
																	}}>
																	<ChecksGroup
																		id={`f-${id}`}
																		isInline>
																		<Checks
																			id={`influencer-size-${id}`}
																			type='checkbox'
																			label={range.label}
																			value={range.id}
																			checked={
																				filtersForm.values
																					.influencerAvgReelPlay
																					?.min ===
																					range.from &&
																				filtersForm.values
																					.influencerAvgReelPlay
																					?.max ===
																					range.to
																			}
																		/>
																	</ChecksGroup>
																</DropdownItem>
															))}
														</DropdownMenu>
													</Dropdown>
												</FormGroup>
											</div>
										)}
										<div className='col-md-2 my-2'>
											<FormGroup label='Influencer Gender' className='col-12'>
												<CustomRadioButton
													radioButtons={Gender}
													id='infulencer-gender'
													changed={(event) =>
														// filtersForm.setFieldValue(
														// 	'influencerGender',
														// 	event.target.value,
														// )
														onChangeHandle(
															'influencerGender',
															event.target.value,
														)
													}
													isSelected={
														filtersForm.values.influencerGender || 'ANY'
													}
												/>
											</FormGroup>
										</div>
										{TABS[key].class === 'instagram' && (
											<div className='col-md-2 my-2'>
												<FormGroup
													label='Influencer Interests'
													className='col-12'>
													<MultiSelect
														key='async-interests'
														id='async-interests'
														labelkey='name'
														multiple={1}
														ref={refAsync}
														onChange={(e) => {
															onChangeHandle(
																'influencerInterests',
																e.map((x) => x),
															);
														}}
														defaultSelected={
															filtersForm.values.influencerInterests
														}
														placeholder='Any'
														platform={searchFilter.platform}
														usefetch={useInterestSuggestionQuery}
														// eslint-disable-next-line react/no-unstable-nested-components
														ChildrenComponent={(option) => (
															<div>
																<span>{option.name}</span>
															</div>
														)}
													/>
												</FormGroup>
											</div>
										)}
										{TABS[key].filters.includes('views per video') && (
											<div className='col-md-2 my-2'>
												<FormGroup label='Views per video'>
													<Dropdown className='w-100'>
														<DropdownToggle>
															<Button
																color={
																	darkModeStatus
																		? 'dark'
																		: 'light'
																}
																hoverShadow='none'
																shadow='none'
																isLight={!darkModeStatus}
																size='md'
																className='w-100'>
																{!filtersForm.values.views?.min &&
																!filtersForm.values.views?.max
																	? `Views Range`
																	: `${nFormatter(
																			filtersForm.values.views
																				?.min,
																			1,
																	  )} to ${nFormatter(
																			filtersForm.values.views
																				?.max,
																			1,
																	  )} `}
															</Button>
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd>
															<DropdownItem>
																<InputGroup>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		isLoading={false}
																		id='views.min'
																		// ref={minViewRef}
																		onSearch={() => {}}
																		labelKey='label'
																		minLength={0}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			// filtersForm.setFieldValue(
																			// 	'views.min',
																			// 	e.map(
																			// 		(x) => x.value,
																			// 	)[0],
																			// )
																			onFollowersAndViewChange(
																				'views',
																				'min',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		options={minOptions}
																		placeholder='From'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																	<InputGroupText>
																		to
																	</InputGroupText>
																	<AsyncTypeahead
																		className='border-0 shadow-none bg-transparent w-100'
																		// filterBy={filterBy}
																		id='views.max'
																		// ref={maxViewRef}
																		isLoading={false}
																		labelKey='label'
																		onSearch={() => {}}
																		minLength={0}
																		ignoreDiacritics={false}
																		onChange={(e) =>
																			// filtersForm.setFieldValue(
																			// 	'views.max',
																			// 	e.map(
																			// 		(x) => x.value,
																			// 	)[0],
																			// )
																			onFollowersAndViewChange(
																				'views',
																				'max',
																				e.map(
																					(x) => x.value,
																				)[0],
																			)
																		}
																		options={maxOptions}
																		placeholder='To'
																		renderMenuItemChildren={(
																			option,
																			props,
																		) => (
																			<div>
																				<span>
																					{option.label}
																				</span>
																			</div>
																		)}
																	/>
																</InputGroup>
															</DropdownItem>
															<DropdownItem isDivider />
															{viewRangeType.map((range, id) => (
																<DropdownItem
																	onChange={() => {
																		// filtersForm.setFieldValue(
																		// 	'views.min',
																		// 	range.from,
																		// );
																		// filtersForm.setFieldValue(
																		// 	'views.max',
																		// 	range.to,
																		// );
																		onFollowersAndViewChange(
																			'views',
																			'min',
																			range.from,
																		);
																		onFollowersAndViewChange(
																			'views',
																			'max',
																			range.to,
																		);
																		// filtersForm.setFieldValue(
																		// 	'checked',
																		// 	range.label,
																		// );
																		onChangeHandle(
																			'checked',
																			range.label,
																		);
																	}}>
																	<ChecksGroup
																		id={`${id}`}
																		isInline>
																		<Checks
																			id={`views-per-${id}`}
																			type='checkbox'
																			label={range.label}
																			value={range.id}
																			// onChange={() => {
																			// 	filtersForm.setFieldValue(
																			// 		'views.min',
																			// 		range.from,
																			// 	);
																			// 	filtersForm.setFieldValue(
																			// 		'views.max',
																			// 		range.to,
																			// 	);
																			// 	filtersForm.setFieldValue(
																			// 		'checked',
																			// 		range.label,
																			// 	);
																			// }}
																			checked={
																				filtersForm.values
																					.views?.min ===
																					range.from &&
																				filtersForm.values
																					.views?.max ===
																					range.to
																			}
																		/>
																	</ChecksGroup>
																</DropdownItem>
															))}
														</DropdownMenu>
													</Dropdown>
												</FormGroup>
											</div>
										)}
										{/* {TABS[key].filters.includes('average views') && (
									<div className='col-md-2 my-2'>
										<FormGroup label='Average views' className='col-12'>
											<InputGroup>
												<Input
													id='minViews'
													ariaLabel='Minimum views'
													placeholder='Min.'
												// onChange={formik.handleChange}
												// value={formik.values.minPrice}
												/>
												<InputGroupText>to</InputGroupText>
												<Input
													id='maxViews'
													ariaLabel='Maximum views'
													placeholder='Max.'
												// onChange={formik.handleChange}
												// value={formik.values.maxPrice}
												/>
											</InputGroup>
										</FormGroup>
									</div>
								)} */}

										<div className='col-md-2 my-2'>
											<FormGroup label='Engagement Rate'>
												<Popovers
													desc="This is an indication of how engaged the influencer's audience is.It is calculated as likes,comments÷followers.The average Engagement Rate is 2%."
													trigger='hover'>
													<Icon
														icon='Info'
														size='lg'
														style={{
															marginLeft: '3px',
															fontSize: '1.55rem',
														}}
													/>
												</Popovers>
												<MultiSelect
													key='async-er'
													id='async-er'
													labelkey='label'
													multiple={0}
													isStatic={1}
													staticOpt={ERValue}
													onChange={(e) => {
														onChangeHandle(
															'engagementRate',
															e.map((x) => x)[0],
														);
													}}
													defaultSelected={defaultSelectedValue(
														filtersForm.values.engagementRate,
													)}
													placeholder='ER%'
													platform={searchFilter.platform}
													usefetch={useLocationSuggestionQuery}
													// eslint-disable-next-line react/no-unstable-nested-components
													ChildrenComponent={(option) => (
														<div>
															<span>{option.label}</span>
														</div>
													)}
												/>
											</FormGroup>
										</div>

										{isAdvanceOpen && (
											<>
												{/* <div className='col-md-2 my-2'>
													<FormGroup label='Last post' className='col-12'>
														<Input
															id='lastposted'
															name='lastposted'
															type='number'
															onChange={filtersForm.handleChange}
															value={filtersForm.values.lastposted}
															placeholder='Last date of post'
															min={30}
														/>
													</FormGroup>
												</div> */}
												<div className='col-md-2 my-2'>
													<FormGroup label='Language' className='col-12'>
														<MultiSelect
															key='async-language'
															id='async-language'
															labelkey='name'
															onChange={(e) => {
																onChangeHandle(
																	'influencerLanguage',
																	e.map((x) => x)[0],
																);
															}}
															defaultSelected={defaultSelectedValue(
																filtersForm.values
																	.influencerLanguage,
															)}
															placeholder='Language of Your Influencers?'
															platform={searchFilter.platform}
															usefetch={useLanguageSuggestionQuery}
															// eslint-disable-next-line react/no-unstable-nested-components
															ChildrenComponent={(option) => (
																<div>
																	<span>{option.name}</span>
																</div>
															)}
														/>
													</FormGroup>
												</div>

												{/* <div className='col-md-2 my-2'>
													<FormGroup label='Growth Rate' className='col-12'>
														<AsyncTypeahead
															className='border-0 shadow-none bg-transparent w-100'
															filterBy={filterBy}
															id='async-growth'
															minLength={0}
															onChange={(e) =>
																filtersForm.setFieldValue(
																	'growthRate',
																	e.map((x) => x)[0],
																)
															}
															defaultSelected={defaultSelectedValue(filtersForm.values.growthRate)}
															options={growthMonthOptions}
															placeholder='Interval'
															useCache={false}
															renderMenuItemChildren={(option, props) => (
																<div>
																	<span>{option}</span>
																</div>
															)}
														/>
													</FormGroup>
												</div> */}

												{TABS[key].filters.includes('bio') && (
													<div className='col-md-2 my-2'>
														<FormGroup label='Bio' className='col-12'>
															<Popovers
																desc='Identify influencers by keywords within their bio description.'
																trigger='hover'>
																<Icon
																	icon='Info'
																	size='lg'
																	style={{
																		marginLeft: '3px',
																		fontSize: '1.55rem',
																	}}
																/>
															</Popovers>

															<Input
																id='Bio'
																name='bio'
																type='text'
																value={filtersForm.values.bio}
																onChange={(e) => {
																	onChangeHandle(
																		'bio',
																		e.target.value,
																	);
																}}
																placeholder='Bio of Influencer'
															/>
														</FormGroup>
													</div>
												)}

												{/* <div className='col-md-2 my-2'>
													<FormGroup label='Account type' className='col-12'>
														<AsyncTypeahead
															multiple
															className='border-0 shadow-none bg-transparent w-100'
															id='account-type'
															isLoading={false}
															labelKey='label'
															minLength={0}
															onSearch={() => { }}
															ignoreDiacritics={false}
															onChange={(e) =>
																filtersForm.setFieldValue(
																	'accountType',
																	e.map((x) => x),
																)
															}
															options={accountTypes}
															defaultSelected={filtersForm.values.accountType}
															placeholder='Account type'
															renderMenuItemChildren={(option, props) => (
																<div>
																	<span>{option.label}</span>
																</div>
															)}
														/>
													</FormGroup>
												</div> */}

												{TABS[key].filters.includes('keywords') && (
													<div className='col-md-2 my-2'>
														<FormGroup
															label='Keywords'
															className='col-12'>
															<Popovers
																desc='Identify influencers by a phrase they use when speaking in their videos.'
																trigger='hover'>
																<Icon
																	icon='Info'
																	size='lg'
																	style={{
																		marginLeft: '3px',
																		fontSize: '1.55rem',
																	}}
																/>
															</Popovers>

															<Input
																id='Keywords'
																name='keywords'
																type='text'
																value={filtersForm.values.keywords}
																onChange={(e) => {
																	onChangeHandle(
																		'keywords',
																		e.target.value,
																	);
																}}
																placeholder='keywords'
															/>
														</FormGroup>
													</div>
												)}
												{TABS[key].filters.includes('hashtags') && (
													<div className='col-md-2 my-2'>
														<FormGroup
															label='HashTags'
															className='col-12'>
															<Popovers
																desc='Search for influencers who have posted with a specific #hashtag in their content.'
																trigger='hover'>
																<Icon
																	icon='Info'
																	size='lg'
																	style={{
																		marginLeft: '3px',
																		fontSize: '1.55rem',
																	}}
																/>
															</Popovers>
															<MultiSelect
																key='async-hashtags'
																id='async-hashtags'
																multiple={1}
																ref={refAsync}
																onChange={(e) => {
																	onChangeHandle(
																		'hashtags',
																		e.map((x) => x),
																	);
																}}
																minLength={3}
																defaultSelected={
																	filtersForm.values.hashtags
																}
																placeholder='#hashtags'
																isQueryOption={1}
																defaultOpt={(query) => [
																	`#${query}`,
																]}
																platform={searchFilter.platform}
																usefetch={useHashTagSuggestionQuery}
																// eslint-disable-next-line react/no-unstable-nested-components
																ChildrenComponent={(option) => (
																	<div>
																		<span>{option}</span>
																	</div>
																)}
															/>
														</FormGroup>
													</div>
												)}
												{TABS[key].filters.includes('mentions') && (
													<div className='col-md-2 my-2'>
														<FormGroup
															label='Mentions'
															className='col-12'>
															<Popovers
																desc='Search for influencers who have tagged or mentioned a @username in their content'
																trigger='hover'>
																<Icon
																	icon='Info'
																	size='lg'
																	style={{
																		marginLeft: '3px',
																		fontSize: '1.55rem',
																	}}
																/>
															</Popovers>
															<MultiSelect
																key='async-mentions'
																id='async-mentions'
																labelkey='username'
																multiple={1}
																ref={refAsync}
																onChange={(e) => {
																	onChangeHandle(
																		'mentions',
																		e.map((x) => x),
																	);
																}}
																defaultSelected={
																	filtersForm.values.mentions
																}
																placeholder='@username'
																platform={searchFilter.platform}
																usefetch={
																	useInfluencersSuggestionQuery
																}
																// eslint-disable-next-line react/no-unstable-nested-components
																ChildrenComponent={(option) => (
																	<div>
																		<span>
																			@{option.username}
																		</span>
																	</div>
																)}
															/>
														</FormGroup>
													</div>
												)}
											</>
										)}
									</div>

									{isAdvanceOpen && (
										<div className='row my-3'>
											<CardLabel iconColor='info' className='mb-4'>
												<CardTitle>Audience filters</CardTitle>
												{/* <CardSubTitle>
										For best results, start with location, gender and age before adding more
										filters
									</CardSubTitle> */}
											</CardLabel>
											<div className='col-md-2 my-2 mb-3'>
												<FormGroup
													label='Audience Location'
													className='col-12'>
													<MultiSelect
														key='async-au-locations'
														id='async-au-locations'
														labelkey='name'
														multiple={1}
														onChange={(e) => {
															onChangeHandle(
																'audienceLocation',
																e.map((x) => x),
															);
														}}
														defaultSelected={
															filtersForm.values.audienceLocation
														}
														placeholder='Location of your Audience'
														platform={searchFilter.platform}
														usefetch={useLocationSuggestionQuery}
														// eslint-disable-next-line react/no-unstable-nested-components
														ChildrenComponent={(option) => (
															<div>
																<span>{option.title}</span>
															</div>
														)}
													/>
												</FormGroup>
											</div>
											<div className='col-md-2 my-2'>
												<FormGroup
													label='Audience Gender'
													className='col-12'>
													{/* <Popovers
														desc='To narrow down your search results,we recommend starting with the default percentage options and then gradually changing them to find the best matching influencers for you,e.g finding influencers whose audience is ≥50% in Mumbai and ≥80% female.'
														trigger='hover'>
														<Icon
															icon='Info'
															size='lg'
															style={{
																marginLeft: '3px',
																fontSize: '1.55rem',
															}}
														/>
													</Popovers> */}
													<CustomRadioButton
														radioButtons={[
															{
																id: 'audience-gender-male',
																label: 'Male',
																value: 'MALE',
															},
															{
																id: 'audience-gender-female',
																label: 'Female',
																value: 'FEMALE',
															},
															{
																id: 'audience-gender-any',
																label: 'Any',
																value: 'ANY',
															},
														]}
														id='audience-gender'
														changed={(event) =>
															// filtersForm.setFieldValue(
															// 	'audienceGender',
															// 	event.target.value,
															// )
															onChangeHandle(
																'audienceGender',
																event.target.value,
															)
														}
														isSelected={
															filtersForm.values.audienceGender ||
															'ANY'
														}
													/>
												</FormGroup>
											</div>
											<div className='col-md-2 my-2 mb-3'>
												<FormGroup label='Age' className='col-12'>
													{/* <Popovers
														desc='To narrow down your search results,we recommend starting with the default percentage options and then gradually changing them to find the best matching influencers for you,e.g finding influencers whose audience is ≥50% in Mumbai and ≥80% female.'
														trigger='hover'>
														<Icon
															icon='Info'
															size='lg'
															style={{
																marginLeft: '3px',
																fontSize: '1.55rem',
															}}
														/>
													</Popovers> */}
													<MultiSelect
														key='audience-age'
														id='audience-age'
														labelkey='label'
														multiple={1}
														isStatic={1}
														staticOpt={ageOption}
														onChange={(e) => {
															onChangeHandle(
																'audienceAge',
																e.map((x) => x),
															);
														}}
														defaultSelected={
															filtersForm.values.audienceAge
														}
														placeholder='Select Age'
														platform={searchFilter.platform}
														// eslint-disable-next-line react/no-unstable-nested-components
														ChildrenComponent={(option) => (
															<div>
																<span>{option.label}</span>
															</div>
														)}
													/>
												</FormGroup>
											</div>
											{TABS[key].class === 'instagram' && (
												<div className='col-md-2 my-2 mb-3'>
													<FormGroup label='Interests' className='col-12'>
														{/* <Popovers
															desc='To narrow down your search results,we recommend starting with the default percentage options and then gradually changing them to find the best matching influencers for you,e.g finding influencers whose audience is ≥50% in Mumbai and ≥80% female.'
															trigger='hover'>
															<Icon
																icon='Info'
																size='lg'
																style={{
																	marginLeft: '3px',
																	fontSize: '1.55rem',
																}}
															/>
														</Popovers> */}
														<MultiSelect
															key='async-audience-interests'
															id='async-audience-interests'
															labelkey='name'
															multiple={1}
															onChange={(e) => {
																onChangeHandle(
																	'audienceInterests',
																	e.map((x) => x),
																);
															}}
															defaultSelected={
																filtersForm.values.audienceInterests
															}
															placeholder='Any'
															platform={searchFilter.platform}
															usefetch={useInterestSuggestionQuery}
															// eslint-disable-next-line react/no-unstable-nested-components
															ChildrenComponent={(option) => (
																<div>
																	<span>{option.name}</span>
																</div>
															)}
														/>
													</FormGroup>
												</div>
											)}
											<div className='col-md-2 my-2 mb-3'>
												<FormGroup label='Language' className='col-12'>
													<MultiSelect
														key='async-audience-language'
														id='async-audience-language'
														labelkey='name'
														onChange={(e) => {
															onChangeHandle(
																'audienceLanguage',
																e.map((x) => x)[0],
															);
														}}
														defaultSelected={defaultSelectedValue(
															filtersForm.values.audienceLanguage,
														)}
														placeholder='Language of Your Audience?'
														platform={searchFilter.platform}
														usefetch={useLanguageSuggestionQuery}
														// eslint-disable-next-line react/no-unstable-nested-components
														ChildrenComponent={(option) => (
															<div>
																<span>{option.name}</span>
															</div>
														)}
													/>
												</FormGroup>
											</div>

											{TABS[key].class === 'instagram' && (
												<div className='col-md-2 my-2'>
													<FormGroup
														label='Fake followers'
														className='col-12'>
														<Popovers
															desc='This indicates the percentage of fake followers an influencer has with their profile. More than 25% is typically a sign of fraud.'
															trigger='hover'>
															<Icon
																icon='Info'
																size='lg'
																style={{
																	marginLeft: '3px',
																	fontSize: '1.55rem',
																}}
															/>
														</Popovers>
														<MultiSelect
															key='audience-credibility'
															id='audience-credibility'
															labelkey='label'
															multiple={0}
															isStatic={1}
															minLength={0}
															staticOpt={credibilityOptions}
															onChange={(e) => {
																onChangeHandle(
																	'credibility',
																	e.map((x) => x)[0],
																);
															}}
															defaultSelected={defaultSelectedValue(
																filtersForm.values.credibility,
															)}
															placeholder='Fake followers'
															platform={searchFilter.platform}
															// eslint-disable-next-line react/no-unstable-nested-components
															ChildrenComponent={(option) => (
																<div>
																	<span>{option.label}</span>
																</div>
															)}
														/>
													</FormGroup>
												</div>
											)}
										</div>
									)}
								</form>
							</CardBody>
						}
						<CardFooter className='px-0 pb-0 bg-l25-secondary'>
							<CardFooterRight>
								{searchResult && searchResult?.pages[0]?.searchId && (
									<SaveFilters searchId={searchResult?.pages[0]?.searchId} />
								)}
								<Button
									color='danger'
									isLight
									isDisable={isGettingMoreInfluencers || isGettingInfluencers}
									onClick={() => {
										filtersForm.setFieldValue('Search', false);
										setSearchLoader(true);
										advancedsearchbtnClick();
										filtersForm.handleSubmit();
									}}
									size='md'>
									{(isGettingMoreInfluencers || isGettingInfluencers) &&
									searchLoader ? (
										' Please Wait ... '
									) : (
										<>
											Find Influencers : <Badge>0.5</Badge>
										</>
									)}
								</Button>
							</CardFooterRight>
						</CardFooter>
					</Card>
				</CardTabItem>
			))}
		</Card>
	);
};

export default QuickSearch;
