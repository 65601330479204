import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Button from '../../../bootstrap/Button';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import { CRMCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';

const CreatorDropdown = ({ selectedCreator, handleSelect, handleDeSelect, showEmail }) => {
	const creator = useSelector(CRMCreator);
	const dropDownref = useRef(null);

	const [showDropdown, setShowDropDown] = useState(false);

	const searchFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			search: '',
		},
	});

	const filterdCreator = creator?.filter(({ email, fullName, phone }) => {
		let include;
		if (showEmail) {
			include =
				email &&
				(email.includes(searchFormik.values.search) ||
					fullName.toLowerCase().includes(searchFormik.values.search.toLowerCase()));
		} else {
			include =
				phone && fullName.toLowerCase().includes(searchFormik.values.search.toLowerCase());
		}
		return include;
	});

	useEffect(() => {
		const outSideClickHnalder = (e) => {
			if (!dropDownref.current.contains(e.target)) {
				setShowDropDown(false);
			}
		};
		document.addEventListener('mousedown', outSideClickHnalder);

		return () => document.removeEventListener('mousedown', outSideClickHnalder);
	}, []);

	return (
		<div className='dropdown' ref={dropDownref}>
			<FormGroup label='To' id='search'>
				<Input
					label='To'
					placeholder='Search Creator'
					onChange={searchFormik.handleChange}
					value={searchFormik.values.search}
					onFocus={() => setShowDropDown(true)}
					autoComplete='off'
				/>
			</FormGroup>
			<div className='selected_creator'>
				{selectedCreator?.length
					? selectedCreator?.map((creatorDetail, i) => (
							<div className='creator'>
								<div className='detail'>{creatorDetail}</div>
								<div
									className='cross'
									role='presentation'
									onClick={() => {
										handleDeSelect(i);
									}}>
									x
								</div>
							</div>
					  ))
					: null}
			</div>
			{showDropdown && (
				<div className='list'>
					{filterdCreator?.map((influencerInfo) => {
						return (
							<Button
								className='dropdown_item'
								onClick={(e) => {
									handleSelect(influencerInfo);
								}}>
								{showEmail && <span>{influencerInfo.email}</span>}{' '}
								<span>{influencerInfo.fullName}</span>
							</Button>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default CreatorDropdown;
