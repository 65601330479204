import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { CONTROL_PANEL_STORE_CONSTANTS } from '../../../store/constants.store';
import { selectIsWorkspaceUpdate } from '../../../store/App/ControlPanel/controlPanel.selector';
import { selectWorkspace } from '../../../store/App/Workspace/workspace.selector';

export const fetchMenu = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { data } = await http.get(`${API_ENDPOINTS.GET_MENU}`);
	return data.data || [];
};

export const useGetMenu = (options) => {
	const dispatch = useDispatch();
	const isWorkspaceUpdate = useSelector(selectIsWorkspaceUpdate);
	const workspace = useSelector(selectWorkspace);
	const onSuccess = (data) => {
		dispatch({
			type: CONTROL_PANEL_STORE_CONSTANTS.GET_MENU,
			payload: data,
		});
	};
	return useQuery([API_ENDPOINTS.GET_MENU, options, isWorkspaceUpdate], fetchMenu, {
		onSuccess,
		enabled: !!workspace
	});
};
