import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';

async function generateApp(input) {
	const { data } = await http.post(API_ENDPOINTS.GENERATE_APP, input);
	return data;
}

const useGenerateApp = () => {
	const { addToast } = useToasts();
	return useMutation((input) => generateApp(input), {
		onSuccess: (res) => {
			if (res.success) {
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
		},
	});
};
export default useGenerateApp;
