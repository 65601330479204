import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { successToast, dangerToast } from '../../../helpers/toast-helper';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function createList(input) {
	const res = await http.post(`${API_ENDPOINTS.CREATE_BUCKET}`, input);
	return res.data;
}

const useCreateListMutation = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch()
	return useMutation((input) => createList(input), {
		onSuccess: (data, input) => {
			// const resp = data.data
			addToast(successToast({ message: data?.message }), {
				autoDismiss: true,
			})
			dispatch({
				type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
				payload: `LIST/Crate-list/${new Date()}`
			});
			recordEvent(`Created List(List)`, {
				input,
				output:data
			});
		},
		onError: (err, input) => {
			console.log(
				'🚀 ~ file: get-filtered-influencers.js ~ line 21 ~ returnuseMutation ~ err',
				err,
			);
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to create list(CRM)`, {
				input,
				output:err?.response?.data
			});
		},
	});
};

export default useCreateListMutation;
