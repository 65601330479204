export const setToken = (token) => {
	if (typeof window === 'undefined') {
		return null;
	}
	return localStorage.setItem('auth_token', token);
};

export const getToken = () => {
	if (typeof window === 'undefined') {
		return null;
	}
	const token = localStorage.getItem('auth_token');
	return token;
};

export const deleteToken = () => {
	if (typeof window === 'undefined') {
		return null;
	}

	return localStorage.removeItem('auth_token');
};

export const setWorkspace = (workspace) => {
	if (typeof window === 'undefined') {
		return null;
	}

	return localStorage.setItem('icc_workspace', workspace);
};

export const getWorkspace = () => {
	if (typeof window === 'undefined') {
		return null;
	}
	const token = localStorage.getItem('icc_workspace');
	return token;
};

export const removeWorkspace = () => {
	if (typeof window === 'undefined') {
		return null;
	}
	return localStorage.removeItem('icc_workspace');
};
