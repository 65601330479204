import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';

import { addDays } from 'date-fns';
import Button from '../../bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import useRemoveInfluencerFromListMutation from '../../../framework/basic-rest/discovery/remove-influencer-from-list';
import FormGroup from '../../bootstrap/forms/FormGroup';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { NoListState } from '../Common/EmptyStates';
import Spinner from '../../bootstrap/Spinner';
import handlers from '../../../pages/discovery/FiltersHelper';
import useGetProjectsQuery from '../../../framework/basic-rest/discovery/get-projects';
import useAnalyticsMutation from '../../../framework/basic-rest/analytics/create-analytics-project';
import useAnalyticsInfluencersMutation from '../../../framework/basic-rest/discovery/use-analytics-influencer';
import { ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import { selectProjects } from '../../../store/App/SocialAnalytics/Project/project.selector';
import { TrackingActions } from '../../../framework/enums/Tracking';
import useRemoveInfluencerFromProject from '../../../framework/basic-rest/discovery/delete-influencer-project';
import API_ENDPOINTS from '../../../framework/basic-rest/utils/api-endpoints';

const AddToAnalytics = ({
	influencerId,
	influencerGlobalIndex,
	existingProject,
	direction = 'down',
}) => {
	const dispatch = useDispatch();
	const {
		isLoading: isProjectLoading,
		data: projectsData,
		refetch: refetchAllProjects,
	} = useGetProjectsQuery();

	useEffect(() => {
		if (!isProjectLoading && projectsData && projectsData.success) {
			dispatch({
				type: ANALYTICS_STORE_CONSTANTS.SET_PROJECT_DATA,
				payload: projectsData,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isProjectLoading, projectsData]);

	const projectData = useSelector(selectProjects);

	const [projectMenu, setProjectMenu] = useState(false);
	const {
		mutate: createAnalyticsProject,
		isLoading: creatingAnalyticsProject,
		data: projectResponse,
	} = useAnalyticsMutation();

	const { mutate: removeInfluencer, isLoading: isRemoving } = useRemoveInfluencerFromProject();

	const projectForm = useFormik({
		initialValues: {
			projectName: '',
		},
		onSubmit: (values) => {
			const body = {
				projectName: values.projectName,
				startDate: new Date(),
				endDate: addDays(new Date(), 7),
			};
			createAnalyticsProject(body);
			// refetchAllProjects();
		},
	});

	useEffect(() => {
		if (!creatingAnalyticsProject && projectResponse) refetchAllProjects();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creatingAnalyticsProject, projectResponse]);

	const {
		mutate: createAnalyticsProjectInfluencers,
		isLoading: creatingAnalyticsProjectInfluencers,
	} = useAnalyticsInfluencersMutation();

	const addInfluencers = async (body) => {
		createAnalyticsProjectInfluencers(body);
	};

	const selectedProjectForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			selectedProject: existingProject || [],
		},
	});

	const handleChange = (e) => {
		const { checked, value, name } = e.target;
		if (checked) {
			const body = {
				projects: [value],
				influencers: [{ influencerGlobalIndex, influencerId }],
			};
			addInfluencers(body);
		} else {
			removeInfluencer({
				projectId: value,
				influencerId,
			});
		}
		selectedProjectForm.setFieldValue(
			'selectedProject',
			handlers.handleProject(e, selectedProjectForm.values.selectedProject),
		);
	};

	return (
		<Dropdown isOpen={projectMenu} setIsOpen={setProjectMenu} direction={direction}>
			<DropdownToggle>
				<Button color='danger' isLight>
					Add to analytics
				</Button>
			</DropdownToggle>
			<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
				<div className='container py-2'>
					<form>
						<div classpcommName='row g-4'>
							<div className='col-12'>
								<FormGroup id='projectName' label='Create project'>
									<InputGroup>
										<Input
											id='projectName'
											ariaLabel='Create project'
											placeholder='Enter project name'
											onChange={projectForm.handleChange}
											value={projectForm.values.projectName}
										/>
										<Button
											color='dark'
											isLight
											icon='Add'
											isDisable={projectForm.values.projectName.length <= 2}
											onClick={() => {
												projectForm.handleSubmit();
											}}
										/>
									</InputGroup>
								</FormGroup>
							</div>
							<div className='col-12 dv-list-check-group'>
								<FormGroup label='Projects'>
									{projectData && projectData.length ? (
										<ChecksGroup>
											{projectData.map((project, index) => (
												<>
													<Checks
														// eslint-disable-next-line react/no-array-index-key
														key={`project-check-${index}`}
														id={project.projectName}
														label={project.projectName}
														value={project._id}
														onChange={handleChange}
														checked={
															project
																? project.socialTrackingId
																		.map((x) => {
																			return (
																				x?.influencer
																					?.influencerProfile ===
																				influencerId
																			);
																		})
																		.includes(true)
																: false
														}
													/>
													{(creatingAnalyticsProjectInfluencers ||
														isRemoving) && <Spinner isSmall inButton />}
												</>
											))}
										</ChecksGroup>
									) : (
										<NoListState height={100} width={100} />
									)}
								</FormGroup>
							</div>
						</div>
						{!(projectData && projectData.length) && (
							<p className='text-muted text-center'>Create a project</p>
						)}
					</form>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default AddToAnalytics;
