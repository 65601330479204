import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/bootstrap/Button';
import { selectComparisonProfiles } from '../../../store/App/Discovery/discovery.selector';
import { DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';

const AddToComparison = ({ influencer, platform }) => {
	const dispatch = useDispatch();
	const comparisonProfile = useSelector(selectComparisonProfiles);

	const ComparisonInfluencer = () => {
		if (
			comparisonProfile.length < 3 ||
			comparisonProfile.some((info) => info.influencer.userId === influencer.userId)
		) {
			dispatch({
				type: DISCOVERY_STORE_CONSTANTS.SET_COMPARISON_PROFILE,
				payload: {
					influencer,
					platform,
				},
			});
		}
	};

	return (
		<div>
			<Button
				color='danger'
				isLight
				icon='AddChart'
				onClick={ComparisonInfluencer}
				disabled={
					comparisonProfile.length > 2 &&
					!comparisonProfile.some((info) => info.influencer.userId === influencer.userId)
				}>
				{comparisonProfile.some((info) => info.influencer.userId === influencer.userId) ? 'Remove' : 'Add'}{' '}
				Comparison
			</Button>
		</div>
	);
};

export default AddToComparison;
