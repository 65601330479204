import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useRemoveInfluencerFromListMutation from '../../../framework/basic-rest/discovery/remove-influencer-from-list';
import useAddInfluencerToListMutation from '../../../framework/basic-rest/list/add-to-list';
import { useFetchAllListNames } from '../../../framework/basic-rest/list/get-all-list-names';
import { checkArrayData } from '../../../helpers/helpers';
import { lists, selectFavouriteList } from '../../../store/App/List/list.selector';
import Button from '../../bootstrap/Button';
import Spinner from '../../bootstrap/Spinner';
import Tooltips from '../../bootstrap/Tooltips';
import recordEvent from '../../../framework/basic-rest/mixpanel/mixpanel';

const AddToFavourite = ({ _id, influencerUserName }) => {
	const [isLiked, setIsLiked] = useState(false);

	const listData = useSelector(lists);
	const favouriteList = useSelector(selectFavouriteList);

	const {
		mutate: removeInfluencer,
		isLoading: isRemoving,
		data: deletedData,
	} = useRemoveInfluencerFromListMutation();

	const {
		// data: lists,
		isLoading: isLoadingAllLists,
		refetch: refetchAllLists,
	} = useFetchAllListNames();

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listData]);

	const {
		mutate: addInfluencerToList,
		isLoading: isAddingInfluencer,
		data,
	} = useAddInfluencerToListMutation();

	const addInfluencerTolist = async (bodyDetails) => {
		recordEvent('Influencer added as favorite', {
			details: bodyDetails
		});
	};
	const removeInfluencerFromlist = async (bodydetails) => {
		recordEvent('Influencer removed as favorite', {
			details: bodydetails
		});
	};

	const onAddFavourite = () => {
		const body = {
			list_ids: [favouriteList?._id],
			influencer_ids: [_id],
		};
		addInfluencerToList(body);
		setIsLiked(true);
		const bodyDetails = {
			list_ids: [listData[0]._id],
			influencerDetails: [{ influencerId: _id, influencerUserName }],
		};
		addInfluencerTolist(bodyDetails);
	};

	const onRemoveFavourite = () => {
		removeInfluencer({
			influencer_id: _id,
			list_id: favouriteList?._id,
		});
		setIsLiked(false);
		const bodydetails = {
			list_ids: [listData[0]._id],
			influencerDetails: [{ influencerId: _id, influencerUserName }],
		};
		removeInfluencerFromlist(bodydetails);
	};

	const checkFavourite = () => {
		if (
			favouriteList
				? favouriteList.influencers
						.map((val) => {
							if (val?.influencer_id === _id) {
								return true;
							}
							return false;
						})
						.includes(true)
				: false
		) {
			onRemoveFavourite();
		} else {
			onAddFavourite();
		}
	};

	return (
		favouriteList && <Tooltips title='Add to favourite' placement='top'>
			{!isAddingInfluencer && !isRemoving ? (
				<Button
					icon='CustomHeart'
					className={`btn_heart ${
						(favouriteList
							? favouriteList.influencers
									.map((val) => {
										if (val?.influencer_id === _id) {
											return true;
										}
										return false;
									})
									.includes(true)
							: false) && 'liked'
					}`}
					shadow='none'
					onClick={() => {
						checkFavourite();
					}}
					hoverShadow='none'
					aria-label='Add to favourite'
				/>
			) : (
				<Spinner isSmall inButton />
			)}
		</Tooltips>
	);
};

export default AddToFavourite;
