import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import recordEvent from '../../mixpanel/mixpanel';
import { CRM_CREATORS_STORE_CONSTANTS } from '../../../../store/constants.store';

async function updateCreator(input) {
	const { data } = await http.post(`${API_ENDPOINTS.UPDATE_CRM_CREATOR}`, input);
	return data;
}

const useUpdateCreatorMutation = () => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation((input) => updateCreator(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				queryClient.invalidateQueries(API_ENDPOINTS.GET_ALL_CRM_CREATORS);
				dispatch({
					type: CRM_CREATORS_STORE_CONSTANTS.IS_UPDATE_CREATOR,
					payload: `/Creator/Update/${new Date()}`,
				});

				addToast(successToast({ message: 'Creator Updated' }), {
					autoDismiss: true,
				});
				recordEvent(`Creator Updated(CRM)`, {
					input,
					output:res
				});
			} else {
				addToast(dangerToast({ message: 'Not able to Update Creator' }), {
					autoDismiss: true,
				});
				recordEvent('Not able to Update Creator', {
					input,
					output:res
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({message:err.response.data.errorMessage}), {
				autoDismiss: true,
			});
			recordEvent('Not able to Update Creator(CRM)', {
				input,
				output:err
			});
		},
	});
};
export default useUpdateCreatorMutation;
