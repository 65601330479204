// Helper Functions

import { FirstLetterCaps, fixDecimal, nFormatter } from '../../../../../helpers/helpers';

export const csvInfluencer = (influencers) => {
	const data = influencers.map((influencer) => ({
		username: influencer?.influencerData?.profile?.username,
		fullname: influencer?.influencerData?.profile?.fullname,
		influencerType: influencer.influencerType,
		followers: nFormatter(influencer?.influencerData?.profile?.followers, 1),
		engagementRate: fixDecimal(
			(influencer?.influencerData?.profile?.engagementRate || 0) * 100,
			2,
		),
		estimatedReach: nFormatter(
			(influencer?.influencerData?.profile?.followers || 0) *
				(influencer?.influencerData?.profile?.engagementRate || 0) *
				10,
			1,
		),
		influencerLists: influencer.lists
			.map((i) => {
				return i.listName;
			})
			.toString(),
		ageGroup: influencer?.influencerData?.ageGroup || 'report unopened',
		avgViews: influencer?.influencerData?.avgViews || 'report unopened',
		avgLikes: influencer.influencerData.avgLikes || 'report unopened',
		avgComments: influencer.influencerData.avgComments || 'report unopened',
		gender: influencer.influencerData.gender || 'report unopened',
		interests:
			influencer.influencerData.interests?.map((interest) => {
				return interest.name;
			}) || 'report unopened',
		posts: influencer.influencerData.postsCount || 'report unopened',
		gender_split: influencer.influencerData?.audience
			? influencer.influencerData?.audience?.genders.map((gender) => {
					return `${gender.code} : ${(Math.round(gender.weight * 100 * 10) / 10).toFixed(
						1,
					)}%`;
			  })
			: 'report unopened',
		top_cities: influencer?.influencer_id?.influencerData?.audience
			? influencer?.influencer_id?.influencerData?.audience?.geoCities.map((geoCity) => {
					return `${geoCity.name} : ${(
						Math.round(geoCity.weight * 100 * 10) / 10
					).toFixed(1)}%`;
			  })
			: 'report unopened',
		handle: influencer.influencerData.profile.url,
	}));
	return data;
};

export const csvApprovedList = (influencers) => {
	const data = influencers.map((influencer) => ({
		username: influencer?.influencer_id?.influencerData?.profile?.username,
		fullname: influencer?.influencer_id?.influencerData?.profile?.fullname,
		influencerType: influencer?.influencer_id?.influencerType,
		followers: nFormatter(influencer?.influencer_id?.influencerData?.profile?.followers, 1),
		engagementRate: fixDecimal(
			(influencer?.influencer_id?.influencerData?.profile?.engagementRate || 0) * 100,
			2,
		),
		estimatedReach: nFormatter(
			(influencer?.influencer_id?.influencerData?.profile?.followers || 0) *
				(influencer?.influencer_id?.influencerData?.profile?.engagementRate || 0) *
				10,
			1,
		),
		status: FirstLetterCaps(influencer?.approvedStatus || 'Undecided'),
		ageGroup: influencer?.influencer_id?.influencerData?.ageGroup || 'report unopened',
		avgViews: influencer?.influencer_id?.influencerData?.avgViews || 'report unopened',
		avgLikes: influencer?.influencer_id?.influencerData.avgLikes || 'report unopened',
		avgComments: influencer?.influencer_id?.influencerData.avgComments || 'report unopened',
		gender: influencer?.influencer_id?.influencerData.gender || 'report unopened',
		interests:
			influencer?.influencer_id?.influencerData.interests?.map((interest) => {
				return interest.name;
			}) || 'report unopened',
		posts: influencer?.influencer_id?.influencerData.postsCount || 'report unopened',
		gender_split: influencer?.influencer_id?.influencerData?.audience
			? influencer?.influencer_id?.influencerData?.audience?.genders.map((gender) => {
					return `${gender.code} : ${(Math.round(gender.weight * 100 * 10) / 10).toFixed(
						1,
					)}%`;
			  })
			: 'report unopened',
		top_cities: influencer?.influencer_id?.influencerData?.audience
			? influencer?.influencer_id?.influencerData?.audience?.geoCities.map((geoCity) => {
					return `${geoCity.name} : ${(
						Math.round(geoCity.weight * 100 * 10) / 10
					).toFixed(1)}%`;
			  })
			: 'report unopened',
		handle: influencer?.influencer_id?.influencerData.profile.url,
	}));

	return data;
};

// Helper Data
export const headers = [
	{ label: 'username', key: 'username' },
	{ label: 'fullname', key: 'fullname' },
	{ label: 'gender', key: 'gender' },
	{ label: 'platform', key: 'influencerType' },
	{ label: 'followers', key: 'followers' },
	{ label: 'engagement_rate', key: 'engagementRate' },
	{ label: 'estimated_reach', key: 'estimatedReach' },
	{ label: 'handle_link', key: 'handle' },
	{ label: 'posts', key: 'posts' },
	{ label: 'lists', key: 'influencerLists' },
	{ label: 'age_group', key: 'ageGroup' },
	{ label: 'average_views', key: 'avgViews' },
	{ label: 'average_likes', key: 'avgLikes' },
	{ label: 'average_comments', key: 'avgComments' },
	{ label: 'interests', key: 'interests' },
	{ label: 'gender_split', key: 'gender_split' },
	{ label: 'top_cities', key: 'top_cities' },
];

export const headersForSingleList = [
	{ label: 'username', key: 'username' },
	{ label: 'fullname', key: 'fullname' },
	{ label: 'gender', key: 'gender' },
	{ label: 'platform', key: 'influencerType' },
	{ label: 'followers', key: 'followers' },
	{ label: 'engagement_rate', key: 'engagementRate' },
	{ label: 'estimated_reach', key: 'estimatedReach' },
	{ label: 'handle_link', key: 'handle' },
	{ label: 'posts', key: 'posts' },
	{ label: 'status', key: 'status' },
	{ label: 'age_group', key: 'ageGroup' },
	{ label: 'average_views', key: 'avgViews' },
	{ label: 'average_likes', key: 'avgLikes' },
	{ label: 'average_comments', key: 'avgComments' },
	{ label: 'interests', key: 'interests' },
	{ label: 'gender_split', key: 'gender_split' },
	{ label: 'top_cities', key: 'top_cities' },
];
