import { getToken, getWorkspace } from '../../../store/App/Auth/helper/cookies';
import http from './http';
import { AUTH_STORE_CONSTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from './api-endpoints';

function setup(store) {
	http.interceptors.request.use(
		(config) => {
			const workspaceId = getWorkspace();

			const token = getToken();

			config.headers = {
				...config.headers,
				Authorization: `${token || ''}`,
				workspace: workspaceId,
			};
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);
	const { dispatch } = store;
	http.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;
			if (originalConfig.url !== API_ENDPOINTS.VERIFY_OTP && err.response) {
				if (err.response.status === 401 && !originalConfig._retry) {
					try {
						dispatch({ type: AUTH_STORE_CONSTANTS.UNAUTHORIZE });
					} catch (_error) {
						return Promise.reject(_error);
					}
				}
			}
			return Promise.reject(err);
		},
	);
}
export default setup;
