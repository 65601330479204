import React from 'react';

const initialState = {
	displayModal: false,
	modalMeta: {
		view: '',
		title: 'Modal',
		fullscreen: false,
		size: 'md',
		props: null,
	},

	offCanvasView: '',
	displayOffCanvas: false,
	filtersClicked: false,
};

export const UIContext = React.createContext(initialState);

UIContext.displayName = 'UIContext';

function uiReducer(state, action) {
	switch (action.type) {
		case 'OPEN_MODAL': {
			return {
				...state,
				displayModal: true,
			};
		}
		case 'CLOSE_MODAL': {
			return {
				...state,
				displayModal: false,
			};
		}
		case 'SET_MODAL_META': {
			return {
				...state,
				modalMeta: action.payload,
			};
		}
		case 'OPEN_OFFCANVAS': {
			return {
				...state,
				displayOffCanvas: true,
			};
		}
		case 'CLOSE_OFFCANVAS': {
			return {
				...state,
				displayOffCanvas: false,
			};
		}
		case 'SET_OFFCANVAS_VIEW': {
			return {
				...state,
				offCanvasView: action.payload,
			};
		}
		case 'FILTERS_CLICKED': {
			return {
				...state,
				filtersClicked: action.payload,
			};
		}
		default:
			return { ...state };
	}
}

export const UIProvider = (props) => {
	const [state, dispatch] = React.useReducer(uiReducer, initialState);

	const openModal = () => dispatch({ type: 'OPEN_MODAL' });
	const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

	const setModalMeta = (payload) => dispatch({ type: 'SET_MODAL_META', payload });

	const openOffCanvas = () => dispatch({ type: 'OPEN_OFFCANVAS' });
	const closeOffCanvas = () => dispatch({ type: 'CLOSE_OFFCANVAS' });

	const setOffCanvasView = (payload) => dispatch({ type: 'SET_OFFCANVAS_VIEW', payload });
	const setFiltersClicked = (payload) => dispatch({ type: 'FILTERS_CLICKED', payload });

	const value = React.useMemo(
		() => ({
			...state,
			openModal,
			closeModal,
			setModalMeta,
			openOffCanvas,
			closeOffCanvas,
			setOffCanvasView,
			setFiltersClicked,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state],
	);

	return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
	const context = React.useContext(UIContext);
	if (context === undefined) {
		throw new Error(`useUI must be used within a UIProvider`);
	}
	return context;
};

export const ManagedUIContext = ({ children }) => (
	// <CartProvider>
	<UIProvider>{children}</UIProvider>
	// </CartProvider>
);
