import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

const fetchWorkspace = async () => {
	const { data } = await http.get(API_ENDPOINTS.WORKSPACE);
	return data;
};

const useWorkspaceQuery = () => {
	return useQuery([API_ENDPOINTS.WORKSPACE], fetchWorkspace);
};

export { useWorkspaceQuery, fetchWorkspace };
