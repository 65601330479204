import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { checkArrayData } from '../../../../helpers/helpers';
// import recordEvent from '../../mixpanel/mixpanel';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import API_ENDPOINTS, { API_ENDPOINTS_CONTROL_PANEL } from '../../utils/api-endpoints';
import http from '../../utils/http';

const ShareToMember = async (input) => {
    const { data } = await http.post(API_ENDPOINTS_CONTROL_PANEL.MEMBER.SHARE_TO_MEMBER, input);
    return data;
};

export const useShareToMember = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const onSuccess = (data, input) => {
        queryClient.invalidateQueries([API_ENDPOINTS.MEMBER.GET_WORKSPACE_MEMBER]);
        addToast(
            successToast({
                message: data.message ? data.message : 'Workspace Shared Successfully',
            }),
            {
                autoDismiss: true,
            },
        );
    };

    const onError = (err) => {
        addToast(dangerToast({ message: checkArrayData(err?.response?.data?.errorMessage) ? "Invalid Input" : (err?.response?.data?.errorMessage || `Don't Have Access`) }), {
            autoDismiss: true,
        });
    };

    return useMutation((input) => ShareToMember(input), {
        onSuccess,
        onError,
    });
};
