import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './list.slice';

const listSlice = (state) => state.list || initialState;

export const lists = createSelector([listSlice], (state) => state.lists);

export const selectFavouriteList = createSelector([listSlice], (state) => state.favouriteList);

export const listInfluencers = createSelector([listSlice], (state) => state.listInfluencers);

export const isUpdateInLists = createSelector([listSlice], (state) => state.isUpdateInLists);

export const selectListInfluencer = createSelector([listSlice], (state) => state.listInfluencer);

export const selectInfluencerContact = createSelector(
	[listSlice],
	(state) => state.influencerContact,
);

export const selectIsContactUpdated = createSelector(
	[listSlice],
	(state) => state.isContactUpdated,
);

export const selectInfluencerPricing = createSelector(
	[listSlice],
	(state) => state.influencerPricing,
);

export const selectIsPriceUpdated = createSelector([listSlice], (state) => state.isPriceUpdated);

export const selectProfileListId = createSelector([listSlice], (state) => state.profileListId);
export const selectCrmListId = createSelector([listSlice], (state) => state.crmListId);
export const selectFilteredInfluencers = createSelector(
	[listSlice],
	(state) => state.filteredInfluencers,
);
export const selectFiltersClicked = createSelector([listSlice], (state) => state.filtersClicked);
