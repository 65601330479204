import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import contentRoute from '../../routes/contentRoutes';
import background from '../../assets/img/bg.svg';
import AuthWrapper from '../Wrapper/AuthWrapper';
import DiscoveryPage from '../../pages/discovery/DiscoveryPage';


const AuthContent = () => {
	const buildRoutes = (route) =>
		route?.isNested ? (
			<Route key={route.id} path={route.path} element={route.element}>
				{route?.nested.map((page) => buildRoutes(page))}
			</Route>
		) : (
			<Route key={route.id} path={route.path} element={route.element} />
		);


	return (
		<Routes>
			<Route path='' element={<AuthWrapper />}>
				{contentRoute.auth.map((page) => {
					return buildRoutes(page);
				})}
				{
				  contentRoute.bandedge.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route
					path=''
					element={<Navigate to='/signin/identifier' />}
				/>
				{/* <Route
					path='*'
					element={<Navigate to='/signin/identifier' />}
				/> */}
			</Route>

			<Route path='/bandEdge/home'>
				
				{
				  contentRoute.bandedge.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/bandEdge/home' />} />
				
				{/* <Route
					path='*'
					element={<Navigate to='/signin/identifier' />}
				/> */}
			</Route>


			<Route path='/privacypolicy'>
				
				{
				  contentRoute.privacyPolicy.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/privacypolicy' />} />
				
				{/* <Route
					path='*'
					element={<Navigate to='/signin/identifier' />}
				/> */}
			</Route>

			<Route path='/termsandcondition'>

				{
				  contentRoute.termsAndCondition.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/termsandcondition' />} />

			</Route>


			<Route path='/refundpolicy'>

				{
				  contentRoute.refundpolicy.map((page) => {
					return buildRoutes(page);
				  })
				}
				<Route path='' element={<Navigate to='/refundpolicy' />} />

			</Route>

			<Route path='/pricing'>

				{
				contentRoute.pricing.map((page) => {
					return buildRoutes(page);
				})
				}
				<Route path='' element={<Navigate to='/pricing' />} />

			</Route>


			<Route path='/aboutus'>

				{
				contentRoute.aboutus.map((page) => {
					return buildRoutes(page);
				})
				}
				<Route path='' element={<Navigate to='/aboutus' />} />

			</Route>



			<Route path='/contactus'>

				{
				contentRoute.contactus.map((page) => {
					return buildRoutes(page);
				})
				}
				<Route path='' element={<Navigate to='/contactus' />} />

			</Route>

			<Route
				path='*'
				element={<Navigate to='/signin/identifier' />}
			/>
		</Routes>
	);
};

export default AuthContent;
