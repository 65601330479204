import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	isUpdateCrmNote: null,
	CRMnotes: null,
};

const slice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		setIsUpdateCrmNote: (state, action) => {
			state.isUpdateCrmNote = action.payload;
		},
		setCrmNotes: (state, action) => {
			state.CRMnotes = action.payload;
		},
	},
});

export const { setIsUpdateCrmNote, setCrmNotes } = slice.actions;

export default slice.reducer;
