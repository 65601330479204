import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

// export const fetchLanguageSuggestionForSelect = async (query) => {
// 	const { text, platform } = query;
// 	const { data } = await http.get(
// 		`${API_ENDPOINTS.LANGUAGE_SUGGESTION}?platform=${platform}&query=${text}`,
// 	);
// 	return data.data || [];
// };


export const fetchHashTagSuggestion = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { text, platform } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.HASHTAG_SUGGESTION}?platform=${platform}&query=${text}`,
	);
	return data.data || [];
};

export const useHashTagSuggestionQuery = (options) => {
	return useQuery([API_ENDPOINTS.HASHTAG_SUGGESTION, options], fetchHashTagSuggestion);
};
