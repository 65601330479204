import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import Button from '../../components/bootstrap/Button';
import Search from '../../components/Search';
import InfluencerTable from '../common/InfluencerTable';
import DiscoveryInfluencerCard from '../../components/custom/DiscoveryComponents/SearchResultCards';
import OffCanvas from '../../components/bootstrap/OffCanvas';
import QuickSearch from '../../components/custom/Common/QuickSearchFilter/QuickSearch';
import SearchResultTable from '../../components/custom/DiscoveryComponents/SearchResultTable';
import useGetFilteredInfluencersQuery from '../../framework/basic-rest/discovery/get-filtered-influencers';
import useGetFilteredInfluencersDbQuery from '../../framework/basic-rest/discovery/get-filtered-influencers-db';
import { useInfluencerReportQuery } from '../../framework/basic-rest/discovery/get-influencers-report';
import { useDummyInfluencerReportQuery } from '../../framework/basic-rest/discovery/get-dummy-influencers-report';
import Icon from '../../components/icon/Icon';
import {
	selectOptionFilter,
	selectSearchFilters,
	selectSearchResults,
	selectComparisonProfiles,
} from '../../store/App/Discovery/discovery.selector';
import { checkArrayData, FirstLetterCaps, nFormatter } from '../../helpers/helpers';
import ProfileReport from '../../components/custom/DiscoveryComponents/ProfileReport';
import {
	EmptyDataState,
	NoDataFound1,
	SearchForInfluencerState,
} from '../../components/custom/Common/EmptyStates';
import { DISCOVERY_STORE_CONSTANTS } from '../../store/constants.store';
import {
	initialFilterState,
	TAB,
} from '../../components/custom/Common/QuickSearchFilter/FilterOptionsData';
import ModuleShare from '../../components/custom/control_panel/common/ModuleShare';
import CustomTabs from '../../components/custom/Common/QuickSearchFilter/CustomTabs';
import SearchHistory from '../../components/custom/DiscoveryComponents/saveSearch/SearchHistory';
import PrevAndNextButton from '../../components/custom/Common/Discovery/PrevAndNextButton';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import SaveFilters from '../../components/custom/DiscoveryComponents/advancedSearch/SaveFilters';
import Badge from '../../components/bootstrap/Badge';

const DiscoveryPage = () => {
	const dispatch = useDispatch();
	const searchResult = useSelector(selectSearchResults);
	console.log("searchResult", searchResult);
	const searchFilter = useSelector(selectSearchFilters);
	const comparisonProfiles = useSelector(selectComparisonProfiles);

	const navigate = useNavigate();

	const filterToSearch = useSelector(selectOptionFilter);
	console.log("filterToSearch", filterToSearch);

	const [toggleListToCard, setToggleListToCard] = useState(true);

	const [isOpen, setIsOpen] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [currentPage, setCurrentPage] = useState(0);
	const [filters, setFilters] = useState(null);
	const {
		// isFetching: isGettingInfluencers,
		isFetchingNextPage: isGettingMoreInfluencers,
		fetchNextPage,
		// hasNextPage,
		// data,
		error: errorWhileGettingInfluencers,
		isLoading: isGettingInfluencers,
		refetch: refetchInfluencers,
	} = useGetFilteredInfluencersQuery(filterToSearch);
	// const {
	// 	// isFetching: isGettingInfluencers,
	// 	isFetchingNextPage: isGettingMoreInfluencers,
	// 	fetchNextPage,
	// 	// hasNextPage,
	// 	// data,
	// 	error: errorWhileGettingInfluencers,
	// 	isLoading: isGettingInfluencers,
	// 	refetch: refetchInfluencers,
	// } = useGetFilteredInfluencersDbQuery(filterToSearch);

	const [influencerData, setInfluencerData] = useState(null);

	const getNextPage = () => {
		setCurrentPage((prev) => prev + 1);
		if (currentPage + 1 > searchResult.pages.length - 1) fetchNextPage();
	};

	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			...searchFilter,
		},
		onSubmit: (values) => {
			const newDate = new Date();

			dispatch({
				type: DISCOVERY_STORE_CONSTANTS.SEARCH_FILTERS,
				payload: { ...values, date: `Discovery/${newDate}`}
			})
			// setFilters(values);
			// refetchInfluencers();
		},
	});

	const {
		// data: influencerReport,
		isLoading: isGettingReport,
		refetch: influencerReportRefetch,
	} = useInfluencerReportQuery(influencerData);
	// const {
	// 	// data: influencerReport,
	// 	isLoading: isGettingReport,
	// 	refetch: influencerReportRefetch,
	// } = useDummyInfluencerReportQuery(influencerData);

	const getPreviousPage = () => {
		setCurrentPage((prev) => prev - 1);
	};

	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [openSearchHistory, setOpenSearchHistory] = useState(false);

	return (
		<PageWrapper title='Discovery Page'>
			{/* <div className='col-12'>
						<Alert
							icon='Verified'
							isLight
							color='primary'
							borderWidth={0}
							className='shadow-3d-primary'
							isDismissible>
							<AlertHeading tag='h2' className='h4'>
								Congratulations! 🎉
							</AlertHeading>
							<span>You have reached your monthly sales targets.</span>
						</Alert>
					</div> */}
			<SubHeader>
				<SubHeaderLeft>
					<span className='h4 mb-0 fw-bold'>Discovery</span>
					<SubheaderSeparator />
					{/* <Button>DropDown</Button> */}
					<CustomTabs
						defaultOption={FirstLetterCaps(filtersForm.values.platform)}
						labelText='value'
						valueText='tab'
						onClick={(value) => {
							console.log({ value });
							filtersForm.setValues({ ...initialFilterState, platform: value });
							dispatch({
								type: DISCOVERY_STORE_CONSTANTS.CHANGE_ACTIVE_TABS,
								payload: value,
							});
						}}
						list={TAB}
					/>
					<SubheaderSeparator />
					<Search
						filtersForm={filtersForm}
						isGettingInfluencers={isGettingInfluencers}
						isGettingMoreInfluencers={isGettingMoreInfluencers}
					/>
				</SubHeaderLeft>
				{/* <SubHeaderRight>
					<Button
						color='danger'
						isLight
						size='md'
						icon='Share'
						onClick={() => setIsOpen(!isOpen)}>
						Share
					</Button>
				</SubHeaderRight> */}
			</SubHeader>
			{/* <ModuleShare setIsOpen={setIsOpen} isOpen={isOpen} moduleName='Discovery' /> */}
			<Page container='fluid'>
				<div className='row '>
					<QuickSearch
						filtersForm={filtersForm}
						isGettingInfluencers={isGettingInfluencers}
						isGettingMoreInfluencers={isGettingMoreInfluencers}
					/>
				</div>
				<div className='row w-100'>
					{/* {(data || errorWhileGettingInfluencers) && ( */}
					<div className='d-flex justify-content-between w-100'>
						<h3>{isGettingInfluencers ? 'Searching for best matches..' : 'Results'}</h3>
						<div className='d-flex'>
							<Button
								className='mx-3'
								// isLight
								color='info'
								isLight
								// isOutline
								disabled={comparisonProfiles.length < 2}
								onClick={() => navigate('comparison')}>
								Compar :<Badge>{comparisonProfiles.length}</Badge>
							</Button>
							<Button
								className='mx-3'
								color='info'
								isLight
								onClick={() => {
									setOpenSearchHistory(!openSearchHistory);
								}}>
								My Saved Searches
							</Button>
							{!errorWhileGettingInfluencers && searchResult && (
								<Button
									color='info'
									isLight
									icon={toggleListToCard ? 'FormatListBulleted' : 'DragIndicator'}
									onClick={() => setToggleListToCard(!toggleListToCard)}
								/>
							)}
						</div>
					</div>
					{/* )} */}
					{(!isGettingInfluencers || isGettingMoreInfluencers) && searchResult && (
						<div class='card-footer'>
							<div class='card-footer-left'>
								<span class='text-muted'>
									<span class='pagination__desc'>{`Showing ${
										(searchResult?.pages[currentPage]?.currentPage ||
											searchResult?.pages[currentPage - 1]?.currentPage ||
											0) *
										(
											checkArrayData(
												searchResult?.pages[currentPage]?.directMatch,
											) ||
											checkArrayData(
												searchResult?.pages[currentPage]?.lookAlikes,
											) ||
											[]
										).length
									} of 15 influencers`}</span>
								</span>
							</div>
						</div>
					)}
					{isGettingInfluencers && <SearchForInfluencerState />}
					{isGettingMoreInfluencers &&
						(toggleListToCard ? (
							Array(8)
								.fill('0')
								.map((item, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<div className='col-md-4' key={`user-card-skeleton-${i}`}>
										<DiscoveryInfluencerCard />
									</div>
								))
						) : (
							<SearchResultTable />
						))}

					{!isGettingInfluencers &&
						searchResult &&
						(toggleListToCard ? (
							(
								checkArrayData(searchResult?.pages[currentPage]?.directMatch) ||
								checkArrayData(searchResult?.pages[currentPage]?.lookAlikes) ||
								[]
							).map((influencer, index) => (
								<div
									className='col-sm-12 col-md-12 col-lg-6 col-xl-4'
									// eslint-disable-next-line react/no-array-index-key
									key={`user-card-${index}`}>
									<DiscoveryInfluencerCard
										influencer={influencer}
										influencerReportRefetch={influencerReportRefetch}
										setIsProfileOpen={setIsProfileOpen}
										setInfluencerData={setInfluencerData}
									/>
								</div>
							))
						) : (
							<SearchResultTable
								influencers={
									checkArrayData(searchResult?.pages[currentPage]?.directMatch) ||
									checkArrayData(searchResult?.pages[currentPage]?.lookAlikes) ||
									[]
								}
								influencerReportRefetch={influencerReportRefetch}
								setIsProfileOpen={setIsProfileOpen}
								setInfluencerData={setInfluencerData}
							/>
						))}

					{errorWhileGettingInfluencers &&
						errorWhileGettingInfluencers?.response?.data?.errorMessage && (
							<div className='d-flex flex-column align-items-center'>
								<NoDataFound1
									message={
										errorWhileGettingInfluencers?.response?.data?.errorMessage
									}
								/>
							</div>
						)}
					{!errorWhileGettingInfluencers &&
						!isGettingInfluencers &&
						!isGettingMoreInfluencers &&
						searchResult &&
						!(
							checkArrayData(searchResult?.pages[currentPage]?.directMatch) ||
							checkArrayData(searchResult?.pages[currentPage]?.lookAlikes)
						) && (
							<div className='d-flex flex-column align-items-center'>
								<NoDataFound1 message='No result found for this query, try changing filter and make sure you are using filter correctly.' />
							</div>
						)}
					{!isGettingInfluencers && !errorWhileGettingInfluencers && !searchResult && (
						<div className='d-flex flex-column align-items-center'>
							<EmptyDataState message='Start searching...' />
						</div>
					)}

					{(!isGettingInfluencers || isGettingMoreInfluencers) && searchResult && (
						<div class='card-footer'>
							<div class='card-footer-left'>
								<span class='text-muted'>
									<span class='pagination__desc'>{`Showing ${
										(searchResult?.pages[currentPage]?.currentPage ||
											searchResult?.pages[currentPage - 1]?.currentPage ||
											0) *
										(
											checkArrayData(
												searchResult?.pages[currentPage]?.directMatch,
											) ||
											checkArrayData(
												searchResult?.pages[currentPage]?.lookAlikes,
											) ||
											[]
										).length
									} of 15 influencers`}</span>
								</span>
							</div>
							<PrevAndNextButton
								prevIsDisabled={
									!searchResult?.pages[currentPage]?.isPrev ||
									isGettingMoreInfluencers
								}
								prevActive={searchResult?.pages[currentPage]?.isPrev}
								prevOnClick={getPreviousPage}
								nextIsDisabled={
									!searchResult?.pages[currentPage]?.isNext ||
									isGettingMoreInfluencers
								}
								nextActive={searchResult?.pages[currentPage]?.isNext}
								nextOnClick={getNextPage}
								isNexthasPopovers={1}
								desc={
									<>
										0.5 <Icon icon='Coin' />
									</>
								}
							/>
						</div>
					)}
				</div>
				{/* <div className='row'>
					<div className='col-xxl-12'>
						<InfluencerTable isFluid />
					</div>
				</div> */}
			</Page>
			<ProfileReport
				setIsOpen={setIsProfileOpen}
				isOpen={isProfileOpen}
				isGettingReport={isGettingReport}
			/>
			<SearchHistory setOpen={setOpenSearchHistory} isOpen={openSearchHistory} />
			<OffCanvas
				style={{ width: '55%', overflowY: 'auto' }}
				setOpen={setIsModalOpen}
				isOpen={isModalOpen}
				titleId='SearchDetails'
				placement='end'>
				<Outlet />
			</OffCanvas>
		</PageWrapper>
	);
};

export default DiscoveryPage;
