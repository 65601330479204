import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../Navigation/Navigation';
import User from '../User/User';
import { pages } from '../../menu';
import { selectAuthUser } from '../../store/App/Auth/auth.selector';
import Icon from '../../components/icon/Icon';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import ThemeContext from '../../states/contexts/themeContext';
import { selectMenu } from '../../store/App/ControlPanel/controlPanel.selector';
import { useGetMenu } from '../../framework/basic-rest/control_panel/get-workspace-menu';
import { useGetOrganizationData } from '../../framework/basic-rest/organizaation/get-organization-detail';
import WorkspaceSelect from './Components/WorkspaceSelect';

const Aside = () => {
	const menu = useSelector(selectMenu);
	const user = useSelector(selectAuthUser);

	const { refetch: refetchMenu } = useGetMenu();

	const organizationFecth = useGetOrganizationData(user?.userId);

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<ul className='navigation'>

					<NavigationTitle className="mb-3" >{t("Workspace")}</NavigationTitle>
					<WorkspaceSelect className="my-5" />
					<NavigationTitle className="mt-3" >{t("Menu")}</NavigationTitle>
					</ul>
					{menu && <Navigation menu={menu} id='aside-dashboard' />}
					{/* <NavigationLine /> */}
					{/* {!doc && (
						<> */}
					{/* <Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
							<NavigationLine /> */}

					{/* </> */}
					{/* )} */}

					{/* {asideStatus && doc && <div className='p-4'>Documentation</div>} */}
				</div>
				<div className='aside-foot'>
					{/* <nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : null}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span>
							</div>
						</div>
					</nav> */}
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1027 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
