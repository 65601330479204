import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import Button from '../../bootstrap/Button';
import OffCanvas, { OffCanvasHeader } from '../../bootstrap/OffCanvas';
import useDarkMode from '../../../hooks/useDarkMode';
import InfluencerProfile from './report_Components/InfluencerProfile';
import { selectInfluencerReport } from '../../../store/App/Discovery/discovery.selector';
import CustomSkeleton from '../../CustomSkeleton';
import ProfileSkeleton from './ProfileSkeleton';
import AddToList from './AddToList';
import AddToFavourite from './AddToFavourite';
import { useGetInfluencerReport } from '../../../framework/basic-rest/discovery/get-influencer-refetch';

const ProfileReport = ({ isOpen, setIsOpen, isGettingReport }) => {

	const navigate = useNavigate();

	const influencerFullReport = useSelector(selectInfluencerReport);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Creator Profile Report',
	});

	const [influencerData, setInfluencerData] = useState(null);

	const {
		data: influencerRepo,
		isLoading: isGettingRefetch,
		refetch: influencerReportRefetch,
	} = useGetInfluencerReport(influencerData);

	const [isDownloading, setIsDownloading] = useState(false);

	const mangePrint = () => {
		setIsDownloading(true);
		setTimeout(() => {
			handlePrint();
			setTimeout(() => {
				setIsDownloading(false);
			}, 200);
		}, 200);
	}

	return (
		<OffCanvas
			style={{ width: '70%', overflowY: 'auto', padding: '10px' }}
			placement='end'
			isOpen={isOpen}
			// isBackdrop={false}
			setOpen={setIsOpen}
			size='xl'
			titleId='Profile'>
			<OffCanvasHeader setOpen={setIsOpen}>
				<div className="d-flex w-100 justify-content-between">
					<div className='h5 fw-bold'>Influencer Profile</div>
					<div className="d-flex">
						{!isGettingReport &&
							<>
								<div className="mx-4">
									<AddToFavourite
										_id={influencerFullReport?.influencer_id}
									/>
								</div>
								<AddToList
									influencerId={influencerFullReport?.influencer_id}
									existingList={influencerFullReport?.lists}
								/>
							</>
						}
						<Button className="mx-4" isLight color='danger' onClick={mangePrint}>
							Download
						</Button>
						{influencerFullReport &&
							<Button className="mx-4" isLight color='danger' onClick={() => {
								
								setInfluencerData({
									userId: influencerFullReport.userId,
									platform:
										influencerFullReport.influencerType,
									isRefetch: true,
									date: new Date()
								})
								
							}}>
								Refresh
							</Button>
						}
					</div>
				</div>
			</OffCanvasHeader>
			<hr />
			<div ref={componentRef} className='h-100 d-inline-block'>
				{(isGettingRefetch || isGettingReport) && <ProfileSkeleton />}
				{!isGettingReport && !isGettingRefetch && influencerFullReport && (
					<InfluencerProfile isDownloading={isDownloading} report={influencerFullReport} ref={componentRef} />
				)}
			</div>
		</OffCanvas>
	);
};

export default ProfileReport;
