import React from 'react';

const ClearIndicator = ({ props }) => {
	const {
		children = 'clear all',
		getStyles,
		innerProps: { ref, ...restInnerProps },
	} = props;
	return (
		<div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
			<div style={{ padding: '0px 5px' }}>{children}</div>
		</div>
	);
};

export default ClearIndicator;
