import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import Avatar from '../../Avatar';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Icon from '../../icon/Icon';
import Wizard, { WizardItem } from '../../Wizard';
import showNotification from '../../extras/showNotification';
import Textarea from '../../bootstrap/forms/Textarea';
import PricingCard from './PricingCard';
import ContactCard from './ContactCard';
import validate from '../../extras/contactValidate';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import validate from '../../../helpers/'
import useCreateInfluencerContact from '../../../framework/basic-rest/contacts_and_pricing/create-influencer-contact';
import useCreateInfluencerPricing from '../../../framework/basic-rest/contacts_and_pricing/create-influencer-pricing';
import { selectListInfluencer, selectProfileListId } from '../../../store/App/List/list.selector';

const ContactDetails = ({ isAddContact, setIsAddContact }) => {
	const listId = useSelector(selectProfileListId);
	const influencer = useSelector(selectListInfluencer);
	const TABS = {
		CONTACT: 'Contact',
		PRICING: 'Pricing',
	};
	const [activeTab, setActiveTab] = useState(TABS.CONTACT);
	const {
		mutate: createInfluencerContact,
		isLoading: isCreatingContact,
		data: contact,
	} = useCreateInfluencerContact();

	const createContact = async (body) => {
		createInfluencerContact(body);
	};

	const contacts = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstName: '',
			lastName: '',
			userName: '',
			emailAddress: '',
			phoneNumber: '',
			notes: '',
			location: '',
			label: '',
			listId: '',
			influencerId: influencer._id,
			i_index_id: influencer.influencer_index_id,
			additionalInfo: [],
		},
		validate,
		onSubmit: (values) => {
			const body = {
				contacts: {
					firstName: values.firstName,
					lastName: values.lastName,
					userName: values.userName,
					email: values.emailAddress,
					phone: values.phoneNumber,
					notes: values.notes,
					loc: values.location,
					label: values.label,
				},
				listId,
				influencerId: values.influencerId,
				i_index_id: values.i_index_id,
				additionalInfo: values.additionalInfo,
			};
			createContact(body);
		},
	});
	return (
		<Modal
			setIsOpen={setIsAddContact}
			isOpen={isAddContact}
			size='lg'
			titleId='add-new-card'
			// isCentered
			isScrollable
			height='50rem'
			isAnimation={false}>
			<ModalHeader setIsOpen={setIsAddContact} />
			<ModalBody>
				<ContactCard
					formik={contacts}
					type='Contact Information'
					influencer={influencer}
					// contact={contact}
					isCreatingContact={isCreatingContact}
				/>
			</ModalBody>
		</Modal>
	);
};

export default ContactDetails;
