import { useQuery } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';

export const fetchSavedFilters = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_SAVE_SEARCH_HISTORY}?page=${1}&limit=${4}`,
	);
	return data.data || [];
};

export const useGetSavedFilters = (options) => {
	return useQuery([API_ENDPOINTS.GET_SAVE_SEARCH_HISTORY, options], fetchSavedFilters);
};
