import { useFormik } from 'formik';
import React from 'react';
import Button from '../../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';

const ListEmailModal = ({ setOpen, open, createNewList, list }) => {
	
	const newListForm = useFormik({
		initialValues: {
			listName: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			// setFilterMenu(false);
			const body = {
				listName: values.listName,
			};
			createNewList(body);
			setOpen(false);
		},
	});

	return (
		<Modal
			setIsOpen={setOpen}
			isOpen={open}
			size='md'
			isAnimation={false}
			titleId='add-new-card'
			isCentered>
			<ModalHeader setIsOpen={setOpen}>
				<ModalTitle>List Emails</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card>
					
					<CardBody className='pt-0'>
						<form className='row g-3'>
							<div className='col-12'>
                            {
                                list?.email_buckets?.map((email) => (
                                    <CardLabel icon='List' iconColor='warning'>
							            <CardTitle>{email}</CardTitle>
						            </CardLabel>
                                ))
                            }
							</div>
						</form>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default ListEmailModal;
