import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cardColor, cardIcon, nFormatter } from '../../../../helpers/helpers';
import Avatar from '../../../Avatar';
import { CardBody } from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import Badge from '../../../bootstrap/Badge';
import Accordion, { AccordionItem } from '../../../bootstrap/Accordion';
import Chips from '../../Common/Chips';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import Spinner from '../../../bootstrap/Spinner';
import useRemoveInfluencerFromListMutation from '../../../../framework/basic-rest/discovery/remove-influencer-from-list';
import AddToList from '../../DiscoveryComponents/AddToList';
import Button from '../../../bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';
import { LIST_STORE_CONTANTS } from '../../../../store/constants.store';
import { useInfluencerReportQuery } from '../../../../framework/basic-rest/discovery/get-influencers-report';
import recordEvent from '../../../../framework/basic-rest/mixpanel/mixpanel';
import ProfileReport from '../../DiscoveryComponents/ProfileReport';
import AddToCrm from '../../DiscoveryComponents/AddToCrm';

const CustomInfluencerCard = ({
	influencerId,
	userId,
	select,
	image,
	fullname,
	platform,
	userName,
	followers,
	engagementRate,
	engagements,
	lists,
	index,
	filterList,
	projects,
	influencerDetails,
}) => {
	const {
		mutate: removeInfluencer,
		isLoading: isRemoving,
		data: deletedData,
	} = useRemoveInfluencerFromListMutation();
	const [removedInfluencer, setRemovedInfluencer] = useState({});
	const removeInfluencers = (iId, listId, influencerIndex, listIndex) => {
		removeInfluencer({
			influencer_id: iId,
			list_id: listId,
		});
		setRemovedInfluencer({ influencerIndex, listIndex });
		filterList.handleSubmit();
	};
	const { darkModeStatus } = useDarkMode();
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [influencerData, setInfluencerData] = useState(null);
	const {
		// data: influencerReport,
		isLoading: isGettingReport,
		refetch: influencerReportRefetch,
	} = useInfluencerReportQuery(influencerData);
	const profileOpened = (body) => {
		recordEvent('Influencer Report Opened', {
			influencerDetails: body,
		});
	};

	return (
		<CardBody
			className={classNames(
				select
					? 'bg-light rounded border border-2 border-info'
					: 'bg-light border border-2 border-light',
				'overflow-visible',
			)}>
			{/* <div className='d-flex justify-content-end'>
				<Button
					color={darkModeStatus ? 'light' : 'info'}
					isLink
					icon='MoreHoriz'
					aria-label='More Actions'
					size='lg'
				/>
			</div> */}
			<div className='row d-flex align-items-center'>
				<div className='col-4 flex-shrink-0 d-flex justify-content-center'>
					<Avatar srcSet={image} src={image} size={80} />
				</div>
				<div className='col-5 row flex-grow-1 d-flex mx-auto justify-content-end'>
					<div className='col-12 fw-bold fs-4'>{fullname && fullname || userName}</div>
					<div className='col-12 text-bold'>
						<Icon
							icon={cardIcon(platform?.toLowerCase())}
							color={cardColor(platform?.toLowerCase())}
							size='lg'
						/>{' '}
						<small className='fs-6'>{!userName ? fullname : userName}</small>
					</div>
				</div>
				<div className='col-2 d-flex justify-content-end align-items-start'>
					<Dropdown className='d-inline' direction='end'>
						<DropdownToggle hasIcon={false}>
							<Button
								color={darkModeStatus ? 'light' : 'dark'}
								isLink
								icon='MoreHoriz'
								aria-label='More Actions'
								size='lg'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							<DropdownItem>
								<AddToList
									influencerId={influencerId}
									existingList={lists}
									direction='end'
								/>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='d-flex flex-column align-items-center col-md-4'>
					<span>
						<strong className='fs-5'>{nFormatter(followers, 1)}</strong>
					</span>
					<span>Followers</span>
				</div>
				<div className='d-flex flex-column align-items-center col-md-4'>
					<span>
						<strong className='fs-5'>{nFormatter(engagementRate, 2)} %</strong>
					</span>
					<span className='text-center'>Engagement Rate</span>
				</div>
				<div className='d-flex flex-column align-items-center col-md-4'>
					<span>
						<strong className='fs-5'>{nFormatter(engagements, 1)}</strong>
					</span>
					<span>Engagements</span>
				</div>
			</div>
			<Accordion id='accSample' activeItemId={false}>
				<AccordionItem
					id={`acc${index}`}
					title='Lists'
					icon='Assignment'
					overWriteColor='primary'>
					<div className='row mt-1'>
						{lists &&
							lists?.length !== 0 &&
							lists?.map((list, i) => (
								<div className='flex-column align-items-center col-auto mt-2'>
									<Chips
										// eslint-disable-next-line react/no-array-index-key
										key={`list-chips-${i}`}
										className='mx-2 my-2 rounded-2'
										color='success'
										// rounded
										label={list.listName}
										value={list._id}
										onRemove={(e) => {
											ConfirmAlert(
												`List ${list.listName}`,
												`Do you really want to remove influencer from ${list.listName} list`,
												() => removeInfluencers(influencerId, e, index, i),
											);
										}}
										isRemoving={isRemoving}
										loaderTemplate={<Spinner isSmall inButton />}
									/>
								</div>
							))}
					</div>
				</AccordionItem>
				<AccordionItem
					id={`acc2${index}`}
					title='Projects'
					icon='Apps'
					overWriteColor='success'>
					<div className='row mt-1'>
						{projects ? (
							projects &&
							projects?.length !== 0 &&
							projects?.map((project, i) => (
								<div className='flex-column align-items-center col-auto mt-2'>
									<Chips
										// eslint-disable-next-line react/no-array-index-key
										key={`project-chips-${i}`}
										className='mx-2 my-2 rounded-2'
										color='success'
										// rounded
										label={project?.projectName}
										value={project?._id}
										// onRemove={(e) => {
										// 	ConfirmAlert(
										// 		`Project ${project.projectName}`,
										// 		`Do you really want to remove influencer from ${project?.projectName} project`,
										// 		() => removeInfluencers(influencerId, e, index, i),
										// 	);
										// }}
										isRemoving={isRemoving}
										loaderTemplate={<Spinner isSmall inButton />}
									/>
								</div>
							))
						) : (
							<div className='flex-column align-items-center col-auto mt-2'>
								This Infuencers is not added to any project
							</div>
						)}
					</div>
				</AccordionItem>
			</Accordion>
			<div className='row mt-3'>
				<div className='col-md-6'>
					<AddToCrm influencer={influencerDetails} platform={platform} />
				</div>
				<div className='col-md-6'>
					<Button
						color={darkModeStatus ? 'dark' : 'info'}
						isLink
						aria-label='More Actions'
						onClick={() => {
							setInfluencerData({
								userId,
								platform,
							});
							// setUserId(influencer.userId);
							setIsProfileOpen(true);
							profileOpened({
								influencerId: userId,
								influencerusername: userName,
								platform,
							});
						}}>
						Show More <Badge>1.5</Badge>
					</Button>
				</div>
				<ProfileReport
					setIsOpen={setIsProfileOpen}
					isOpen={isProfileOpen}
					isGettingReport={isGettingReport}
				/>
			</div>
		</CardBody>
	);
};

export default CustomInfluencerCard;
