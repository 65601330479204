import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';

async function deleteContact(input) {
	const { data } = await http.delete(
		`${API_ENDPOINTS.DELETE_CONTACT}?listId=${input.list_id}&influencer_id=${input.influencer_id}&list_id=${input.list_id}&i_index_id=${input.i_index_id}&contact_id=${input.contact_id}`,
	);
	return data;
}

export const useDeleteContact = () => {
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	return useMutation((input) => deleteContact(input), {
		onSuccess: (data) => {
			if (data.data.success) {
				dispatch({
					type: LIST_STORE_CONTANTS.SET_IS_UPDATE_CONTACT,
					payload: `delete/Contact/${new Date()}`
				})
				addToast(successToast({ message: 'Successfully Deleted Contact' }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err) => {
			addToast(dangerToast({ message: err.data.errorMessage }), {
				autoDismiss: true,
			});
		},
	});
};
