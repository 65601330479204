import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import { selectIsPriceUpdated } from '../../../store/App/List/list.selector';

export const getAllPricing = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { influencerId, listId, iIndexId } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_ALL_PRICINGS}?influencer_id=${influencerId}&list_id=${listId}&i_index_id=${iIndexId}`,
	);
	return data.data || [];
};

export const useGetAllPricing = (options) => {
	const dispatch = useDispatch();
	const isPriceUpdated = useSelector(selectIsPriceUpdated)
	const onSuccess = (data) =>{
		dispatch({
			type: LIST_STORE_CONTANTS.SET_LIST_INFLUENCERS_PRICE,
			payload: data
		})
	}
	return useQuery([API_ENDPOINTS.GET_ALL_PRICINGS, options, isPriceUpdated], getAllPricing, {
		onSuccess
	});
};
