import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

const fetchProjectContent = async ({ projectId }) => {
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_ANALYTICS_PROJECT_CONTENT}?projectId=${projectId}`,
	);
	return data;
};
const fetchProjectStats = async ({ projectId }) => {
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_ANALYTICS_PROJECT_STATS}?projectId=${projectId}`,
	);
	return data;
};

export { fetchProjectContent, fetchProjectStats };
