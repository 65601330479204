import React, { useState, useEffect, useRef } from 'react';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import { CreatorPermissionTypes } from '../../../Common/Workspace/PermissionTypes';
import CustomRadioButton from '../../../Common/CustomRadioButton';
import { PERMISSIONS } from '../../utils/constData';
import Option from '../../../../bootstrap/Option';
import Select from '../../../../bootstrap/forms/Select';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../bootstrap/Card';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const Creator = ({ filtersForm }) => {
	const onChangeHandle = (fieldName, value) => {
		filtersForm.setFieldValue(fieldName, value);
	};
	return (
		<div className='col-md-6'>
			<Card>
				<CardBody>
					<div className='row'>
						<div className='col-md-12'>
							<CardLabel icon='Person' iconColor='danger'>
								<CardTitle>Creator
								<Popovers
									desc={<>Access to creators and partners.</>}
									trigger='hover'>
									<Icon
										icon='Info'
										size='lg'
										style={{
											marginLeft: '3px',
											fontSize: '1.55rem',
										}}
									/>
								</Popovers>
								</CardTitle>
							</CardLabel>
							<FormGroup id='creator_permission' label='Permission'>
								<CustomRadioButton
									radioButtons={CreatorPermissionTypes}
									id='creator-permission'
									changed={(event) =>
										// filtersForm.setFieldValue(
										// 	'influencerGender',
										// 	event.target.value,
										// )
										onChangeHandle('creator_permission', event.target.value)
									}
									isSelected={filtersForm.values.creator_permission || 'VIEW'}
								/>
							</FormGroup>
						</div>
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default Creator;
