import React, { FC, useRef, useEffect } from 'react';
import { useUI } from '../../../states/contexts/ui.context';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';

const CustomOffCanvas = ({
	children,
	open,
	onClose,
	rootClassName,
	useBlurBackdrop,
	containerClassName,
	placement = 'end',
	title,
}) => {
	const { closeOffCanvas } = useUI();

	return (
		<OffCanvas
			style={{ width: '55%', overflowY: 'auto' }}
			setOpen={closeOffCanvas}
			isOpen={open}
			titleId='SearchDetails'
			placement={placement}
			isBodyScroll='true'>
			<OffCanvasHeader setOpen={open && closeOffCanvas}>
				<OffCanvasTitle id='offcanvasExampleLabel'>{title}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody className='p-4'>{children}</OffCanvasBody>
		</OffCanvas>
	);
};

export default CustomOffCanvas;
