import React from 'react'

const CreditHistory = ({ title }) => {
    return (
        <div className='container'>
            <h2>{title}</h2>
            <table className='table mt-4'>
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Date</th>
                        <th>Workspace</th>
                        <th>Credit used</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Search</td>
                        <td>22 Oct</td>
                        <td>Pradeep's Workspace</td>
                        <td>0.5</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CreditHistory