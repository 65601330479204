import React, { FC, useRef, useEffect } from 'react';
import { useUI } from '../../../states/contexts/ui.context';
import AdvanceInstagramFilters from '../DiscoveryComponents/advancedSearch/InstagramFilter';
import AdvanceYoutubeFilters from '../DiscoveryComponents/advancedSearch/YouTubeFilter';
import AdvanceTikTokFilters from '../DiscoveryComponents/advancedSearch/TikTokFilter';
import CustomOffCanvas from './offcanvas';

const ManagedOffCanvas = () => {
	const { displayOffCanvas, offCanvasView } = useUI();
	return (
		// eslint-disable-next-line react/jsx-boolean-value
		<CustomOffCanvas open={displayOffCanvas}>
			{offCanvasView === 'ADVANCE_INSTAGRAM_FILTER_VIEW' && <AdvanceInstagramFilters />}
			{offCanvasView === 'ADVANCE_YOUTUBE_FILTER_VIEW' && <AdvanceYoutubeFilters />}
			{offCanvasView === 'ADVANCE_TIKTOK_FILTER_VIEW' && <AdvanceTikTokFilters />}
		</CustomOffCanvas>
	);
};

export default ManagedOffCanvas;
