import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { CRM_CREATORS_STORE_CONSTANTS } from '../../../../store/constants.store';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmProject } from '../../../../store/App/crm/projects/crm.projects.selectors';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';

const getAllCrmNotes = async () => {
	const data = await http.get(`${API_ENDPOINTS.GET_CRM_NOTES}`);
	return data?.data;
};

const useGetAllCrmNotes = (options) => {
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isUpdateCreator = useSelector(selectIsUpdateCrmCreator);
	const isUpdateCompany = useSelector(selectIsCompanyUpdate);
	const isProjectUpdated = useSelector(selectIsUpdateCrmProject);
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);


	return useQuery(
		[
			API_ENDPOINTS.GET_CRM_NOTES,
			options,
			isUpdateCreator,
			isNOTEupdated,
			isUpdateCompany,
			isProjectUpdated,
			isTaskUpdated
		],
		getAllCrmNotes,
	);
};

export { useGetAllCrmNotes, getAllCrmNotes };
