import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import { checkArrayData } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';

const WsDropdown = ({
	children,
	defaultOption,
	isDefaultValue = false,
	list,
	labelText,
	Field,
	FieldValue,
	valueText,
	onClick,
}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'md',
	};
	const [value, setValue] = useState(null);
	const checkMemberType = list[1].workspace.length;
	console.log('element-----', checkMemberType);

	const navigate = useNavigate();

	const gotoManage = () => {
		navigate('/usermanagement/access-permission');
	};

	useEffect(() => {
		const defaultWs = localStorage.getItem('icc_workspace');
		if (isDefaultValue && list.length && list[0]?.workspace && list[0]?.workspace.length) {
			// eslint-disable-next-line no-unused-expressions
			const setData =
				list.length &&
				list
					.find(
						(memType) =>
							memType &&
							memType?.workspace.length &&
							memType?.workspace.find((kk) => kk._id === defaultWs),
					)
					?.workspace.find((jj) => jj._id === defaultWs);
			// eslint-disable-next-line no-unused-expressions
			setValue(setData);
		} else if (isDefaultValue) {
			setValue(list[0].workspace[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDefaultValue, list]);

	return (
		<Dropdown direction='down'>
			<DropdownToggle>
				<Button isLink isActive className='d-flex align-items-center w-100'>
					<span className='navigation-link-info'>
						<Icon
							icon='TrackChanges'
							// icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							// color={darkModeStatus ? 'info' : 'warning'}
							className='navigation-icon'
						/>
						<span className='navigation-text'>
							{value ? value[labelText] : defaultOption}
						</span>
					</span>
				</Button>
				{/* <Button color='ligth' className='w-100' isActive isOutline>
					{value ? value[labelText] : defaultOption}
				</Button> */}
			</DropdownToggle>
			<DropdownMenu className='px-2 ms-5' style={{ width: '23rem' }}>
				<DropdownItem key={value ? value[valueText] : defaultOption}>
					<div className='row'>
						<div className='fw-bold col-md-7'>
							{value ? value[labelText] : defaultOption}
						</div>
						<div className='col-md-5'>
							<Button icon='SettingsInputComponent' onClick={gotoManage}>
								Manage
							</Button>
						</div>
					</div>
				</DropdownItem>
				<DropdownItem isDivider />
				{checkArrayData(list) &&
					list.map((element) => (
						// <div className="text-muted">{JSON.stringify(Field)}</div>
						/* eslint-disable no-nested-ternary */
						<>
							<div key={element[Field].type} className='text-muted mx-3'>
								{element[Field]}
							</div>
							{checkArrayData(element[FieldValue]) &&
								element[FieldValue].map((workspace) =>
									checkMemberType === 1 && element[Field] === 'MEMBER' ? (
										<DropdownItem
											key={workspace[valueText]}
											onClick={() => {
												setValue(workspace);
												onClick(workspace[valueText]);
											}}>
											{workspace[labelText]}
										</DropdownItem>
									) : checkMemberType === 0 ? (
										<DropdownItem
											key={workspace[valueText]}
											onClick={() => {
												setValue(workspace);
												onClick(workspace[valueText]);
											}}>
											{workspace[labelText]}
										</DropdownItem>
									) : (
										<div>
											<p>Not Owner</p>
										</div>
									),
								)}
							<DropdownItem isDivider />
						</>

						/* eslint-enable no-nested-ternary */
					))}
				{/* {list?.map((element, i) => (
                    // <DropdownItem
                    //     key={element[valueText]}
                    //     onClick={() => {
                    //         setValue(element);
                    //         onClick(element[valueText]);
                    //     }}>
                    //     {element[labelText]}
                    // </DropdownItem>
                ))} */}
				{children}
			</DropdownMenu>
		</Dropdown>
	);
};

export default WsDropdown;
