import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { addDays } from 'date-fns';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { useFormik } from 'formik';
import useAnalyticsMutation from '../../../framework/basic-rest/analytics/create-analytics-project';
import { organizationslector } from '../../../store/App/Organization/organization.selector';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import { ModalBody, ModalFooter, ModalTitle } from '../../bootstrap/Modal';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import Card from '../../bootstrap/Card';
import Popovers from '../../bootstrap/Popovers';
import useUpdateAnalyticsMutation from '../../../framework/basic-rest/analytics/update-analytics-project';
import Spinner from '../../bootstrap/Spinner';
import validateProject from '../../../validations/Analytics/createProject';
import {
	selectSocialTrackingTypes,
	selectSocialTrackingPlatform,
} from '../../../store/App/SocialAnalytics/analytics.selector';
import {
	selectCurrentProjectId,
	selectProjects,
} from '../../../store/App/SocialAnalytics/Project/project.selector';
import { useGetCurrencies } from '../../../framework/basic-rest/contacts_and_pricing/get-system-currencies';
import SelectProjectKeys from './SelectProjectKeys';

export const AnalyticsProjectModal = () => {
	const { data: currencies } = useGetCurrencies();
	const projectId = useSelector(selectCurrentProjectId);
	const projects = useSelector(selectProjects);
	const organizationsDetail = useSelector(organizationslector); // here it used to get a countly response
	const analyticsTypes = useSelector(selectSocialTrackingTypes);
	const analyticsPlatform = useSelector(selectSocialTrackingPlatform);

	const [currency, setCurrency] = useState([]);

	const { mutate: updateAnalyticsProject, isLoading: updatingAnalyticsProject } =
		useUpdateAnalyticsMutation();
	const { mutate: createAnalyticsProject, isLoading: creatingAnalyticsProject } =
		useAnalyticsMutation();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			projectName: '',
			projectType: '',
			platform: '',
			startDate: '',
			endDate: '',
			commerceTrackingId: organizationsDetail._id,
			currency: '',
			budget: 0,
		},
		validate: validateProject,
	});

	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: addDays(new Date(), 7),
		key: 'selection',
	});

	const datePicker = (
		<DateRangePicker
			minDate={new Date()}
			onChange={(item) => onDatePickerChange(item?.range1 || item?.selection)}
			showSelectionPreview
			moveRangeOnFirstSelection={false}
			ranges={[dateRange]}
			direction='horizontal'
			preventSnapRefocus
			calendarFocus='backwards'
		/>
	);

	const onDatePickerChange = (range) => {
		setDateRange(range);
		formik.setFieldValue('startDate', new Date(range?.startDate).toISOString());
		formik.setFieldValue('endDate', new Date(range?.endDate).toISOString());
	};

	useEffect(() => {
		if (projectId) {
			const projectObject = projects.find((project) => project._id === projectId);
			// console.log("project",projectObject)
			formik.setValues(projectObject);
			setDateRange({
				startDate: new Date(projectObject?.startDate || new Date()),
				endDate: new Date(projectObject?.endDate || new Date()),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId]);
	useEffect(() => {
		if (projectId && currencies?.data.length > 0) {
			const updateCurrency = currencies?.data.filter((c) => c.cc === formik.values.currency);
			setCurrency(updateCurrency);
		}
	}, [currencies, formik.values.currency, projectId]);
	useEffect(() => {
		if (projectId && currencies?.data.length > 0) {
			const updateCurrency = currencies?.data.filter((c) => c.cc === formik.values.currency);
			setCurrency(updateCurrency);
		}
	}, [currencies, formik.values.currency, projectId]);
	useEffect(() => {
		formik.setFieldValue('startDate', new Date().toISOString());
		formik.setFieldValue('endDate', addDays(new Date(), 7).toISOString());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleAnalyticsProject = () => {
		const projectObj = {
			eventsToTrack: stepOneDetails.eventList,
			goals: stepOneDetails.goalList,
			...formik.values,
			commerceTrackingId: organizationsDetail._id,
		};
		// console.log("created", projectObj)

		createAnalyticsProject(projectObj);
	};
	const updatehandleAnalyticsProject = () => {
		const updatedprojectObj = {
			eventsToTrack: stepOneDetails.eventList,
			goals: stepOneDetails.goalList,
			...formik.values,
		};
		// console.log("updated", updatedprojectObj)

		updateAnalyticsProject(updatedprojectObj);
	};

	const [stepOneDetails, setStepOneDetails] = useState({
		goalList: [],
		eventList: [],
	});

	return (
		<>
			<Card>
				<ModalBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='projectName' label='Project name'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.projectName}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.projectName}
									invalidFeedback={formik.errors.projectName}
									validFeedback='Looks good!'
									placeholder='Enter project name'
								/>
							</FormGroup>
						</div>

						<div className='col-12'>
							<FormGroup id='platform' label='Choose Platform'>
								<Select
									id='platform'
									onChange={formik.handleChange}
									value={formik.values?.platform}
									ariaLabel='Select Platform '
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched?.platform}
									invalidFeedback={formik.errors?.platform}
									validFeedback='Looks good!'
									placeholder='Select Platform'>
									{analyticsPlatform &&
										analyticsPlatform.map((x) => (
											<Option key={x.value} value={x.value}>
												{x.label}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label='Project type' id='projectType'>
								<Select
									onChange={formik.handleChange}
									value={formik.values.projectType}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.projectType}
									invalidFeedback={formik.errors.projectType}
									validFeedback='Looks good!'
									ariaLabel='Select project type'
									placeholder='Select project type'>
									{analyticsTypes &&
										analyticsTypes.map((x) => (
											<Option key={x.value} value={x.value}>
												{x.label}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
						{formik.values.projectType && formik.values.projectType !== 'SOCIAL' && (
							<SelectProjectKeys
								organizationDeatil={organizationsDetail}
								formik={formik}
								setStepOneDetails={setStepOneDetails}
								stepOneDetails={stepOneDetails}
							/>
						)}

						<div className='col-md-6'>
							<FormGroup id='currency' label='Select currency'>
								{projectId && currencies?.data.length ? (
									<AsyncTypeahead
										id='currency'
										labelKey='cc'
										minLength={0}
										onSearch={(query) => {}}
										clearButton
										onChange={(e) => {
											formik.setFieldValue('currency', e.map((x) => x.cc)[0]);
										}}
										selected={currency}
										options={currencies && currencies?.data}
										placeholder='Select your currency'
										useCache={false}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched.currency}
										invalidFeedback={formik.errors.currency}
										validFeedback='Looks good!'
										renderMenuItemChildren={(option, props) => (
											<div>
												<span>{option.cc}</span>
											</div>
										)}
									/>
								) : (
									<AsyncTypeahead
										id='currency'
										labelKey='cc'
										minLength={0}
										onSearch={(query) => {}}
										clearButton
										onChange={(e) => {
											formik.setFieldValue('currency', e.map((x) => x.cc)[0]);
										}}
										options={currencies && currencies?.data}
										placeholder='Select your currency'
										useCache={false}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched.currency}
										invalidFeedback={formik.errors.currency}
										validFeedback='Looks good!'
										renderMenuItemChildren={(option, props) => (
											<div>
												<span>{option.cc}</span>
											</div>
										)}
									/>
								)}
							</FormGroup>
						</div>
						<div className='col-md-6'>
							<FormGroup id='budget' label='Enter Budget'>
								<Input
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.budget}
									invalidFeedback={formik.errors.budget}
									validFeedback='Looks good!'
									onChange={formik.handleChange}
									value={formik.values.budget}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label='Start date - End date'>
								<Popovers
									placement='bottom-end'
									className='mw-100 overflow-hidden'
									data-tour='date-range-menu'
									bodyClassName='p-0'
									trigger='click'
									desc={datePicker}>
									<Button
										color='dark'
										isLight
										data-tour='date-range'
										className='w-100'>
										{`${moment(dateRange.startDate).format(
											'MMM Do YY',
										)} - ${moment(dateRange.endDate).format('MMM Do YY')}`}
									</Button>
								</Popovers>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
			</Card>

			<ModalFooter>
				{!projectId ? (
					<Button
						isDisable={creatingAnalyticsProject || !(formik.isValid && formik.dirty)}
						className='w-100'
						icon='Save'
						color='info'
						onClick={() => {
							handleAnalyticsProject();
						}}>
						{creatingAnalyticsProject ? 'Please wait...' : 'Create'}
						{creatingAnalyticsProject && <Spinner isSmall inButton />}
					</Button>
				) : (
					<Button
						isDisable={updatingAnalyticsProject || !(formik.isValid && formik.dirty)}
						className='w-100'
						color='info'
						onClick={() => {
							updatehandleAnalyticsProject();
						}}>
						{updatingAnalyticsProject ? 'Please wait...' : 'Update'}
						{updatingAnalyticsProject && <Spinner isSmall inButton />}
					</Button>
				)}
			</ModalFooter>
		</>
	);
};
