import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmAlert } from '../../../helpers/confirm-helper';
import CommonFilterTag from '../../../pages/common/CommonFilterTag';
import { selectListInfluencer } from '../../../store/App/List/list.selector';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import Avatar from '../../Avatar';
import Button from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Textarea from '../../bootstrap/forms/Textarea';
import Spinner from '../../bootstrap/Spinner';
import { NoListState } from './EmptyStates';

const ContactCard = ({ type, formik, isCreatingContact }) => {
	const influencer = useSelector(selectListInfluencer);
	const addInput = () => {
		if (formik.values.additionalInfo.length < 10) {
			formik.setFieldValue('additionalInfo', [
				...formik.values.additionalInfo,
				{
					key: '',
					value: '',
				},
			]);
		}
	};
	const removeInput = (i) => {
		const updateInput = formik.values.additionalInfo;
		updateInput.splice(i, 1);
		formik.setFieldValue('additionalInfo', [...updateInput]);
	};
	const onChangeValue = (i, e) => {
		const updateValue = formik.values.additionalInfo;
		updateValue[i].value = e.target.value;
		formik.setFieldValue('additionalInfo', [...updateValue]);
	};
	const onChangeKey = (i, e) => {
		const updateValue = formik.values.additionalInfo;
		updateValue[i].key = e.target.value;
		formik.setFieldValue('additionalInfo', [...updateValue]);
	};

	return (
		<>
			<CardLabel className='ms-5' icon='Person' iconColor='info'>
				<CardTitle>{type}</CardTitle>
			</CardLabel>
			<CardHeader>
				<CardLabel icon='Edit' iconColor='warning'>
					<CardTitle>Personal Information</CardTitle>
				</CardLabel>
			</CardHeader>
			<div className='row g-4'>
				<div className='col-md-6'>
					<FormGroup id='label' label='Label' isFloating>
						<Input
							placeholder='type of contact'
							autoComplete='additional-name'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.label}
							isValid={formik.isValid}
							isTouched={formik.touched.label}
							invalidFeedback={formik.errors.label}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-md-6'>
					<FormGroup id='firstName' label='First Name' isFloating>
						<Input
							placeholder='First Name'
							autoComplete='additional-name'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.firstName}
							isValid={formik.isValid}
							isTouched={formik.touched.firstName}
							invalidFeedback={formik.errors.firstName}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-md-6'>
					<FormGroup id='lastName' label='Last Name' isFloating>
						<Input
							placeholder='First Name'
							autoComplete='additional-name'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.lastName}
							isValid={formik.isValid}
							isTouched={formik.touched.lastName}
							invalidFeedback={formik.errors.lastName}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-md-6'>
					<FormGroup
						id='userName'
						label='User name'
						isFloating
						formText='This will be how the influencer name will be displayed'>
						<Input
							placeholder='Username'
							autoComplete='username'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.userName}
							isValid={formik.isValid}
							isTouched={formik.touched.userName}
							invalidFeedback={formik.errors.userName}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
			</div>
			<CardHeader>
				<CardLabel icon='MarkunreadMailbox' iconColor='success'>
					<CardTitle>Contact Information</CardTitle>
				</CardLabel>
			</CardHeader>
			<div className='row g-4'>
				<div className='col-12'>
					<FormGroup id='phoneNumber' label='Phone Number' isFloating>
						<Input
							placeholder='Phone Number'
							type='tel'
							autoComplete='tel'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.phoneNumber}
							isValid={formik.isValid}
							isTouched={formik.touched.phoneNumber}
							invalidFeedback={formik.errors.phoneNumber}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='emailAddress' label='Email address' isFloating>
						<Input
							type='email'
							placeholder='Email address'
							autoComplete='email'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.emailAddress}
							isValid={formik.isValid}
							isTouched={formik.touched.emailAddress}
							invalidFeedback={formik.errors.emailAddress}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='location' label='Location' isFloating>
						<Input
							placeholder='Location'
							type='text'
							autoComplete='text'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.location}
							isValid={formik.isValid}
							isTouched={formik.touched.location}
							invalidFeedback={formik.errors.location}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='notes' label='About' isFloating>
						<Textarea
							type='text'
							placeholder='write here..'
							// autoComplete=''
							size='lg'
							height='10rem'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.notes}
							isValid={formik.isValid}
							isTouched={formik.touched.notes}
							invalidFeedback={formik.errors.notes}
							validFeedback='Looks good!'
						/>
					</FormGroup>
				</div>
				<h3>Custom Fields</h3>
				<div className='row'>
					{formik.values.additionalInfo &&
						formik.values.additionalInfo.length !== 0 &&
						formik.values.additionalInfo.map((x, i) => (
							<div className='row g-3'>
								<div className='col-md-10'>
									<FormGroup id='key' label='field' isFloating>
										<Input
											placeholder='key'
											type='text'
											autoComplete='text'
											// onBlur={formik.handleBlur}
											value={x[i]}
											onChange={(e) => onChangeKey(i, e)}
											// isValid={formik.isValid}
											// isTouched={x.key}
											// invalidFeedback={formik.errors.additionalInfo}
											// validFeedback='Looks good!'
										/>
									</FormGroup>
									<br />
									<FormGroup id='value' label='value' isFloating>
										<Input
											placeholder='value'
											type='text'
											autoComplete='text'
											// onBlur={formik.handleBlur}
											value={x[i]}
											onChange={(e) => onChangeValue(i, e)}
											// isValid={x.isValid}
											// isTouched={x.value}
											// invalidFeedback={formik.errors.additionalInfo}
											// validFeedback='Looks good!'
										/>
									</FormGroup>
								</div>
								<div className='col-md-2'>
									<Button
										style={{ marginTop: '40px' }}
										color='danger'
										isLight
										onClick={() => removeInput(i)}>
										Remove
									</Button>
								</div>
							</div>
						))}
				</div>
				<div />
				<div className='row'>
					<div className='col-md-6'>
						<Button
							color='info'
							style={{ marginRight: '10px' }}
							isLight
							onClick={() => addInput()}>
							Add Fields
						</Button>
					</div>
				</div>
			</div>
			{/* </CardBody> */}
			<CardFooter>
				<CardFooterRight>
					<Button
						color='info'
						icon='Save'
						type='submit'
						isDisable={!formik.isValid || isCreatingContact}
						onClick={() => formik.handleSubmit()}>
						{isCreatingContact ? 'Please wait...' : 'Save'}
						{isCreatingContact && <Spinner isSmall inButton />}
					</Button>
				</CardFooterRight>
			</CardFooter>
		</>
	);
};

export default ContactCard;
