import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	crmProject: [],
	crmProjectId: null,
	crmProjectById: null,
	isUpdateCrmProject: null,
	getProject: null,
};

const slice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setCrmProject: (state, action) => {
			state.crmProject = action.payload;
		},
		setCrmProjectId: (state, action) => {
			state.crmProjectId = action.payload;
		},
		setCrmProjectById: (state, action) => {
			state.crmProjectById = action.payload;
		},
		setIsUpdateCrmProject: (state, action) => {
			state.isUpdateCrmProject = action.payload;
		},
		setGetProject: (state, action) => {
			state.getProject = action.payload;
		},
	},
});

export const {
	setCrmProject,
	setCrmProjectId,
	setCrmProjectById,
	setIsUpdateCrmProject,
	setGetProject,
} = slice.actions;

export default slice.reducer;
