import { useToasts } from 'react-toast-notifications';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { useUI } from '../../../states/contexts/ui.context';
import { WORKSPACE_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function workspace(input) {
	return http.post(API_ENDPOINTS.ADD_WORKSPACE, input);
}

const useWorkspaceMutation = () => {
	const dispatch = useDispatch();
	const { setModalMeta, closeModal } = useUI();
	const { addToast } = useToasts();

	return useMutation((input) => workspace(input), {
		onSuccess: (res, input) => {
			if (res.data.success) {
				dispatch({ type: WORKSPACE_STORE_CONSTANTS.SYNC_WORKSPACE_DETAILS });
				setModalMeta(null);
				closeModal();
				addToast(successToast({ message: res.data.message }), {
					autoDismiss: true,
				});
				recordEvent(`Created Workspace(Workspace)`, {
					input,
					output:res.data
				});
				
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				recordEvent(res.data.data.errorMessage, {
					input,
					output:res.data
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to create workspace(Workspace)`, {
				input,
				output:err?.response?.data
			});
		},
	});
};
export default useWorkspaceMutation;
