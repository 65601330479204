import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { successToast, dangerToast } from '../../../helpers/toast-helper';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import recordEvent from '../mixpanel/mixpanel';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';

async function updateList(input) {
	const res = await http.post(`${API_ENDPOINTS.UPDATE_LIST}?listId=${input.list_id}`, input);
	return res.data.data;
}

const useUpdateListMutation = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch()
	return useMutation((input) => updateList(input), {
		onSuccess: (data, input) => {
			addToast(successToast({ message: 'List Name updated' }), {
				autoDismiss: true,
			});
			dispatch({
				type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
				payload: `LIST/Delete-list/${new Date()}`,
			});
			recordEvent(`Updated List(List)`, {
				input,
				output: data,
			});
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to update List(List)`, {
				input,
				output: err?.response?.data,
			});
		},
	});
};

export default useUpdateListMutation;
