const validateKey = (values) => {
	const errors = {};
	if (!values.name) {
		errors.name = 'Please Enter the campaign name first';
	}

	return errors;
};

export default validateKey;
