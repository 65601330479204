import moment from 'moment';

export const ANALYZE_REDUCER_CONSTANT = {
	SET_TOTAL_EVENT_COUNT: 'SET_TOTAL_EVENT_COUNT',
	SET_INFLUENCER_USER_DATE_RANGE: 'SET_INFLUENCER_USER_DATE_RANGE',
	SET_INFLUENCER_USER_DATE_LABEL: 'SET_INFLUENCER_USER_DATE_LABEL',
	SET_TOTAL_INFLUE_EVENT_MATRIX: 'SET_TOTAL_INFLUE_EVENT_MATRIX',
	SET_SELECT_EVENT_MATRIX_ERROR: 'SET_SELECT_EVENT_MATRIX_ERROR',
	SET_INLFUENCER_DATE_RANGE: 'SET_INLFUENCER_DATE_RANGE',
	SET_CITIES_DATE_RANGE: 'SET_CITIES_DATE_RANGE',
	GET_CITIES_DATE_REQUEST: 'GET_CITIES_DATE_REQUEST',
	GET_COUNTRIES_DATE_REQUEST: 'GET_COUNTRIES_DATE_REQUEST',
	SET_CITIES_DATA: 'SET_CITIES_DATA',
	SET_CAMPAIGN_EVENT_MATRIX: 'SET_CAMPAIGN_EVENT_MATRIX',
	SET_INFL_TOTAL_COUNT: 'SET_INFL_TOTAL_COUNT',
	SET_COUNTRY_DATE_RANGE: 'SET_COUNTRY_DATE_RANGE',
	SET_COUNTRIES_DATA: 'SET_COUNTRIES_DATA',
	SET_EVENT_MATRIX_LIST: 'SET_EVENT_MATRIX_LIST',
	SET_INFLUENCER_DATE_RANGE: 'SET_INFLUENCER_DATE_RANGE',
	SET_DEVICE_EVENT_DATE_RANGE: 'SET_DEVICE_EVENT_DATE_RANGE',
	SET_DEVICE_EVENT_DATE_LABEL: 'SET_DEVICE_EVENT_DATE_LABEL',
	GET_INFLU_EVENT_MATRIX_REQUEST: 'GET_INFLU_EVENT_MATRIX_REQUEST',
	SET_INFLUENCER_EVENT_ARRAY: 'SET_INFLUENCER_EVENT_ARRAY',
	GET_ALL_INFLUENCER_EVENT_REQUEST: 'GET_ALL_INFLUENCER_EVENT_REQUEST',
	SET_ALL_INFLUENCER_EVENT_DATA: 'SET_ALL_INFLUENCER_EVENT_DATA',
	GET_DEVICE_EVENT_DETAILS_REQUEST: 'GET_DEVICE_EVENT_DETAILS_REQUEST',
	SET_DEVICE_EVENT_DETAILS_DATA: 'SET_DEVICE_EVENT_DETAILS_DATA',
	SET_TOTAL_DEVICE_EVENT_COUNT: 'SET_TOTAL_DEVICE_EVENT_COUNT',
	GET_CAMPAIGN_ALL_EVENT_MATRIX_REQUEST: 'GET_CAMPAIGN_ALL_EVENT_MATRIX_REQUEST',
};

export const initialState = {
	showInfluencerDetails: false,
	onInfluencerDetailClick: {},
	onClickDonutGraph: false,
	selectedEventMatrixValue: null,
	selectEventError: '',
	defaultEventMatrixValue: {},
	influencerCitiesDateRange: {},
	citiesArray: [],
	eventMatrixList: [],
	countriesArray: [],
	totalEventCount: 0,
	campaignAllEventMatrix: [],
	influTotalCount: 0,
	influencerEventArray: [],
	influencerDateRange: {
		startDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
		endDate: moment(moment()).format('YYYY-MM-DD'),
	},
	citiesDateRange: {
		startDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
		endDate: moment(moment()).format('YYYY-MM-DD'),
	},
	countryDateRange: {
		startDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
		endDate: moment(moment()).format('YYYY-MM-DD'),
	},
	influencerUserDateRange: {
		startDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
		endDate: moment(moment()).format('YYYY-MM-DD'),
	},
	deviceEventDateRange: {
		startDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
		endDate: moment(moment()).format('YYYY-MM-DD'),
	},
	deviceEventDateLabel: 'Last 7 Days',
	influencerUserDateLabel: 'Last 7 Days',
	allInfluencerEventData: [],
	totalInfluencerEventCount: 0,
	deviceEventDetailsData: [],
	totalDeviceEventCount: 0,
};
export const analyzeReducers = (state = initialState, action = {}) => {
	switch (action?.type) {
		case ANALYZE_REDUCER_CONSTANT.SHOW_INFLUENCER_DETAILS:
			return { ...state, showInfluencerDetails: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_DETAILS_ON_CLICK:
			return { ...state, onInfluencerDetailClick: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_CITIES_DATA:
			return { ...state, citiesArray: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_COUNTRIES_DATA:
			return { ...state, countriesArray: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_ON_CLICK_DONUT_GRAPH:
			return { ...state, onClickDonutGraph: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DETAILS_DATA:
			return { ...state, deviceEventDetailsData: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_TOTAL_DEVICE_EVENT_COUNT:
			return { ...state, totalDeviceEventCount: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_TOTAL_EVENT_COUNT:
			return { ...state, totalEventCount: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SELECTED_MATRIX_VALUE:
			return { ...state, selectedEventMatrixValue: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_CITIES_DATE_RANGE:
			return { ...state, citiesDateRange: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_EVENT_MATRIX_LIST:
			return { ...state, eventMatrixList: action.value };
		case ANALYZE_REDUCER_CONSTANT.SET_COUNTRY_DATE_RANGE:
			return { ...state, countryDateRange: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_CAMPAIGN_EVENT_MATRIX:
			return { ...state, campaignAllEventMatrix: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_DATE_RANGE:
			return { ...state, influencerDateRange: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DATE_RANGE:
			return { ...state, deviceEventDateRange: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_DEVICE_EVENT_DATE_LABEL:
			return { ...state, deviceEventDateLabel: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_SELECT_EVENT_MATRIX_ERROR:
			return { ...state, selectEventError: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFL_TOTAL_COUNT:
			return { ...state, influTotalCount: action?.value };
		case ANALYZE_REDUCER_CONSTANT.DEFAULT_EVENT_VALUE:
			return { ...state, defaultEventMatrixValue: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_USER_DATE_LABEL:
			return { ...state, influencerUserDateLabel: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_USER_DATE_RANGE:
			return { ...state, influencerUserDateRange: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_TOTAL_INFLUE_EVENT_MATRIX:
			return { ...state, totalInfluencerEventCount: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_ALL_INFLUENCER_EVENT_DATA:
			return { ...state, allInfluencerEventData: action?.value };
		case ANALYZE_REDUCER_CONSTANT.SET_INFLUENCER_EVENT_ARRAY: {
			if (action.value.length === 0) {
				return { ...state, influencerEventArray: [] };
			}
			return {
				...state,
				influencerEventArray: [
					{
						influencerDetails: action?.value?.influencer,
						value: action?.value?.count,
						name: action?.value?.influencer?.name,
						eventName: action?.eventName,
					},
					...state.influencerEventArray,
				],
			};
		}

		default:
			return state;
	}
};
