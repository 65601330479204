import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import useWorkspaceMutation from '../../../framework/basic-rest/workspace/use-workspace';
import Button from '../../bootstrap/Button';
import validateWorkspace from '../../../validations/Workspace/workspace';
import Spinner from '../../bootstrap/Spinner';
import { ModalBody, ModalFooter, ModalTitle } from '../../bootstrap/Modal';
import { CREDIT_TYPE } from '../Member_System/utils/constData';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Option from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select';
import { selectWorkspaces } from '../../../store/App/Workspace/workspace.selector';
import { ConfirmAlert } from '../../../helpers/confirm-helper';
import Popovers from '../../bootstrap/Popovers';
import Icon from '../../icon/Icon';

export const WorkspaceModal = () => {
	const workspaces = useSelector(selectWorkspaces);

	const { mutate: createWorkspace, isLoading: creatingWorkspace } = useWorkspaceMutation();

	const formik = useFormik({
		initialValues: {
			name: '',
			creditType: CREDIT_TYPE.UNLIMITED.key,
			credit: 0,
		},
		validate: validateWorkspace,
		onSubmit: (values) => {
			ConfirmAlert(
				'Do you really want to create workspace?',
				workspaces.length && workspaces[0].workspace.length < 2
					? 'you can create this workspace free'
					: 'this workspace cost you 2 credit/month',
				() => createWorkspace(values),
			);
		},
	});

	return (
		<>
			<ModalBody>
				<ModalTitle id='exampleModalLabel'>
					<h5>Are you sure you want to create a new workspace ?
					<Popovers
								desc={
									<>   <b>Credit Type</b> <br />
										<b>Unlimited:</b> Available credits will be transferred to
										Owner.
										<br /> <b>Allot:</b> Specific credits will be transferred to
										Owner.
									</>
								}
								trigger='hover'>
								<Icon
									icon='Info'
									size='lg'
									style={{
										marginLeft: '3px',
										fontSize: '1.55rem',
									}}
								/>
							</Popovers>
					</h5>
					<h6 className='text-muted'>
						A workspace is the place where you can communicate, collaborate, plan your
						project or work to be done, and follow up on progress
					</h6>
				</ModalTitle>
				<form className='row mt-2 g-4'>
					<div className='col-12'>
						<FormGroup id='name' isFloating label='Workspace name'>
							<Input
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
								isValid={formik.isValid}
								isTouched={formik.touched.name}
								invalidFeedback={formik.errors.name}
								validFeedback='Looks good!'
							/>
						</FormGroup>
					</div>
					<div className='col-md-6'>
						
						{/* <Popovers
							desc={
								<>
									<b>Unlimited:</b> Available credits will be transferred to
									Member.
									<br /> <b>Allot:</b> Specific credits will be transferred to
									Member.
								</>
							}
							trigger='hover'>
							<Icon
								icon='Info'
								size='lg'
								style={{
									marginLeft: '3px',
									fontSize: '1.55rem',
								}}
							/>
						</Popovers> */}
						<FormGroup id='creditType' label='Credit Type'>
							<Select
								onChange={formik.handleChange}
								value={formik.values.creditType}
								onBlur={formik.handleBlur}
								// isValid={formik.isValid}
								isTouched={formik.touched.creditType}
								invalidFeedback={formik.errors.creditType}
								// validFeedback='Looks good!'
								ariaLabel='Select Credit Type'
								placeholder='Select Credit Type'>
								{CREDIT_TYPE &&
									Object.keys(CREDIT_TYPE).map((key) => (
										<Option value={CREDIT_TYPE[key].key}>
											{CREDIT_TYPE[key].text}
										</Option>
									))}
							</Select>
						</FormGroup>
					</div>
					{formik.values.creditType !== CREDIT_TYPE.UNLIMITED.key && (
						<div className='col-md-6'>
							<FormGroup id='credit' label='Credit'>
								<InputGroup>
									<Input
										id='credit'
										ariaLabel='Credit'
										placeholder='Enter Credit'
										type='number'
										min={0}
										onChange={formik.handleChange}
										value={formik.values.credit}
									/>
								</InputGroup>
							</FormGroup>
						</div>
					)}
				</form>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={() => formik.handleSubmit()}
					isOutline
					color='secondary'
					className='w-100'
					icon='Add'>
					{creatingWorkspace ? 'Please wait...' : 'Create a workspace'}
					{creatingWorkspace && <Spinner isSmall inButton />}
				</Button>
			</ModalFooter>
		</>
	);
};
