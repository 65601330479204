import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const fetchInfluencerSuggestion = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { text, platform } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.INFLUENCER_SUGGESTION}?platform=${platform}&query=${text}`,
	);
	return data.data || [];
};

export const useInfluencersSuggestionQuery = (options) => {
	return useQuery([API_ENDPOINTS.INFLUENCER_SUGGESTION, options], fetchInfluencerSuggestion);
};
