import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Accordion, { AccordionItem } from '../../../bootstrap/Accordion';
import { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../../../bootstrap/OffCanvas';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import { CardLabel, CardSubTitle, CardTitle } from '../../../bootstrap/Card';
import useDebounce from '../../../../hooks/useDebounce';
import { useLocationSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-location';
import { useInterestSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-interest';
import { useLanguageSuggestionQuery } from '../../../../framework/basic-rest/discovery/get-suggestion-language';
import Input from '../../../bootstrap/forms/Input';
import Button from '../../../bootstrap/Button';

//   followersGrowthRate -- {
//    interval --> no. of months to compair
// operator --> operator to compair
// value --> value to compair with
//    }
//
//   hasContactDetails -- [
// {
// contactType --> Specifies the contact channels of an influencer.
// filterAction --> Defines condition enforced by the filter - "must" include, "should" include, or must "not" include.
// }
//   ]
//

const InstagramFilter = ({ getInfluencers }) => {
	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			influencerLocation: [],
			influencerInterests: [],
			influencerLanguage: '',
			lastposted: null,
			engagementRate: null,
			bio: '',
			hashtags: '',
			mentions: '',
			keywords: '',
			influencerFollowers: {
				min: 0,
				max: 0,
			},
			audienceLocation: [],
			audienceAge: [],
			influencerGender: '',
			audienceInterests: [],
			audienceLanguage: [],
			platform: 'instagram',
		},
		onSubmit: (values) => {
			getInfluencers(values);
		},
	});

	const [locationQuery, setLocationQuery] = useState('');
	const debouncedLocationSearch = useDebounce(
		{ text: locationQuery, platform: 'instagram' },
		800,
	);
	const { data: locations, isLoading: isLocationLoading } =
		useLocationSuggestionQuery(debouncedLocationSearch);

	const [interestQuery, setInterestQuery] = useState('');
	const debouncedInterestSearch = useDebounce(
		{ text: interestQuery, platform: 'instagram' },
		800,
	);
	const { data: interests, isLoading: isInterestLoading } =
		useInterestSuggestionQuery(debouncedInterestSearch);

	const [languageQuery, setLanguageQuery] = useState('');
	const debouncedLanguageSearch = useDebounce(
		{ text: languageQuery, platform: 'instagram' },
		800,
	);
	const { data: languages, isLoading: isLanguageLoading } =
		useLanguageSuggestionQuery(debouncedLanguageSearch);

	const followersFrom = [
		'1,000',
		'3,000',
		'5,000',
		'10,0000',
		'15,000',
		'25,000',
		'35,000',
		'50,000',
		'75,000',
		'100,000',
		'175,000',
		'250,000',
		'350,000',
		'500,000',
		'1,000,000',
		'2,000,000',
	];
	const followersTo = [
		'3,000',
		'5,000',
		'10,0000',
		'15,000',
		'25,000',
		'35,000',
		'50,000',
		'75,000',
		'100,000',
		'175,000',
		'250,000',
		'350,000',
		'500,000',
		'1,000,000',
		'2,000,000',
	];

	const fakeFollowers = ['<=25%', '<=35%', '<=50%'];

	const age = ['13-17', '18-24', '25-34', '35-44', '45+'];

	const [interest, setInterest] = useState([]);
	const [ageFilter, setAgeFilter] = useState([]);

	const addAndRemoveInterest = (value) => {
		if (interest.includes(value)) {
			const updateArray = interest.filter((ele) => {
				return ele !== value;
			});
			setInterest(updateArray);
		} else {
			setInterest([...interest, value]);
		}
	};

	const addAndRemoveAge = (value) => {
		if (ageFilter.includes(value)) {
			const updateArray = ageFilter.filter((ele) => {
				return ele !== value;
			});
			setAgeFilter(updateArray);
		} else {
			setAgeFilter([...ageFilter, value]);
		}
	};

	return (
		<>
			<div className='row mb-4'>
				<CardLabel iconColor='info' className='mb-4'>
					<CardTitle>Influencer filters</CardTitle>
					<CardSubTitle>
						Try starting with number of followers and audience filters narrowing your
						search
					</CardSubTitle>
				</CardLabel>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Location' className='col-12'>
						{/* <AsyncTypeahead
							multiple

							className='border-0 shadow-none bg-transparent w-100'
							id='search'
							labelKey='label'
							minLength={1}
							ignoreDiacritics={false}
							// onChange={(e) =>
							// 	filtersForm.setFieldValue(
							// 		'audienceAge',
							// 		e.map((x) => x.value),
							// 	)
							// }
							options={[
								{
									id: 1,
									value: '13-17',
									label: '13-17',
								},
								{
									id: 2,
									value: '18-24',
									label: '18-24',
								},
								{
									id: 3,
									value: '25-34',
									label: '25-34',
								},
								{
									id: 4,
									value: '35-44',
									label: '35-44',
								},
								{
									id: 5,
									value: '45+',
									label: '45+',
								},
							]}
							placeholder='Select Age'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.label}</span>
								</div>
							)}
						/> */}
						<AsyncTypeahead
							multiple
							id='async-locations'
							isLoading={isLocationLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => setLocationQuery(query)}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'influencerLocation',
									e.map((x) => x.id),
								)
							}
							options={locations}
							placeholder='Where are you influencers?'
							useCache={false}
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Interest' className='col-12'>
						<AsyncTypeahead
							multiple
							id='async-interests'
							isLoading={isInterestLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => {
								setInterestQuery(query);
							}}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'influencerInterests',
									e.map((x) => x.id),
								)
							}
							options={interests}
							placeholder='Any'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Language' className='col-12'>
						<AsyncTypeahead
							// multiple
							id='async-language'
							isLoading={isLanguageLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => setLanguageQuery(query)}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'influencerLanguage',
									e.map((x) => x.id),
								)
							}
							options={languages}
							placeholder='Language of your influencers?'
							useCache={false}
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Last post' className='col-12'>
						<Input
							id='lastposted'
							name='lastposted'
							type='number'
							onChange={filtersForm.handleChange}
							value={filtersForm.lastposted}
							placeholder='Last date of post'
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Engagement rate' className='col-12'>
						<Input
							id='engagementRate'
							name='engagementRate'
							type='number'
							onChange={filtersForm.handleChange}
							value={filtersForm.engagementRate}
							placeholder='Engagement Rate'
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Bio' className='col-12'>
						<Input
							id='Bio'
							name='bio'
							type='text'
							value={filtersForm.bio}
							onChange={filtersForm.handleChange}
							placeholder='bio of influencer'
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Account type' className='col-12'>
						<AsyncTypeahead
							multiple
							className='border-0 shadow-none bg-transparent w-100'
							id='account-type'
							labelKey='label'
							minLength={1}
							ignoreDiacritics={false}
							// onChange={(e) =>
							// 	filtersForm.setFieldValue(
							// 		'audienceAge',
							// 		e.map((x) => x.value),
							// 	)
							// }
							options={[
								{
									id: 1,
									value: 'Regular',
									label: 'Regular',
								},
								{
									id: 2,
									value: 'Business',
									label: 'Business',
								},
								{
									id: 3,
									value: 'Creator',
									label: 'Creator',
								},
							]}
							placeholder='Select Age'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.label}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Hashtags' className='col-12'>
						<Input
							id='Hashtags'
							name='hashtags'
							type='text'
							value={filtersForm.hashtags}
							onChange={filtersForm.handleChange}
							placeholder='hashtags'
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Mentions' className='col-12'>
						<Input
							id='Mentions'
							name='mentions'
							type='text'
							value={filtersForm.mentions}
							onChange={filtersForm.handleChange}
							placeholder='mentions'
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Keywords' className='col-12'>
						<Input
							id='Keywords'
							name='keywords'
							type='text'
							value={filtersForm.keywords}
							onChange={filtersForm.handleChange}
							placeholder='keywords'
						/>
					</FormGroup>
				</div>
			</div>
			<div className='row'>
				<CardLabel iconColor='info' className='mb-4'>
					<CardTitle>Audience filters</CardTitle>
					<CardSubTitle>
						For best results, start with location, gender and age before adding more
						filters
					</CardSubTitle>
				</CardLabel>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Audience Location' className='col-12'>
						<AsyncTypeahead
							multiple
							id='async-au-locations'
							isLoading={isLocationLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => setLocationQuery(query)}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'audienceLocation',
									e.map((x) => x.id),
								)
							}
							options={locations}
							placeholder='Where are you audience of influencers?'
							useCache={false}
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Gender' className='col-12'>
						<AsyncTypeahead
							multiple
							className='border-0 shadow-none bg-transparent w-100'
							id='search'
							labelKey='label'
							minLength={1}
							ignoreDiacritics={false}
							// onChange={(e) =>
							// 	filtersForm.setFieldValue(
							// 		'audienceAge',
							// 		e.map((x) => x.value),
							// 	)
							// }
							options={[
								{
									id: 1,
									value: '13-17',
									label: '13-17',
								},
								{
									id: 2,
									value: '18-24',
									label: '18-24',
								},
								{
									id: 3,
									value: '25-34',
									label: '25-34',
								},
								{
									id: 4,
									value: '35-44',
									label: '35-44',
								},
								{
									id: 5,
									value: '45+',
									label: '45+',
								},
							]}
							placeholder='Select Age'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.label}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Age' className='col-12'>
						<AsyncTypeahead
							multiple
							className='border-0 shadow-none bg-transparent w-100'
							id='search'
							labelKey='label'
							minLength={1}
							ignoreDiacritics={false}
							// onChange={(e) =>
							// 	filtersForm.setFieldValue(
							// 		'audienceAge',
							// 		e.map((x) => x.value),
							// 	)
							// }
							options={[
								{
									id: 1,
									value: '13-17',
									label: '13-17',
								},
								{
									id: 2,
									value: '18-24',
									label: '18-24',
								},
								{
									id: 3,
									value: '25-34',
									label: '25-34',
								},
								{
									id: 4,
									value: '35-44',
									label: '35-44',
								},
								{
									id: 5,
									value: '45+',
									label: '45+',
								},
							]}
							placeholder='Select Age'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.label}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Interests' className='col-12'>
						<AsyncTypeahead
							multiple
							id='async-interests'
							isLoading={isInterestLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => {
								setInterestQuery(query);
							}}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'audienceInterests',
									e.map((x) => x.id),
								)
							}
							options={interests}
							placeholder='Any'
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<div className='col-md-4 mb-3'>
					<FormGroup label='Language' className='col-12'>
						<AsyncTypeahead
							multiple
							id='async-language'
							isLoading={isLanguageLoading}
							labelKey='name'
							minLength={3}
							onSearch={(query) => setLanguageQuery(query)}
							onChange={(e) =>
								filtersForm.setFieldValue(
									'audienceLanguage',
									e.map((x) => x.id),
								)
							}
							options={languages}
							placeholder='Language of your influencers?'
							useCache={false}
							renderMenuItemChildren={(option, props) => (
								<div>
									<span>{option.name}</span>
								</div>
							)}
						/>
					</FormGroup>
				</div>
				<Button color='danger' isOutline onClick={filtersForm.handleSubmit} size='md'>
					Show Influencers
				</Button>
			</div>
			<footer class='page-footer font-small purple pt-4 fixed-bottom'>
				<div class='d-flex bd-highlight'>
					<div class='p-2 flex-grow-1 bd-highlight'>
						<Button
							color='danger'
							isOutline
							// onClick={(e) => handleModal(TABS[key])}
							size='md'>
							Show influencers
						</Button>
					</div>
					<div class='p-2 bd-highlight'>
						<Button
							color='danger'
							isOutline
							// onClick={(e) => handleModal(TABS[key])}
							size='md'>
							Clear filters
						</Button>
					</div>
				</div>
			</footer>
		</>
	);
};

export default InstagramFilter;
