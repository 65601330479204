import { useFormik } from 'formik';
import React from 'react';
import { useShareToMember } from '../../../../../framework/basic-rest/member_system/member/share_to_member';
import Button from '../../../../bootstrap/Button';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Input from '../../../../bootstrap/forms/Input';
import InputGroup from '../../../../bootstrap/forms/InputGroup';
import Select from '../../../../bootstrap/forms/Select';
import Option from '../../../../bootstrap/Option';
import { CREDIT_TYPE, PERMISSIONS, ROLES } from '../../utils/constData';
import CreditLimit from './CreditLimit';
import validateMember from './shareMemberData';
import Campaign from './Campaign';
import Creator from './Creator';
import List from './List';
import SocialProject from './SocialProject';

const ShareModal = () => {
	const { mutate: shareToMember, isLoading: isSharing } = useShareToMember();

	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			role: ROLES?.MEMBER?.key,
			permission: PERMISSIONS.VIEW.key,
			discovery_permission: PERMISSIONS.VIEW.key,
			campaign_permission: PERMISSIONS.VIEW.key,
			creator_permission: PERMISSIONS.VIEW.key,
			list_permission: PERMISSIONS.VIEW.key,
			socialproject__permission: PERMISSIONS.VIEW.key,
			creditType: CREDIT_TYPE.UNLIMITED.key,
			credit: 0,
		},
		onSubmit: (values) => {
			console.log({ values });
			console.log({
				...values,
				permission: 'VIEW',
				discovery_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.discovery_permission,
				campaign_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.campaign_permission,
				creator_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.creator_permission,
				list_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.list_permission,
				socialproject_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.socialproject_permission,
				credit: values.creditType === CREDIT_TYPE.UNLIMITED.key ? 0 : values.credit,
			});
			shareToMember(values);
		},
		onReset: (values) => {
			filtersForm.setValues({
				email: '',
				role: ROLES?.MEMBER?.key,
				permission: PERMISSIONS.VIEW.key,
				discovery_permission: PERMISSIONS.VIEW.key,
				campaign_permission: PERMISSIONS.VIEW.key,
				creator_permission: PERMISSIONS.VIEW.key,
				list_permission: PERMISSIONS.VIEW.key,
				socialproject__permission: PERMISSIONS.VIEW.key,
				creditType: CREDIT_TYPE.UNLIMITED.key,
				credit: 0,
			});
		},
		validate: validateMember,
	});

	return (
		<div className='container'>
			<form onSubmit={filtersForm.handleSubmit} onReset={filtersForm.handleReset}>
				<div className='row'>
					<div className='row'>
						<div className='col-md-6'>
							<FormGroup id='email' label='Email'>
								{/* <InputGroup> */}
								<Input
									id='email'
									ariaLabel='email'
									placeholder='Enter email'
									onBlur={filtersForm.handleBlur}
									isTouched={filtersForm.touched?.email}
									isValid={filtersForm.isValid}
									invalidFeedback={filtersForm.errors.email}
									validFeedback='Looks good!'
									onChange={filtersForm.handleChange}
									value={filtersForm.values.email}
								/>
								{/* </InputGroup> */}
							</FormGroup>
						</div>
						<div className='col-md-6'>
							<FormGroup id='role' label='Role'>
								<Select
									onChange={filtersForm.handleChange}
									value={filtersForm.values.role}
									onBlur={filtersForm.handleBlur}
									// isValid={filtersForm.isValid}
									isTouched={filtersForm.touched.role}
									invalidFeedback={filtersForm.errors.role}
									// validFeedback='Looks good!'
									ariaLabel='Select Role'
									placeholder='Select Role'>
									{ROLES &&
										Object.keys(ROLES).map((key) => (
											<Option value={ROLES[key].key}>
												{ROLES[key].text}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
					</div>

					<div className='row mt-3'>
						{filtersForm.values.role !== ROLES?.GUEST?.key && (
							<CreditLimit filtersForm={filtersForm} />
						)}
					</div>
					<div className='row'>
						{filtersForm.values.role !== ROLES?.GUEST?.key && (
							<Campaign filtersForm={filtersForm} />
						)}
						{filtersForm.values.role !== ROLES?.GUEST?.key && (
							<Creator filtersForm={filtersForm} />
						)}
						{filtersForm.values.role !== ROLES?.GUEST?.key && (
							<List filtersForm={filtersForm} />
						)}
						{filtersForm.values.role !== ROLES?.GUEST?.key && (
							<SocialProject filtersForm={filtersForm} />
						)}
					</div>
				</div>
				<div className='d-flex justify-content-between mt-4'>
					<Button className='px-5' type='reset' color='danger' isLight>
						Reset
					</Button>
					<Button className='px-5' type='submit' color='primary' isLight>
						Submit
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ShareModal;
