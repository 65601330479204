import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';
import useDarkMode from '../../../../hooks/useDarkMode';
import Button from '../../../bootstrap/Button';
// import Icon from '../../icon/Icon';

const CustomTabs = ({
	children,
	defaultOption,
	isDefaultValue = false,
	list,
	labelText,
	valueText,
	onClick,
}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'md',
	};
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (isDefaultValue) {
			// eslint-disable-next-line no-unused-expressions
			list && setValue(list[0]);
		}
	}, [isDefaultValue, list]);

	return (
		<Dropdown direction='end'>
			<DropdownToggle>
				<Button
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...styledBtn}
					color='info'
					aria-label='Change workspace'>
					{defaultOption}
				</Button>
			</DropdownToggle>
			<DropdownMenu>
				{list?.map((element, i) => (
					<DropdownItem
						key={element[valueText]}
						onClick={() => {
							setValue(element);
							onClick(element[valueText]);
						}}>
						{element[labelText]}
					</DropdownItem>
				))}
				{children}
			</DropdownMenu>
		</Dropdown>
	);
};

export default CustomTabs;
