import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useSelector } from 'react-redux';
import useInfluencerSuggestionQuery from '../../../framework/basic-rest/analytics/get-suggestion-inflencer';
import useDebounce from '../../../hooks/useDebounce';
import { selectCurrentTrackingId } from '../../../store/App/SocialAnalytics/Tracking/tracking.selector';
import Input from '../../bootstrap/forms/Input';

const Search = ({
	id = '',
	name = '',
	onSelect,
	debounceSet = {},
	isReadOnly = false,
	readValue = '',
	labelKey = '',
	optionKey = '',
	placeHolder = '',
	searchQuery = '',
	isStatic = false,
	defaultSelected,
	staticOptions = [],
}) => {
	const [query, setQuery] = useState('');
	// const debouncedInfluencerSearch = useDebounce({ influencer: influencerQuery }, 800);
	const debouncedInfluencerSearch = useDebounce({ query, ...debounceSet }, 800);
	// const { data: influencers, isLoading: isFetchingInfluencer } =
	// 	useInfluencerSuggestionQuery(debouncedInfluencerSearch);
	const { data, isLoading } = searchQuery && searchQuery(debouncedInfluencerSearch);
	// const trackingId = useSelector(selectCurrentTrackingId);

	return isReadOnly ? (
		<Input readOnly disabled value={readValue} />
	) : (
		<AsyncTypeahead
			id={id}
			name={name}
			isLoading={isLoading}
			labelKey={labelKey}
			minLength={0}
			defaultSelected={defaultSelected}
			{...(!isStatic && { onSearch: setQuery })}
			onChange={(e) => onSelect(e)}
			options={isStatic ? staticOptions : data}
			placeholder={placeHolder}
			useCache={false}
			renderMenuItemChildren={(option, props) => (
				<div>
					<span>{option[optionKey]}</span>
				</div>
			)}
			// isTouched={formik.touched.globalInfluencerIndex}
			// invalidFeedback={formik.errors.globalInfluencerIndex}
			// validFeedback='Looks good!'
			// onBlur={formik.handleBlur}
			// isValid={formik.isValid}
		/>
	);
};

export default Search;
