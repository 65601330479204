import React, { useContext, useEffect, useLayoutEffect, useRef, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
// import { TourProvider } from '@reactour/tour';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { publicPage } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import ThemeContext from '../states/contexts/themeContext';
import { ManagedUIContext } from '../states/contexts/ui.context';

import ManagedOffCanvas from '../components/custom/offcanvas/managed-offcanvas';
import ManagedModal from '../components/custom/modal/managed-modal';

import contentRoute from '../routes/contentRoutes';
import { selectIsAuthenticated, selectAuthUser } from '../store/App/Auth/auth.selector';
import { UserProvider } from '../states/contexts/userContext';
import { WorkspaceProvider } from '../states/contexts/workspaceContext';
import { AUTH_STORE_CONSTANTS } from '../store/constants.store';
import AuthContent from '../layout/Content/AuthRoutes';
import GlobalWrapper from '../layout/Wrapper/GlobalWrapper';

const App = () => {
	getOS();
	const dispatch = useDispatch();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const queryClientRef = useRef();
	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient({
			defaultOptions: {
				queries: {
					refetchOnWindowFocus: false,
					refetchOnMount: false,
					retry: 0,
				},
			},
		});
	}
	// const workspace = useSelector(selectWorkspace);

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<UserProvider>
					<WorkspaceProvider>
						<QueryClientProvider client={queryClientRef.current}>
							<ManagedUIContext>
								<GlobalWrapper />
							</ManagedUIContext>
							<ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
						</QueryClientProvider>
					</WorkspaceProvider>
				</UserProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
