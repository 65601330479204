import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import recordEvent from '../../mixpanel/mixpanel';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import {
	CRM_COMPANIES_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
	CRM_NOTES_STORE_CONSTANTS,
	CRM_PROJECTS_STORE_CONSTANTS,
	CRM_TASKS_STORE_CONSTANTS,
} from '../../../../store/constants.store';

async function createTaskAssociation(input) {
	const { data } = await http.post(API_ENDPOINTS.CREATE_TASK_ASSOCIATION, input);
	return data;
}

const useCreateTaskAssociationMutation = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	return useMutation((input) => createTaskAssociation(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_TASKS]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_TASK_BY_ID]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_CRM_COMAPNY_BY_ID]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_PROJECT_BY_ID]);
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_ALL_CRM_PROJECTS]);

				dispatch({
					type: CRM_TASKS_STORE_CONSTANTS.UPDATE_CRM_TASKS,
					payload: `TASKS/Associated/${new Date()}`,
				});
				addToast(successToast({ message: 'Task Associated' }), {
					autoDismiss: true,
				});
				recordEvent(`Associated Task(CRM)`, {
					input,
					output:res
				});
			} else {
				addToast(dangerToast({ message: `${res.message}` }), {
					autoDismiss: true,
				});
				recordEvent(res.message, {
					taskDetails:res
				});
			}
		},
		onError: (err,input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to Associate Task(CRM)`, {
				input,
				output:err
			});
		},
	});
};
export default useCreateTaskAssociationMutation;
