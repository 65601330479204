import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { successToast, dangerToast } from '../../../helpers/toast-helper';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import recordEvent from '../mixpanel/mixpanel';

async function deleteList(input) {
	return http.delete(`${API_ENDPOINTS.DELETE_LIST}?listId=${input.listId}`);
}

const useDeleteList = () => {
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	return useMutation((input) => deleteList(input), {
		onSuccess: (data, input) => {
			if (data.data.success) {
				
				addToast(successToast({ message: data.data.message }), {
					autoDismiss: true,
				});
				dispatch({
					type: LIST_STORE_CONTANTS.CHANGE_LIST_UPDATE,
					payload: `LIST/Delete-list/${new Date()}`,
				});
				recordEvent(`List Deleted(List)`, {
					input,
					output: data,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent( `Failed to delete list(List)` , {
				input,
				output: err?.response?.data,
			});
		},
	});
};
export default useDeleteList;
