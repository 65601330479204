import React from 'react';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Input from '../../../../bootstrap/forms/Input';
import InputGroup from '../../../../bootstrap/forms/InputGroup';
import Select from '../../../../bootstrap/forms/Select';
import Option from '../../../../bootstrap/Option';
import { CREDIT_TYPE, PERMISSIONS } from '../../utils/constData';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../bootstrap/Card';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const CreditLimit = ({ filtersForm }) => {
	const onChangeHandle = (fieldName, value) => {
		filtersForm.setFieldValue(fieldName, value);
	};
	return (
		<div className='col-md-12'>
			<Card>
				<CardBody>
					<div className='row'>
						<CardLabel icon='Person-Search' iconColor='success'>
							<CardTitle>Discovery<Popovers
									desc={<>Access to search and manage influencers. <br /><b>Credit Type</b> <br /><b>Unlimited:</b> Available credits will be transferred to Member.<br /> <b>Allot:</b> Specific credits will be transferred to Member.</>}
									trigger='hover'>
									<Icon
										icon='Info'
										size='lg'
										style={{
											marginLeft: '3px',
											fontSize: '1.55rem',
										}}
									/>
								</Popovers></CardTitle>
						</CardLabel>
						<div className='col-md-6'>
							<FormGroup id='discovery_permission' label='Permission'>
								<Select
									onChange={filtersForm.handleChange}
									value={filtersForm.values.discovery_permission}
									onBlur={filtersForm.handleBlur}
									// isValid={filtersForm.isValid}
									isTouched={filtersForm.touched.discovery_permission}
									invalidFeedback={filtersForm.errors.discovery_permission}
									// validFeedback='Looks good!'
									ariaLabel='Select Permission'
									placeholder='Select Permission'>
									{PERMISSIONS &&
										Object.keys(PERMISSIONS).map((key) => (
											<Option value={PERMISSIONS[key].key}>
												{PERMISSIONS[key].text}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
						<div className='col-md-6'>
							<FormGroup id="creditType" label='Credit Type'>
							
								<Select
									onChange={filtersForm.handleChange}
									value={filtersForm.values.creditType}
									onBlur={filtersForm.handleBlur}
									// isValid={filtersForm.isValid}
									isTouched={filtersForm.touched.creditType}
									invalidFeedback={filtersForm.errors.creditType}
									// validFeedback='Looks good!'
									ariaLabel='Select Credit Type'
									placeholder='Select Credit Type'>
									{CREDIT_TYPE &&
										Object.keys(CREDIT_TYPE).map((key) => (
											<Option value={CREDIT_TYPE[key].key}>
												{CREDIT_TYPE[key].text}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
						{filtersForm.values.creditType !== CREDIT_TYPE.UNLIMITED.key && (
							<div className='col-md-6'>
								<FormGroup id='credit' label='Credit'>
									<InputGroup>
										<Input
											id='credit'
											ariaLabel='Credit'
											placeholder='Enter Credit'
											type='number'
											min={0}
											onChange={filtersForm.handleChange}
											value={filtersForm.values.credit}
										/>
									</InputGroup>
								</FormGroup>
							</div>
						)}
					</div>
				</CardBody>
			</Card>
		</div>
	);
};

export default CreditLimit;
