import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useSharedPermission } from '../../../../framework/basic-rest/control_panel/get-modules-permission';
import { useShareItem } from '../../../../framework/basic-rest/control_panel/share-item-permission';
import Button from '../../../bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import CustomButtonGroup from '../components/CustomButtonGroup';
import { ItemRef, ITEM_NAME } from './ControlData';

const ItemShare = ({
    setIsOpen,
    isOpen,
    ItemName,
    moduleType,
    itemList,
    itemRefType
}) => {
    const ACCESS_TYPE = [{
        _id: "FULL",
        type: "FULL",
        permission: "Full"
    },
    {
        _id: "NULL",
        type: "NULL",
        permission: "No Access"
    }]

    const {
        mutate: shareItem
    } = useShareItem();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            permission: null,
            accessType: "CUSTOM",
            moduleType: moduleType.toUpperCase(),
            listName: "",
            items: {
                item_id: null,
                permission: null,
                accessType: "NULL",
                itemRef: ItemRef[itemRefType]
            }
        },
        onSubmit: (values) => {
            shareItem({ body: values })
            setIsOpen(false);
        },
    })

    const [permissionOf, setPermissionOf] = useState({ type: "items", name: ItemName });

    const {
        data: itemPermission,
        refetch: refetchPermission,
        isLoading: isPermissionLoading
    } = useSharedPermission(permissionOf);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            size='xl'
            titleId='add-new-card'
            isCentered
            isStaticBackdrop
        >
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle>Sharing {ItemName}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                    <div className='w-100 rounded-2 bg-l10-info py-3 px-5 my-3'>
                        <div className='row'>
                            <div className='col-md-2 d-flex justify-content-start h5 fw-bold align-items-center'>
                                <Dropdown className='d-inline'>
                                    <DropdownToggle hasIcon={false}>
                                        <Button isLight color="info">
                                           {formik.values.listName || `Select the ${ItemName}`}
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {itemList && itemList.map(item => (
                                            <DropdownItem
                                                onClick={e => {
                                                    formik.setFieldValue(
                                                        'items.item_id',
                                                        item._id
                                                    )
                                                    formik.setFieldValue(
                                                        'listName',
                                                        item[ITEM_NAME[moduleType]]
                                                    )
                                                }}
                                            >
                                                {item[ITEM_NAME[moduleType]]}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="col-md-4">
                                <Input type="email" value={formik.values?.email} placeholder="Email" name="email" id="email" onChange={formik.handleChange} />
                            </div>
                            <div className="col-md-6 d-flex justify-content-around align-items-center">
                                {!isPermissionLoading && <CustomButtonGroup
                                    key="permission"
                                    buttonArr={itemPermission}
                                    activeTab={formik.values?.items?.permission}
                                    isDisabled={formik.values?.items?.accessType !== "FULL"}
                                    onClickTab={(e) => {
                                        formik.setFieldValue(
                                            'items.permission',
                                            e.target.id
                                        )
                                    }}
                                />}
                                <CustomButtonGroup
                                    key="accesstype"
                                    buttonArr={ACCESS_TYPE}
                                    activeTab={formik.values?.items?.accessType}
                                    onClickTab={(e) => {
                                        formik.setFieldValue(
                                            'items.accessType',
                                            e.target.id
                                        )
                                        if (e.target.id !== "FULL") {
                                            formik.setFieldValue(
                                                'items.permission',
                                                null
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button type="submit" className="mx-3" isLight color="info">
                            Submit
                        </Button>
                        <Button type="reset" className="mx-3" isLight color="info">
                            Reset
                        </Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default ItemShare