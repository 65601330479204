import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './tracking.slice';

const socialTrackingSlice = (state) => state.socialTracking || initialState;

export const selectCurrentTrackingId = createSelector(
	[socialTrackingSlice],
	(state) => state.currentTrackingId,
);

export const selectInfluencers = createSelector(
	[socialTrackingSlice],
	(state) => state.influencers,
);
export const selectTrackingIds = createSelector(
	[socialTrackingSlice],
	(state) => state.trackingIds,
);
export const selectTrackingDetails = createSelector(
	[socialTrackingSlice],
	(state) => state.currentTrackingDetails,
);
