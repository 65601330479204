import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CRMCreator } from '../../../store/App/crm/creators/crm.creators.selectors';
import { CRMProject } from '../../../store/App/crm/projects/crm.projects.selectors';
import { CRMCompany } from '../../../store/App/crm/companies/crm.companies.selectors';
import useDarkMode from '../../../hooks/useDarkMode';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import { CardBody } from '../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Select from '../../../components/bootstrap/forms/Select';
import useCreateTaskAssociationMutation from '../../../framework/basic-rest/crm/tasks/create-task-association';
import Option from '../../../components/bootstrap/Option';
import Creators from './AssociatedRecords/Creators';
import Companies from './AssociatedRecords/Companies';
import Projects from './AssociatedRecords/Projects';
import useCreateEmailAssociationMutation from '../../../framework/basic-rest/gmailIntegration/create-email-association';
import useEmailRemoveAssociationMutation from '../../../framework/basic-rest/gmailIntegration/remove-email-association';
import useAssignEmail from '../../../framework/basic-rest/gmailIntegration/assign-to-email';
import useRemoveAssignedEmail from '../../../framework/basic-rest/gmailIntegration/remove-assigned-email';

const AssociateEmail = ({ emailId, email }) => {
	const associateToModule = ['creator', 'project', 'partner'];

	const AllCreators = useSelector(CRMCreator);
	const allCreators = AllCreators?.map((creator) => creator);
	const associatedCreators = email?.associations?.creators;

	const AllProjects = useSelector(CRMProject);
	const allProjects = AllProjects?.map((project) => project);
	const associatedProject = email?.associations?.projects;

	const AllCompany = useSelector(CRMCompany);
	const allCompany = AllCompany?.map((company) => company);
	const associatedCompany = email?.associations?.companies;

	// const AllNotes = useSelector(CRMNotes);
	// const allNotes = AllCompany?.map((company) => company);
	// const associatedNotes = associateData?.data?.data?.associations?.notes;

	const { mutate: CreateAssociation, isLoading } = useCreateEmailAssociationMutation();
	const { isLoading: removingAssociation, mutate: removeEmailAssociation } =
		useEmailRemoveAssociationMutation();

	const associateFormik = useFormik({
		initialValues: {
			moduleSelected: '',
			itemOfModuleSelected: '',
		},
	});
	const handleAssociate = () => {
		const associateObj = {
			id: email?._id,
			toId: associateFormik.values.itemOfModuleSelected,
			type:
				associateFormik.values.moduleSelected === 'company'
					? 'company'
					: associateFormik.values.moduleSelected,
		};
		CreateAssociation(associateObj);
		// alert(JSON.stringify(associateObj));

		setShowAssociatedData(!showAssociatedData);
		setCreateAssociation(!createAssociation);
	};
	const { themeStatus, darkModeStatus } = useDarkMode();
	const OVERVIEW_TYPES = {
		CREATORS: 'Creator',
		PARTNER: 'Partners',
		PROJECTS: 'Projects',
		// NOTES: 'Notes',
	};
	const [activeOverviewTabs, setActiveOverviewTabs] = useState(OVERVIEW_TYPES.CREATORS);
	const [createAssociation, setCreateAssociation] = useState(false);
	const [showAssociatedData, setShowAssociatedData] = useState(true);

	return (
		// <Modal
		// 	setIsOpen={setShowAssociation}
		// 	isOpen={showAssociation}
		// 	size='xl'
		// 	titleId='associateTask'
		// 	isCentered
		// 	isAnimation={false}
		// 	isScrollable
		// 	isStaticBackdrop={false}
		// 	height='50rem'>
		// 	<ModalHeader setIsOpen={setShowAssociation}>
		// 		<h3 className='fs-bold'>Email</h3>
		// 	</ModalHeader>
		<ModalBody>
			<CardBody>
				<div className='d-flex justify-content-end'>
					<Button
						icon='Add'
						color='info'
						isLight
						onClick={() => {
							setCreateAssociation(!createAssociation);
							setShowAssociatedData(!showAssociatedData);
						}}>
						Associate this Email
					</Button>
				</div>
				<br />
				{createAssociation && (
					<div>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup className='col-12' label='Associate' id='moduleSelected'>
									<Select
										id='moduleSelected'
										onChange={associateFormik.handleChange}
										value={associateFormik.values.moduleSelected}
										ariaLabel='Select Module '
										// onBlur={associateFormik.handleBlur}
										validFeedback='Looks good!'
										placeholder='Select Associate'>
										{associateToModule &&
											associateToModule.map((x) => (
												<Option value={x}>{x}</Option>
											))}
									</Select>
								</FormGroup>
							</div>
							{associateFormik.values.moduleSelected === 'creator' && (
								<div className='col-12'>
									<FormGroup
										className='col-12'
										label='Associate Creator'
										id='itemOfModuleSelected'>
										<Select
											id='itemOfModuleSelected'
											onChange={associateFormik.handleChange}
											value={associateFormik.values.itemOfModuleSelected}
											ariaLabel='Select Module '
											onBlur={associateFormik.handleBlur}
											validFeedback='Looks good!'
											placeholder='Select Creator'>
											{allCreators &&
												allCreators.map((creator) => (
													<Option
														value={
															creator?._id
														}>{`${creator?.fullName && creator?.fullName || creator?.userName}`}</Option>
												))}
										</Select>
									</FormGroup>
								</div>
							)}
							{/* {associateFormik.values.moduleSelected === 'notes' && (
									<div className='col-12'>
										<FormGroup
											className='col-12'
											label='Associate'
											id='moduleSelected'>
											<Select
												id='moduleSelected'
												onChange={associateFormik.handleChange}
												value={associateFormik.values?.platform}
												ariaLabel='Select Module '
												onBlur={associateFormik.handleBlur}
												validFeedback='Looks good!'
												placeholder='Select Module'>
												{allProjects &&
													allProjects.map((x) => (
														<Option value={x}>{x}</Option>
													))}
											</Select>
										</FormGroup>
									</div>
								)} */}
							{associateFormik.values.moduleSelected === 'project' && (
								<div className='col-12'>
									<FormGroup
										className='col-12'
										label='Associate Project'
										id='itemOfModuleSelected'>
										<Select
											id='itemOfModuleSelected'
											onChange={associateFormik.handleChange}
											value={associateFormik.values.itemOfModuleSelected}
											ariaLabel='Select Module '
											onBlur={associateFormik.handleBlur}
											validFeedback='Looks good!'
											placeholder='Select Project'>
											{allProjects &&
												allProjects.map((project) => (
													<Option value={project?._id}>
														{project?.projectName}
													</Option>
												))}
										</Select>
									</FormGroup>
								</div>
							)}
							{associateFormik.values.moduleSelected === 'partner' && (
								<div className='col-12'>
									<FormGroup
										className='col-12'
										label='Associate Partner'
										id='itemOfModuleSelected'>
										<Select
											id='itemOfModuleSelected'
											onChange={associateFormik.handleChange}
											value={associateFormik.values.itemOfModuleSelected}
											ariaLabel='Select Module '
											onBlur={associateFormik.handleBlur}
											validFeedback='Looks good!'
											placeholder='Select Partner'>
											{allCompany &&
												allCompany.map((company) => (
													<Option
														value={
															company?._id
														}>{`${company?.name}`}</Option>
												))}
										</Select>
									</FormGroup>
								</div>
							)}
						</div>
						<br />
						<div className='row d-flex justify-content-end'>
							<div className='col-2'>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => {
										handleAssociate();
									}}>
									Associate
								</Button>
							</div>
						</div>
						<br />
					</div>
				)}
				{showAssociatedData && (
					<div>
						<div className='row g-3 mb-4'>
							<ButtonGroup>
								{Object.keys(OVERVIEW_TYPES).map((key) => (
									<Button
										size='lg'
										key={key}
										color={
											activeOverviewTabs === OVERVIEW_TYPES[key]
												? 'info'
												: themeStatus
										}
										onClick={() => setActiveOverviewTabs(OVERVIEW_TYPES[key])}>
										{OVERVIEW_TYPES[key]}
									</Button>
								))}
							</ButtonGroup>
						</div>
						<br />
						{activeOverviewTabs === OVERVIEW_TYPES.CREATORS && (
							<Creators
								associatedCreators={associatedCreators}
								emailId={email?._id}
								removeEmailAssociation={removeEmailAssociation}
							/>
						)}
						{activeOverviewTabs === OVERVIEW_TYPES.PARTNER && (
							<Companies
								associatedCompany={associatedCompany}
								emailId={email?._id}
								removeEmailAssociation={removeEmailAssociation}
							/>
						)}
						{activeOverviewTabs === OVERVIEW_TYPES.PROJECTS && (
							<Projects
								associatedProject={associatedProject}
								emailId={email?._id}
								removeEmailAssociation={removeEmailAssociation}
							/>
						)}
						{/* {activeOverviewTabs === OVERVIEW_TYPES.NOTES && (
								<Companies associatedProject={associatedProject} />
							)} */}
					</div>
				)}
			</CardBody>
		</ModalBody>
		// </Modal>
	);
};

export default AssociateEmail;
