import { useInfiniteQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import recordEvent from '../mixpanel/mixpanel';

async function getFilteredInfluencersDb({ queryKey, pageParam = 1 }) {
	const [_key, params] = queryKey;
	console.log("all",[_key, params])
	// if (!params) return false;
	const { platform } = params;
	// console.log({ params });
	const filters = params;
	const res = await http.post(
		`${API_ENDPOINTS.GET_INFLUENCERS_DB}?platform=${platform}&page=${pageParam}`,
		filters,
	);
    console.log("res", res);
	return res?.data?.data || null;
}

const useGetFilteredInfluencersDbQuery = (options) => {
	const dispatch = useDispatch();

	const onSuccess = (data) => {
		dispatch({ type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_RESULTS, payload: data });
		// dispatch({
		// 	type: CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS,
		// 	payload: `SearchFilter/${JSON.stringify(new Date())}`,
		// });
	};

	const onError = (data) => {
		dispatch({ type: DISCOVERY_STORE_CONSTANTS.SET_SEARCH_RESULTS, payload: null });
		// recordEvent('Modash Error(Search)', {
		// 	options,
		// 	output:data
		// });
	};

	console.log({
		options
	})

	return useInfiniteQuery([API_ENDPOINTS.GET_INFLUENCERS_DB, options], getFilteredInfluencersDb, {
		getNextPageParam: (lastPage) => {
			return lastPage.isNext && lastPage.currentPage + 1;
		},
		onSuccess,
		onError,
		enabled: !!options,
	});
};
export default useGetFilteredInfluencersDbQuery;