import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	workspaces: [],
	workspace: null,
	teamSync: null,
	workspaceSync: null
};

const slice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		setDefaultWorkspace: (state, action) => {
			state.workspace = action.payload;
		},
		setWorkspaces: (state, action) => {
			state.workspaces = action.payload;
		},
		setTeamSync: (state, action) => {
			state.teamSync = action.payload;
		},
		setWorkspaceSync: (state, action) => {
			state.workspaceSync = action.payload;
		}
	},
});

export const { setDefaultWorkspace, setWorkspaces, setTeamSync, setWorkspaceSync } = slice.actions;

export default slice.reducer;
