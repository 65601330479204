import React, { useState } from 'react';
import { getColorNameWithIndex } from '../../../../common/data/enumColors';
import Alert from '../../../../components/bootstrap/Alert';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../../components/PaginationButtons';
import useTaskRemoveAssociationMutation from '../../../../framework/basic-rest/crm/tasks/remove-task-association';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import { cardColor, cardIcon, getFirstLetter } from '../../../../helpers/helpers';
import useDarkMode from '../../../../hooks/useDarkMode';

const Creators = ({ associatedCreators, emailId, removeEmailAssociation }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['3']);

	return (
		<div>
			{associatedCreators && associatedCreators.length === 0 ? (
				<div className='row g-4'>
					<div className='col-md-12'>
						<Alert color='warning' isLight icon='Report'>
							No Associated Creators Found.
						</Alert>
					</div>
				</div>
			) : (
				<CardBody className='table-responsive overflow-auto'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th className='text-center'>NAME</th>
								<th className='text-center'>EMAIL</th>
								<th className='text-center'>CONTACT</th>
								<th className='text-center'>CREATOR OWNER</th>
								<th className='text-center'>HANDLE NAME</th>
								<th className='text-center'>PLATFORM</th>
								<th className='text-center'>CREATOR STAGE</th>
								<th className='text-center'>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{associatedCreators &&
								dataPagination(associatedCreators, currentPage, perPage).map(
									(creator, i) => (
										<tr key={creator._id} className='tableRow'>
											<td>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 48 }}>
															<div
																className={`bg-l${
																	darkModeStatus ? 'o25' : '25'
																}-${getColorNameWithIndex(
																	i,
																)} text-${getColorNameWithIndex(
																	i,
																)} rounded-2 d-flex align-items-center justify-content-center`}>
																<span className='fw-bold'>
																	{getFirstLetter(
																		creator?.fullName,
																	)}
																</span>
															</div>
														</div>
													</div>
													<div className='flex-grow-1'>
														<div className='fs-6'>
															<span
																className='text-nowrap'
																aria-hidden='true'
																role='button'>
																{`${creator?.fullName}`}
															</span>
														</div>
													</div>
												</div>
											</td>

											<td className='text-center'>
												<Button
													isLink
													color='light'
													icon='Email'
													className='text-lowercase'
													tag='a'
													href={`mailto:${creator?.email}`}>
													{creator?.email}
												</Button>
											</td>
											<td className='text-center'>{creator?.phone}</td>
											<td className='text-center'>
												{`${creator?.createdBy?.firstName} ${creator?.createdBy?.lastName}`}
											</td>
											<td className='text-center'>{creator?.userName}</td>
											<td className='text-center'>
												<Icon
													icon={cardIcon(creator?.platform.toLowerCase())}
													color={cardColor(
														creator?.platform.toLowerCase(),
													)}
													size='2x'
												/>
											</td>

											<td className='text-center'>
												{creator?.creator_stage}
											</td>
											<td className='text-center'>
												<Button
													isLink
													color='info'
													onClick={() => {
														ConfirmAlert(
															`Creator - ${creator?.firstName}`,
															`Do you really want to remove this Creator?`,
															() =>
																removeEmailAssociation({
																	toId: creator?._id,
																	id: emailId,
																	type: 'creator',
																}),
														);
													}}>
													Remove
												</Button>
											</td>
										</tr>
									),
								)}
						</tbody>
					</table>
					{associatedCreators && associatedCreators.length > 3 && (
						<PaginationButtons
							data={associatedCreators}
							label='creators'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					)}
				</CardBody>
			)}
		</div>
	);
};

export default Creators;
