import types from '@testing-library/user-event';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCreateProjectAssociationMutation from '../../../../framework/basic-rest/crm/projects/create-project-association';
import { CRMCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectSelectedCreators } from '../../../../store/App/crm/utils/crm.utils.selector';
import { CRM_PROJECTS_STORE_CONSTANTS } from '../../../../store/constants.store';
import Button from '../../../bootstrap/Button';
import { CardBody, CardFooter } from '../../../bootstrap/Card';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Select from '../../../bootstrap/forms/Select';
import Modal, { ModalBody, ModalHeader } from '../../../bootstrap/Modal';
import Option from '../../../bootstrap/Option';
import CustomSelect from '../crmHelpers/CustomSelect';
import { getLabel } from '../crmHelpers/helper';

const CreateAssociation = ({
	isOpen,
	setIsOpen,
	associate,
	from,
	formik,
	createAssociation,
	projectId,
}) => {
	return (
		<Modal
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			size='lg'
			titleId='upcomingEvent'
			isAnimation={false}
			isCentered
			isScrollable
			isStaticBackdrop
			height='50rem'>
			<ModalHeader setIsOpen={setIsOpen}>
				<h3 className='fs-bold'>Adding {from}</h3>
			</ModalHeader>
			<ModalBody>
				<CardBody>
					<div className='row g-3'>
						<div className='col-12'>
							<FormGroup className='col-12' id='toId'>
								<Select
									onChange={formik.handleChange}
									value={formik.values.toId}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.toId}
									invalidFeedback={formik.errors.toId}
									validFeedback='Looks good!'
									ariaLabel={`Select ${from}`}
									placeholder={`Select ${from}`}>
									{associate &&
										associate?.length !== 0 &&
										associate?.map(
											(x) =>
												x &&
												!x.isDeleted && (
													<Option
														value={x?._id}
														onClick={(e) => {
															formik.setFieldValue(
																'toId',
																e.target.value,
															);
														}}>
														{getLabel(from, x)}
													</Option>
												),
										)}
								</Select>
							</FormGroup>
						</div>
					</div>
					{/* <CustomSelect creators={associate} projectId={projectId} /> */}
				</CardBody>
				<CardFooter>
					<div className='row g-3'>
						<div className='col-12'>
							<Button
								color='info'
								className='w-100'
								onClick={() => {
									createAssociation(formik.values);
									setIsOpen(false);
								}}>
								Add
							</Button>
						</div>
					</div>
				</CardFooter>
			</ModalBody>
		</Modal>
	);
};

export default CreateAssociation;
