import React, { useState } from 'react';

import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardActions } from '../../../../bootstrap/Card';
import Chart from '../../../../extras/Chart';
import Popovers from '../../../../bootstrap/Popovers';
import Icon from '../../../../icon/Icon';

const AudienceDataChart = ({ data, title, desc, className="col-md-6" }) => {
	const [chartData] = useState({
		series: [
			{
				name: 'value',
				data: data.map((val) => (val.weight * 100).toFixed(2)),
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				categories: data.map((val) => (val?.name ? val.name : val?.code)),
			},
		},
	});

	return (
		<div className={className}>
			<Card stretch>
				<CardHeader>
					<CardLabel>
						<CardTitle>{title}</CardTitle>
					</CardLabel>
					<CardActions>
						<Popovers desc={desc} trigger='hover'>
							<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
						</Popovers>
					</CardActions>
				</CardHeader>
				<CardBody>
					<Chart
						series={chartData.series}
						options={chartData.options}
						type='bar'
						height={280}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

export default AudienceDataChart;
