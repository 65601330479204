import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import recordEvent from '../../mixpanel/mixpanel';
import { CRM_CREATORS_STORE_CONSTANTS } from '../../../../store/constants.store';

async function createCreator(input) {
	const { data } = await http.post(API_ENDPOINTS.CREATE_CRM_CREATOR, input);
	return data;
}

const useCreateCreatorMutation = () => {
	const { addToast } = useToasts();
	const queryClient = useQueryClient();
	const dispatch = useDispatch()
	return useMutation((input) => createCreator(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data
				// queryClient.invalidateQueries([API_ENDPOINTS.GET_ALL_CRM_CREATORS]);
				dispatch({
					type: CRM_CREATORS_STORE_CONSTANTS.IS_UPDATE_CREATOR,
					payload: `/Creator/NewCreator/${new Date()}`,
				});
				addToast(successToast({ message: 'Creator Created' }), {
					autoDismiss: true,
				});
				recordEvent(`Creator Created(CRM)`, {
					input,
					output:res
				});
			} else {
				addToast(dangerToast({ message: `${res.errorMessage}` }), {
					autoDismiss: true,
				});
			}
		},
		onError: (err, input) => {
			console.log(err);
			addToast(dangerToast({ message: `${err?.response?.data?.errorMessage}` }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to create creator(CRM)`, {
				input,
				output:err
			});
		},
	});
};
export default useCreateCreatorMutation;
