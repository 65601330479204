import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteContact } from '../../../framework/basic-rest/contacts_and_pricing/delete-contact';
import { useGetAllContacts } from '../../../framework/basic-rest/contacts_and_pricing/get-all-contacts';
import { useFetchAllInfluencerCP } from '../../../framework/basic-rest/list/get-influencer-cp';
import { ConfirmAlert } from '../../../helpers/confirm-helper';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import CommonFilterTag from '../../../pages/common/CommonFilterTag';
import {
	selectInfluencerContact,
	selectIsContactUpdated,
} from '../../../store/App/List/list.selector';
import { LIST_STORE_CONTANTS } from '../../../store/constants.store';
import Alert from '../../bootstrap/Alert';
import Badge from '../../bootstrap/Badge';
import Button from '../../bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import OpenContact from './Contact_Pricing_Modals/OpenContact';
import DefaultContactCard from './DefaultContact';
import { NoListState } from './EmptyStates';

const MyContactsCard = ({ influencer, setIsAddContact }) => {
	// const { data: influencerInfo } = useFetchAllInfluencerCP();
	const contacts = useSelector(selectInfluencerContact);

	const isContactUpdated = useSelector(selectIsContactUpdated);

	const { darkModeStatus } = useDarkMode;
	const [listName, setlistName] = useState('');
	const [isOpenContact, setIsOpenContact] = useState(false);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			influencer_id: influencer?._id,
			listId: influencer?.lists[0]._id || '',
			i_index_id: influencer?.influencer_index_id,
			listName: influencer?.lists[0].listName || '',
		},
	});

	const {
		// data: contacts,
		isLoading: isLoadingContacts,
		refetch: refetchContacts,
	} = useGetAllContacts({
		influencerId: formik.values.influencer_id,
		listId: formik.values.listId,
		iIndexId: formik.values.i_index_id,
	});

	const [removedContact, setRemovedContact] = useState({});

	const { mutate: removeContact, isLoading: isRemoving, data: deletedData } = useDeleteContact();
	const deleteContact = (influencerId, listId, iIndexId, contactId, contactIndex) => {
		removeContact({
			influencer_id: influencerId,
			list_id: listId,
			i_index_id: iIndexId,
			contact_id: contactId,
		});
		setRemovedContact({ contactIndex });
	};

	useEffect(() => {
		refetchContacts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deletedData, isContactUpdated]);

	const dispatch = useDispatch();
	dispatch({
		type: LIST_STORE_CONTANTS.SET_PROFILE_LIST_ID,
		payload: formik.values.listId,
	});
	const handleChange = () => {
		dispatch({
			type: LIST_STORE_CONTANTS.SET_PROFILE_LIST_ID,
			payload: formik.values.listId,
		});
	};

	return (
		<Page container='fluid'>
			{/* <div className='row w-100'> */}
			<SubHeader>
				<SubHeaderLeft>
					<span className='h4 fw-bold'>Contacts</span>
				</SubHeaderLeft>
				<SubHeaderRight>
					<CommonFilterTag
						title='Source'
						text={<Badge className='fs-6'>{formik.values.listName}</Badge>}
					/>
					<div className='row'>
						<div className='col'>
							<Dropdown direction='down'>
								<DropdownToggle>
									<Button color='danger' isLight>
										Select
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<div className='container py-2'>
										<form>
											<div className='col-12 dv-list-check-group'>
												<FormGroup label='Lists'>
													{influencer &&
													influencer.lists &&
													influencer.lists.length !== 0 ? (
														<ChecksGroup>
															{influencer.lists.map((list, index) => (
																<Checks
																	// eslint-disable-next-line react/no-array-index-key
																	key={`list-check-${index}`}
																	id={list.listName}
																	label={list.listName}
																	value={list._id}
																	onChange={(e) => {
																		formik.setFieldValue(
																			'listId',
																			e.target.value,
																		);
																		formik.setFieldValue(
																			'listName',
																			e.target.id,
																		);
																		// handleChange();
																	}}
																	checked={
																		list._id ===
																		formik.values.listId
																	}
																/>
															))}
														</ChecksGroup>
													) : (
														<NoListState height={100} width={100} />
													)}
												</FormGroup>
											</div>
										</form>
									</div>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</SubHeaderRight>
			</SubHeader>
			{/* </div> */}
			<div className='row g-3 mt-4 h-100'>
				{!isLoadingContacts &&
					contacts &&
					contacts.length !== 0 &&
					contacts.map(
						(data, index) =>
							!data.isDeleted && (
								<div className='col-md-4'>
									<OpenContact
										contact={data.contacts}
										additionalInfo={data.additionalInfo}
										isOpenContact={isOpenContact}
										setIsOpenContact={setIsOpenContact}
										source={data.list.listName}
									/>
									<Card stretch>
										<CardHeader>
											<CardLabel icon='StackedLineChart'>
												<CardTitle>{data.contacts.label}</CardTitle>
											</CardLabel>
											<CardActions>
												Source : <strong>{`${data.list.listName}`}</strong>.
											</CardActions>
										</CardHeader>
										<div style={{ height: '30rem' }}>
											<CardBody className='align-items-center justify-content-center'>
												<div className='row g-4 align-items-center'>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-warning rounded-2 p-2`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PersonPin'
																	size='3x'
																	color='warning'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-4 mb-0'>{`${data.contacts.firstName} ${data.contacts.lastName}`}</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	FullName
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<div
															className={`d-flex overflow-auto align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-info rounded-2 p-2`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Email'
																	ovonts
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-4 mb-0'>
																	{data.contacts.email}
																</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	Email
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-primary rounded-2 p-2`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Call'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-4 mb-0'>
																	{data.contacts.phone}
																</div>
																<div className='text-muted mt-n2 truncate-line-1'>
																	Phone
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-12'>
														<div
															className={`d-flex align-items-center bg-l${
																darkModeStatus ? 'o25' : '10'
															}-success rounded-2 p-2`}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='LocationOn'
																	size='3x'
																	color='success'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-4 mb-0'>
																	{data.contacts.loc}
																</div>
																<div className='text-muted mt-n2'>
																	Location
																</div>
															</div>
														</div>
													</div>
													<div className='row g-3'>
														<div className='col-xl-6'>
															{data?.additionalInfo.length !== 0 ? (
																<Button
																	onClick={() =>
																		setIsOpenContact(true)
																	}
																	color='info'
																	isLight
																	className='w-100'>
																	Show More
																</Button>
															) : (
																<Button
																	isDisable
																	color='info'
																	isLight
																	className='w-100'>
																	Show More
																</Button>
															)}
														</div>
														<div className='col-xl-6'>
															<Button
																onClick={() => {
																	ConfirmAlert(
																		`${data.contacts.firstName}'s Contact`,
																		`Do you really want to remove Contact for ${influencer.influencerData.profile.fullname}`,
																		() =>
																			deleteContact(
																				influencer._id,
																				data.list._id,
																				influencer.influencer_index_id,
																				data._id,
																				index,
																			),
																	);
																}}
																color='danger'
																isLight
																className='w-100'>
																Delete
															</Button>
														</div>
													</div>
												</div>
											</CardBody>
										</div>
									</Card>
								</div>
							),
					)}
				<div className='col-md-4'>
					<DefaultContactCard influencer={influencer} source='Direct' />
				</div>
				<div className='col-md-4' style={{ height: '30rem' }}>
					<Card stretch>
						<CardBody className='d-flex align-items-center justify-content-center'>
							<Button
								color='info'
								size='lg'
								isLight
								className='w-100 h-100'
								onClick={() => {
									setIsAddContact(true);
								}}
								icon='AddCircle'>
								Create Contact
							</Button>
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	);
};

export default MyContactsCard;
