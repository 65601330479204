import { useQuery } from 'react-query';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const fetchInterestSuggestion = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { text, platform } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.INTEREST_SUGGESTION}?platform=${platform}&query=${text}`,
	);
	return data.data || [];
};

export const useInterestSuggestionQuery = (options) => {
	return useQuery([API_ENDPOINTS.INTEREST_SUGGESTION, options], fetchInterestSuggestion);
};
