import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { CREDITS_STORE_CONSTANTS, DISCOVERY_STORE_CONSTANTS } from '../../../store/constants.store';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';

export const fetchInfluencerReport = async ({ queryKey }) => {
	const [_key, _params] = queryKey;
	const { userId, platform } = _params;
	const { data } = await http.get(
		`${API_ENDPOINTS.GET_INFLUENCER_REPORT}?platform=${platform}&userId=${userId}`,
	);
	return data.data || [];
};

export const useInfluencerReportQuery = (options) => {
	const dispatch = useDispatch();
	const onSuccess = (data) => {
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_INFLUENCER_REPORT,
			payload: data
		})
		dispatch({
			type: CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS,
			payload: `Full/Report/${data.updatedAt}`
		})
	}
	const onError = (data) => {
		dispatch({
			type: DISCOVERY_STORE_CONSTANTS.SET_INFLUENCER_REPORT,
			payload: null
		})
	}

	return useQuery([API_ENDPOINTS.GET_INFLUENCER_REPORT, options], fetchInfluencerReport, {
		onSuccess,
		onError,
		enabled: !!options,
	});
};
