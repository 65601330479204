import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';

import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import recordEvent from '../mixpanel/mixpanel';

async function getEmailList (input) {
    console.log("Email bucket name", input);
    const {data} = await http.post(API_ENDPOINTS.GET_BUCKET_EMAIL, input);
    return data;
}

const useGetEmailList = () => {
    const { addToast } = useToasts();
	const queryClient = useQueryClient();
	
	return useMutation((input) => getEmailList(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				
				// addToast(successToast({ message: res.message}), {
				// 	autoDismiss: true,
				// });
				recordEvent('-------', {
					input,
					output: res,
				});
			} else {
				addToast(dangerToast({ message: `${res.message}` }), {
					autoDismiss: true,
				});
				recordEvent(res.message, {
					input,
					output: res,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({message:err.response.data.errorMessage}), {
				autoDismiss: true,
			});
			recordEvent(`Failed to add to bucket(CRM)`, {
				input,
				output: err,
			});
		},
	});
};
export default useGetEmailList;
