import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useUI } from '../../../states/contexts/ui.context';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';


async function analyticsProjectUpdateInfluencers(input) {
	const { data } = await http.put(`${API_ENDPOINTS.UPDATE_TRACKING_POST}?projectId=${input.socialProjectId}`, input);
	return data;
}

const useAnalyticsUpdateInfluencersMutation = () => {
	const { setModalMeta, closeModal } = useUI();
	const { addToast } = useToasts();
	const cache = useQueryClient();

	return useMutation((input) => analyticsProjectUpdateInfluencers(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				cache.invalidateQueries(API_ENDPOINTS.GET_TRACKING_POST);
				cache.invalidateQueries(API_ENDPOINTS.GET_CREDITS);
				setModalMeta(null);
				closeModal();
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
				recordEvent(`Influencer post updated in tracking(Reports & Analytics)`, {
					input,
					output: res,
				});
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				recordEvent(res.data.data.errorMessage, {
					influencerandProjectDetails: res.data.data,
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent("Failed to update Influencer postin tracking(Reports & Analytics)", {
				input,
				output: err?.response?.data,
			});
		},
	});
};
export default useAnalyticsUpdateInfluencersMutation;
