import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import { CREDITS_STORE_CONSTANTS } from '../../constants.store';
import { setCreditsDetails, setIsCreditsUpdate } from './credits.slice';

function* saveCredits(actions) {
	try {
		const creditsData = actions.payload;
		yield put(setCreditsDetails(creditsData));
		return true;
	} catch (error) {
		return false;
	}
}

function* changeIsUpdateCredits(actions) {
	try {
		const creditsUpdated = actions.payload;
		yield put(setIsCreditsUpdate(creditsUpdated));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* creditsSaga() {
	yield takeLatest(CREDITS_STORE_CONSTANTS.SET_CREDITS_DETAILS, saveCredits);
	yield takeLatest(CREDITS_STORE_CONSTANTS.SET_ISUPDATE_CREDITS, changeIsUpdateCredits);
}
