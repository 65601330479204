import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import http from '../../utils/http';
import API_ENDPOINTS from '../../utils/api-endpoints';
import { selectIsUpdateCrmNotes } from '../../../../store/App/crm/notes/crm.notes.selector';
import { selectIsUpdateCrmTasks } from '../../../../store/App/crm/tasks/crm-tasks-selector';
import { selectIsUpdateCrmCreator } from '../../../../store/App/crm/creators/crm.creators.selectors';
import { selectIsCompanyUpdate } from '../../../../store/App/crm/companies/crm.companies.selectors';
import { selectIsUpdateCrmProject } from '../../../../store/App/crm/projects/crm.projects.selectors';

const getAllCrmTasks = async () => {
	const data = await http.get(`${API_ENDPOINTS.GET_CRM_TASKS}`);
	return data?.data;
};

const useGetAllCrmTasks = () => {
	const isNOTEupdated = useSelector(selectIsUpdateCrmNotes);
	const isTaskUpdated = useSelector(selectIsUpdateCrmTasks);
	const isUpdateCreator = useSelector(selectIsUpdateCrmCreator);
	const isUpdateCompany = useSelector(selectIsCompanyUpdate);
	const isProjectUpdated = useSelector(selectIsUpdateCrmProject);
	return useQuery(
		[
			API_ENDPOINTS.GET_CRM_TASKS,
			isNOTEupdated,
			isTaskUpdated,
			isUpdateCompany,
			isUpdateCreator,
			isProjectUpdated,
		],
		getAllCrmTasks,
	);
};

export { useGetAllCrmTasks, getAllCrmTasks };
