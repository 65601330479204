import React from 'react';
import Button from '../../../bootstrap/Button';
import { CardBody } from '../../../bootstrap/Card';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Textarea from '../../../bootstrap/forms/Textarea';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';

const SaveSearchModal = ({ isOpen, setIsOpen, formik }) => {
	return (
		<Modal setIsOpen={setIsOpen} isOpen={isOpen} size='lg' titleId='add-new-card' isCentered>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id='add-new-card'>Save Search</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<form onSubmit={formik.handleSubmit}>
					<div className='col-12'>
						<FormGroup id='name' label='Name'>
							<Input
								placeholder='Name'
								onChange={formik.handleChange}
								value={formik.values.name}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup id='desc' label='Description'>
							<Textarea
								placeholder='Description about filters'
								onChange={formik.handleChange}
								value={formik.values.desc}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-3 d-flex flex-row-reverse'>
						<Button color='info' type='submit'>
							Submit
						</Button>
						<Button
							className='mx-3'
							color='info'
							onClick={() => {
								formik.handleReset();
							}}>
							reset
						</Button>
					</div>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default SaveSearchModal;
