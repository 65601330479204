import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './crm.companies.slice';

const crmCompanySlice = (state) => state.companies || initialState;

export const CRMCompany = createSelector([crmCompanySlice], (state) => state.crmCompany);
export const selectCompanyToEdit = createSelector(
	[crmCompanySlice],
	(state) => state.companyToEdit,
);

export const selectCrmCompanyId = createSelector([crmCompanySlice], (state) => state.crmCompanyId);
export const selectCrmCompanyById = createSelector(
	[crmCompanySlice],
	(state) => state.crmCompanyById,
);
export const selectGetCompany = createSelector([crmCompanySlice], (state) => state.getCompany);
export const selectIsCompanyUpdate = createSelector(
	[crmCompanySlice],
	(state) => state.isCompanyUpdate,
);
