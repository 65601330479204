import { useFormik } from 'formik';
import { set } from 'js-cookie';
import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../bootstrap/Button';
import { CardBody } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Select from '../../bootstrap/forms/Select';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Option from '../../bootstrap/Option';
import Spinner from '../../bootstrap/Spinner';
import { useGetCurrencies } from '../../../framework/basic-rest/contacts_and_pricing/get-system-currencies';
import useformikMutation from '../../../framework/basic-rest/crm/creators/update-crm-creator';
import { checkArrayData, fixDecimal } from '../../../helpers/helpers';
import { CRMCreator } from '../../../store/App/crm/creators/crm.creators.selectors';
import {
	selectSocialTrackingPlatform,
	selectSocialTrackingPostTypes,
} from '../../../store/App/SocialAnalytics/analytics.selector';
import {
	ANALYTICS_STORE_CONSTANTS,
	CRM_CREATORS_STORE_CONSTANTS,
} from '../../../store/constants.store';
import validateCreator from '../../../pages/crm/crmHelpers/crmCreatorValidation';
import useAddCreatorsMutation from '../../../framework/basic-rest/crm/creators/create-crm-creator';

const AddToCrmModal = ({ influencer, platform, setShowEdit, showEdit }) => {
	const {
		mutate: addCreators,
		isLoading: addingCreators,
		isError: errorCreatingCreator,
	} = useAddCreatorsMutation();

	const { data: currencies } = useGetCurrencies();
	const dispatch = useDispatch();

	let phone;
	let email;
	const contactDetails = influencer?.contacts
		? influencer?.contacts?.map((contact) => {
				if (contact.type === 'email') email = contact.value;
				if (contact.type === 'phone') phone = contact.value;
				return null;
		  })
		: (email = 'Not Provided');
	phone = 'Not Provided';

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			fullName: influencer?.profile?.fullname,
			userName: influencer?.profile?.username,
			phone,
			email,
			creator_stage: '',
			platform: platform.toUpperCase(),
			pricing: [],
			loc: '',
			tags: [],
			followers: influencer?.profile?.followers,
			engagementRate: influencer?.profile?.engagementRate,
			engagements: influencer?.profile?.engagements,
			handle_link: influencer?.profile?.url,
			address: {
				address_line_1: '',
				address_line_2: '',
				city: '',
				state: '',
				country: '',
				pincode: '',
			},
			opt_contacts: [],
		},
		validate: validateCreator,
	});
	const creatorStages = [
		'Prospect',
		'Applicant',
		'My Influencer',
		'Needs Outreach',
		'In Talks',
		'Negotiation Ongoing',
	];
	const platforms = useSelector(selectSocialTrackingPlatform);
	const contentTypes = useSelector(selectSocialTrackingPostTypes);
	const [tagsOption, setTagsOption] = useState('');
	const addInput = () => {
		formik.setFieldValue('pricing', [
			...formik.values.pricing,
			{
				contentType: '',
				price: {
					upper: 0,
					lower: 0,
				},
				currency: '',
			},
		]);
	};

	const addContact = () => {
		formik.setFieldValue('opt_contacts', [
			...formik.values.opt_contacts,
			{
				fullName: '',
				c_type: '',
				value: '',
			},
		]);
	};
	const removeInput = (i) => {
		// console.log(i);
		const updateInput = formik.values.pricing;
		updateInput.splice(i, 1);
		formik.setFieldValue('pricing', [...updateInput]);
	};

	const removeContact = (i) => {
		// console.log(i);
		const updateInput = formik.values.opt_contacts;
		updateInput.splice(i, 1);
		formik.setFieldValue('opt_contacts', [...updateInput]);
	};
	const onChangeContentType = (i, e) => {
		// const updateValue = formik.values.pricing;
		const contentType = checkArrayData(e) ? e[0].value : null;
		formik.setFieldValue(`pricing[${i}].contentType`, contentType);
	};
	const onChangeContactType = (i, e) => {
		// const updateValue = formik.values.pricing;
		// const type = checkArrayData(e) ? e[0].value : null;
		formik.setFieldValue(`opt_contacts[${i}].c_type`, e.target.value);
	};
	const onChangeContact = (i, e) => {
		// const updateValue = formik.values.pricing;
		// updateValue[i].price.upper = e.target.value;
		formik.setFieldValue(`opt_contacts[${i}].value`, e.target.value);
	};
	const onChangeFullName = (i, e) => {
		// const updateValue = formik.values.pricing;
		// updateValue[i].price.upper = e.target.value;
		formik.setFieldValue(`opt_contacts[${i}].fullName`, e.target.value);
	};
	const onChangeUpperPrice = (i, e) => {
		// const updateValue = formik.values.pricing;
		// updateValue[i].price.upper = e.target.value;
		formik.setFieldValue(`pricing[${i}].price.upper`, e.target.value);
	};
	const onChangeLowerPrice = (i, e) => {
		// const updateValue = formik.values.pricing;
		// updateValue[i].price.lower = e.target.value;
		formik.setFieldValue(`pricing[${i}].price.lower`, e.target.value);
	};
	const onChangeCurrency = (i, e) => {
		formik.setFieldValue(`pricing[${i}].currency`, e);
	};

	return (
		<Modal
			setIsOpen={setShowEdit}
			isOpen={showEdit}
			size='lg'
			titleId='upcomingEvent'
			isCentered
			isAnimation={false}
			isScrollable
			isStaticBackdrop
			height='50rem'>
			<ModalHeader setIsOpen={setShowEdit}>
				<ModalTitle id='creatorEdit' className='fs-4'>
					Add To Creators
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<CardBody>
					<div className='row g-4'>
						<div className='col-md-5'>
							<h3>Basic Details</h3>
						</div>
						<div className='row g-4'>
							<div className='col-md-6'>
								<FormGroup id='fullName' label='Full Name'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.fullName}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.fullName}
										invalidFeedback={formik.errors?.fullName}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='email' label='Email'>
									<Input
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										value={formik.values.email}
										isValid={formik.isValid}
										isTouched={formik.touched?.email}
										invalidFeedback={formik.errors?.email}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='phone' label='Phone'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.phone}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup className='col-12' label='Platform' id='platform'>
									<AsyncTypeahead
										id='async-creatorstage'
										// isLoading={isLoadingCurrencies || false}
										labelKey='label'
										minLength={0}
										onSearch={(query) => {
											// setLocationQuery(query);
											// refetechCurrencies();
										}}
										onChange={(e) => {
											dispatch({
												type: ANALYTICS_STORE_CONSTANTS.SET_POST_TYPES,
												payload: {
													platform: e[0]?.value,
												},
											});
											formik.setFieldValue('platform', e[0]?.value);
										}}
										options={platforms}
										placeholder={formik.values.platform}
										useCache={false}
										renderMenuItemChildren={(option, props) => (
											<div>
												<span>{option.label}</span>
											</div>
										)}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='userName' label='Platform username'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.userName}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup
									id='handle_link'
									label={
										formik.values.platform === 'YOUTUBE'
											? 'Channel Link'
											: 'Handle Link'
									}>
									<Input
										onChange={formik.handleChange}
										value={formik.values.handle_link}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.handle_link}
										invalidFeedback={formik.errors?.handle_link}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup
									id='followers'
									label={
										formik.values.platform === 'YOUTUBE'
											? 'Subscribers'
											: 'Followers'
									}>
									<Input
										onChange={formik.handleChange}
										value={formik.values.followers}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='engagementRate' label='Engagement Rate (0 - 100)'>
									<Input
										onChange={formik.handleChange}
										value={fixDecimal(formik.values.engagementRate * 100, 2)}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='engagements' label='Engagements'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.engagements}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='loc' label='Location'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.loc}
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup
									className='col-12'
									label='Relationship Stage'
									id='creator_stage'>
									<AsyncTypeahead
										id='async-creatorstage'
										// isLoading={isLoadingCurrencies || false}
										labelKey='name'
										minLength={0}
										onSearch={(query) => {
											// setLocationQuery(query);
											// refetechCurrencies();
										}}
										onChange={(e) => {
											formik.setFieldValue('creator_stage', e[0]);
										}}
										options={creatorStages}
										placeholder={formik.values.creator_stage}
										useCache={false}
										renderMenuItemChildren={(option, props) => (
											<div>
												<span>{option}</span>
											</div>
										)}
									/>
								</FormGroup>
							</div>
							<div className='col-md-5'>
								<FormGroup id='tags.label' label='Tag'>
									{/* <Input
													onBlur={formik.handleBlur}
													placeholder='Tag for Creator'
													onChange={(e) => {
														onChangeTag(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.tags}
													invalidFeedback={formik.errors?.tags}
													validFeedback='Looks good!'
												/> */}
									<AsyncTypeahead
										className='border-0 shadow-none bg-transparent w-100'
										multiple
										id='async-tag'
										minLength={0}
										onSearch={(query) => {
											setTagsOption(query);
										}}
										onChange={(e) =>
											formik.setFieldValue(
												'tags',
												e.map((tag) => tag),
											)
										}
										defaultSelected={formik.values.tags}
										options={[{ label: tagsOption }]}
										placeholder='Tags'
										renderMenuItemChildren={(option) => (
											<div>
												<span>{option.label}</span>
											</div>
										)}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row g-3'>
							<div className='col-md-12'>
								<h3>Address</h3>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.address_line_1' label='Address Line 1'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.address_line_1}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.address_line_1}
										invalidFeedback={formik.errors?.address?.address_line_1}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.address_line_2' label='Address Line 2'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.address_line_2}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.address_line_2}
										invalidFeedback={formik.errors?.address?.address_line_2}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.city' label='City'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.city}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.city}
										invalidFeedback={formik.errors?.address?.city}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.state' label='State'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.state}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.state}
										invalidFeedback={formik.errors?.address?.state}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.country' label='Country'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.country}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.country}
										invalidFeedback={formik.errors?.address?.country}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
							<div className='col-md-6'>
								<FormGroup id='address.pincode' label='Pincode'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.address?.pincode}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched?.address?.pincode}
										invalidFeedback={formik.errors?.address?.pincode}
										validFeedback='Looks good!'
									/>
								</FormGroup>
							</div>
						</div>

						<div className='row g-4'>
							<div className='col-md-5'>
								<h3>Pricing</h3>
							</div>
							<div className='col-md-6'>
								<Button
									color='info'
									style={{ marginRight: '15px' }}
									isLight
									onClick={() => addInput()}>
									Add Pricing
								</Button>
							</div>
							{formik.values.pricing &&
								formik.values.pricing.length !== 0 &&
								formik.values.pricing.map((x, i) => (
									<div className='row g-3'>
										<div className='col-md-5'>
											<FormGroup
												id='pricing.contentType'
												label='Content Type'>
												<AsyncTypeahead
													id='async-currency'
													// isLoading={isLoadingCurrencies || false}
													labelKey='label'
													minLength={0}
													onSearch={(query) => {
														// setLocationQuery(query);
														// refetechCurrencies();
													}}
													onChange={(e) => {
														onChangeContentType(i, e);
													}}
													options={contentTypes}
													placeholder={
														formik.values.pricing[i].contentType
													}
													useCache={false}
													renderMenuItemChildren={(option, props) => (
														<div>
															<span>{option.label}</span>
														</div>
													)}
												/>
											</FormGroup>
										</div>
										<div className='col-md-5'>
											<FormGroup
												id='pricing.price.upper'
												label='Highest Price'>
												<Input
													onBlur={formik.handleBlur}
													placeholder={
														formik.values.pricing[i].price.upper
													}
													onChange={(e) => {
														onChangeUpperPrice(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.pricing}
													invalidFeedback={formik.errors?.pricing}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-5'>
											<FormGroup
												id='pricing.price.lower'
												label='Lowest Price'>
												<Input
													onBlur={formik.handleBlur}
													placeholder={
														formik.values.pricing[i].price.lower
													}
													onChange={(e) => {
														onChangeLowerPrice(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.pricing}
													invalidFeedback={formik.errors?.pricing}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-5'>
											<FormGroup id='pricing.currency' label='Currency'>
												<AsyncTypeahead
													id='async-currency'
													// isLoading={isLoadingCurrencies || false}
													labelKey='name'
													minLength={0}
													onSearch={(query) => {
														// setLocationQuery(query);
														// refetechCurrencies();
													}}
													onChange={(e) => {
														onChangeCurrency(
															i,
															e.map((x_) => x_.cc)[0],
														);
													}}
													options={currencies && currencies?.data}
													placeholder={formik.values.pricing[i].currency}
													useCache={false}
													renderMenuItemChildren={(option, props) => (
														<div>
															<span>{option.name}</span>
														</div>
													)}
												/>
											</FormGroup>
										</div>
										<div className='col-md-2'>
											<Button
												style={{ marginTop: '27px' }}
												color='danger'
												isLight
												onClick={() => removeInput(i)}>
												Remove
											</Button>
										</div>
									</div>
								))}
						</div>
						<div className='row g-4'>
							<div className='col-md-5'>
								<h3>Additional Contact</h3>
							</div>
							<div className='col-md-6'>
								<Button
									color='info'
									style={{ marginRight: '15px' }}
									isLight
									onClick={() => addContact()}>
									Add Contact
								</Button>
							</div>
							{formik.values.opt_contacts &&
								formik.values.opt_contacts.length !== 0 &&
								formik.values.opt_contacts.map((x, i) => (
									<div className='row g-3'>
										<div className='col-md-5'>
											<FormGroup id='opt_contacts.fullName' label='Fullname'>
												<Input
													onBlur={formik.handleBlur}
													placeholder={
														formik.values.opt_contacts[i].fullName
															? formik.values.opt_contacts[i].fullName
															: 'Fullname for contact'
													}
													onChange={(e) => {
														onChangeFullName(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.opt_contacts}
													invalidFeedback={formik.errors?.opt_contacts}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-5'>
											<FormGroup
												id='opt_contacts.c_type'
												label='Contact Type'>
												<Input
													onBlur={formik.handleBlur}
													placeholder={
														formik.values.opt_contacts[i].c_type
															? formik.values.opt_contacts[i].c_type
															: 'Type of Contact'
													}
													onChange={(e) => {
														onChangeContactType(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.opt_contacts}
													invalidFeedback={formik.errors?.opt_contacts}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>
										<div className='col-md-5'>
											<FormGroup id='opt_contacts.value' label='Contact'>
												<Input
													onBlur={formik.handleBlur}
													placeholder={
														formik.values.opt_contacts[i].value
															? formik.values.opt_contacts[i].value
															: 'Contact'
													}
													onChange={(e) => {
														onChangeContact(i, e);
													}}
													value={x[i]}
													isValid={formik.isValid}
													isTouched={formik.touched?.opt_contacts}
													invalidFeedback={formik.errors?.opt_contacts}
													validFeedback='Looks good!'
												/>
											</FormGroup>
										</div>

										<div className='col-md-2'>
											<Button
												style={{ marginTop: '27px' }}
												color='danger'
												isLight
												onClick={() => removeContact(i)}>
												Remove
											</Button>
										</div>
									</div>
								))}
						</div>
						<div className='row g-3'>
							<div className='row g-3' style={{ paddingTop: '3rem' }}>
								<div className='col-12'>
									<Button
										isDisable={
											addingCreators && !errorCreatingCreator
											// !(formik.isValid && formik.dirty)
										}
										color='info'
										onClick={() => {
											addCreators(formik.values);

											// handleEdit();
											setShowEdit(!showEdit);
										}}>
										{addingCreators && !errorCreatingCreator
											? 'Please wait...'
											: 'Add'}
										{addingCreators && !errorCreatingCreator && (
											<Spinner isSmall inButton />
										)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</ModalBody>
		</Modal>
	);
};

export default AddToCrmModal;
