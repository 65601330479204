import React, { useCallback, useEffect, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';

import Button from '../../bootstrap/Button';
import Avatar from '../../Avatar';
import Icon from '../../icon/Icon';
import {
	cardIcon,
	fixDecimal,
	nFormatter,
	profileUrl,
	shortString,
} from '../../../helpers/helpers';
import CustomSkeleton from '../../CustomSkeleton';
import Tooltips from '../../bootstrap/Tooltips';
import AddToList from './AddToList';

import AddToFavourite from './AddToFavourite';
import Popovers from '../../bootstrap/Popovers';
import Badge from '../../bootstrap/Badge';
import AddToAnalytics from './AddToAnalytics';
import recordEvent from '../../../framework/basic-rest/mixpanel/mixpanel';
import { followersType } from './commonData/common.data';
import AddToComparison from '../../../pages/comparison/components/AddToComparison';
import AddToCrm from './AddToCrm';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';

const DiscoveryInfluencerCard = ({ influencer, setIsProfileOpen, setInfluencerData }) => {
	const [isLiked, setIsLiked] = useState(false);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const profileOpened = (body) => {
		recordEvent('Influencer Report Opened', {
			influencerDetails: body,
		});
	};
	// console.log({ influencer });

	return (
		<Card className='cursor-pointer shadow-3d-primary shadow-3d-hover'>
			<CardBody>
				{influencer ? (
					<>
						<CardHeader className='bg-transparent'>
							<CardLabel>
								<CardTitle tag='h5' className=''>
									{influencer.profile?.fullname
										? influencer.profile.fullname
										: influencer.profile.username}
								</CardTitle>
								<CardSubTitle>
									<a
										href={profileUrl(
											influencer.profile.url,
											influencer.profile.username,
											influencer.userId,
											influencer.influencerType,
										)}
										target='_blank'
										rel='noreferrer'>
										@
										{influencer.profile?.username
											? influencer.profile.username
											: influencer.profile.fullname}
									</a>
								</CardSubTitle>
							</CardLabel>
							<CardActions>
								<Tooltips title='View report' placement='top'>
									<Popovers
										desc={
											<>
												1.5 <Icon icon='Coin' />
											</>
										}
										trigger='hover'>
										<Button
											icon='Info'
											size='lg'
											aria-label='View report'
											hoverShadow='default'
											shadow='lg'
											color={darkModeStatus ? 'dark' : null}
											onClick={() => {
												setInfluencerData({
													userId: influencer.userId,
													platform: influencer.influencerType,
												});
												// setUserId(influencer.userId);
												setIsProfileOpen(true);
												profileOpened({
													influencerId: influencer.userId,
													influencerusername: influencer.profile.username,
													platform: influencer.influencerType,
												});
											}}
										/>
									</Popovers>
								</Tooltips>
							</CardActions>
						</CardHeader>

						<div class='infulencer_details d-flex align-items-center'>
							<div class='p-2 flex-fill'>
								<Avatar
									srcSet={influencer.profile.picture}
									src={influencer.profile.picture}
									color='danger'
									size={80}
								/>
							</div>
							<div class='infulencer_stats p-2 flex-fill'>
								<div className='d-flex my-2 fw-bold align-items-center'>
									<Icon
										icon={cardIcon(influencer.influencerType)}
										className='icon mr-1'
									/>
									<span className='ms-2'>
										ER -{' '}
										{fixDecimal(influencer.profile.engagementRate * 100, 2)} %
									</span>
								</div>
								<div className='infulencer_stats d-flex my-2 fw-bold align-items-center'>
									<Icon icon='PeopleFill' className='icon mr-1' />
									<span className='ms-2'>
										{followersType[influencer.influencerType]} -{' '}
										{nFormatter(influencer.profile.followers, 1)}
									</span>
								</div>
							</div>
							<div class='p-2 flex-fill d-flex justify-content-center'>
								<AddToFavourite
									_id={influencer.influencer_id}
									influencerUserName={influencer.profile.username}
								/>
							</div>
						</div>
					</>
				) : (
					<div className='d-flex align-items-center'>
						<div className='col-md-3 d-flex flex-column align-items-center justify-content-center'>
							<CustomSkeleton
								count={1}
								isCircle
								style={{ height: '8rem', width: '8rem' }}
							/>
						</div>
						<div className='col-md-8 ms-3'>
							<CustomSkeleton count={1} style={{ width: '12rem' }} />
							<div className='d-flex my-2 fw-bold align-items-center w-100'>
								<CustomSkeleton count={2} style={{ width: '15rem' }} />
							</div>
							<div className='d-flex my-2 fw-bold align-items-center w-100'>
								<CustomSkeleton count={2} style={{ width: '15rem' }} />
							</div>
							<div className='row my-2 g-2'>
								{Array(3)
									.fill('2')
									.map((tag, i) => (
										// eslint-disable-next-line react/no-array-index-key
										<div key={`skeleton-${i}`} className='col-auto'>
											<CustomSkeleton count={1} style={{ width: '5rem' }} />
										</div>
									))}
							</div>
						</div>
					</div>
				)}
				{/* {influencer && (
					<div className='d-flex mt-3'>
						<Dropdown className='d-inline' direction='end'>
							<DropdownToggle hasIcon={false}>
								<Button
									color={darkModeStatus ? 'light' : 'danger'}
									isLight
									icon='MoreHoriz'
									aria-label='More Actions'>
									Actions
								</Button>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem>
									<div className='me-auto'>
										<AddToList
											influencerId={influencer.influencer_id}
											existingList={influencer.lists}
											influencerUserName={influencer.profile.username}
										/>
									</div>
								</DropdownItem>
								<DropdownItem>
									<div className='me-auto'>
										<AddToCrm influencer={influencer} />
									</div>
								</DropdownItem>
								<DropdownItem>
									<div className='me-auto'>
										<AddToAnalytics
											influencerId={influencer.influencer_id}
											influencerGlobalIndex={influencer.influencer_index_id}
											existingList={influencer.lists}
											influencerUserName={influencer.profile.username}
										/>
									</div>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				)} */}
			</CardBody>

			<CardFooter>
				{influencer ? (
					<>
						<CardFooterLeft>
							<div className='row'>
								<div className='col-auto'>
									<Dropdown className='d-inline' direction='end'>
										<DropdownToggle hasIcon={false}>
											<Button
												color={darkModeStatus ? 'light' : 'danger'}
												isLight
												icon='MoreHoriz'
												aria-label='More Actions'>
												Actions
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<AddToList
													influencerId={influencer?.influencer_id}
													existingList={influencer?.lists}
													influencerUserName={
														influencer?.profile?.username
													}
												/>
											</DropdownItem>

											<DropdownItem isDivider />
											<DropdownItem>
												<AddToAnalytics
													influencerId={influencer.influencer_id}
													influencerGlobalIndex={
														influencer.influencer_index_id
													}
													existingList={influencer.lists}
													influencerUserName={influencer.profile.username}
												/>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</div>
								<div className='col-auto'>
									<AddToCrm
										influencer={influencer}
										platform={influencer.influencerType}
									/>
								</div>
							</div>
						</CardFooterLeft>
						<CardFooterRight>
							{/* <div className='me-auto d-flex align-items-right'> */}
							<AddToComparison
								influencer={influencer}
								platform={influencer.influencerType}
							/>
							{/* </div> */}
						</CardFooterRight>
					</>
				) : null}
			</CardFooter>
		</Card>
	);
};

export default DiscoveryInfluencerCard;
