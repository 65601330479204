import { useFormik } from 'formik';
import React from 'react';
import Avatar from 'react-avatar';
import { useRemoveMember } from '../../../../../../framework/basic-rest/member_system/member/remove-workspace-member';
import { useShareToMember } from '../../../../../../framework/basic-rest/member_system/member/share_to_member';
import Button from '../../../../../bootstrap/Button';
import FormGroup from '../../../../../bootstrap/forms/FormGroup';
import Select from '../../../../../bootstrap/forms/Select';
import Option from '../../../../../bootstrap/Option';
import CustomDropdown from '../../../../Common/CustomDropdown';
import { CREDIT_TYPE, PERMISSIONS, ROLES } from '../../../utils/constData';
import CreditLimit from '../../ShareWorkspace/CreditLimit';
import Campaign from '../../ShareWorkspace/Campaign';
import Creator from '../../ShareWorkspace/Creator';
import List from '../../ShareWorkspace/List';
import SocialProject from '../../ShareWorkspace/SocialProject';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../../bootstrap/Card';

const PermissionModal = ({ name, ...rest }) => {
	const { mutate: removeMember } = useRemoveMember();

	const { mutate: shareToMember, isLoading: isSharing } = useShareToMember();

	const filtersForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: rest.email,
			role: ROLES[rest?.role]?.key,
			permission: PERMISSIONS.VIEW.key,
			discovery_permission: PERMISSIONS[rest?.discovery_permission]?.key,
			campaign_permission: PERMISSIONS[rest?.campaign_permission]?.key,
			creator_permission: PERMISSIONS[rest?.creator_permission]?.key,
			list_permission: PERMISSIONS[rest?.list_permission]?.key,
			socialproject_permission: PERMISSIONS[rest?.socialproject_permission]?.key,
			creditType: CREDIT_TYPE[rest?.creditDetail?.credit_type]?.key,
			credit: rest?.creditDetail?.avl_credit,
		},
		onSubmit: (values) => {
			console.log({ values });
			console.log({
				...values,
				permission: 'VIEW',
				discovery_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.discovery_permission,
				campaign_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.campaign_permission,
				creator_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.creator_permission,
				list_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.list_permission,
				socialproject_permission:
					values.role === ROLES?.GUEST?.key
						? PERMISSIONS.VIEW.key
						: values.socialproject_permission,
				credit: values.creditType === CREDIT_TYPE.UNLIMITED.key ? 0 : values.credit,
			});
			shareToMember(values);
		},
		onReset: (values) => {
			filtersForm.setValues({
				email: rest?.email,
				role: ROLES[rest?.role]?.key,
				permission: PERMISSIONS[rest?.permission]?.key,
				creditType: CREDIT_TYPE[rest?.creditDetail?.credit_type]?.key,
				credit: rest?.creditDetail?.avl_credit,
			});
		},
	});

	const onRemove = () => {
		removeMember({
			email: filtersForm.values.email,
		});
	};

	return (
		<div className='container'>
			<div className='row px-3'>
				<div className='col-md-12 ms-3'>
					<div className='d-flex'>
					
						<div className='col-md-1'>
							<Avatar name={name} round size={60} />
						</div>
						<div className='fs-1 fw-bold'>{name}</div>
						
					</div>

					<form className='row mt-3'>
						<div className="row">
						<div className='col-md-6'>
							<FormGroup id='role' label='Role'>
								<Select
									onChange={filtersForm.handleChange}
									value={filtersForm.values.role}
									onBlur={filtersForm.handleBlur}
									// isValid={filtersForm.isValid}
									isTouched={filtersForm.touched.role}
									invalidFeedback={filtersForm.errors.role}
									// validFeedback='Looks good!'
									ariaLabel='Select Role'
									placeholder='Select Role'>
									{ROLES &&
										Object.keys(ROLES).map((key) => (
											<Option value={ROLES[key].key}>
												{ROLES[key].text}
											</Option>
										))}
								</Select>
							</FormGroup>
						</div>
						</div>
						
						<div className='row mt-3'>
							{filtersForm.values.role !== ROLES?.GUEST?.key && (
								<CreditLimit filtersForm={filtersForm} />
							)}
						</div>
						<div className='row'>
							{filtersForm.values.role !== ROLES?.GUEST?.key && (
								<Campaign filtersForm={filtersForm} />
							)}
							{filtersForm.values.role !== ROLES?.GUEST?.key && (
								<Creator filtersForm={filtersForm} />
							)}
						
							{filtersForm.values.role !== ROLES?.GUEST?.key && (
								<List filtersForm={filtersForm} />
							)}
							{filtersForm.values.role !== ROLES?.GUEST?.key && (
								<SocialProject filtersForm={filtersForm} />
							)}
						</div>
					</form>
				</div>
			</div>
			<div className='w-100 text-end mt-1 px-4'><b>Last modified:</b> {rest.lastUpdated}</div>
			<div className='d-flex justify-content-between mt-2 px-4'>
				<Button isLight color='danger' icon='delete' onClick={onRemove}>
					Remove user
				</Button>
				<Button
					isLight
					color='primary'
					onClick={filtersForm.handleSubmit}
					icon='pencil-square'>
					Update
				</Button>
			</div>
		</div>
	);
};

export default PermissionModal;
