import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSaveSearchFilter } from '../../../../framework/basic-rest/discovery/savedHistory/save-search-filters';
import { ConfirmAlert } from '../../../../helpers/confirm-helper';
import Button from '../../../bootstrap/Button';
import SaveSearchModal from './SaveSearchModal';

const SaveFilters = ({ searchId }) => {
	const [isOpen, setIsOpen] = useState(false);

    const {
        mutate: saveFilters,
        isLoading: isFilterSaved
    } = useSaveSearchFilter();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: searchId,
			name: '',
            desc: ''
		},
		onSubmit: (values) => {
            ConfirmAlert(
                'Save Search Filter',
                "Do you really want to save the search filter",
                () =>{
                    saveFilters({ ... values});
                }
            )
		},
	});

	return (
		<>
			<SaveSearchModal formik={formik} isOpen={isOpen} setIsOpen={setIsOpen} />
			{!isFilterSaved && <Button
				icon='SavedSearch'
				// isLight
				color='danger'
				isOutline
				onClick={() => {
					setIsOpen(true);
				}}
			>Save Filters</Button>}
		</>
	);
};

export default SaveFilters;
