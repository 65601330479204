import { takeLatest, call, put, delay } from 'redux-saga/effects';
import moment from 'moment';

import { QUERY_BUILDER_REDUCER } from './reducers';
import {
	deleteUserQuery,
	fetchQueryRequestData,
	getCsvS3Url,
	getSegmentByCampaign,
	getSegmentCount,
	getUserPropertyFieldList,
} from './service';
import {
	getSegmentListByCampaignRequest,
	setDownlaodUrlLink,
	setFetchDataArray,
	setIsFetchDataLoaded,
	setIsQueryLoaded,
	setQueryCount,
	setRecentSegmentData,
	setSegmentListByCampaign,
	setSegmentListByUser,
	setUserPropertyList,
} from './action';

export function* fetchQueryRequestSaga(action) {
	try {
		const data = yield call(fetchQueryRequestData, action.data);
		yield put(setIsQueryLoaded(false));
		yield put(setFetchDataArray(data?.data?.data?.Items));
		yield put(setIsFetchDataLoaded(false));
	} catch (error) {}
}

export function* getUserPropertyListSaga(action) {
	try {
		const data = yield call(getUserPropertyFieldList);
		yield put(setUserPropertyList(data?.data?.data));
	} catch (error) {}
}

export function* deleteQuerySaga(action) {
	try {
		const data = yield call(deleteUserQuery, action.value);
		// if (data?.data?.message === 'Success') {
		// 	swal('Segment is successfully deleted!', '', 'success', {
		// 		buttons: false,
		// 	});
		// }
	} catch (error) {}
}

export function* getSegmentByCampaignSaga(action) {
	try {
		const data = yield call(getSegmentByCampaign, action.data);
		const reverseArray = data?.data?.data?.associatedSegments.reverse();
		yield put(setSegmentListByUser(reverseArray));
		const arrayData = reverseArray?.map((e) => {
			return {
				queryTime: moment().format('hh:mm A DD MMM YYYY'),
				source: 'Segmentation',
				count: e?.resultMetaData?.count,
				segmentId: e._id,
				queryData: e.countlyMatchObject,
				downlaodUrl: e.s3CsvUrl,
				dataUrl: e.s3DataUrl,
				userName: e.user.name,
				isDownloadUrl: 's3CsvUrl' in e,
			};
		});

		yield put(setSegmentListByCampaign(arrayData));
	} catch (error) {}
}

export function* downlaodDataUrlSaga(action) {
	try {
		const data = yield call(getCsvS3Url, action.data);
		if (Object.keys(data?.data?.data).length !== 0) {
			yield put(setDownlaodUrlLink(data?.data?.data?.csvUrl));
		} else {
			yield put(setDownlaodUrlLink(''));
		}
	} catch (error) {}
}

export function* getQueryResultRequest(action) {
	try {
		const data = yield call(getSegmentCount, action.data);
		let fetchQueryData;
		fetchQueryData.queryConditionsArray = data?.data?.data?.queryConditionsArray;
		fetchQueryData.userIds = data?.data?.data?.userIds;
		yield put(getSegmentListByCampaignRequest({ campaignId: action.data.campaignId }));
		const segmentIdData = { segmentId: data?.data?.data?.segmentId };
		const fetch = yield call(fetchQueryRequestData, segmentIdData);
		yield delay(3000);
		if (fetch?.data?.data?.showData) {
			const urlData = yield call(getCsvS3Url, segmentIdData);
			if (Object.keys(urlData?.data?.data).length !== 0) {
				yield put(setDownlaodUrlLink(urlData?.data?.data?.csvUrl));
			} else {
				yield put(setDownlaodUrlLink(''));
			}
		}

		yield put(setRecentSegmentData(fetchQueryData));
		yield put(setQueryCount(data?.data?.data));
		yield put(setIsQueryLoaded(false));
	} catch (error) {}
}

export default function* queryBuilderSaga() {
	yield takeLatest(QUERY_BUILDER_REDUCER.GET_QUERY_COUNT_REQUEST, getQueryResultRequest);
	yield takeLatest(QUERY_BUILDER_REDUCER.FETCH_QUERY_REQUEST, fetchQueryRequestSaga);
	yield takeLatest(QUERY_BUILDER_REDUCER.DOWNLOAD_FETCH_URL, downlaodDataUrlSaga);
	yield takeLatest(QUERY_BUILDER_REDUCER.GET_SEGMENT_LIST_BY_CAMPAIGN, getSegmentByCampaignSaga);
	yield takeLatest(QUERY_BUILDER_REDUCER.DELETE_SEGMENT_REQUEST, deleteQuerySaga);
	yield takeLatest(QUERY_BUILDER_REDUCER.USER_PROPERTY_LIST_REQUEST, getUserPropertyListSaga);
}
