import { takeLatest, call, put } from 'redux-saga/effects';
import { ANALYZE_REDUCER_CONSTANT } from './reducers';
import {
	getInfluencerCities,
	getInfluencerEventMatrix,
	getAllInfluencerEventMatrix,
	getEventDetailsByDevicePlatform,
	getCampaignAllEventMatrix,
} from './service';
import {
	setCitiesData,
	setCountriesData,
	setInfluencerEventData,
	setAllInfluencerEventData,
	setTotalInflueEventMatrix,
	setDeviceEventDetailsData,
	setTotalDeviceEventDetailsCount,
	setCampaignEventMatrix,
	setTotalEventCount,
} from './action';

export function* getInfluencerCitiesSaga(action) {
	try {
		const data = yield call(getInfluencerCities, action.value);
		const citiesArray = data?.data?.data.map((obj) => {
			return { value: obj.count, name: obj.city };
		});
		yield put(setCitiesData(citiesArray));
	} catch (error) {}
}

export function* getInfluencerCountriesSaga(action) {
	try {
		const data = yield call(getInfluencerCities, action.value);
		const countriesArray = data?.data?.data.map((obj) => {
			return { value: obj.count, name: obj.country };
		});
		yield put(setCountriesData(countriesArray));
	} catch (error) {}
}

export function* getInfluencerEventSaga(action) {
	try {
		const data = yield call(getInfluencerEventMatrix, action.value);

		yield put(setInfluencerEventData(data?.data?.data, action?.eventName));
	} catch (error) {}
}

export function* getAllInfluencerEventSaga(action) {
	try {
		const data = yield call(getAllInfluencerEventMatrix, action.value);
		let count = 0;
		yield put(setAllInfluencerEventData(data?.data?.data));
		data?.data?.data.forEach((element) => {
			count += element.count;
		});
		yield put(setTotalInflueEventMatrix(count));
	} catch (error) {}
}

export function* getDeviceEventDetailsSaga(action) {
	try {
		const data = yield call(getEventDetailsByDevicePlatform, action.value);
		const deviceArray = data?.data?.data.map((obj) => {
			return {
				value: obj.eventCount,
				name: obj.devicePlatform,
				list: obj.list,
			};
		});
		yield put(setDeviceEventDetailsData(deviceArray));
		// let count = 0;
		// data?.data?.data.forEach((element) => {
		// 	count += element.count;
		// });
		// yield put(setTotalDeviceEventDetailsCount(count));
	} catch (error) {}
}

export function* getCampaignEventMatrixSaga(action) {
	try {
		const data = yield call(getCampaignAllEventMatrix, action.data);
		let count = 0;

		yield put(setCampaignEventMatrix(data?.data?.data));
		data?.data?.data?.forEach((element) => {
			count += element.count;
		});

		yield put(setTotalEventCount(count));
	} catch (error) {}
}

export default function* analyzeSaga() {
	yield takeLatest(ANALYZE_REDUCER_CONSTANT.GET_CITIES_DATE_REQUEST, getInfluencerCitiesSaga);
	yield takeLatest(
		ANALYZE_REDUCER_CONSTANT.GET_COUNTRIES_DATE_REQUEST,
		getInfluencerCountriesSaga,
	);
	yield takeLatest(
		ANALYZE_REDUCER_CONSTANT.GET_INFLU_EVENT_MATRIX_REQUEST,
		getInfluencerEventSaga,
	);
	yield takeLatest(
		ANALYZE_REDUCER_CONSTANT.GET_ALL_INFLUENCER_EVENT_REQUEST,
		getAllInfluencerEventSaga,
	);
	yield takeLatest(
		ANALYZE_REDUCER_CONSTANT.GET_DEVICE_EVENT_DETAILS_REQUEST,
		getDeviceEventDetailsSaga,
	);
	yield takeLatest(
		ANALYZE_REDUCER_CONSTANT.GET_CAMPAIGN_ALL_EVENT_MATRIX_REQUEST,
		getCampaignEventMatrixSaga,
	);
}
