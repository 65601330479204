import React, { useState } from 'react';
import { nFormatter } from '../../../../../helpers/helpers';
import Card, { CardBody } from '../../../../bootstrap/Card';
import Chart from '../../../../extras/Chart';
import Popovers from '../../../../bootstrap/Popovers';

const ShowCardChart = ({ data, name, title, desc, className="col-md-6"}) => {
	const [monthlyData] = useState({
		series: [
			{
				name,
				data: data?.map((val) => val[`${name}`]),
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight',
			},
			title: {
				text: `${title} By Month `,
				align: 'left',
			},
			grid: {
				row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: data.map((val) => val.month),
			},
		},
	});

	return (
		<div className={className}>
			<Card stretch>
				<CardBody>
					<Chart
						series={monthlyData.series}
						options={monthlyData.options}
						type={monthlyData.options.chart.type}
						height={monthlyData.options.chart.height}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

export default ShowCardChart;
