import axios from 'axios';

export const API_HOST_URL = 'https://api.ovonts.com';
export const ACCEPT_TYPE = 'application/json, */*';
export const CONTENT_TYPE = 'application/json';

export const callApi = (path, method, data = {}, apiHeadres = {}, params = {}) => {
	const headers = {
		Accept: ACCEPT_TYPE,
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Credentials': true,
		Authorization:
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MjE0MDZjZDE0OTEzMjExMzYyNjRiNDciLCJlbWFpbCI6ImRldjEyM0BnbWFpbC5jb20iLCJpYXQiOjE2NTg1NTg1NDAsImV4cCI6MTY1ODY0NDk0MH0.3Jssl8yNeGu2OqjXA0IA-iabYhSBbfonlNhyvZuhBsU',
	};
	// if (localStorage.getItem('accessToken')) {
	// 	headers.Authorization = getAccessToken();
	// }
	return axios({
		method,
		url: `${API_HOST_URL}${path}`,
		data,
		params,
		headers,
	});
};

export const getAccessToken = () => {
	let accessToken = '';
	accessToken = localStorage.getItem('accessToken');
	return accessToken;
};

export const setAccessTokenFromLocal = (accessToken) => {
	if (accessToken !== null) localStorage.setItem('accessToken', accessToken);
};
