import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './auth.slice';

const authSlice = (state) => state.auth || initialState;

export const selectIsAuthenticated = createSelector([authSlice], (state) => state.isAuthenticated);

export const selectInboxConnected = createSelector(
	[authSlice],
	(state) => state.authUser?.outreachModeDetails?.outreachModeConnected,
);

export const selectAuthToken = createSelector([authSlice], (state) => state.authToken);

export const selectAuthUser = createSelector([authSlice], (state) => state.authUser);

export const selectIsExistingUser = createSelector([authSlice], (state) => state.isExistingUser);

export const selectSignupUserDetails = createSelector([authSlice], (state) => state.signupUser);

export const selectLoginDetails = createSelector([authSlice], (state) => state.loginDetails);
