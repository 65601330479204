import React, { useRef, useState, useEffect } from 'react';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';
import { checkArrayData } from '../../../../../helpers/helpers';
import useDebounce from '../../../../../hooks/useDebounce';
import {
	selectIsFilterApply,
	selectIsFilterClear,
} from '../../../../../store/App/Discovery/discovery.selector';

const MultiSelect = ({
	id,
	labelkey,
	minLength = 0,
	multiple = false,
	isStatic = false,
	staticOpt,
	className = '',
	style,
	onChange,
	defaultSelected,
	placeholder,
	platform,
	ChildrenComponent,
	usefetch = '',
	isQueryOption = false,
	defaultOpt,
}) => {
	const isFilterClear = useSelector(selectIsFilterClear);
	const isFilterApply = useSelector(selectIsFilterApply);

	const [query, setQuery] = useState('');
	const ref = useRef();

	const debouncedSearch = useDebounce({ text: query, platform }, 800);

	const { data: options, isLoading: optionsIsLoading } = usefetch && usefetch(debouncedSearch);

	const [isApply, setIsApply] = useState(true);

	useEffect(() => {
		if(ref && isFilterClear){   ref.current.clear(); }
	}, [isFilterClear]);

	useEffect(() => {
		setIsApply(false);
	}, [isFilterApply]);

	useEffect(()=>{
		if(!isApply){
			setIsApply(true);
		}
	},[isApply])

	// useEffect
	return (
		(isApply && <AsyncTypeahead
			className={className}
			filterBy={() => true}
			style={style}
			multiple={multiple}
			id={id}
			isLoading={optionsIsLoading}
			labelKey={labelkey}
			minLength={minLength}
			clearButton
			ref={ref}
			onSearch={(search) => setQuery(search)}
			onChange={onChange}
			defaultSelected={defaultSelected}
			// eslint-disable-next-line no-nested-ternary
			options={isStatic? staticOpt :(isQueryOption ? checkArrayData(options) || defaultOpt(query) : options)}
			placeholder={placeholder}
			useCache={false}
			renderMenuItemChildren={ChildrenComponent}
		/>)
	);
};

export default MultiSelect;
