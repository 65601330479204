import { createSlice } from '@reduxjs/toolkit';
import { getToken } from './helper/cookies';

export const initialState = {
	isAuthenticated: !!getToken(),
	authToken: null,
	authUser: null,
	signupUser: null,
	isExistingUser: false,
	loginDetails: {
		email: null,
		firstName: null,
	},
};

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		setAuthToken: (state, action) => {
			state.authToken = action.payload;
		},
		setAuthUser: (state, action) => {
			state.authUser = action.payload;
		},
		setSignupDetails: (state, action) => {
			state.signupUser = action.payload;
		},
		setExistingUser: (state, action) => {
			state.isExistingUser = action.payload;
		},
		setLoginDetails: (state, action) => {
			state.loginDetails = action.payload;
		},
	},
});

export const {
	setIsAuthenticated,
	setAuthToken,
	setAuthUser,
	setSignupDetails,
	setExistingUser,
	setLoginDetails,
} = slice.actions;

export default slice.reducer;
