import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useUI } from '../../../states/contexts/ui.context';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function analyticsProjectAddInfluencers(input) {
	const { data } = await http.post(`${API_ENDPOINTS.CREATE_TRACKING_POST}?projectId=${input.socialProjectId}`, input);
	return data;
}

const useAnalyticsAddInfluencersMutation = () => {
	const { setModalMeta, closeModal } = useUI();
	const { addToast } = useToasts();
	const dispatch = useDispatch();
	const cache = useQueryClient();

	return useMutation((input) => analyticsProjectAddInfluencers(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				// dispatch({ type: ANALYTICS_STORE_CONSTANTS.PROJECT_INFLUECNER_TOGGLER });
				cache.invalidateQueries(API_ENDPOINTS.GET_TRACKING_POST);
				setModalMeta(null);
				closeModal();
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
				recordEvent(res.message, {
					input,
					output: res,
				});
				
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(err?.response?.data?.errorMessage, {
				input,
				output: err?.response?.data,
			});
		},
	});
};
export default useAnalyticsAddInfluencersMutation;
