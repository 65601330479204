import classNames from 'classnames';
import React from 'react';
import { nFormatter } from '../../../../helpers/helpers';
import { CardHeader, CardLabel, CardTitle, CardActions } from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import UserCard from './report_Part_Components/UserNameCard';
import Popovers from '../../../bootstrap/Popovers';
import { followersType } from '../commonData/common.data';

const ProfileInfluencer = ({ report, darkModeStatus }) => {
	const checkValues = (value) => {
		if (value && value !== null) {
			return true;
		}
		return false;
	};

	return (
		<div className='row d-flex'>
			<div className='col-md-5 mt-5'>
				<UserCard
					userId={report?.userId}
					influencerType={report.influencerType} 
					url={report?.profile?.url}
					name={
						report?.profile?.fullname
							? report.profile.fullname
							: report.profile.username
					}
					position={
						report?.profile?.username
							? report.profile.username
							: report.profile.fullname
					}
					srcSet={report?.profile?.picture}
					color='info'
				/>
			</div>

			<div className='col-md-7 row g-4 align-items-center'>
				{checkValues(report.profile?.followers) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-warning': !darkModeStatus,
									'bg-lo25-warning': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='SupervisorAccount' size='3x' color='warning' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report.profile?.followers, 1)}
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>{followersType[report.influencerType]}</span>
								</div>
							</div>
							<CardActions>
								{report.influencerType === 'instagram' ? (
									<Popovers
										desc='How many total followers the influencer has on instagram.'
										trigger='hover'>
										<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
									</Popovers>
								) : (
									<Popovers
										desc='How many total followers the influencer has on youtube.'
										trigger='hover'>
										<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
									</Popovers>
								)}
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report.profile?.engagementRate) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-info': !darkModeStatus,
									'bg-lo25-info': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='AllInclusive' size='3x' color='info' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{(report.profile.engagementRate * 100).toFixed(2)}%
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>ENGAGEMENT RATE</span>
								</div>
							</div>
							<CardActions>
								{report.influencerType === 'instagram' ? (
									<Popovers
										desc="This is an indication of how engaged the influencer's audience is. It is calculated as likes, comments etc.÷ followers. The average Instagram Engagement Rate is 2%."
										trigger='hover'>
										<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
									</Popovers>
								) : (
									<Popovers
										desc="This is an indication of how engaged the influencer's audience is. It is calculated as likes, comments etc.÷ followers. The average Youtube Engagement Rate is 2%."
										trigger='hover'>
										<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
									</Popovers>
								)}
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report?.avgViews) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-primary': !darkModeStatus,
									'bg-lo25-primary': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='RemoveRedEye' size='3x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report?.avgViews, 1)}
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>AVG VIEWS</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='The average sum of views on the last 30 posts.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report?.profile.averageViews) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-primary': !darkModeStatus,
									'bg-lo25-primary': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='RemoveRedEye' size='3x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report?.profile.averageViews, 1)}
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>AVG VIEWS</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='The average sum of views on the last 30 posts.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report?.avgComments) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-success': !darkModeStatus,
									'bg-lo25-success': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='CommentBank' size='3x' color='success' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report?.avgComments, 1)}
								</div>
								<div className='text-muted mt-n2'>
									<span>AVG COMMENTS</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='The average sum of comments on the last 30 posts.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report?.avgLikes) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-success': !darkModeStatus,
									'bg-lo25-success': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='ThumbUpAlt' size='3x' color='info' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report?.avgLikes, 1)}
								</div>
								<div className='text-muted mt-n2'>
									<span>AVG LIKES</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='The average sum of likes on the last 30 posts.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
				{checkValues(report?.avgDislikes) && (
					<div className='col-xl-6'>
						<div
							className={classNames(
								'd-flex align-items-center overflow-auto rounded-2 p-3',
								{
									'bg-l10-success': !darkModeStatus,
									'bg-lo25-success': darkModeStatus,
								},
							)}>
							<div className='flex-shrink-0'>
								<Icon icon='ThumbDown' size='3x' color='danger' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{nFormatter(report?.avgDislikes, 1)}
								</div>
								<div className='text-muted mt-n2'>AVG DISLIKES</div>
							</div>
							<CardActions>
								<Popovers
									desc='The average sum of dislikes on the last 30 posts.'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfileInfluencer;
