import React, { Suspense, useContext, useEffect, useRef } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import ThemeContext from '../../states/contexts/themeContext';
import ManagedModal from '../../components/custom/modal/managed-modal';
import ManagedOffCanvas from '../../components/custom/offcanvas/managed-offcanvas';
import Portal from '../Portal/Portal';
import Wrapper from './Wrapper';
import AuthContent from '../Content/AuthRoutes';
import ContentRoutes from '../Content/ContentRoutes';
import { selectIsAuthenticated } from '../../store/App/Auth/auth.selector';
import { AUTH_STORE_CONSTANTS } from '../../store/constants.store';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';

const GlobalWrapper = () => {
	const dispatch = useDispatch();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);

	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	const isAuthorize = useSelector(selectIsAuthenticated);
	// const workspace = useSelector(selectWorkspace);

	useEffect(() => {
		dispatch({ type: AUTH_STORE_CONSTANTS.SYNC_USER_DETAILS });
		// if (isAuthorize) dispatch({ type: WORKSPACE_STORE_CONSTANTS.SYNC_WORKSPACE_DETAILS });
	}, [isAuthorize, dispatch]);

	const _loading = (
		<PageWrapper>
			<Page>
				<div className='row h-100'>
					<div className='col-lg-6'>
						<Card stretch>
							<CardBody>
								<div />
							</CardBody>
						</Card>
					</div>
					<div className='col-lg-6'>
						<Card stretch='semi'>
							<CardBody>
								<div />
							</CardBody>
						</Card>
						<Card stretch='semi'>
							<CardBody>
								<div />
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);

	return !isAuthorize ? (
		<Suspense fallback={_loading}>
			<AuthContent />
		</Suspense>
	) : (
		// <TourProvider
		// 	steps={steps}
		// 	styles={styles}
		// 	showNavigation={false}
		// 	showBadge={false}>
		<>
			<div
				ref={ref}
				className='app'
				style={{
					backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
					zIndex: fullScreenStatus && 1,
					overflow: fullScreenStatus && 'scroll',
				}}>
				<Suspense fallback={_loading}>
					<ContentRoutes />
				</Suspense>
			</div>
			<Portal id='portal-notification'>
				<ReactNotifications />
			</Portal>
			<ManagedOffCanvas />
			<ManagedModal />
		</>
		// </TourProvider>
	);
};

export default GlobalWrapper;
