import { put, takeLatest } from 'redux-saga/effects';
import { ORGANIZATION_CONSTANTS } from '../../constants.store';
import { setOrganization } from './organization.slice';

function* saveOrganization(action) {
	try {
		const organizationDetali = action.payload;
		yield put(setOrganization(organizationDetali));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* organizationSaga() {
	yield takeLatest(ORGANIZATION_CONSTANTS.GET_APP_ID, saveOrganization);
}
