import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import { analyzeReducers } from './analyseReducers';
// import { queryBuilderReducers } from './queryBuilderReducers';
import rootSaga from './root.saga';
// import reducers manually here
import campaignReducer from './App/Campaign/campaign.slice';
import authReducer from './App/Auth/auth.slice';
import workspaceReducer from './App/Workspace/workspace.slice';
import discoveryReducer from './App/Discovery/discovery.slice';
import listReducer from './App/List/list.slice';

import controlPanelReducer from './App/ControlPanel/controlPanel.slice';
import { queryBuilderReducers } from './App/QueryBuilder/queryBuilder.slice';
import { analyzeReducers } from './App/Analyze/analyze.slice';
import creditsReducer from './App/Credits/credits.slice';
import analyticsReducer from './App/SocialAnalytics/analytics.slice';
import socialTrackingReducer from './App/SocialAnalytics/Tracking/tracking.slice';
import socialProjectReducer from './App/SocialAnalytics/Project/project.slice';
import crmCreatorReducer from './App/crm/creators/crm.creators.slice';
import crmProjectReducer from './App/crm/projects/crm.projects.slice';
import crmCompanyReducer from './App/crm/companies/crm.companies.slice';
import crmNotesReducer from './App/crm/notes/crm.notes.slice';
import crmTasksReducer from './App/crm/tasks/crm-tasks-slice';
import crmMailsReducer from './App/crm/outreachMails/crm-mails-slice';
import conversionAnalyticsReducer from './App/ConversionAnalytics/conversionAnalytics.slice';
import creatorAssociation from './App/crm/utils/crm.utils.slice';
import cmsReducer from './App/cms/cms.slice';
import organizationReducer from './App/Organization/organization.slice';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
	auth: authReducer,
	campaign: campaignReducer,
	workspace: workspaceReducer,
	discovery: discoveryReducer,
	list: listReducer,
	queryBuilderReducers,
	analyzeReducers,
	credits: creditsReducer,
	controlPanel: controlPanelReducer,
	socialAnalytics: analyticsReducer,
	socialTracking: socialTrackingReducer,
	socialProject: socialProjectReducer,
	creators: crmCreatorReducer,
	projects: crmProjectReducer,
	companies: crmCompanyReducer,
	notes: crmNotesReducer,
	tasks: crmTasksReducer,
	mails: crmMailsReducer,
	conversionAnalytics: conversionAnalyticsReducer,
	creator_association: creatorAssociation,
	cms: cmsReducer,
	organizationReducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
});

export default store;

sagaMiddleware.run(rootSaga);
