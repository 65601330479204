import React from 'react'
import Card from '../../bootstrap/Card'
import CustomSkeleton from '../../CustomSkeleton'

const ProfileSkeleton = () => {
    return (
        <div>
            <div className='px-3 row'>
                <Card className="col-md-4 p-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className='col-md-7'>
                            <CustomSkeleton
                                count={3}
                                style={{ height: '1rem', width: '15rem' }}
                            />
                        </div>
                        <CustomSkeleton
                            count={1}
                            isCircle
                            style={{ height: '8rem', width: '8rem' }}
                        />
                    </div>
                </Card>
                <div className="col-md-8 row my-3">
                    <div className="col-md-4">
                        <CustomSkeleton
                            count={3}
                            style={{ height: '3rem', width: '100%' }}
                        />
                    </div>
                    <div className="col-md-4">
                        <CustomSkeleton
                            count={3}
                            style={{ height: '3rem', width: '100%' }}
                        />
                    </div>
                    <div className="col-md-4">
                        <CustomSkeleton
                            count={3}
                            style={{ height: '3rem', width: '100%' }}
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div className='overflow-auto px-5 w-100'>
                <div className="d-flex flex-wrap justify-content-around w-100">
                    {
                        Array(3)
                            .fill('0')
                            .map((item, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <CustomSkeleton
                                    key={`Card-${item}`}
                                    count={3}
                                    style={{ height: '25rem', width: '23rem', margin: "6px" }}
                                />
                            ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ProfileSkeleton