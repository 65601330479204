import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ThemeContextProvider } from './states/contexts/themeContext';
import store from './store/store';
import setupInterceptors from './framework/basic-rest/utils/setupInterceptors';

const children = (
	<React.StrictMode>
		<Provider store={store}>
			<ThemeContextProvider>
				<Router>
					<App />
				</Router>
			</ThemeContextProvider>
		</Provider>
	</React.StrictMode>
);

const container = document.getElementById('root');

setupInterceptors(store);

// ReactDOM.render(children, container); // For React 17
createRoot(container).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
