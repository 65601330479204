import { useContext } from 'react';
import ThemeContext from '../states/contexts/themeContext';

export default function useOnClickMode() {
	const { OnClickStatus, setOnClickStatus } = useContext(ThemeContext);

	const themeStatus = OnClickStatus ? 'danger' : 'light';

	return { themeStatus, OnClickStatus, setOnClickStatus };
}
