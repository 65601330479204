import React from 'react';
// import { homeMenu, demoPages, layoutMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	// { path: demoPages.login.path, element: null, exact: true },
	// { path: demoPages.signUp.path, element: null, exact: true },
	// { path: demoPages.page404.path, element: null, exact: true },
	// { path: homeMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DashboardHeader />,
	},
];

export default headers;
