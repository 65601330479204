import React from 'react'
import { CardBody } from '../../bootstrap/Card'

const NotesCard = ({influencer}) => {

    return (
        <CardBody>
            Notes
        </CardBody>
    )
}

export default NotesCard