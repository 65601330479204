import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
// import recordEvent from '../../mixpanel/mixpanel';
import { dangerToast, successToast } from '../../../../helpers/toast-helper';
import API_ENDPOINTS, { API_ENDPOINTS_CONTROL_PANEL } from '../../utils/api-endpoints';
import http from '../../utils/http';
import { WORKSPACE_STORE_CONSTANTS } from '../../../../store/constants.store';

const RemoveMember = (input) => {
    const { data } = http.delete(API_ENDPOINTS_CONTROL_PANEL.MEMBER.REMOVE_MEMBER_WORKSPACE, {
      data: input
    });
    return data;
};

export const useRemoveMember = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const onSuccess = (data, input) => {
        // queryClient.invalidateQueries([API_ENDPOINTS.MEMBER.GET_WORKSPACE_MEMBER]);
        addToast(
            successToast({
                message: 'Remove Successfully',
            }),
            {
                autoDismiss: true,
            },
        );

        dispatch({
            type: WORKSPACE_STORE_CONSTANTS.SYNC_TEAM_MEMBER,
            payload: `removeTeam/${new Date()}`
        })
    };

    const onError = (err, input) => {
        addToast(dangerToast({ message: `Don't Have Access` }), {
            autoDismiss: true,
        });
    };

    return useMutation((input) => RemoveMember(input), {
        onSuccess,
        onError,
    });
};
