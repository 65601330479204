import { useToasts } from 'react-toast-notifications';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useUI } from '../../../states/contexts/ui.context';
import http from '../utils/http';
import API_ENDPOINTS from '../utils/api-endpoints';
import { dangerToast, successToast } from '../../../helpers/toast-helper';
import { ANALYTICS_STORE_CONSTANTS } from '../../../store/constants.store';
import recordEvent from '../mixpanel/mixpanel';

async function analyticsProjectAddInfluencers(input) {
	const { data } = await http.post(`${API_ENDPOINTS.ADD_TO_PROJECT}?projectId=${input.projects[0]}`, input);
	return data;
}

const useAnalyticsInfluencersMutation = () => {
	const { setModalMeta, closeModal } = useUI();
	const { addToast } = useToasts();
	const cache = useQueryClient();

	return useMutation((input) => analyticsProjectAddInfluencers(input), {
		onSuccess: (res, input) => {
			if (res.success) {
				const resp = res.data;
				cache.invalidateQueries(API_ENDPOINTS.GET_PROJECT_INFLUENCERS);
				cache.invalidateQueries(API_ENDPOINTS.GET_ANALYTICS_PROJECTS);
				setModalMeta(null);
				closeModal();
				addToast(successToast({ message: res.message }), {
					autoDismiss: true,
				});
				recordEvent(`Influencer Added to Project(Search)`, {
					influencerandProjectDetails:resp
				});
			} else {
				addToast(dangerToast({ message: res.data.data.errorMessage }), {
					autoDismiss: true,
				});
				recordEvent(res.data.data.errorMessage, {
					input,
					output:res.data
				});
			}
		},
		onError: (err, input) => {
			addToast(dangerToast({ message: err?.response?.data?.errorMessage }), {
				autoDismiss: true,
			});
			recordEvent(`Failed to add Influencer to Project(Search)`, {
				input,
				output:err?.response?.data
			});
		},
	});
};
export default useAnalyticsInfluencersMutation;
