import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	organization: {},
};

const slice = createSlice({
	name: 'organization',
	initialState,
	reducers: {
		setOrganization: (state, action) => {
			state.organization = action.payload;
		},
	},
});

export const { setOrganization } = slice.actions;
export default slice.reducer;
