import React from 'react';
import Select from 'react-select';
import ClearIndicator from './ClearIndicator';

const MultiSelectOption = (props) => {
	const { optionList, onChange, onFocus, className, value, disabled, defaultValue } = props;

	const ClearIndicatorStyles = (base, state) => ({
		...base,
		cursor: 'pointer',
		color: state.isFocused ? 'blue' : 'black',
		// eslint-disable-next-line no-nested-ternary
		backgroundColor: state.isFocused ? '#f5f2f2' : state.isSelected ? '#f9e3cb' : null,
	});

	return (
		<Select
		    
			className={className}
			closeMenuOnSelect={false}
			components={<ClearIndicator props={props} />}
			styles={{ clearIndicator: ClearIndicatorStyles }}
			//   defaultValue={[colourOptions[4], colourOptions[5]]}
			isMulti
			value={value}
			
			options={optionList}
			onChange={onChange}
			onFocus={onFocus}
			isDisabled={disabled}
		/>
	);
};

export default MultiSelectOption;
