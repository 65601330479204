import React, { useEffect, useState } from 'react';
// import Accordion, { AccordionItem } from '../../components/bootstrap/Accordion';
import Accordion, { AccordionItem } from '../../../bootstrap/Accordion';

const YouTubeFilter = () => {
	const followersFrom = [
		'1,000',
		'3,000',
		'5,000',
		'10,0000',
		'15,000',
		'25,000',
		'35,000',
		'50,000',
		'75,000',
		'100,000',
		'175,000',
		'250,000',
		'350,000',
		'500,000',
		'1,000,000',
		'2,000,000',
	];
	const followersTo = [
		'3,000',
		'5,000',
		'10,0000',
		'15,000',
		'25,000',
		'35,000',
		'50,000',
		'75,000',
		'100,000',
		'175,000',
		'250,000',
		'350,000',
		'500,000',
		'1,000,000',
		'2,000,000',
	];

	const Interest = [
		'Music',
		'Television & Flim',
		'Shopping & Retail',
		'Coffee,Tea & Beverages',
		'Camera & Photography',
		'Cloths,Shoes,Handbags & Accessories',
		'Beer,Wine & Spirits',
		'Sports',
		'Electronics & Computers',
		'Gaming',
		'Activewear',
		'Art & Design',
		'Travel,Tourism & Aviation',
		'Business & Careers',
		'Beauty & Cosmetics',
		'HealthCare & Medicine',
		'Jewellery & Watches',
		'Restaurants,Food & Grocery',
		'Toys,Children & Baby',
		'Fiteness & Yoga',
		'Wedding',
		'Tabbacco & Smoking',
		'Pets',
		'Healthy Lifestyle',
		'Luxury Goods',
		'Home Decor, Furniture & Garden',
		'Friends,Family & Relationship',
		'Cars & Motorbikes',
	];

	const fakeFollowers = ['<=25%', '<=35%', '<=50%'];

	const age = ['13-17', '18-24', '25-34', '35-44', '45+'];

	const [interest, setInterest] = useState([]);
	const [ageFilter, setAgeFilter] = useState([]);

	const addAndRemoveInterest = (value) => {
		if (interest.includes(value)) {
			const updateArray = interest.filter((ele) => {
				return ele !== value;
			});
			setInterest(updateArray);
		} else {
			setInterest([...interest, value]);
		}
	};

	const addAndRemoveAge = (value) => {
		if (ageFilter.includes(value)) {
			const updateArray = ageFilter.filter((ele) => {
				return ele !== value;
			});
			setAgeFilter(updateArray);
		} else {
			setAgeFilter([...ageFilter, value]);
		}
	};

	return (
		<div className='row'>
			<div className='col-md-4 p-4'>
				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Influencer Location'>
						<div>Youtube</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Followers'>
						<div className='d-flex justify-content-evenly'>
							<select placeholder='form'>
								<option>From</option>
								{followersFrom.map((follow) => {
									return (
										<option key={follow} value={follow}>
											{follow}
										</option>
									);
								})}
							</select>
							<select name='' id=''>
								<option>To</option>
								{followersTo.map((follow) => {
									return (
										<option key={follow} value={follow}>
											{follow}
										</option>
									);
								})}
							</select>
						</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Gender'>
						<select>
							<option>Male</option>
							<option>FeMale</option>
						</select>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='' title='Interest'>
						<div className=''>
							{Interest.map((ele) => {
								return (
									<div className='form-check' key={ele}>
										<input
											onClick={() => addAndRemoveInterest(ele)}
											className='form-check-input'
											type='checkbox'
											value={ele}
											id='flexCheckDefault'
										/>
										<label
											className='form-check-label'
											htmlFor='flexCheckDefault'>
											{ele}
										</label>
									</div>
								);
							})}
						</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Language'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Engagement rate'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Contact Information'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>
			</div>

			<div className='col-md-4 p-4'>
				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Growth Rate'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Bio'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Account type'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Topics'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Hashtags'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Mentions'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>

				<Accordion id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Keywords'>
						<div>one</div>
						<div>one</div>
						<div>one</div>
					</AccordionItem>
				</Accordion>
			</div>

			<div className='col-md-4 p-4'>
				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Gender'>
						<select>
							<option>Male</option>
							<option>FeMale</option>
						</select>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='Age'>
						<div className=''>
							{ageFilter.map((ele) => {
								return `${ele},`;
							})}
						</div>
						{age.map((ele) => {
							return (
								<div className='form-check' key={ele}>
									<input
										onClick={() => addAndRemoveAge(ele)}
										className='form-check-input'
										type='checkbox'
										value={ele}
										id='flexCheckDefault'
									/>
									<label className='form-check-label' htmlFor='flexCheckDefault'>
										{ele}
									</label>
								</div>
							);
						})}
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='ehfu' title='fakeFollowers'>
						<select>
							{fakeFollowers.map((fake) => {
								return <option key={fake}>{fake}</option>;
							})}
						</select>
					</AccordionItem>
				</Accordion>

				<Accordion className='my-2' id='smallselect4' activeItemId={false} color='danger'>
					<AccordionItem id='' title='Interest'>
						<div className=''>
							{interest.map((ele) => {
								return `${ele},`;
							})}
						</div>
						{Interest.map((ele) => {
							return (
								<div className='form-check' key={ele}>
									<input
										onClick={() => addAndRemoveInterest(ele)}
										className='form-check-input'
										type='checkbox'
										value={ele}
										id='flexCheckDefault'
									/>
									<label className='form-check-label' htmlFor='flexCheckDefault'>
										{ele}
									</label>
								</div>
							);
						})}
					</AccordionItem>
				</Accordion>
			</div>
		</div>
	);
};

export default YouTubeFilter;
