import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './cms.slice';

const cmsSlice = (state) => state.cms || initialState;

export const selectContents = createSelector([cmsSlice], (state) => state.content);
export const selectSearch = createSelector([cmsSlice], (state) => state.search);
export const selectHashtags = createSelector([cmsSlice], (state) => state.hashtags);
export const selectTypes = createSelector([cmsSlice], (state) => state.types);
export const selectStatus = createSelector([cmsSlice], (state) => state.status);
