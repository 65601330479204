import { useQueryClient, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import API_ENDPOINTS from '../utils/api-endpoints';
import http from '../utils/http';
import { AUTH_STORE_CONSTANTS } from '../../../store/constants.store';
import { dangerToast } from '../../../helpers/toast-helper';

const update = async (payload) => {
	const { data } = await http.post(`${API_ENDPOINTS.UPDATE_USER}/whatsAppDetail`, payload);
	return data.data;
};

const useUpdateUserWhatsApDetailMutation = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const { addToast } = useToasts();

	return useMutation({
		mutationFn: update,
		onSuccess: (data) => {
			dispatch({
				type: AUTH_STORE_CONSTANTS.SYNC_TOKEN,
				payload: {
					token: data.token,
				},
			});
		},
		onError: (res) => {
			console.log(res);
			addToast(dangerToast({ message: 'Number ID already exist' }), { autoDismiss: true });
		},
	});
};

export default useUpdateUserWhatsApDetailMutation;
