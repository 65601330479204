import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './project.slice';

const socialProjectSlice = (state) => state.socialProject || initialState;

export const selectCurrentProjectId = createSelector(
	[socialProjectSlice],
	(state) => state.currentProject?._id,
);

export const selectCurrentProjectName = createSelector(
	[socialProjectSlice],
	(state) => state.currentProject?.projectName,
);

export const selectProjects = createSelector([socialProjectSlice], (state) => state.projects);

export const selectProject = createSelector([socialProjectSlice], (state) => state.currentProject);

export const selectProjectAnalytics = createSelector(
	[socialProjectSlice],
	(state) => state.analytics,
);

export const selectProjectContent = createSelector(
	[socialProjectSlice],
	(state) => state.contentStreams,
);

export const selectProjectStats = createSelector([socialProjectSlice], (state) => state.stats);
