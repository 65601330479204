const validate = (values) => {
	const errors = {};

	if (!values.lower_price) {
		errors.lower_price = 'Required';
	}
	if (!values.upper_price) {
		errors.upper_price = 'Required';
	}

	if (Number(values.upper_price) < Number(values.lower_price)) {
		errors.lower_price = 'Upper price must be greater than lower price';
	}
	// } else if (values.upper_price < values.lower_price) {
	// 	errors.upper_price = 'Upper price must be greater than lower price';
	// }

	return errors;
};

export default validate;
