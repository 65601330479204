import { put, takeLatest, select } from 'redux-saga/effects';
import { CMS_STORE_CONSTANTS } from '../../constants.store';
import { selectHashtags, selectStatus, selectTypes } from './cms.selector';
import { setContents, setHashtags, setSearch, setStatus, setTypes } from './cms.slice';
import { setCheckedParameters } from './utils/setParameters';

function* setCMSContents(action) {
	try {
		const contents = action.payload;
		yield put(setContents(contents));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCMSSearch(action) {
	try {
		const { search } = action.payload;
		yield put(setSearch(search));
		return true;
	} catch (error) {
		return false;
	}
}
function* setCMSTypes(action) {
	try {
		const { types, checked } = action.payload;
		const currTypes = yield select(selectTypes);
		yield put(setTypes(setCheckedParameters(currTypes, types, checked)));

		return true;
	} catch (error) {
		return false;
	}
}

function* setCMSHashtags(action) {
	try {
		const { types, checked } = action.payload;
		const currHashtags = yield select(selectHashtags);
		yield put(setHashtags(setCheckedParameters(currHashtags, types, checked)));
		return true;
	} catch (error) {
		return false;
	}
}

function* setCMSStatus(action) {
	try {
		const { types, checked } = action.payload;
		const currStatus = yield select(selectStatus);
		yield put(setStatus(setCheckedParameters(currStatus, types, checked)));
		return true;
	} catch (error) {
		return false;
	}
}

export default function* cmsSaga() {
	yield takeLatest(CMS_STORE_CONSTANTS.SET_CMS_CONTENT, setCMSContents);
	yield takeLatest(CMS_STORE_CONSTANTS.SET_CMS_SEARCH, setCMSSearch);
	yield takeLatest(CMS_STORE_CONSTANTS.SET_CMS_HASHTAGS, setCMSHashtags);
	yield takeLatest(CMS_STORE_CONSTANTS.SET_CMS_TYPES, setCMSTypes);
	yield takeLatest(CMS_STORE_CONSTANTS.SET_CMS_STATUS, setCMSStatus);
}
