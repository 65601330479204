import React from 'react';
import PostCard from './report_Part_Components/PostCard';

const InfluencerPost = ({ report }) => {
	const checkData = (value) => {
		if (value && value.length) {
			return true;
		}
		return false;
	};

	return (
		<div className='row px-5 scroll-margin' id='content'>
			{/* Popular Post */}
			{checkData(report?.popularPosts) && (
				<>
					<div className='h3 mx-5 w-75 fw-bold'>Popular Post : </div>
					<div className='row'>
						{report?.popularPosts.map((post) => {
							return <PostCard key={post.id} post={post} />;
						})}
					</div>
				</>
			)}
			{/* Recent Post */}
			{/* {checkData(report?.recentPosts) && <>
                <div className='h3 mx-5 w-75 fw-bold'>Recent Post : </div>
                <div className='row mt-2'>
                    {report?.recentPosts.map((post) => {
                        return <PostCard key={post.id} post={post} />;
                    })}
                </div>
            </>} */}
			{/* Sponsored Post  */}
			{checkData(report?.sponsoredPosts) && (
				<>
					<div className='h3 mx-5 w-75 fw-bold'>Sponsored-Post : </div>
					<div className='row'>
						{report?.sponsoredPosts.map((post) => {
							return <PostCard key={post.id} post={post} />;
						})}
					</div>
				</>
			)}
		</div>
	);
};

export default InfluencerPost;
