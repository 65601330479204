import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useSharedPermission } from '../../../../framework/basic-rest/control_panel/get-modules-permission';
import { useShareModule } from '../../../../framework/basic-rest/control_panel/share-module-permission';
import Button from '../../../bootstrap/Button';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import CustomButtonGroup from '../components/CustomButtonGroup';
// import { ACCESS_TYPE, PERMISSION } from './ControlData';

const ModuleShare = ({
    setIsOpen,
    isOpen,
    moduleName,
}) => {
    const ACCESS_TYPE = [{
        _id: "FULL",
        type: "FULL",
        permission: "Full"
    },
    {
        _id: "NULL",
        type: "NULL",
        permission: "No Access"
    }]
    const [moduleShare, setModuleShare] = useState({
        email: "",
        permission: null,
        accessType: "NULL",
        modules: {
            moduleType: moduleName.toUpperCase(),
            permission: null,
            accessType: "NULL",
            items: []
        }
    });

    const {
        mutate: shareModule
    } = useShareModule()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            permission: null,
            // accessType: "NULL",
            modules: {
                moduleType: moduleName.toUpperCase(),
                permission: null,
                accessType: "NULL",
                items: []
            }
        },
        onSubmit: (values) => {
            shareModule({body : values})
            setIsOpen(false);
        },
    })

	const [permissionOf, setPermissionOf] = useState({ type: 'Module', name: moduleName });

	const {
		data: modulePermission,
		refetch: refetchPermission,
		isLoading: isPermissionLoading,
	} = useSharedPermission(permissionOf);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            size='xl'
            titleId='add-new-card'
            isCentered
            isStaticBackdrop
        >
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle>Sharing Module</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                    <div className='w-100 rounded-2 bg-l10-info py-3 px-5 my-3'>
                        <div className='row'>
                            <div className='col-md-2 d-flex justify-content-start h5 fw-bold align-items-center'>
                                {moduleName}
                            </div>
                            <div className="col-md-4">
                                <Input type="email" value={formik.values.email} placeholder="Email" name="email" id="email" onChange={formik.handleChange} />
                            </div>
                            <div className="col-md-6 d-flex justify-content-around align-items-center">
                                {!isPermissionLoading && <CustomButtonGroup
                                    key="permission"
                                    buttonArr={modulePermission}
                                    activeTab={formik.values.modules.permission}
                                    isDisabled={formik.values.modules.accessType !== "FULL"}
                                    onClickTab={(e) => {
                                        formik.setFieldValue(
                                            'modules.permission',
                                            e.target.id
                                        )
                                    }}
                                />}
                                <CustomButtonGroup
                                    key="accesstype"
                                    buttonArr={ACCESS_TYPE}
                                    activeTab={formik.values.modules.accessType}
                                    onClickTab={(e) => {
                                        formik.setFieldValue(
                                            'modules.accessType',
                                            e.target.id
                                        )
                                        if (e.target.id !== "FULL") {
                                            formik.setFieldValue(
                                                'modules.permission',
                                                null
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button type="submit" className="mx-3" isLight color="info">
                            Submit
                        </Button>
                        <Button type="reset" className="mx-3" isLight color="info">
                            Reset
                        </Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default ModuleShare
