import classNames from 'classnames';
import React from 'react';

import { checkArrayData } from '../../../../helpers/helpers';
import { CardHeader, CardLabel, CardTitle, CardActions } from '../../../bootstrap/Card';
import Icon from '../../../icon/Icon';
import AudienceDataChart from './report_Part_Components/AudienceDataChart';
import AudienceStat from './report_Part_Components/AudienceStat';
import CardBarChart from './report_Part_Components/CardBarChart';
import ContactsData from './report_Part_Components/ContactsData';
import GenderPieChar from './report_Part_Components/GenderPieChar';
import Popovers from '../../../bootstrap/Popovers';

const AudienceData = ({ report, darkModeStatus, isDownloading }) => {
	const checkData = (value) => {
		if (value && value.length) {
			return true;
		}
		return false;
	};
	return (
		<div className='row px-5 scroll-margin' id='audience'>
			{/* Audience data by followers */}
			<div className='d-flex justify-content-center'>
				<CardHeader>
					<CardLabel>
						<CardTitle>
							<span>Audience Data by Followers</span>
						</CardTitle>
					</CardLabel>
				</CardHeader>
			</div>
			<hr />
			<div className='row'>
				{/* Instagram Audience Credibility  */}
				{report.audience.credibility && (
					<div className='col-md-4'>
						<div
							className={classNames('d-flex align-items-center rounded-2 p-3', {
								'bg-l10-primary': !darkModeStatus,
								'bg-lo25-primary': darkModeStatus,
							})}>
							<div className='flex-shrink-0'>
								<Icon icon='SupervisorAccount' size='4x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{(
										Math.round(report.audience.credibility * 100 * 10) / 10
									).toFixed(1)}
									%
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									<span>Audience Credibility</span>
								</div>
							</div>
							<CardActions>
								<Popovers
									desc='Does this influencer have a real audience? Less than 75% is typically a sign of fraud'
									trigger='hover'>
									<Icon icon='Info' size='2x' style={{ marginLeft: '3px' }} />
								</Popovers>
							</CardActions>
						</div>
					</div>
				)}
				{/* YouTube 		Notable subscribers  */}
				{report?.audience?.notable && (
					<div className='col-md-4'>
						<div
							className={classNames('d-flex align-items-center rounded-2 p-3', {
								'bg-l10-primary': !darkModeStatus,
								'bg-lo25-primary': darkModeStatus,
							})}>
							<div className='flex-shrink-0'>
								<Icon icon='SupervisorAccount' size='4x' color='primary' />
							</div>
							<div className='flex-grow-1 ms-3'>
								<div className='fw-bold fs-3 mb-0'>
									{(Math.round(report.audience.notable * 100 * 10) / 10).toFixed(
										1,
									)}
									%
								</div>
								<div className='text-muted mt-n2 truncate-line-1'>
									Notable{' '}
									{report.influencerType === 'youtube'
										? 'subscribers'
										: 'followers'}
								</div>
							</div>
						</div>
					</div>
				)}
				{/* TikTok       	Notable followers   */}
				{/* {report?.audience?.notable && <div className='col-md-4'>
                    <div
                        className={classNames('d-flex align-items-center rounded-2 p-3', {
                            'bg-l10-primary': !darkModeStatus,
                            'bg-lo25-primary': darkModeStatus,
                        })}>
                        <div className='flex-shrink-0'>
                            <Icon icon='SupervisorAccount' size='4x' color='primary' />
                        </div>
                        <div className='flex-grow-1 ms-3'>
                            <div className='fw-bold fs-3 mb-0'>
                                {(
                                    Math.round(report.audience.notable * 100 * 10) / 10
                                ).toFixed(1)}
                                %
                            </div>
                            <div className='text-muted mt-n2 truncate-line-1'>
                                Notable followers
                            </div>
                        </div>
                    </div>
                </div>} */}
				{/* Location by country */}

				<div className='my-3' />

				{checkData(report?.audience?.geoCountries) && (
					<AudienceDataChart
						data={report?.audience?.geoCountries}
						title='Audience Location by Country'
						desc="This indicates the countries the influencer's audience is located in."
					/>
				)}

				{/* Location by city */}
				{checkData(report?.audience?.geoCities) && (
					<AudienceDataChart
						data={report?.audience?.geoCities}
						title='Audience Location by City'
						desc="This indicates the cities the influencer's audience is located in."
					/>
				)}

				{/* Age Split */}
				{checkData(report?.audience?.ages) && (
					<AudienceDataChart
						data={report?.audience?.ages}
						title='Audience Age Split'
						desc='A breakdown of the age an influencer reaches.'
					/>
				)}

				{/* Age and gender split */}
				{report?.audience && (
					<CardBarChart
						className={isDownloading ? 'col-md-6' : 'col-md-10'}
						data={report?.audience}
						title='Audience Age and Gender Split'
						desc='A breakdown of the age and gender an influencer reaches.'
					/>
				)}
				{/* Gender split */}
				{checkData(report?.audience?.genders) && (
					<GenderPieChar
						data={report?.audience?.genders}
						title='Gender Split'
						height='200rem'
						desc='What gender categories does the influencer reach?'
					/>
				)}

				{/* Languages */}
				{checkArrayData(report?.audience?.languages) && (
					<AudienceStat
						isDownloading={isDownloading}
						data={report?.audience?.languages}
						title='Languages of Audience'
						desc='Different languages of audience spoken all over the world.'
					/>
				)}

				{/*  Languages */}
				{checkArrayData(report?.audienceCommenters?.languages) && (
					<AudienceStat
						isDownloading={isDownloading}
						data={report.audienceCommenters.languages}
						title='Languages of Audience Commenters'
						desc='Different languages of audience commenters spoken all over the world.'
					/>
				)}

				{/* Brand Affinity */}
				{checkArrayData(report?.audience?.brandAffinity) && (
					<AudienceStat
						isDownloading={isDownloading}
						data={report?.audience?.brandAffinity}
						title='Brand Affinity'
						desc='Brand Affinity shows which brands the audience frequently interacts.'
					/>
				)}

				{/* Interests */}
				{checkArrayData(report?.audience?.interests) && (
					<AudienceStat
						isDownloading={isDownloading}
						data={report?.audience?.interests}
						title='Interests of Audience'
						desc='These are the topics the audience posts about and interacts with the most often.'
					/>
				)}

				{/* contact Details */}
				{/* {checkArrayData(report?.contacts) && <ContactsData data={report.contacts} />} */}
			</div>
		</div>
	);
};

export default AudienceData;
